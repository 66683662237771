import { Col, Row, Card, List } from "antd";
import { useHistory } from "react-router-dom";
import DS_ICON from "../../../assets/img/icon/DS.svg";

const datatest = [
  {
    key: 1,
    text: "รายงานสรุปยอดรวมผู้ที่ติดต่อเข้ามาแยกตามประเภท Requirement (หน่วย : เคส)",
    path: "requirement",
  },
  {
    key: 2,
    text: "รายงานสรุปยอด Qualify Lead (หน่วย : เคส)",
    path: "qualifylead",
  },
  {
    key: 3,
    text: "รายงานสรุปยอดรวมผู้ที่ติดต่อเข้ามาแยกตามช่องทางการติดต่อ และ ประเภทผู้ติดต่อ (หน่วย : คน)",
    path: "contact",
  },
  {
    key: 4,
    text: "รายงานสรุปยอดรวมผู้ติดต่อเข้ามาแยกตามประเภทผู้ติดต่อจากช่องทางการติดต่อต่างๆ",
    path: "contacttype",
  },
  {
    key: 5,
    text: "รายงานสรุปยอดรวมสินค้าที่ลูกค้าสนใจแยกตามประเภทสินค้า",
    path: "products",
  },
  {
    key: 6,
    text: "รายงานสรุปยอด Forecast เป้าการขายในโปรเจคที่ได้รับมอบหมาย แยกตามรายชื่อ AE และ รายชื่อแต่ละโปรเจค",
    path: "forecast",
  },
  {
    key: 7,
    text: "รายงานสรุปข้อมูลการดําเนินการให้บริการลูกค้าใหม่ New Inquiry",
    path: "newinquiry",
  },
];

const Report = () => {
  const history = useHistory();
  return (
    <Row className="h-100 min-vh-100">
      <Col
        lg={24}
        className="d-flex justify-content-center align-items-center w-100 mt-2"
      >
        <Col lg={24} md={24}>
          <Card
            className="setting-cover-card mt-3"
            cover={
              <>
                <h1 className="mb-0">
                  <span
                    style={{
                      fontFamily: "Bold",
                      color: "#faa41a",
                      fontSize: "23px",
                    }}
                  >
                    REPORT
                  </span>
                  <span
                    className="ml-2"
                    style={{ fontFamily: "Bold", fontSize: "23px" }}
                  >
                    สรุปรายงาน
                  </span>
                </h1>
              </>
            }
          >
            <Row>
              <Col span={24}>
                <List
                  size="large"
                  bordered
                  dataSource={datatest}
                  renderItem={(item) => (
                    <List.Item
                      key={item.key}
                      style={{
                        height: "70px",
                        cursor: "pointer",
                        backgroundColor:
                          item.key % 2 === 1 ? "white" : "#FAFAFA",
                        padding: "16px 24px !important",
                        borderRadius:
                          item.key === 1
                            ? "15px 15px 0 0"
                            : item.key === 7
                            ? "0 0 15px 15px"
                            : 0,
                      }}
                      onClick={() =>
                        history.push({
                          pathname: `/report/${item.path}`,
                          state: { item },
                        })
                      }
                    >
                      <Col lg={1}>
                        <img src={DS_ICON} alt="icon" height={50} />
                      </Col>
                      <Col lg={23}>
                        <div
                          className="mb-0 ml-4 text-overflow-setting"
                          style={{ fontSize: 18, fontWeight: "bold" }}
                        >
                          {item.text}
                        </div>
                      </Col>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Col>
    </Row>
  );
};

export default Report;

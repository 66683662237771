import React from "react";
import { Col, Row} from "antd";

class PrintPDF extends React.Component<{ Ref?:React.LegacyRef<HTMLDivElement>,dataPrint?:any }> {
    render() {
        const useRef = this.props.Ref
        return (
            <div ref={useRef}>
                 <div className="card-modal-customer-database-PDF">
                    <Row className="header-modal-customer-database-pdf" justify='space-between' align='middle'>
                        <Col>CUSTOMER CONTACT I ข้อมูลการติดต่อลูกค้า</Col>
                    </Row>
                    <div className='m-2 mr-3 ml-3'>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>รหัสลูกค้า</Col>
                                    <Col className='text-value-card-modal'>{this.props.dataPrint.userCode && this.props.dataPrint.userCode}</Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>ประเภทลูกค้า</Col>
                                    <Col className='text-value-card-modal'>{this.props.dataPrint.type && this.props.dataPrint.type}</Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>ชื่อบริษัท</Col>
                            <Col className='text-value-card-modal'>{this.props.dataPrint.companyName && this.props.dataPrint.companyName}</Col>
                        </Row>
                    </div>
                    {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                    <div style={{ border: '1px solid lightgray', margin: 10, borderRadius: 8 }} >
                        <div style={{ backgroundColor: '#F1F1F2', padding: 8, borderTopRightRadius: 8, borderTopLeftRadius: 8 }}>
                            สาขา / ที่ตั้ง
                        </div>
                        <Row className='m-2'>
                            <Col span={24}>
                                <Row>
                                    <Col span={4}>ที่อยู่</Col>
                                    <Col className='text-value-card-modal'>บ้านเลขที่ 11/2 หมู่บ้าน ทาวอินทาวน์ ถนน ลาดพร้าว</Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>ตำบล/เเขวง</Col>
                                    <Col className='text-value-card-modal'>ลาดพร้าว</Col>
                                </Row>
                                <Row>
                                    <Col span={8}>อำเภอ/เขต</Col>
                                    <Col className='text-value-card-modal'>ลาดพร้าว</Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>จังหวัด</Col>
                                    <Col className='text-value-card-modal'>กรุงเทพ</Col>
                                </Row>
                                <Row>
                                    <Col span={8}>รหัสไปรษณีย์</Col>
                                    <Col className='text-value-card-modal'>20150</Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                    <Row className='mr-3 ml-3'>
                        <Col span={24}>
                            <Row>
                                <Col span={6}>เว็บไซต์</Col>
                                <Col className='text-value-card-modal'>{this.props.dataPrint.companyWebsite && this.props.dataPrint.companyWebsite}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>Facebook</Col>
                                <Col className='text-value-card-modal'>{this.props.dataPrint.companyFacebook && this.props.dataPrint.companyFacebook}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>Line ID</Col>
                                <Col className='text-value-card-modal'>{this.props.dataPrint.companyLine && this.props.dataPrint.companyLine}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>เบอร์โทรสำนักงาน</Col>
                                <Col className='text-value-card-modal'>{this.props.dataPrint.officeTel && this.props.dataPrint.officeTel}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>เเฟ็กซ์</Col>
                                <Col className='text-value-card-modal'>{this.props.dataPrint.fax && this.props.dataPrint.fax}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>อีเมลล์</Col>
                                <Col className='text-value-card-modal'>{this.props.dataPrint.companyEmail && this.props.dataPrint.companyEmail}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>Location</Col>
                                <Col className='text-value-card-modal'>{this.props.dataPrint.companyLocation && this.props.dataPrint.companyLocation}</Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                    <div style={{ border: '1px solid lightgray', margin: 10, borderRadius: 8 }} >
                        <div style={{ backgroundColor: '#F1F1F2', padding: 8, borderTopRightRadius: 8, borderTopLeftRadius: 8 }}>
                            ชื่อสามารถติดต่อได้
                        </div>
                        {this.props.dataPrint.customerContactPersons && this.props.dataPrint.customerContactPersons.map((item:any,index:number)=>
                        <Row key={index} className='p-2' style={{ borderBottom: index !== 
                         this.props.dataPrint.customerContactPersons.length - 1 ? '1px solid lightgray' : 0 }}>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>ชื่อผู้ติดต่อ</Col>
                                    <Col className='text-value-card-modal'>{item.contactName && item.contactName}</Col>
                                </Row>
                                <Row>
                                    <Col span={8}>เบอร์โทร</Col>
                                    <Col className='text-value-card-modal'>{item.tel && item.tel}</Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>ตำเเหน่ง/เเผนก</Col>
                                    <Col className='text-value-card-modal'>{item.position && item.position}</Col>
                                </Row>
                                <Row>
                                    <Col span={8}>อีเมลล์</Col>
                                    <Col className='text-value-card-modal'>{item.email && item.email}</Col>
                                </Row>
                            </Col>
                        </Row>
                        )}
                    </div>
                   <div>
                    <Row className='pr-3 pl-3 pb-3'>
                        <Col span={12}>
                            <Row>
                                 <Col span={8}>ผู้ดูเเล (AE)</Col>
                                <Col className='text-value-card-modal'>{`${this.props.dataPrint.aE?.firstName} ${this.props.dataPrint.aE?.lastName}`}</Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={8}>ผู้ดูเเล (AO)</Col>
                                <Col className='text-value-card-modal'>{`${this.props.dataPrint.aO?.firstName} ${this.props.dataPrint.aO?.lastName}`}</Col> 
                            </Row>
                        </Col>
                    </Row>
                    </div>
                </div>
            </div>
        )
    }
}

export default PrintPDF;
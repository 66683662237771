import { Table, Pagination, Row } from "antd";
import { tableLoading } from "../loading/loading";

type Props = {
  dataSource?: any[];
  columns?: any[];
  loading?: boolean | any;
  bordered?: boolean;
  config?: any;
  scroll?: { x?: number; y?: number };
  onChangePage?: (page: number, pageSize?: string) => void;
  onClickRow?: (row: any) => void;
  rowKey?: string;
  footer?: any;
  cursor?: boolean;
  className?: string;
  style?: any;
  rowSelection?: any;
};
const TableModifind = ({
  dataSource = [],
  columns = [],
  loading = tableLoading(),
  config = { total: 15, currentPage: 1, pageSize: 10 },
  onChangePage,
  rowKey = "index",
  bordered,
  onClickRow,
  scroll = {y: 500 },
  footer,
  cursor = false,
  className,
  style,
  rowSelection,
  ...props
}: Props) => {
  return (
    <div className="card-table">
      <Table
        style={style}
        className={`${className} ${cursor && "cursor"} my-table`}
        scroll={scroll}
        bordered={bordered}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        loading={loading}
        rowKey={rowKey}
        rowSelection={rowSelection}
        onRow={(record, _rowIndex) => {
          return {
            onClick: (_event) => {
              onClickRow && onClickRow(record);
            },
          };
        }}
        {...props}
        footer={footer}
      />
      <Row className="pt-3" justify="end" align="middle">
        <div className="pr-3">แสดงรายการ</div>
        <Pagination
          showSizeChanger
          onChange={(page) => onChangePage && onChangePage(page, "page")}
          total={config.total}
          current={config.currentPage}
          pageSize={config.pageSize}
          className="pagi"
          onShowSizeChange={(_current, pageSize) =>
            onChangePage && onChangePage(pageSize, "pageSize")
          }
        />
      </Row>
    </div>
  );
};

export default TableModifind;

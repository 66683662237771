import { Layout } from "antd";
import Headerbar from "./HeadbarLayout";
import Sidebarlayout from "./SidebarLayout";
import Contentlayout from "./Contentlayout";
import NavList from "../_navbar";
import { routers } from "../routes/router";

type Props = {
  children?: React.ReactNode;
};

const { Header } = Layout;

const DefaultLayout: React.FC<Props> = () => {
  const currentUser = JSON.parse(localStorage.getItem("userInfo") || "");
  const navMenu = NavList.filter((f) =>
    currentUser?.permission.some((item: any) => item?.name === f?.keyName)
  );
  let routerMenu = routers.filter((f) =>
    navMenu.some(
      (item: any) => item.link.split("/")[1] === f.path.split("/")[1]
    )
  );
  const notFound = routers?.filter((r) => r?.name === "404");
  routerMenu = [...routerMenu, ...notFound];

  return (
    <Layout style={{ height: "100%" }}>
      <Header style={{ position: "fixed", width: "100%", zIndex: 1000 }}>
        <Headerbar />
      </Header>
      <Layout>
        <Sidebarlayout navMenu={navMenu} />
        <Contentlayout router={routerMenu} />
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;

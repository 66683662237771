/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Form, Input, DatePicker, Row, Cascader, Select, Divider } from "antd";
import {
  SearchNew,
  Delete,
  // DateNew
} from "../assets/img/index";
// @ts-ignore
import Date from "../assets/img/icon/Date.png";
// @ts-ignore
import Search from "../assets/img/icon/Search.png";
// @ts-ignore
import Graph from "../assets/img/icon/Graph.png";
// @ts-ignore
import Excel from "../assets/img/icon/Excel.png";
import { _isEmpty } from "@yim/util-tool-ts";

interface Props {
  justify?: string;
  HandleOnFinish: (data: any) => void;
  HandleOnReset?: (data?: any) => void;
  Field: {
    type?: string;
    name: string;
    initialValue?: any;
    options?: [] | any;
    placeholder?: string | [] | any;
    keyName?: string;
    keyValue?: string;
    keyName2?: string;
  }[];
  Action?: {
    type?: string;
    onClick?: () => void;
  }[];
}

const { RangePicker } = DatePicker;

const FormDefault = ({ HandleOnFinish,HandleOnReset, Field, Action = [] }: Props) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    initialValues();
    return undefined;
  }, [Field]);

  const onFinish = (values: any) => {
    HandleOnFinish(values);
  };

  const initialValues = () => {
    const result = Field.reduce((obj, current) => {
      return {
        ...obj,
        [current.name]: current?.initialValue,
      };
    }, {});
    form.setFieldsValue(result);
  };

  const onReset = () => {
    const result = Field.reduce((obj, current) => {
      return {
        ...obj,
        [current.name]:
          current.type === "select"
            ? null
            : current.type === "selectCuscader"
            ? []
            : current.type === "rangepicker"
            ? []
            : "",
      };
    }, {});
    form.setFieldsValue(result);
    HandleOnReset && HandleOnReset(result);
  };

  const CheckTypeField = (value: any, index: number) => {
    return value.type === "rangepicker" ? (
      <Form.Item name={value.name} key={index} className="mr-2 mb-2">
        <RangePicker
          {...value.childrenProps}
          size="large"
          style={{ width: 280 }}
          allowClear={false}
          placeholder={["วันที่เริ่ม", "วันที่สิ้นสุด"]}
          className="range-picker-custom"
          suffixIcon={
            <img src={Date} style={{ width: "25px" }} alt="pdf-btn" />
          }
        />
      </Form.Item>
    ) : value.type === "selectCuscader" ? (
      <Form.Item name={value.name} key={index} className="mr-2 mb-2">
        <Cascader
          {...value.childrenProps}
          allowClear={false}
          style={{ width: 280 }}
          className="cascader-custom"
          options={value.options}
          size="large"
          placeholder={value.placeholder || "เลือก"}
        />
      </Form.Item>
    ) : value.type === "select" ? (
      <Form.Item name={value.name} key={index} className="mr-2 mb-2">
        <Select
          {...value.childrenProps}
          allowClear={false}
          style={{ width: 280 }}
          className="select-custom2"
          size="large"
          placeholder={value.placeholder || "เลือก"}
        >
          {value.options.map((item: any, idx: number) => {
            return (
              <Option value={item[value.keyValue]} key={idx}>
                {item[value.keyName]}
                <span> {item[value.keyName2]}</span>
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    ) : value.type === "input" ? (
      <Form.Item
        key={index}
        name={value.name}
        style={{ width: 280 }}
        rules={[{ required: false, message: "Please input your username!" }]}
        className="mr-2 mb-2"
      >
        <Input
          {...value.childrenProps}
          size="large"
          className={`${
            value.type === "input" ? "input-from-custom" : "input-from-custom2"
          }`}
          style={{ width: 280 }}
          suffix={<img src={Search} style={{ width: "25px" }} alt="pdf-btn" />}
          placeholder={value.placeholder ? value.placeholder : "ค้นหา"}
        />
      </Form.Item>
    ) : null;
  };

  const CheckTypeBtn = (value: any, index: number) => {
    return value.type === "btnReport" ? (
      <div onClick={value.onClick} className="mr-1" key={index}>
        <Row className="pdf-btn mb-2" justify="center" align="middle">
          <img src={Graph} style={{ width: "30px" }} alt="pdf-btn" />
          <span className="pdf-btn-text">รายงาน</span>
        </Row>
      </div>
    ) : value.type === "btnExport" ? (
      <div onClick={value.onClick} key={index}>
        <Row className="pdf-btn mb-2" justify="center" align="middle">
          <img src={Excel} style={{ width: "30px" }} alt="pdf-btn" />
          <span className="pdf-btn-text">EXCEL</span>
        </Row>
      </div>
    ) : null;
  };

  return (
    <Row align="middle">
      <Form
        form={form}
        name="fromheader"
        layout="inline"
        onFinish={onFinish}
        className="w-100"
      >
        {Field.map((item, index) => CheckTypeField(item, index))}
        <Form.Item shouldUpdate className="mr-1 ml-0">
          <div onClick={() => form.submit()}>
            <Row className="pdf-btn mb-2" justify="center" align="middle">
              <img src={SearchNew} style={{ width: "30px" }} alt="pdf-btn" />
              <span className="pdf-btn-text">ค้นหา</span>
            </Row>
          </div>
        </Form.Item>
        <Form.Item shouldUpdate className="mr-0">
          <div onClick={onReset}>
            <Row className="pdf-btn mb-2" justify="center" align="middle">
              <img src={Delete} style={{ width: "30px" }} alt="pdf-btn" />
              <span className="pdf-btn-text">ล้าง</span>
            </Row>
          </div>
        </Form.Item>
        {!_isEmpty(Action) && (
          <Divider
            type="vertical"
            style={{
              height: 50,
              borderLeft: "2px solid #d9d9d9",
              opacity: 0.6,
            }}
            className="mx-3"
          />
        )}
        {Action.map((item, index) => CheckTypeBtn(item, index))}
      </Form>
    </Row>
  );
};

export default FormDefault;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Popover,
  Row,
  Select,
} from "antd";
import { useState, useEffect } from "react";
import { generateButton } from "../../components/button_generate";
import { onImgErrorProfile, _numToDate } from "../../tools/util";
import { clock, DateNew, profile } from "../../assets/img";
import UploadFileLabel from "../UploadFileLabel";
import UploadFileLead from "../upload_file/UploadFileLead";
import moment from "moment";

type Props = {
  label?: string;
  text: string;
  remark?: boolean;
  value?: any;
  id: string | number;
  onFinish: (value: any) => void;
  onDelete: (id: any) => void;
  isBtn?: boolean;
  isEdit?: boolean;
  srcImg?: string;
};

const InputPickerFile = ({
  value = {},
  remark = false,
  onFinish,
  onDelete,
  isBtn = true,
  label,
  id,
  srcImg = profile,
  isEdit = false,
}: Props) => {
  const [vsb, setVsb] = useState<boolean>(true);
  const [showPop, setShowPop] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    form.setFieldsValue({
      file: value.file || [],
      text: value.note?.note || "",
      type: value.note?.type || "",
      date:
        value.note?.date === ""
          ? moment()
          : moment(new Date(Number(value.note?.date))),
    });
    return undefined;

  }, []);

  const Onfinish = (values: any) => {
    const dataInfo = {
      files: values.file.filter((item: any) => item.originFileObj) || [],
      text: values.text,
      type: values.type,
      date: values.date.valueOf(),
      id: id,
      currentFile: values.file
        .filter((item: any) => item.id)
        .map((child: any) => child.id),
    };
    onFinish(dataInfo);
    onEditForm();
  };

  const onEditForm = () => {
    setVsb(!vsb);
  };

  const saveBtn = generateButton({
    Icon: SaveOutlined,
    text: "บันทึกข้อมูล",
    onClick: () => form.submit(),
  });

  const cancelBtn = generateButton({
    text: "ยกเลิก",
    onClick: () => onEditForm(),
  });

  const editBtn = generateButton({
    Icon: EditOutlined,
    onClick: () => onEditForm(),
  });

  const delBtn = generateButton({
    Icon: DeleteOutlined,
    onClick: () => setShowPop(!showPop),
  });

  const DateNow = (date: string | number) => {
    const result = Number(date);
    if (result) {
      return _numToDate(result, "DD/MM/YYYY | h:mm:ss");
    } else {
      return date;
    }
  };

  const StatusForwardList = [
    { name: "นัดหมาย" },
    { name: "ติดตาม" },
    { name: "ปิดจ็อบ" },
    { name: "อื่นๆ" },
  ];

  const contentDelPop = () => {
    return (
      <Row justify="space-between" align="middle">
        <Button onClick={() => setShowPop(false)}>ยกเลิก</Button>
        <Button
          type="primary"
          icon={<DeleteOutlined />}
          onClick={() => onDelete(id)}
          danger
        >
          ลบ
        </Button>
      </Row>
    );
  };
  return vsb ? (
    <Row align="top" className="my-3" justify="center">
      <img
        src={srcImg}
        onError={(e: any) => {
          onImgErrorProfile(e);
        }}
        alt={"imgPost"}
        width={30}
        height={30}
        className="mx-2 img-item-note"
      />
      <Col
        span={22}
        style={{
          border: "1px solid #CCCCCC",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <Row
          justify="space-between"
          align="middle"
          className="p-2"
          style={{
            backgroundColor: "#F6F7F8",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Row align="middle">
            {label}{" "}
            <span className="ml-5 mr-3">
              <CalendarOutlined
                className="mx-2"
                style={{ color: "lightgray" }}
              />
              {DateNow(value.dateTime)}
            </span>
            {remark && (
              <Row align="middle">
                <img
                  src={clock}
                  style={{
                    width: 18,
                    height: 18,
                    borderRadius: 30,
                    margin: "2px 2px",
                  }}
                  alt="userImage"
                />
                <span>{value?.note?.type || ""}</span>
                <span style={{ margin: "0 5px" }}>
                  {_numToDate(
                    Number(value?.note?.date),
                    "DD/MM/YYYY | h:mm:ss"
                  ) || ""}
                </span>
              </Row>
            )}
          </Row>
          {isBtn && isEdit && (
            <Row>
              {editBtn}
              <Popover
                placement="top"
                title={"คุณเเน่ใจหรือไม่!"}
                content={contentDelPop}
                trigger="click"
                open={showPop}
              >
                {delBtn}
              </Popover>
            </Row>
          )}
        </Row>
        <Card className="card-note-show">
          {value.note?.note}
          <Row className="mt-3">
            {value.file?.map((item: any, idx: number) => (
              <Col className="mr-1 mt-2" key={idx}>
                <UploadFileLabel name={item?.name} value={item.url} />
              </Col>
            ))}
          </Row>
        </Card>
      </Col>
    </Row>
  ) : (
    <Form form={form} onFinish={Onfinish}>
      <Row align="top" className="my-3" justify="center">
        <img
          src={srcImg}
          alt={"imgPost"}
          onError={(e: any) => {
            onImgErrorProfile(e);
          }}
          width={30}
          height={30}
          className="mx-2 img-item-note"
        />
        <Col
          span={22}
          style={{ border: "1px solid #CCCCCC", borderRadius: "5px" }}
        >
          <Row
            justify="space-between"
            align="middle"
            className="p-2"
            style={{
              backgroundColor: "#F6F7F8",
              borderRadius: "5px 5px 0 0",
            }}
          >
            <span>กรอกรายละเอียด</span>
            <Row align="middle">
              {remark && (
                <Row align="middle">
                  <Form.Item
                    name={"type"}
                    rules={[{ required: false, message: "กรุณาเลือกสถานะ" }]}
                    noStyle
                  >
                    <Select
                      style={{
                        width: "11rem",
                        marginRight: 8,
                      }}
                      placeholder="สถานะ"
                      className="my-select"
                    >
                      {StatusForwardList.map((item: any, idx: number) => (
                        <Option value={item.name} key={idx}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="date"
                    rules={[{ required: true, message: "!กรุณาป้อนข้อความ" }]}
                    className="px-2"
                    noStyle
                  >
                    <DatePicker
                      format={"DD/MM/YYYY | h:mm:ss"}
                      allowClear={false}
                      placeholder="เลือกวันที่ติดต่อ"
                      suffixIcon={
                        <img
                          src={DateNew}
                          style={{ width: "25px" }}
                          alt="pdf-btn"
                        />
                      }
                      style={{
                        border: `1px solid #d9d9d9`,
                      }}
                    />
                  </Form.Item>
                </Row>
              )}
              {cancelBtn}
              {saveBtn}
            </Row>
          </Row>
          <Form.Item
            name="text"
            rules={[{ required: true, message: "!กรุณาป้อนข้อความ" }]}
            className="px-2"
          >
            <Input.TextArea
              bordered={false}
              autoSize={{ minRows: 5, maxRows: 5 }}
              className="p-1"
            />
          </Form.Item>
          <UploadFileLead name="file" />
        </Col>
      </Row>
    </Form>
  );
};

export default InputPickerFile;

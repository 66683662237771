import { Col, Row, Card, List } from "antd";
import {
  profile,
  user_management,
  technician,
  member,
  contact_type,
  project_type,
  aluminium_color,
  color_type,
  position,
  contact_channel,
  subject_contact,
  interesting,
  requirement_type,
  project_transfer,
} from "../../../assets/img/icon-setting/icon-seting";
import { useHistory, useRouteMatch } from "react-router";

const Setting = () => {
  const history = useHistory();

  const { url } = useRouteMatch();
  const dataMocks = [
    {
      text: "จัดการโปรไฟล์ส่วนตัว",
      img: profile,
      to: "/profile",
    },
    {
      text: "จัดการผู้ใช้",
      img: user_management,
      to: "/usermanagement/member",
    },
    {
      text: "จัดการช่างสมาร์ท",
      img: technician,
      to: "/technicianmanagement/member",
    },
    {
      text: "สมาชิกลูกค้า",
      img: member,
      to: "/customermanagement/member",
    },
    {
      text: "ประเภทผู้ติดต่อ",
      img: contact_type,
      to: "/contact-type",
    },
    {
      text: "ประเภทโครงการ",
      img: project_type,
      to: "/project-type",
    },
    {
      text: "สีอลูมิเนียม",
      img: aluminium_color,
      to: "/aluminium-color",
    },
    {
      text: "ประเภทสี",
      img: color_type,
      to: "/color-type",
    },
    {
      text: "ตำแหน่ง",
      img: position,
      to: "/position",
    },
    {
      text: "ช่องทางการติดต่อ",
      img: contact_channel,
      to: "/contact-channel",
    },
    {
      text: "เรื่องที่ต้องการติดต่อ",
      img: subject_contact,
      to: "/contact-matter",
    },
    {
      text: "สินค้าที่สนใจ",
      img: interesting,
      to: "/interest-product",
    },
    {
      text: "ประเภทความต้องการ",
      img: requirement_type,
      to: "/requirement-type",
    },
    {
      text: "เป้าหมาย",
      img: requirement_type,
      to: "/goal",
    },
    {
      text: "โอนย้ายโครงการ",
      img: project_transfer,
      to: "/project-transfer",
    },
  ];

  return (
    <Row className="h-100 min-vh-100">
      <Col
        lg={24}
        className="d-flex justify-content-center align-items-center w-100 "
      >
        <Col lg={24} md={24}>
          <Card
            className="setting-cover-card mt-3"
            cover={
              <>
                <h1 className="mb-0">
                  <span
                    style={{
                      fontFamily: "Bold",
                      color: "#faa41a",
                      fontSize: "23px",
                    }}
                  >
                    SETTING
                  </span>
                  <span
                    className="ml-2"
                    style={{ fontFamily: "Bold", fontSize: "23px" }}
                  >
                    กำหนดตั้งค่า
                  </span>
                </h1>
              </>
            }
          >
            <Row>
              <Col span={24}>
                <List
                  size="large"
                  bordered
                  dataSource={dataMocks}
                  renderItem={(item, key) => (
                    <List.Item
                      key={key}
                      style={{
                        height: "70px",
                        cursor: "pointer",
                        backgroundColor: key % 2 === 1 ? "white" : "#FAFAFA",
                        padding:'16px 40px'
                      }}
                      onClick={() => history.push(`${url}${item.to}`)}
                    >
                      <Col lg={1} className="mr-4">
                        <img src={item.img} alt="icon" height={40} />
                      </Col>
                      <Col lg={23}>
                        <h3 className="mb-0 ml-2 text-overflow-setting">
                          <b>{item.text}</b>
                        </h3>
                      </Col>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Col>
    </Row>
  );
};

export default Setting;

import { Button, Col } from "antd";
import FInputPassword from "./form_floatlabel/FInputPassword";

type Props = {
  handleClickEdit: (value: any) => void;
  disabledEditPassword: boolean;
  isChangePassword: boolean;
  isCreate: boolean;
  disabledEdit: boolean;
  isProfile: boolean;
};

const PasswordEdit = ({
  handleClickEdit,
  disabledEditPassword,
  isCreate,
  isChangePassword,
  disabledEdit,
  isProfile,
}: Props) => {
  return (
    <>
      {!disabledEditPassword ? (
        <>
          <Col md={12}>
            <FInputPassword
              label={isCreate ? "รหัสผ่าน" : "รหัสผ่านใหม่"}
              name="password"
              rules={{ required: true }}
            />
          </Col>
          {isChangePassword && (
            <Col md={12}>
              <FInputPassword
                label="ยืนยันรหัสผ่านใหม่"
                name="confirmPassword"
                rulesConfirm={true}
              />
            </Col>
          )}
          {!disabledEditPassword && isChangePassword && isProfile && (
            <Col md={12}>
              <FInputPassword
                label="รหัสผ่านเดิม"
                name="currentPassword"
                rules={{ required: true }}
              />
            </Col>
          )}
        </>
      ) : (
        <Col md={12}>
          <Button
            className="btn-editpass"
            onClick={handleClickEdit}
            disabled={disabledEdit}
          >
            ตั้งรหัสผ่านใหม่
          </Button>
        </Col>
      )}
    </>
  );
};

export default PasswordEdit;

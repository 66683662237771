import { Col, Empty } from "antd";

const EmptyPart = () => {
  return (
    <Col
      span={24}
      className="pt-5 pb-5"
      style={{ backgroundColor: "white", borderRadius: 5 }}
    >
      <Empty />
    </Col>
  );
};

export default EmptyPart;

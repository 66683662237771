import { Card, Popover } from "antd";

type Props = {
  dataset: {
    key: number;
    type: string;
    data?: {
      key: number;
      img: any;
      nameEn: string;
      nameTh: string;
      // amount: number;
    }[];
  }[];
  handle: (value: any) => void;
  handle2: (value: any) => void;
  typeTab: any;
  statusTab: any;
};

const CardFilter = ({
  dataset,
  handle,
  handle2,
  typeTab,
  statusTab,
}: Props) => {
  const dataType: any = dataset[0];
  const dataStatus: any = dataset[1];

  const CheckLengthText = (title: string, text: string) => {
    return title?.length > 15 || text?.length > 17 ? (
      <Popover
        content={
          <div
            style={{
              wordWrap: "break-word",
              color: "#f37d31",
              textAlign: "center",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 16 }}>
              {title || ""}
            </div>
            <div style={{ fontSize: 14 }}>{text || ""}</div>
          </div>
        }
        title={false}
      >
        <div className="text-title-card-filter">{title || ""}</div>
        <div className="text-card-filter">{text || ""}</div>
      </Popover>
    ) : (
      <div>
        <div className="text-title-card-filter">{title || ""}</div>
        <div className="text-card-filter">{text || ""}</div>
      </div>
    );
  };

  return (
    <div className="mt-5 mb-4 fillter-heabar w-100">
      {dataType.data.map((item: any, idx: any) => {
        return (
          <div key={idx} className="mx-1 pb-2">
            <Card
              className={
                typeTab === item.key ? "card-menu-tab-active" : "card-menu-tab"
              }
              onClick={() => handle(item.key)}
              key={item.key}
              style={{
                width: "160px",
                height: "175px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <img
                src={typeTab === item.key ? item.img_hover : item.img}
                width={40}
                style={{ marginBottom: "10px" }}
                alt="alt"
              />
              {CheckLengthText(item?.nameEn, item?.nameTh)}
              <div style={{ fontSize: "22px", fontWeight: "bold" }}>
                {item.amount || 0}
              </div>
            </Card>
          </div>
        );
      })}
      {dataStatus.data.map((item: any, idx: number) => {
        return (
          <div key={idx} className="mx-1">
            <Card
              onClick={() => handle2(item.key)}
              key={item.key}
              style={{
                width: "160px",
                height: "175px",
                textAlign: "center",
                cursor: "pointer",
              }}
              className={
                statusTab === 1 && statusTab === item.key
                  ? "card-menu-green"
                  : statusTab === 2 && statusTab === item.key
                  ? "card-menu-red"
                  : statusTab === 3 && statusTab === item.key
                  ? "card-menu-lightblue"
                  : `card-menu-uniq ${
                      item.key === 1
                        ? "card-bd-1"
                        : item.key === 2
                        ? "card-bd-2"
                        : "card-bd-3"
                    }`
              }
            >
              <img src={item.img} style={{ marginBottom: "10px" }} alt="alt" />
              <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                {item?.nameEn || ""}
              </div>
              <div style={{ fontSize: "14px", fontWeight: "normal" }}>
                {item?.nameTh || ""}
              </div>
              <div style={{ fontSize: "22px", fontWeight: "bold" }}>
                {item.amount || 0}
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default CardFilter;

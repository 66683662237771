import { Card, Col, Row } from "antd";
import React, { ReactNode } from "react";
import CImage from "../../../../../components/custom_image/CImage";

class PrintPDF extends React.Component<{
  Ref?: React.LegacyRef<HTMLDivElement>;
  data?: any | ReactNode;
}> {
  render() {
    const dataArray = [
      {
        label: "ชื่อจริง",
        name: "firstName",
        span: 12,
        value: this?.props?.data?.firstName,
      },
      {
        label: "นามสกุล",
        name: "lastName",
        span: 12,
        value: this?.props?.data?.lastName,
      },
      {
        label: "ชื่อเล่น",
        name: "nickname",
        span: 8,
        value: this?.props?.data?.nickname,
      },
      {
        label: "เบอร์โทรศัพท์",
        name: "phone",
        span: 8,
        value: this?.props?.data?.phone,
      },
      {
        label: "ไอดีไลน์",
        name: "line",
        span: 8,
        value: this?.props?.data?.line,
      },
      {
        label: "ชื่อบริษัท",
        name: "companyName",
        span: 12,
        value: this?.props?.data?.phone,
      },
      {
        label: "เลขผู้เสียภาษี",
        name: "taxpayerNumber",
        span: 12,
        value: this?.props?.data?.phone,
      },
      {
        label: "เบอร์โทรศัพทที่ทำงาน",
        name: "companyPhone",
        span: 12,
        value: this?.props?.data?.phone,
      },
      {
        label: "อีเมล",
        name: "email",
        span: 12,
        value: this?.props?.data?.email,
      },
      {
        label: "ที่อยู่",
        name: "address",
        span: 24,
        value: this?.props?.data?.address,
      },
      {
        label: "จังหวัด",
        name: "province",
        span: 12,
        value: this?.props?.data?.province,
      },
      {
        label: "อำเภอ",
        name: "district",
        span: 12,
        value: this?.props?.data?.district,
      },
      {
        label: "ตำบล",
        name: "subDistrict",
        span: 12,
        value: this?.props?.data?.subDistrict,
      },
      {
        label: "รหัสไปรษณีย์",
        name: "postalCode",
        span: 12,
        value: this?.props?.data?.postalCode,
      },
    ];

    const useRef = this.props.Ref;
    return (
      <div ref={useRef}>
        <div className="p-3">
          <b className="pb-3">ข้อมูลพื้นฐาน</b>
          <Row justify="center" className="mt-4 mb-5">
            <CImage
              src={this?.props?.data?.image || ""}
              alt="icon"
              style={{
                height: "14rem",
                width: "14rem",
                objectFit: "cover",
              }}
              className="circle-img h-100"
              defautImage="user"
            />
          </Row>
          <Row gutter={[10, 10]}>
            {dataArray?.map((item: any, index: number) => (
              <Col span={item?.span} key={item?.name}>
                <Card>
                  <b>{item?.label}</b> : {item?.value}
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    );
  }
}

export default PrintPDF;

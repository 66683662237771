/* eslint-disable react-hooks/exhaustive-deps */

import {
  DeleteFilled,
  PhoneFilled,
  UserOutlined,
  SafetyCertificateFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Pagination, Button, Popconfirm } from "antd";
import { FormDefault } from "../../../../../components";
import CImage from "../../../../../components/custom_image/CImage";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import EmptyPart from "../../../../../components/EmptyPart";

type Props = {
  dataSource: any[];
  handleDelete: (id: number) => void;
  handleSubmit: (values: any) => void;
  fieldFilter: any;
  pageConfig: any;
  pathmenu: string;
  onChangePage?: (page: number, pageSize?: string) => void;
};
const DataMember = ({
  dataSource,
  handleDelete,
  handleSubmit,
  fieldFilter,
  pageConfig,
  pathmenu,
  onChangePage,
}: Props) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const isTechnician = url.includes("/technicianmanagement");
  const isCustomer = url.includes("/customermanagement");

  const RenderContent = () => {
    return (
      <Row
        gutter={[10, 10]}
        justify={dataSource?.length > 0 ? "center" : "start"}
      >
        {dataSource?.length > 0 ? (
          dataSource.map((item: any, idx: number) => (
            <Col xl={6} md={12} lg={12} key={idx}>
              <Card className="card-user">
                <Row gutter={[18, 18]}>
                  <Col lg={10}>
                    <CImage
                      src={item.image}
                      alt="user-member"
                      className="user-image"
                      defautImage="user"
                    />
                  </Col>
                  <Col lg={14}>
                    <h3 className="cut-text-1-row">
                      {item.firstName ? (
                        <b>
                          {item.firstName} {item.lastName}
                        </b>
                      ) : (
                        <b>ไม่มีข้อมูล</b>
                      )}
                    </h3>
                    {!isTechnician && !isCustomer ? (
                      <span>
                        <SafetyCertificateFilled
                          style={{ paddingRight: "10px" }}
                        />
                        {item.department?.abbreviation}
                      </span>
                    ) : (
                      <span>
                        <UserOutlined style={{ paddingRight: "10px" }} />
                        {item.nickname}
                      </span>
                    )}
                    <p className="cut-text-1-row">
                      <PhoneFilled style={{ paddingRight: "10px" }} />
                      {item.phone}
                    </p>
                    <hr className="line-custom" />
                    <Row gutter={10} align="middle">
                      <Col md={20}>
                        <Button
                          className="btn-user-detail"
                          block
                          onClick={() =>
                            history.push({
                              pathname:
                                `/setting/${pathmenu}/form-user/` + item.id,
                            })
                          }
                        >
                          ดูรายละเอียด
                        </Button>
                      </Col>
                      <Col md={4}>
                        <Popconfirm
                          className="pop-faq mt-1"
                          placement="top"
                          title={"คุณเเน่ใจหรือไม่"}
                          onConfirm={() => handleDelete(item.id)}
                          okType="danger"
                          okText="ลบ"
                          cancelText="ยกเลิก"
                          icon={
                            <QuestionCircleOutlined style={{ color: "red" }} />
                          }
                          okButtonProps={{
                            style: {
                              width: 50,
                            },
                          }}
                        >
                          <DeleteFilled
                            style={{ fontSize: 22 }}
                            className="faq-btn-profile"
                          />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))
        ) : (
          <EmptyPart/>
        )}
      </Row>
    );
  };

  return (
    <div className="mt-4">
      <div style={{ paddingBottom: "20px" }}>
        <FormDefault
          HandleOnFinish={handleSubmit}
          Field={fieldFilter}
          Action={[]}
        />
      </div>
      <RenderContent />
      <div className="pagination-custom mt-3">
        <Pagination
          showSizeChanger
          pageSize={pageConfig.limitPage}
          current={pageConfig.currentPage}
          total={pageConfig.totalPage}
          onShowSizeChange={(_current, pageSize) =>
            onChangePage && onChangePage(pageSize, "pageSize")
          }
          onChange={(page) => onChangePage && onChangePage(page, "page")}
          pageSizeOptions={["12", "24", "36", "48"]}
          showTotal={(total) => `Total ${total} items`}
        />
      </div>
    </div>
  );
};
export default DataMember;

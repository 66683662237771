import { Fetch, FetchFormData } from "../../../tools/fecth/fecth";
import { apiVersion } from '../../../config/api'
import { createQueryString } from "../../../tools/util";

export const GETTECHNICIANDATA_API = async (data: {}) => {
    const qrs = createQueryString(data);
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/technician${qrs}` });
    return res;
}
export const ADDTECHNICIAN_API = async (data: any) => {
    const res = await FetchFormData({ method: 'POST', path: `${apiVersion}/technician`, data });
    return res;
}

export const EDITTECHNICIAN_API = async ({ data, id }: { data: any, id: number }) => {
    const res = await FetchFormData({ method: 'PUT', path: `${apiVersion}/technician/${id}`, data });
    return res;
}

export const GETTECHNICIANID_API = async (id: number) => {
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/technician/${id}` });
    return res;
}

export const DELETETECHNICIAN_API = async (id: number) => {
    const res = await Fetch({ method: 'DELETE', path: `${apiVersion}/technician/${id}` });
    return res;
}
import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Row, Button } from "antd";
import { useHistory } from "react-router-dom";
import { IHeaderTab } from "../../interface/ICustomerService.interface";
import { _isEmpty } from "@yim/util-tool-ts";

const HeaderTabMenu = ({
  title = "REPORT",
  message = "Customer Service Report",
  style = {},
  isButton = false,
  customButton = {},
  onFinish,
  goBack,
}: IHeaderTab) => {
  const history = useHistory();
  goBack = () => history.goBack();
  return (
    <Card
      style={
        style
          ? { ...style }
          : { borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }
      }
      className="header-setting border-header-diy-no-tab"
    >
      <Row className="page-heder">
        <div style={{ cursor: "pointer" }} onClick={goBack}>
          <Row gutter={12} align="middle">
            <Col>
              <LeftOutlined style={{ fontSize: "16px" }} />
            </Col>
            <Col>
              <div
                style={{
                  fontWeight:'bold',
                  color: "#FFC627",
                  fontSize: "25px",
                }}
              >
                {title}
              </div>
              <div
                style={{
                  fontFamily: "SemiBold",
                  fontSize: "18px",
                }}
              >
                {message}
              </div>
            </Col>
          </Row>
        </div>
        {isButton && (
          <Col>
            <Button
              onClick={onFinish}
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                height: "50px",
                fontSize: "18px",
                paddingLeft: "3rem",
                paddingRight: "3rem",
                background: "#fe6b0b",
              }}
            >
              DONE
            </Button>
          </Col>
        )}
        {!_isEmpty(customButton) && <Col>{customButton}</Col>}
      </Row>
    </Card>
  );
};
export default HeaderTabMenu;

import { atom, selectorFamily } from "recoil";

export enum EFields {
  CUSTOMER_CONTACT = "customer_contact",
  REQUIREMENT = "requirement",
  ACTION = "action",
  NOTE = "note",
  ACTIVITY = "activity",
}

const tab_visible_atom = atom({
  key: "tab_visible_atom",
  default: {
    [EFields.CUSTOMER_CONTACT]: true,
    [EFields.REQUIREMENT]: true,
    [EFields.ACTION]: true,
    [EFields.NOTE]: true,
    [EFields.ACTIVITY]: true,
  },
});

export const tab_visible_selector = selectorFamily({
  key: "tab_visible_selector",
  get:
    (key: EFields) =>
    ({ get }) => {
      return get(tab_visible_atom)[key];
    },
  set:
    (key: EFields) =>
    ({ set, get }, bool) => {
      const current = get(tab_visible_atom);
      const next = { ...current, [key]: bool };
      set(tab_visible_atom, next);
    },
});

const tab_edit_atom = atom({
  key: "tab_edit_atom",
  default: {
    [EFields.CUSTOMER_CONTACT]: false,
    [EFields.REQUIREMENT]: false,
    [EFields.ACTION]: false,
    [EFields.NOTE]: false,
    [EFields.ACTIVITY]: false,
  },
});

export const tab_edit_selector = selectorFamily({
  key: "tab_edit_selector",
  get:
    (key: EFields) =>
    ({ get }) => {
      return get(tab_edit_atom)[key];
    },
  set:
    (key: EFields) =>
    ({ set, get }, bool) => {
      const current = get(tab_edit_atom);
      const next = { ...current, [key]: bool };
      set(tab_edit_atom, next);
    },
});

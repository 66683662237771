/* eslint-disable react-hooks/exhaustive-deps */
import {
  CloseOutlined,
  LeftOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Row,
  Image,
  Form,
  FormInstance,
  Divider,
} from "antd";
import { useHistory } from "react-router-dom";
import Edit from "../../../../../assets/img/edit.png";
import FInput from "../../../../../components/form_floatlabel/FInput";
import FSelect from "../../../../../components/form_floatlabel/FSelect";
import { ISettingList } from "../../../../../interface/ISetting.interface";

interface Props {
  Action: {
    type?: string;
    name?: string;
    edit?: boolean;
    onClick?: () => void;
  }[];
  Title: {
    nameTH: string;
    nameEN: string;
  };
  handleClickEdit: () => void;
  handleClickCancel: () => void;

  disabledEdit: boolean;
  editOnPage?: boolean;
  manageContent?: boolean;
  actionName?: string;
  onFinish?: (value: any) => void;
  fieldKey?: any[];
  form: FormInstance<any>;
  requirementTypeSource: ISettingList[];
}

const HeaderSetting = ({
  Action,
  Title,
  handleClickEdit,
  disabledEdit = false,
  handleClickCancel,
  editOnPage = false,
  manageContent = false,
  actionName = "",
  onFinish,
  form,
  requirementTypeSource,
}: Props) => {
  const history = useHistory();

  const CheckTypeBtn = (value: any, index: number) => {
    const handleSubmitContentHeader = () => {
      form.submit();
    };

    return value.type === "btnPush" && !manageContent ? (
      <Button
        className="btn-gradient mb2"
        type="primary"
        onClick={() => (editOnPage ? handleClickEdit() : value.onClick())}
        key={index}
      >
        <PlusOutlined />
        {value.name}
      </Button>
    ) : value.type === "btnEdit" && disabledEdit ? (
      <Button
        className="btn-edit"
        onClick={() => handleClickEdit()}
        key={index}
      >
        <Image src={Edit} width={14} preview={false}></Image>
        <b>{value.name}</b>
      </Button>
    ) : (
      <Row gutter={10} key={index}>
        <Col>
          <Button
            className="btn-gray"
            type="primary"
            onClick={() => {
              handleClickCancel();
              form.resetFields();
            }}
          >
            <CloseOutlined />
            ยกเลิก
          </Button>
        </Col>
        <Col>
          <Button
            className="btn-save"
            type="primary"
            onClick={() =>
              editOnPage ? handleSubmitContentHeader() : value.onClick()
            }
          >
            <SaveOutlined />
            บันทึก
          </Button>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Card className="border-header-diy ">
        <Row className="page-heder">
          <div style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
            <Row gutter={12} align="middle">
              <Col>
                <LeftOutlined style={{ fontSize: "16px", marginRight: 10 }} />
              </Col>
              <Col>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#FFC627",
                    fontSize: "25px",
                  }}
                >
                  <b>{Title.nameEN}</b>
                </div>
                <div
                  style={{
                    fontFamily: "SemiBold",
                    fontSize: "18px",
                  }}
                >
                  {Title.nameTH}
                </div>
              </Col>
            </Row>
          </div>

          <div className={manageContent ? "d-none" : ""}>
            {Action.map((item, index) => CheckTypeBtn(item, index))}
          </div>
        </Row>
      </Card>
      {manageContent && (
        <Card className="card-round">
          <Form onFinish={onFinish} form={form}>
            <Row className="page-heder" align="middle">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => history.goBack()}
              >
                <Row gutter={12} align="middle">
                  <Col>
                    <div
                      style={{
                        fontFamily: "SemiBold",
                        color: "#FFC627",
                        fontSize: "20px",
                      }}
                    >
                      <b>
                        {actionName} {Title.nameEN}
                      </b>
                    </div>
                  </Col>
                  <Col>
                    <div
                     style={{
                      fontFamily: "bold",
                      fontSize: "18px",
                    }}
                    >
                      {Title.nameTH}
                    </div>
                  </Col>
                </Row>
              </div>

              {Action.map((item, index) => CheckTypeBtn(item, index))}
              <Divider className="custom-devide" />
              <Row className="w-100 mt-3" gutter={[14, 14]}>
                <Col lg={24} className="mb-2">
                <FInput
                    label="รายละเอียด"
                    name="description"
                    rules={{ required: false }}
                  />
                </Col>
                <Col lg={12} className="mb-2">
                  <FSelect
                    label="ประเภทความต้องการ"
                    name="requirementType"
                    rules={{ required: true }}
                    dataSource={requirementTypeSource}
                    keyName={"name"}
                    keyValue={"id"}
                  />
                </Col>
                <Col lg={12} className="mb-2">
                  <FInput
                    label="เป้าหมาย"
                    name="goal"
                    rules={{ required: true }}
                    type="number"
                  />
                </Col>
              </Row>
            </Row>
          </Form>
        </Card>
      )}
    </>
  );
};
export default HeaderSetting;

/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Card, Button, Image, Divider } from "antd";
import HeaderTab from "./header_tab/HeaderTab";
import basic_info from "../../../../assets/img/icon_customer_service/basic_info.svg";
import contact from "../../../../assets/img/icon_customer_service/contact.svg";
import caretaker from "../../../../assets/img/icon_customer_service/caretaker.svg";
import poin from "../../../../assets/img/icon_customer_service/point.svg";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  GET_CUSTOMER_SERVICE_DETAIL_API,
  ACKNOWLEDGE_CUSTOMER_SERVICE_DETAIL_API,
  ACKNOWLEDGE_CUSTOMER_SERVICE_DELETE_API,
} from "../../../../services/api/customer_api";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../../tools/recoil/recoil-atom";
import { EditOutlined, CheckOutlined, EyeOutlined } from "@ant-design/icons";
import { AlertToast } from "../../../../components/alert/alert";
import { onImgErrorProfile } from "../../../../tools/util";
import { Excel, PDF } from "../../../../assets/img";
import { _isEmpty } from "@yim/util-tool-ts";
import Swal from "sweetalert2";

const Report = () => {
  let history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [dataInfo, setDataInfo] = useState({} as any);
  const setLoading = useSetRecoilState<boolean>(loadingState);

  useEffect(() => {
    getDataInfo();
    return undefined;
  }, []);

  const getDataInfo = async () => {
    setLoading(true);
    try {
      const res = await GET_CUSTOMER_SERVICE_DETAIL_API(pathID);
      if (res.code === 200) {
        setDataInfo(res.result[0]);
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
        });
        history.push(`/customerservice`);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const CheckEmptyValue = (value: any) => {
    if (!_isEmpty(value)) {
      return value;
    } else {
      return "";
    }
  };

  const HandleOnEdit = () => {
    history.push({
      pathname: `/customerservice/list/detail/edit/${pathID}`,
      state: dataInfo,
    });
  };

  const HandleDelete = async () => {
    try {
      const res = await ACKNOWLEDGE_CUSTOMER_SERVICE_DELETE_API(pathID);
      if (res.code === 200) {
        AlertToast({
          icon: "success",
          title: `ลบข้อมูลสำเร็จ!`,
        });
        history.push(`/customerservice`);
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถลบข้อมูลได้ เนื่องจาก ข้อผิดพลาด !${res.message}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleOnAcknowledge = async () => {
    try {
      const res = await ACKNOWLEDGE_CUSTOMER_SERVICE_DETAIL_API(pathID);
      if (res.code === 200) {
        AlertToast({
          icon: "success",
          title: `สำเร็จ! คุณได้รับทราบข้อมูลเรียบร้อยเเล้ว`,
        });
        history.push(`/customerservice`);
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FileDisplay = ({ file }: { file: any }) => {
    const { url, name } = file;

    const _getFileExtension = (_url: string): string => {
      return _url.split(".").pop() || "";
    };

    const _getImageHolder = (extension: string, url: string) => {
      switch (extension) {
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
          return (
            <Image
              src={url}
              onError={(e: any) => {
                onImgErrorProfile(e);
              }}
              height={43}
              style={{ objectFit: "cover" }}
              alt={url}
              wrapperClassName="image-file-cs"
            />
          );
        case "pdf":
          return (
            <div className="pdf-icon-overlay">
              <Image
                src={PDF}
                onError={(e: any) => {
                  onImgErrorProfile(e);
                }}
                height={43}
                style={{ objectFit: "cover", cursor: "pointer" }}
                alt={"PDF"}
                preview={false}
              />
              <div
                className="pdf-file-overlay"
                onClick={() => window.open(url)}
              >
                <div className="eye-icon-overlay">
                  <EyeOutlined />
                </div>
                <div className="text-file-overlay">Preview</div>
              </div>
            </div>
          );
        default:
          return (
            <div className="pdf-icon-overlay">
              <Image
                src={Excel}
                onError={(e: any) => {
                  onImgErrorProfile(e);
                }}
                height={43}
                style={{ objectFit: "cover" }}
                alt={"Excel"}
                preview={false}
              />
              <div
                className="pdf-file-overlay"
                onClick={() => window.open(url)}
              >
                <div className="eye-icon-overlay">
                  <EyeOutlined />
                </div>
                <div className="text-file-overlay">Preview</div>
              </div>
            </div>
          );
      }
    };

    const extension = _getFileExtension(url);
    const holder = _getImageHolder(extension, url);

    return (
      <div>
        <div
          className="mt-2 mx-2"
          style={{
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid rgb(204, 204, 204)",
            borderRadius: 5,
            position: "relative",
          }}
        >
          <Row
            justify="center"
            align="middle"
            style={{ fontSize: 20 }}
            className="p-1"
          >
            {holder}
          </Row>
        </div>
        <div className="text-name-file-upload-detail">{name}</div>
      </div>
    );
  };

  return (
    <div>
      <HeaderTab
        title="CUSTOMER SERVICE"
        message="การบริการลูกค้า"
        style={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          // position: "fixed",
          // top: 0,
          // zIndex: 1060,
        }}
        customButton={
          <Row justify="space-between" align="middle">
            <Col className="mr-2">
              <Button
                onClick={HandleOnAcknowledge}
                type="primary"
                className="btn-acknowledge"
                icon={<CheckOutlined />}
                disabled={dataInfo.disable}
              >
                ACKNOWLEDGE
              </Button>
            </Col>
            <Col className="ml-2">
              <Button
                onClick={HandleOnEdit}
                type="ghost"
                className="customer-service-btn-action"
                icon={<EditOutlined />}
              >
                EDIT
              </Button>
            </Col>
            <Col className="ml-2">
              <Button
                onClick={() => {
                  Swal.fire({
                    title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                    showCancelButton: true,
                    showDenyButton: true,
                    showConfirmButton: false,
                    denyButtonText: "ตกลง",
                    cancelButtonText: "ยกเลิก",
                  }).then((result: { isConfirmed: any; isDenied: any }) => {
                    if (result.isDenied) {
                      HandleDelete();
                    }
                  });
                }}
                type="ghost"
                className="customer-service-btn-action action-cs-del"
                icon={<EditOutlined />}
              >
                DELETE
              </Button>
            </Col>
          </Row>
        }
        goBack={() => history.push(`/customerservice`)}
      />
      <Card
        style={{
          marginTop: "15px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <h3
          style={{
            fontSize: "17px",
            fontWeight: "bolder",
          }}
        >
          REQUIREMENT | ความต้องการของลูกค้า
        </h3>
      </Card>
      <Card>
        <Row gutter={15}>
          <Col lg={9}>
            <div className="side-card-sticky">
              <Card
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
              >
                <div className="d-flex y-center">
                  <img src={basic_info} alt="basic information" />
                  <h4
                    style={{
                      fontSize: "16px",
                      fontWeight: "bolder",
                      marginBottom: 0,
                      marginLeft: "10px",
                    }}
                  >
                    ข้อมูลพื้นฐาน
                  </h4>
                </div>
              </Card>
              <Card
                style={{
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                }}
              >
                <Row>
                  <Col lg={24} className="d-flex x-between">
                    <h5 className="text-14bold">รหัสลูกค้า</h5>
                    <h5 className="text-14">
                      {CheckEmptyValue(dataInfo?.userCS?.code)}
                    </h5>
                  </Col>

                  <Col lg={24} className="d-flex x-between">
                    <h5 className="text-14bold">INQUIRY NO.</h5>
                    <h5 className="text-14">
                      {CheckEmptyValue(dataInfo?.inquiryNO)}
                    </h5>
                  </Col>

                  <Col lg={24} className="d-flex x-between">
                    <h5 className="text-14bold">ชื่อบริษัท</h5>
                    <h5 className="text-14 text-overflow-info">
                      {CheckEmptyValue(dataInfo?.userCS?.companyName)}
                    </h5>
                  </Col>

                  <Col lg={24} className="d-flex x-between">
                    <h5 className="text-14bold">ชื่อผู้ติดต่อ</h5>
                    <h5 className="text-14 text-overflow-info">
                      {CheckEmptyValue(dataInfo?.userCS?.contactName)}
                    </h5>
                  </Col>

                  <Col lg={24} className="d-flex x-between">
                    <h5 className="text-14bold">ประเภทลูกค้า</h5>
                    <h5 className="text-14 text-overflow-info">
                      {CheckEmptyValue(dataInfo?.userCS?.type)}
                    </h5>
                  </Col>

                  <Col lg={24} className="d-flex x-between">
                    <h5 className="text-14bold">วันที่ | เวลา</h5>
                    <h5 className="text-14">
                      {CheckEmptyValue(dataInfo?.contactDate)}
                    </h5>
                  </Col>
                </Row>
                <div className="d-flex y-center mt-4">
                  <img src={caretaker} alt="basic information" />
                  <h4
                    style={{
                      fontSize: "16px",
                      fontWeight: "bolder",
                      marginBottom: 0,
                      marginLeft: "10px",
                    }}
                  >
                    ผู้ดูแล / รับเรื่อง
                  </h4>
                </div>
              </Card>
              <Card
                style={{
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                }}
              >
                <Row>
                  <Col lg={24} className="d-flex x-between">
                    <h5 className="text-14bold">ผู้ดูแล(AE)</h5>
                    <h5 className="text-14 text-overflow-info">
                      {`${CheckEmptyValue(
                        dataInfo?.aE?.firstName
                      )} ${CheckEmptyValue(dataInfo?.aE?.lastName)}`}
                    </h5>
                  </Col>

                  <Col lg={24} className="d-flex x-between">
                    <h5 className="text-14bold">ผู้ดูแล(AO)</h5>
                    <h5 className="text-14 text-overflow-info">
                      {`${CheckEmptyValue(
                        dataInfo?.aO?.firstName
                      )} ${CheckEmptyValue(dataInfo?.aO?.lastName)}`}
                    </h5>
                  </Col>

                  <Col lg={24} className="d-flex x-between">
                    <h5 className="text-14bold">ผู้รับเรื่อง</h5>
                    <h5 className="text-14 text-overflow-info">
                      {`${CheckEmptyValue(dataInfo?.createBy?.firstName)}
                      ${CheckEmptyValue(dataInfo?.createBy?.lastName)}`}
                    </h5>
                  </Col>
                </Row>
                <div className="d-flex y-center mt-4">
                  <img src={contact} alt="basic information" />
                  <h4
                    style={{
                      fontSize: "16px",
                      fontWeight: "bolder",
                      marginBottom: 0,
                      marginLeft: "10px",
                    }}
                  >
                    ช่องทางการติดต่อ
                  </h4>
                </div>
              </Card>
              <Card
                style={{
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <Row>
                  <Col lg={24} className="d-flex x-between">
                    <h5 className="text-14bold">ช่องทางการติดต่อ</h5>
                    <h5 className="text-14">{dataInfo?.contactChannel}</h5>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
          <Col lg={15}>
            <Card
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <div className="d-flex y-center">
                <img src={contact} alt="basic information" />
                <h4
                  style={{
                    fontSize: "16px",
                    fontWeight: "bolder",
                    marginBottom: 0,
                    marginLeft: "10px",
                  }}
                >
                  บันทึกเรื่องที่ติดต่อ
                </h4>
              </div>
            </Card>
            {dataInfo?.answer?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <Row style={{ background: "#DEDEDE", paddingLeft: "65px" }}>
                    <h4
                      style={{
                        fontSize: "16px",
                        fontWeight: "bolder",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      {item.firstAnswer.description}
                    </h4>
                  </Row>
                  <Card
                    style={{
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                    }}
                  >
                    {item.detail.map((child: any, idx: number) => {
                      return (
                        <div key={idx} className="mb-4">
                          <div className="d-flex y-center">
                            <img
                              src={poin}
                              width={25}
                              alt="basic information"
                            />
                            <h4
                              style={{
                                fontSize: "16px",
                                fontWeight: "bolder",
                                marginBottom: 0,
                                marginLeft: "15px",
                              }}
                            >
                              {child.secondAnswer?.description}
                              {child.thirdAnswer?.map(
                                (third: any, index: number) => {
                                  return " | " + third?.choice?.description;
                                }
                              )}
                            </h4>
                          </div>
                          <div
                            style={{
                              marginLeft: "40px",
                              marginTop: "15px",
                              marginRight: 40,
                            }}
                          >
                            <div>
                              {child?.fillDetail && (
                                // <h5 className="text-overflow-detail">
                                <h5>
                                  <span className="text-14bold">
                                    รายละเอียด :
                                  </span>
                                  <span className="text-14">
                                    {" "}
                                    {child.fillDetail}
                                  </span>
                                </h5>
                              )}
                              {child.fillDetailFile?.length > 0 && (
                                <div>
                                  <h5>
                                    <span className="text-14bold">
                                      ไฟล์รายละเอียด :
                                    </span>
                                  </h5>
                                  <Row className="mb-2">
                                    {child.fillDetailFile?.map(
                                      (item: any, index: number) => {
                                        return (
                                          <FileDisplay
                                            file={item}
                                            key={index}
                                          />
                                        );
                                      }
                                    )}
                                  </Row>
                                </div>
                              )}
                              {(child?.fillDetail ||
                                child.fillDetailFile?.length > 0) && (
                                <Divider />
                              )}
                            </div>
                            <div>
                              {child?.operationDetail && (
                                <h5 className="text-overflow-detail">
                                  <span className="text-14bold">
                                    การดำเนินการ :
                                  </span>
                                  <span className="text-14">
                                    {" "}
                                    {child.operationDetail}
                                  </span>
                                </h5>
                              )}
                              {child.operationDetailFile?.length > 0 && (
                                <div>
                                  <h5>
                                    <span className="text-14bold">
                                      ไฟล์การดำเนินการ :
                                    </span>
                                  </h5>
                                  <Row className="mb-4">
                                    {child.operationDetailFile?.map(
                                      (item: any, index: number) => {
                                        return (
                                          <FileDisplay
                                            file={item}
                                            key={index}
                                          />
                                        );
                                      }
                                    )}
                                  </Row>
                                </div>
                              )}
                              {(child?.operationDetail ||
                                child.operationDetailFile?.length > 0) && (
                                <Divider />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Card>
                </div>
              );
            })}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Report;

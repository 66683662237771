/* eslint-disable react-hooks/exhaustive-deps */

import { Form, Table } from "antd";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { AlertToast } from "../../../components/alert/alert";
import { tableLoading } from "../../../components/loading/loading";
import {
  CONTACTTYPE_EXCEL_API,
  GETCONTACTTYPEREPORT_API,
} from "../../../services/api/report/contact_type";
import { IResStatus } from "../../../tools/fecth/interface";
import { loadingTable } from "../../../tools/recoil/recoil-atom";
import Header from "./Header/Header";

const SubReportContactType = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [isFirstWork, setisFirstWork] = useState<boolean>(true);
  const [searchYears, setSearchYears] = useState(new Date().getFullYear().toString());
  const [contactType, setContactType] = useState<any[]>([]);

  const [form] = Form.useForm();
  const currentYear = new Date();

  useEffect(() => {
    if (isFirstWork) {
      form.setFieldsValue({ years: `${currentYear.getFullYear()}` });
      handleGetReportContactType();
      setisFirstWork(false);
    } else {
      handleGetReportContactType();
    }
    return undefined;

  }, [searchYears]);

  const handleGetReportContactType = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await GETCONTACTTYPEREPORT_API({
        year: searchYears,
      });
      if (res.code === 200) {
        const itemList = res.result[0].item;
        const totalList = res.result[0].total;
        let totalObj = {};
        totalList.forEach((item: any, idTotal: number) => {
          totalObj = {
            key: `total ${idTotal + 1}`,
            name: "TOTAL INQUIRY (ยอดรวมผู้ติดต่อเข้ามาทั้งหมด)",
            avr: res.result[0].average.toFixed(2),
            ...totalObj,
            [item.label]: item.count,
          };
        });

        const contactTypeDataFormat = itemList.map(
          (item: any, idMain: number) => {
            let mainObj = {};

            item.header.forEach((header: any) => {
              mainObj = {
                key: `main ${idMain + 1}`,
                name: `${idMain + 1}. ${item.name}`,
                avr: item.average.toFixed(2),
                ...mainObj,
                [header.label]: header.count,
              };
            });
            let childrenList: any[] = [];

            item.subHeader.forEach((subHeader: any, idChild: number) => {
              let children = {};
              children = { name: subHeader.name, key: `child ${idChild + 1}` };
              subHeader.data.forEach((child: any) => {
                children = {
                  ...children,
                  [child.label]: child.count,
                  avr: subHeader.average.toFixed(2),
                };
              });
              childrenList = [...childrenList, children];
            });
            mainObj = { ...mainObj, children: [...childrenList] };
            return mainObj;
          }
        );
        const mergeContactType = [...contactTypeDataFormat, totalObj];
        setContactType(mergeContactType);
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleExportExcel = async () => {
    setLoading(true);
    try {
      const res = await CONTACTTYPE_EXCEL_API({
        year: searchYears,
      });
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `contact-type.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถส่งออกข้อมูลได้`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const filterCoulumnToCurrentMount = (columns: any) => {
    const dateTime = new Date();
    const currentMount = dateTime.getMonth();
    const toCurrentMount = Number(searchYears) === dateTime.getFullYear()
    ? columns.filter(
      (item: any) =>
        item.key === "name" ||
        item.key === "avr" ||
        Number(item.key) <= currentMount + 1
    ) : columns;
    return toCurrentMount;
  };

  const columns = [
    {
      title: "ช่องทางการติดต่อ",
      dataIndex: "name",
      key: "name",
      width: 400,
      fixed: true,
    },

    {
      title: "ม.ค.",
      dataIndex: "1",
      key: "1",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "ก.พ.",
      dataIndex: "2",
      key: "2",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "มี.ค.",
      dataIndex: "3",
      key: "3",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "เม.ย.",
      dataIndex: "4",
      key: "4",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "พ.ค.",
      dataIndex: "5",
      key: "5",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "มิ.ย.",
      dataIndex: "6",
      key: "6",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "ก.ค.",
      dataIndex: "7",
      key: "7",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "ส.ค.",
      dataIndex: "8",
      key: "8",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "ก.ย.",
      dataIndex: "9",
      key: "9",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "ต.ค.",
      dataIndex: "10",
      key: "10",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "พ.ย.",
      dataIndex: "11",
      key: "11",
      width: 100,
       align: "center" as "center",
    },
    {
      title: "ธ.ค.",
      dataIndex: "12",
      key: "12",
      width: 100,
       align: "center" as "center",
    },

    {
      title: "ค่าเฉลี่ย",
      dataIndex: "avr",
      key: "avr",
      width: 100,
      align: "center" as "center",
    },
  ];

  const handleFilter = (value: string) => {
    setSearchYears(value);
  };

  return (
    <div>
      <Header
        form={form}
        handleFilter={handleFilter}
        handleExportExcel={handleExportExcel}
        data={{
          item: {
            key: 1,
            text: "รายงานสรุปยอดรวมผู้ติดต่อเข้ามาแยกตามประเภทผู้ติดต่อจากช่องทางการติดต่อต่างๆ",
          },
        }}
      >
        <Table
          rowKey={(row) => row.key}
          style={{ marginTop: "20px" }}
          scroll={{y: 500 }}
          dataSource={contactType}
          loading={tableLoading()}
          columns={filterCoulumnToCurrentMount(columns)}
          bordered
          pagination={false}
          expandRowByClick
        />
      </Header>
    </div>
  );
};

export default SubReportContactType;

import { PDF } from "../assets/img";

const UploadFileLabel = ({ name, value }: { value: string; name?: string }) => {
  return (
    <a
      href={value}
      target="_blank"
      rel="noreferrer"
      style={{
        backgroundColor: "#F4F7FC",
        cursor: "pointer",
        borderRadius: 5,
        color: "gray",
      }}
      className="py-1 px-2"
      download={name}
    >
      <img
        src={PDF}
        alt="etc"
        style={{ color: "#CB1F2B" }}
        width={15}
        className="mr-1"
      />{" "}
      <span style={{ color: "#6F6F6F" }}>{name}</span>
    </a>
  );
};

export default UploadFileLabel;

import { Form, Input } from "antd";
import { FloatLabel } from "..";
type Props = {
  options?: any;
  label: string;
  name: string;
  rules?: { required: boolean; message?: string };
  type?: string;
};
const { TextArea } = Input;
const FInput = ({
  options,
  label = "",
  name,
  rules = { required: false, message: "" },
  type,
}: Props) => {
  return (
    <Form.Item
      className="formlabel"
      name={name}
      rules={[
        {
          required: rules && rules.required,
          message:
            rules && rules.message ? rules.message : `โปรดกรอกข้อมูล${label}!`,
        },
      ]}
    >
      <ChildrenRender {...options} label={label} type={type} />
    </Form.Item>
  );
};
const ChildrenRender = (propsChild: any) => (
  <>
    <FloatLabel
      label={propsChild.label ? propsChild.label : ""}
      name={propsChild.name}
      value={propsChild.value}
    >
      <TextArea {...propsChild} type={propsChild.type} />
    </FloatLabel>
  </>
);

export default FInput;

/* eslint-disable react-hooks/exhaustive-deps */

import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { _isEmpty } from "@yim/util-tool-ts";

import { Card, Col, Form, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSetRecoilState } from "recoil";
import { FormDefault } from "../../../../../components";
import { CAlertToast } from "../../../../../components/alert/CAlert";
import { tableLoading } from "../../../../../components/loading/loading";
import TableModifind from "../../../../../components/table/Table";
import { ISettingList } from "../../../../../interface/ISetting.interface";
import {
  ADDCONTACTCHANNEL_API,
  DELETECONTACTCHANNEL_API,
  EDITCONTACTCHANNEL_API,
  GETCONTACTCHANNEL_API,
} from "../../../../../services/api/setting/contact_channel_api";

import { loadingTable } from "../../../../../tools/recoil/recoil-atom";
import { HandleScroll, toFormData } from "../../../../../tools/util";
import HeaderSetting from "./HerderSetting";

const ContactChannel = () => {
  const setLoading = useSetRecoilState(loadingTable);
  const history = useHistory();
  const [manageContent, setManageContent] = useState<boolean>(false);
  const [valueContactChannel, setValueContactChannel] = useState<string>("");
  const [id, setId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(10);
  const [initialValueSearch, setInitialValueSearch] = useState({
    search: "",
  });
  const [contactChanelList, setContactChannelList] = useState<ISettingList[]>(
    []
  );

  const [formHeader] = Form.useForm();

  useEffect(() => {
    handleGetData();
    return undefined;

  }, [currentPage, limitPage, initialValueSearch]);

  const handleGetData = () => {
    handleGetContactChannel();
  };

  const handleSetValue = (value: any) => {
    HandleScroll("setting-header");
    setManageContent(true);
    setId(value.id);
    setValueContactChannel(value.name);

    formHeader.setFieldsValue({
      name: value.name,
      showIn: value.showIn,
      icon: value.icon ? [{ name: value.icon.name, url: value.icon.url }] : [],
    });
  };

  const handleClickEdit = () => {
    setManageContent(!manageContent);
    setValueContactChannel("");
    setId(0);
  };

  const onFinish = (value: any) => {
    if (id !== 0) handleUpdate(value);
    else handleCreate(value);
  };

  const handleCreate = async (data: any) => {
    setLoading(true);
    try {
      const dataFormat = {
        ...data,
        icon: data.icon ? data.icon[0].originFileObj : "",
      };
      const formData = toFormData(dataFormat);
      const res = await ADDCONTACTCHANNEL_API(formData);
      if (res.code === 200) {
        handleClickEdit();
        formHeader.resetFields();
        CAlertToast({ method: "POST_SUCCESS", text: res.message });
        handleGetContactChannel();
      } else {
        CAlertToast({ method: "POST_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleUpdate = async (data: any) => {
    setLoading(true);
    try {
      const dataFormat = {
        ...data,
        icon: data.icon ? data.icon[0].originFileObj : "",
      };
      const formData = toFormData(dataFormat);
      const res = await EDITCONTACTCHANNEL_API({ id, data: formData });
      if (res.code === 200) {
        handleClickEdit();
        formHeader.resetFields();
        CAlertToast({ method: "PUT_SUCCESS", text: res.message });
        handleGetContactChannel();
        setId(0);
      } else {
        CAlertToast({ method: "PUT_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      const res = await DELETECONTACTCHANNEL_API(id);
      if (res.code === 200) {
        CAlertToast({ method: "DEL_SUCCESS", text: res.message });
        setContactChannelList(
          contactChanelList.filter((item) => item.id !== id)
        );
      } else {
        CAlertToast({ method: "DEL_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };
  const handleSearch = (values: any) => {
    setCurrentPage(1);
    setInitialValueSearch({ ...values });
  };

  const addButton = [
    {
      type: "btnPush",
      name: "เพิ่ม",
      onClick: () => history.push(`/setting/contact-type`),
    },
  ];

  const fieldFilter = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา ช่องทางติดต่อ",
      initialValue: initialValueSearch.search,
    },
  ];

  const columns: any = [
    {
      title: "ลำดับ",
      align: "center",
      width: "10%",
      render: (_value: any, _item: any, index: number) => {
        return <>{(currentPage - 1) * limitPage + (index + 1)}</>;
      },
    },
    {
      title: "ไอคอน",
      dataIndex: "icon",
      key: "icon",
      render: (value: { name: string; url: string }) => {
        return (
          <>
            {!_isEmpty(value) ? (
              <img src={value.url} alt="icons" className="icon-image" />
            ) : (
              <span style={{ color: "lightgray" }}>ไม่มีรูปภาพ</span>
            )}
          </>
        );
      },
    },
    {
      title: "ช่องทางติดต่อ",
      dataIndex: "name",
      key: "name",
      width: "45%",
    },
    {
      title: "ไปยัง",
      dataIndex: "showIn",
      key: "showIn",
    },
    {
      title: "จัดการ",
      align: "center",
      render: (value: ISettingList) => {
        return (
          <div className="action-table">
          <EditOutlined
            onClick={() => handleSetValue(value)}
            className="faq-btn-edit"
          />
          <Popconfirm
            className="pop-faq"
            placement="top"
            title={"คุณเเน่ใจหรือไม่"}
            onConfirm={() =>handleDelete(value.id)}
            okType="danger"
            okText="ลบ"
            cancelText="ยกเลิก"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okButtonProps={{
              style: {
                width: 50,
              },
            }}
          >
            <DeleteOutlined className="ml-3 faq-btn" />
          </Popconfirm>
        </div>
        );
      },
    },
  ];

  const handleGetContactChannel = async () => {
    setLoading(true);
    try {
      const res = await GETCONTACTCHANNEL_API({
        page: currentPage,
        limit: limitPage,
        ...initialValueSearch,
      });
      if (res.code === 200) {
        setContactChannelList(res.result[0].item);
        const dataSet = res.result[0];
        setTotalPage(dataSet.total);
        setLimitPage(dataSet.perpage);
      }
    } catch (error) {}

    setLoading(false);
  };

  return (
    <div id="setting-header">
      <HeaderSetting
        form={formHeader}
        Title={tiltleHeader}
        Action={addButton}
        handleClickEdit={handleClickEdit}
        handleClickCancel={handleClickEdit}
        disabledEdit={false}
        editOnPage={true}
        manageContent={manageContent}
        actionName={!_isEmpty(valueContactChannel) ? "EDIT" : "ADD"}
        onFinish={onFinish}
      />
      <div className="form-tab-bg">
        <FormDefault
          HandleOnFinish={handleSearch}
          Field={fieldFilter}
          Action={[]}
        />
      </div>
      <Row>
        <Col lg={24} md={24} className="mt-3">
          <Card
            className="setting-cover-card"
            cover={
              <>
                <h1 className="mb-0">
                  <span
                    style={{
                      fontFamily: "Bold",
                      color: "#faa41a",
                      fontSize: "23px",
                    }}
                  >
                    CONTACT CHANNEL
                  </span>
                  <span
                    className="ml-2"
                    style={{ fontFamily: "Bold", fontSize: "23px" }}
                  >
                    ช่องทางติดต่อ
                  </span>
                </h1>
              </>
            }
          >
            <TableModifind
              cursor={true}
              rowKey="id"
              loading={tableLoading()}
              bordered={false}
              config={{ currentPage, total: totalPage, pageSize: limitPage }}
              columns={columns}
              dataSource={contactChanelList}
              onChangePage={onChangePage}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const tiltleHeader = {
  nameEN: "CONTACT CHANNEL",
  nameTH: "ช่องทางติดต่อ",
};

export default ContactChannel;

import { Fetch } from "../../tools/fecth/fecth";
import { apiVersion } from "../../config/api";

import { createQueryString } from "../../tools/util";

export const GETFAQDATA_API = async (data?: any) => {
  const result = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/f-a-q${result}`,
  });
  return res;
};

export const GETFAQDATA_ID_API = async (data?: any) => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/f-a-q/${data}`,
    data: data,
  });
  return res;
};

export const UPDATE_FAQ_BY_ID_API = async (id:number,data?: any) => {
    const res = await Fetch({
      method: "PUT",
      path: `${apiVersion}/f-a-q/${id}`,
      data: data,
    });
    return res;
  };

export const ADDFAQDATA_API = async (data?: any) => {
  const res = await Fetch({
    method: "POST",
    path: `${apiVersion}/f-a-q`,
    data: data,
  });
  return res;
};

export const DELETEFAQDATA_API = async (id: any) => {
  const res = await Fetch({
    method: "DELETE",
    path: `${apiVersion}/f-a-q/${id}`,
  });
  return res;
};

import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { useHistory } from "react-router-dom";

const HeaderLead = ({
  title = "NEW INQUIRY",
  message = "กรอกข้อมูลความต้องการของลูกค้า",
}) => {
  const history = useHistory();
  return (
    <Card
      style={{
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        // zIndex: 1060,
        marginBottom: 15,
      }}
      className="header-setting border-header-diy-no-tab"
    >
      <Row className="page-heder">
        <div style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
          <Row gutter={12} align="middle">
            <Col>
              <LeftOutlined style={{ fontSize: "16px" }} />
            </Col>
            <Col>
              <div
                style={{
                  fontFamily: "bold",
                  color: "#FFC627",
                  fontSize: "23px",
                }}
              >
                {title}
              </div>
              <div
                style={{
                  fontFamily: "SemiBold",
                  fontSize: "18px",
                }}
              >
                {message}
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </Card>
  );
};
export default HeaderLead;

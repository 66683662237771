
export const titleFormat: any = {
    POST_SUCCESS: {
        type: "success",
        title: "สร้างข้อมูลสำเร็จ",
    },
    POST_ERROR: {
        type: "error",
        title: "สร้างข้อมูลไม่สำเร็จ",
    },
    PUT_SUCCESS: {
        type: "success",
        title: "อัปเดตข้อมูลสำเร็จ",
    },
    PUT_ERROR: {
        type: "error",
        title: "อัปเดตข้อมูลไม่สำเร็จ",
    },
    DEL_SUCCESS: {
        type: "success",
        title: "ลบข้อมูลสำเร็จ",
    },
    DEL_ERROR: {
        type: "error",
        title: "ลบข้อมูลไม่สำเร็จ",
    },
    DOWNLOAD_SUCCESS: {
        type: "success",
        title: "ดาวน์โหลดสำเร็จ",
    },
    DOWNLOAD_ERROR: {
        type: "error",
        title: "ดาวน์โหลดไม่สำเร็จ",
    },

    UPLOAD_SUCCESS: {
        type: "success",
        title: "อัพโหลดสำเร็จ",
    },
    UPLOAD_ERROR: {
        type: "error",
        title: "อัพโหลดไม่สำเร็จ",
    },
    TRANSFER_SUCCESS: {
        type: "success",
        title: "โอนย้ายสำเร็จ",
    },
    TRANSFER_ERROR: {
        type: "error",
        title: "โอนย้ายไม่สำเร็จ",
    },
};

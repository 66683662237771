import { Form, Select } from "antd";
import { FloatLabel } from "..";
type Props = {
  options?: any;
  label: string;
  name: string;
  rules?: { required: boolean; message?: string };
  dataSource: any[];
  keyName: string;
  keyName2?: string;
  keyValue: string;
  onChange?: (value: any) => void;
};

const FSelect = ({
  options,
  label = "",
  name,
  rules = { required: false, message: "" },
  dataSource = [],
  keyName,
  keyName2,
  keyValue,
  onChange,
}: Props) => {
  const { Option } = Select;

  const ChildrenRender = (propsChild: any) => (
    <FloatLabel
      label={propsChild.label ? propsChild.label : ""}
      name={propsChild.name}
      value={propsChild.value}
    >
      <Select {...propsChild} >
        {propsChild.datasource.map((item: any, idx: number) => {
          return (
            <Option value={item[keyValue]} key={idx}>
              {item[keyName]} <span>{keyName2 && item[keyName2]}</span>
            </Option>
          );
        })}
      </Select>
    </FloatLabel>
  );

  return (
    <Form.Item
      className="formlabel"
      name={name}
      rules={[
        {
          required: rules && rules.required,
          message:
            rules && rules.message ? rules.message : `โปรดกรอกข้อมูล${label}!`,
        },
      ]}
    >
      <ChildrenRender
        {...options}
        label={label}
        datasource={dataSource}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default FSelect;

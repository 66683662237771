import { Checkbox, Row, Col, Form } from "antd";
export interface IOptionValue {
  values: object[];
  key: string;
  name: any;
  span:number;
}


const TxCheckBoxGroup = ({
  optionValue = { span: 8, values: [], key: "", name: "" },
  ...props
}: {
  optionValue: IOptionValue;
  value?: any;
  disabled?: boolean;
}) => {
  const renderOption = (): any => {
    return (
      <Row gutter={15} >
        <Col span={24}>
          <Row>
            {optionValue.values?.map((item: any, idx: number) => {
              return (
                <Col key={idx} span={optionValue.span || 8}>
                  <Form.Item
                    name={item[optionValue.name]}
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: false,
                        message: "กรุณาเลือกสินค้าที่สนใจ!",
                      },
                    ]}
                  >
                    <Checkbox value={item[optionValue.key]}>
                      {item[optionValue.name]}
                    </Checkbox>
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    );
  };

  return <Checkbox.Group {...props}>{renderOption()}</Checkbox.Group>;
};

export default TxCheckBoxGroup;

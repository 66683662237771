/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  FormInstance,
  Button,
  Popover,
  Progress,
  Input,
  Space,
  DatePicker,
  Select,
  Divider,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  CaretDownOutlined,
  SaveOutlined,
  CaretUpOutlined,
  CalendarOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { ICurrentItem } from "./Leads";
import HashLoader from "react-spinners/HashLoader";
import { EFields, tab_edit_selector, tab_visible_selector } from "./_store";
import { useRecoilState, RecoilRoot } from "recoil";
import UploadFileLabel from "../../../components/UploadFileLabel";
import TxCheckBoxGroup from "../../../components/CheckBoxGroup";
import {
  GET_INQUIRY_BY_ID,
  INQUERY_GET_FORM_API,
  UPDATE_INITAIL_SERVICE,
  UPDATE_REQUIMENT,
  UPDATE_CUSTOMER_CONTACT,
  GET_ADMIN_ALL,
  FORWARD_FORM_ITEM,
  ACCEPT_FORM_ITEM,
  DELETE_ITEM_NOTE_BY_ID,
  UPDATE_ACTIVITY_BY_ID,
  POST_NOTE,
  SOURT_NOTE,
  POST_ACTIVITY,
  SUCCESS_ACTIVITY,
  AUTO_INQUIRY_BY_ID,
  CREATE_INQUIRY_BY_ID,
  STEP_DONE_API,
  NOTE_TRACKING_API,
  GET_FORCAST_BY_ID,
  POST_FORCAST,
  UPDATE_FORCAST,
  DELETE_CARD_BY_ID,
} from "../../../services/api/Lead_api";
import moment from "moment";
import UploadFile from "../../../components/upload_file/UploadFile";
import FSelect from "../../../components/form_floatlabel/FSelect";
import FInput from "../../../components/form_floatlabel/FInput";
import FTextArea from "../../../components/form_floatlabel/FTextArea";
import { AlertToast } from "../../../components/alert/alert";
import InputPickerFile from "../../../components/input_picker_file/InputPickerFile";
import InputPickerFilePost from "../../../components/input_picker_file/InputPickerFilePost";
import { generateButton } from "../../../components/button_generate";
import { ConvertDateNum, HandleScroll, _numToDate } from "../../../tools/util";
import { IDataDetailCard } from "../../../interface/ILead.interface";
import VisibleDivider from "../../../components/DetailCardProject/VisibleDivider";
import { profile } from "../../../assets/img/index";
import { _isEmpty } from "@yim/util-tool-ts";
import FDatePicker from "../../../components/form_floatlabel/FDatePicker";
interface ICardModal {
  visible: boolean;
  boardItem: ICurrentItem;
  toggle: () => void;
  onChange: () => void;
}
interface IFieldParent {
  fieldTitle: string;
  key: EFields;
  items: IFieldChild[];
  visibleChildKey?: string;
  visibleSubChildKey?: string;
}
interface ICol {
  span?: number;
  className?: string;
  style?: {};
}
interface IFieldChild {
  label: string;
  name: string;
  value: any;
  input?: {
    type?: EInputType;
    props?: any;
  };
  col?: ICol;
  typeSelf?: string;
}

interface IFormInstance {
  contactForm: { form: FormInstance<any>; id: string; field: IFieldParent };
  requirmentForm: { form: FormInstance<any>; id: string; field: IFieldParent };
  actionForm: { form: FormInstance<any>; id: string; field: IFieldParent };
}

enum EInputType {
  INPUT = "INPUT",
  CHECKBOX = "CHECKBOX",
  SELECT = "SELECT",
  UPLOAD = "UPLOAD",
  TEXTAREA = "TEXTAREA",
  DATE = "DATE",
}

const { TextArea } = Input;
const { Option } = Select;

const DetailCardModal = ({
  visible,
  toggle,
  boardItem,
  onChange,
}: ICardModal) => {
  let inquiry_No = boardItem?.id;
  const [loading, setLoading] = useState<boolean>(false);
  const [contactForm] = Form.useForm();
  const [requirmentForm] = Form.useForm();
  const [actionForm] = Form.useForm();
  const [adminList, setAdminList] = useState([]);
  const [dataSource, setDatasource] = useState<IDataDetailCard>({
    customerContact: {
      address: "",
      companyName: "",
      district: "",
      email: "",
      explainAboutBusiness: "",
      firstName: "",
      inquiryNO: "",
      lastName: "",
      nickname: "",
      phone: "",
      position: "",
      postalCode: "",
      province: "",
      specAlumet: null,
      subDistrict: "",
      line: "",
    },
    action: {
      admin: "",
      dateTime: "",
      initialService: null,
      otherContact: { id: 0, name: "" },
    },
    requirement: {
      aluminiumColor: [{ id: 0, name: "" }],
      contactChannel: [{ id: 0, name: "" }],
      file: [
        {
          name: "",
          url: "",
        },
      ],
      interestedProduct: [{ id: 0, name: "" }],
      projectCategory: { id: 0, name: "" },
      projectName: "",
      requirement: null,
      requirementType: { id: 0, name: "" },
      subjectContact: [{ id: 0, name: "" }],
      userContactType: { id: 0, name: "" },
      specAlumet: [0],
    },
    note: [],
    activity: {},
    contactDate: "",
    date: "",
  });
  const [sourtNote, setSourtNote] = useState("เรียงกิจกรรมล่าสุด");
  const [forCastField, setForCastField] = useState({
    forcastName: [undefined],
  });

  const [listItem, setListItem] = useState({
    projectList: [],
    colorList: [],
    interestedList: [],
    contactChannelList: [],
    requirementTypeList: [],
    subjectContactList: [],
    userContactTypeList: [],
  });

  const contact_field: IFieldParent = {
    key: EFields.CUSTOMER_CONTACT,
    fieldTitle: "CUSTOMER CONTACT | ข้อมูลการติดต่อลูกค้า",
    visibleChildKey: "child1",
    visibleSubChildKey: "subChild1",
    items: [
      {
        label: "INQUIRY NO",
        value: dataSource?.customerContact?.inquiryNO,
        col: { span: 8 },
        name: "inquiryNO",
        input: {
          props: { disabled: true },
        },
      },
      {
        label: "วัน : เวลา",
        value: !_isEmpty(dataSource?.date)
          ? _numToDate(Number(dataSource?.date), "DD/MM/YYYY | h:mm:ss")
          : "",
        col: { span: 8 },
        name: "date",
        input: {
          props: { disabled: true },
        },
      },
      {
        label: "วันที่ติดต่อ",
        value: !_isEmpty(dataSource?.contactDate)
          ? _numToDate(Number(dataSource?.contactDate))
          : _numToDate(Number(dataSource?.date)),
        col: { span: 8 },
        name: "contactDate",
        input: {
          type: EInputType.DATE,
          props: { disabled: false, rule: { required: true } },
        },
      },
      {
        label: "divider",
        value: "",
        col: { span: 24 },
        name: "",
      },
      {
        label: "ชื่อ - นามสกุล",
        value: `${dataSource?.customerContact?.firstName} ${dataSource?.customerContact?.lastName}`,
        col: { span: 8 },
        name: "fullName",
        input: {
          props: { disabled: false },
        },
      },
      {
        label: "ชื่อจริง",
        value: dataSource?.customerContact?.firstName,
        name: "firstName",
        col: { span: 8 },
        input: {
          props: { disabled: false },
        },
      },
      {
        label: "นามสกุล",
        value: dataSource?.customerContact?.lastName,
        name: "lastName",
        col: { span: 8 },
        input: {
          props: { disabled: false },
        },
      },
      {
        label: "ชื่อเล่น",
        value: dataSource?.customerContact?.nickname,
        name: "nickname",
        col: { span: 8 },
        input: {
          props: { disabled: false },
        },
      },
      {
        label: "เบอร์โทรศัพท์",
        value: dataSource?.customerContact?.phone,
        name: "phone",
        col: { span: 8 },
        input: {
          props: { disabled: false },
        },
      },
      {
        label: "อีเมล",
        value: dataSource?.customerContact?.email,
        col: { span: 8 },
        name: "email",
        input: {
          props: { rule: { required: false }, disabled: false },
        },
      },
      {
        label: "ไลน์",
        value: dataSource?.customerContact?.line,
        col: { span: 8 },
        name: "line",
        input: {
          props: { rule: { required: false }, disabled: false },
        },
      },
      {
        label: "ชื่อบัญชีโซเชียลมีเดีย",
        value: dataSource?.customerContact?.socialName,
        col: { span: 8 },
        name: "socialName",
        input: {
          props: { rule: { required: false }, disabled: false },
        },
      },
      // child
      {
        label: "ชื่อบริษัท",
        value: dataSource?.customerContact?.companyName,
        col: { span: 12 },
        name: "companyName",
        typeSelf: "child",
      },
      {
        label: "ตำแหน่งงาน หรือแผนก",
        value: dataSource?.customerContact?.position,
        col: { span: 12 },
        name: "position",
        typeSelf: "child",
      },
      {
        label: "ที่อยู่",
        value: dataSource?.customerContact?.address,
        col: { span: 24 },
        name: "address",
        typeSelf: "child",
      },
      {
        label: "ตำบล",
        value: dataSource?.customerContact?.subDistrict,
        col: { span: 6 },
        name: "subDistrict",
        typeSelf: "child",
      },
      {
        label: "อำเภอ",
        value: dataSource?.customerContact?.district,
        col: { span: 6 },
        name: "district",
        typeSelf: "child",
      },
      {
        label: "จังหวัด",
        value: dataSource?.customerContact?.province,
        col: { span: 6 },
        name: "province",
        typeSelf: "child",
      },
      {
        label: "รหัสไปรษณีย์",
        value: dataSource?.customerContact?.postalCode,
        col: { span: 6 },
        name: "postalCode",
        typeSelf: "child",
      },
      // sub-child
      {
        label: "อธิบายเกี่ยวกับธุรกิจ",
        value: dataSource?.customerContact?.explainAboutBusiness,
        col: { span: 24 },
        name: "explainAboutBusiness",
        input: {
          type: EInputType.TEXTAREA,
          props: { rows: 3 },
        },
        typeSelf: "subChild",
      },
    ],
  };

  const requirment_field: IFieldParent = {
    key: EFields.REQUIREMENT,
    fieldTitle: "REQUIREMENT | ความต้องการของลูกค้า",
    visibleChildKey: "child2",
    items: [
      {
        label: "ประเภทผู้ติดต่อ",
        value: dataSource.requirement?.userContactType?.id,
        name: "userContactType",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            menuItem: {
              values: listItem?.userContactTypeList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "ประเภทโครงการ",
        value: dataSource.requirement?.projectCategory?.id,
        name: "projectCategory",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            menuItem: {
              values: listItem?.projectList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "สีอลูมิเนียม",
        value:
          dataSource.requirement?.aluminiumColor?.map((item) => item.id) || [],
        name: "colorAluminium",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            multiple: true,
            menuItem: {
              values: listItem?.colorList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "ช่องทางการติดต่อ",
        value:
          dataSource.requirement?.contactChannel?.map((item) => item.id) || [],
        name: "contactChannel",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            multiple: true,
            menuItem: {
              values: listItem?.contactChannelList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "ชื่อโครงการ",
        value: dataSource.requirement.projectName,
        name: "projectName",
        col: { span: 8 },
        input: {
          props: {
            rule: { required: true },
          },
        },
      },
      {
        label: "สินค้าที่สนใจ",
        col: { span: 8 },
        value: dataSource.requirement?.interestedProduct,
        name: "interestedProduct",
        input: {
          type: EInputType.SELECT,
          props: {
            multiple: true,
            menuItem: {
              values: listItem?.interestedList,
              key: "id",
              name: "nameTh",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "เรื่องที่ต้องการติดต่อ",
        value: dataSource.requirement?.subjectContact?.map((item) => item.id),
        name: "subjectContact",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            multiple: true,
            menuItem: {
              values: listItem?.subjectContactList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "ประเภทความต้องการ",
        value: dataSource.requirement?.requirementType?.id,
        name: "requirementType",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            menuItem: {
              values: listItem?.requirementTypeList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "",
        value: dataSource.requirement?.specAlumet,
        name: "specAlumet",
        col: { className: "d-flex y-center", span: 8 },
        input: {
          type: EInputType.CHECKBOX,
          props: {
            optionValue: {
              span: 24,
              name: "label",
              key: "id",
              values: [{ label: "สแป็คอลูเม็ท", id: 1 }],
            },
          },
        },
      },
      {
        label: "อัพโหลดไฟล์",
        value: dataSource.requirement?.file,
        name: "file",
        input: {
          type: EInputType.UPLOAD,
          props: {
            disabled: true,
          },
        },
      },
      //child
      {
        label: "ความต้องการของลูกค้า",
        value: dataSource.requirement.requirement,
        name: "requirement",
        input: {
          type: EInputType.TEXTAREA,
          props: {
            rows: 3,
            rule: { required: false },
          },
        },
        col: { span: 24 },
        typeSelf: "child",
      },
    ],
  };

  const action_field: IFieldParent = {
    key: EFields.ACTION,
    fieldTitle: "ACTION | การให้บริการเบื้องต้น",
    items: [
      {
        label: "ผู้ให้บริการ",
        value: dataSource.action.admin,
        name: "admin",
        input: { props: { disabled: true } },
      },
      {
        label: "วันที่และเวลา",
        value: !_isEmpty(dataSource.action.dateTime)
          ? moment(Number(dataSource.action.dateTime)).format(
              "DD/MM/YYYY | h:mm:ss"
            )
          : "",
        name: "dateTime",
        input: { props: { disabled: true } },
      },
      {
        label: "การดำเนินการ",
        value: dataSource.action.initialService,
        col: { span: 24 },
        name: "initialService",
      },
      {
        label: "แจ้งลูกค้าติดต่อช่องทางอื่นๆ",
        value: dataSource.action?.otherContact?.id,
        col: { span: 24 },
        name: "otherContact",
        input: {
          type: EInputType.SELECT,
          props: {
            // multiple: true,
            menuItem: {
              values: listItem?.contactChannelList,
              key: "id",
              name: "name",
            },
          },
        },
      },
    ],
  };

  const note_field = {
    fieldTitle: "NOTE | บันทึกการดำเนินการ",
    key: EFields.NOTE,
    items: {
      listField: dataSource.note,
    },
  };

  const activity_field = {
    fieldTitle: "ACTIVITY | กิจกรรมระหว่างดำเนินการ",
    key: EFields.ACTIVITY,
    items: {
      listField: dataSource.activity,
    },
  };

  const initialForm: IFormInstance = {
    contactForm: { form: contactForm, id: "contactForm", field: contact_field },
    requirmentForm: {
      form: requirmentForm,
      id: "requirmentForm",
      field: requirment_field,
    },
    actionForm: { form: actionForm, id: "actionForm", field: action_field },
  };

  //remove form className from Form Element
  for (const [, value] of Object.entries(initialForm)) {
    document.getElementById(value.id)?.classList.remove("ant-form");
  }

  useEffect(() => {
    GET_DATA();
    HandleGetAllDatalist();
    HandleGetAdminAll();
    if (boardItem?.status === "doing") {
      HandleGetByIdForCast();
    }
    return undefined;
  }, [visible]);

  useEffect(() => {
    setFormField(initialForm.contactForm);
    setFormField(initialForm.requirmentForm);
    setFormField(initialForm.actionForm);
    return undefined;
  }, [dataSource]);

  const HandleAllSuccessDoing = (code: number, scroll?: string) => {
    if (code === 200) {
      GET_DATA(scroll);
    } else {
      setLoading(false);
    }
  };

  const HandleGetAdminAll = async () => {
    const currentUser = JSON.parse(localStorage.getItem("userInfo") || "");
    try {
      const res = await GET_ADMIN_ALL();
      if (res.code === 200) {
        const list = res.result[0].item.filter(
          (item: any) =>
            `${currentUser?.firstName} ${currentUser?.lastName}` !==
            `${item?.firstName} ${item?.lastName}`
        );
        setAdminList(list);
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถเรียกข้อมูลเเอดมินได้เนื่องจาก ${res.message}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GET_DATA = async (scroll?: string) => {
    if (!visible) return;
    setLoading(true);
    try {
      const res = await GET_INQUIRY_BY_ID(boardItem?.id);
      if (res.code === 200) {
        const [result] = res.result;
        setDatasource({
          ...dataSource,
          customerContact: result?.customerContact,
          action: result?.action,
          requirement: {
            ...result?.requirement,
            file: result?.requirement?.file,
            specAlumet: result?.requirement?.specAlumet ? [1] : [0],
            interestedProduct: result?.requirement?.interestedProduct?.map(
              (item: any) => item.id
            ),
          },
          activity: result?.activity,
          note: result?.note,
          contactDate: result?.contactDate,
          date: result?.createdAt,
        });
        onChange();
      } else {
        AlertToast({
          icon: "error",
          title: `เกิดข้อผิดพลาด! ระบบไม่สามารถเรียกข้อมูลได้ กรุณาลองอีกครั้งในภายหลัง`,
        });
        setTimeout(function () {
          toggle();
          onChange();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      HandleScroll(scroll || "");
    }
  };

  const HandleGetAllDatalist = async () => {
    try {
      const res = await INQUERY_GET_FORM_API();
      if (res.code === 200) {
        const result = res.result[0];
        setListItem({
          ...listItem,
          projectList: result?.projectCategory || [],
          colorList: result?.aluminiumColor || [],
          interestedList: result?.interestedProduct || [],
          contactChannelList: result?.contactChannel || [],
          requirementTypeList: result?.requirementType || [],
          subjectContactList: result?.subjectContact || [],
          userContactTypeList: result?.userContactType || [],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setFormField = ({
    form,
    field,
  }: {
    field: IFieldParent;
    form: FormInstance<any>;
  }) => {
    const { items } = field;
    const val = items.reduce((result, item) => {
      return { ...result, [item.name]: item.value };
    }, {});
    form.setFieldsValue(val); //this is warning pass form
  };

  ///////////////////////////////////////////////////////////////////////// FieldGenerateNote /////////////////////////////////////////////////////////////
  const FieldGenerateNote = ({ fieldList }: { fieldList: any }) => {
    const [vsb, setVsb] = useRecoilState(tab_visible_selector(fieldList.key));

    const listField = fieldList?.items?.listField || [];
    const onPost = async (value: any) => {
      let resCode = 0;
      setLoading(true);
      const inquiryId = boardItem?.id || 0;
      const formData = new FormData();
      for (let i = 0; i < value.files.length; i++) {
        formData.append("fileUpload", value?.files[i]?.originFileObj || "");
      }
      formData.append("inquiryId", inquiryId.toString());
      formData.append("note", value?.text || "");
      try {
        const res = await POST_NOTE(formData);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! บันทึกข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถบันทึกข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      }
      HandleAllSuccessDoing(resCode, "noteField");
    };

    const onFinish = async (value: any) => {
      let resCode = 0;
      setLoading(true);
      const currentFile = {
        file: value?.currentFile,
      };
      const formData = new FormData();
      for (let i = 0; i < value.files.length; i++) {
        formData.append("fileUpload", value?.files[i]?.originFileObj || "");
      }
      formData.append("files", JSON.stringify(currentFile));
      formData.append("note", value?.text || "");
      formData.append("type", value?.type || "");
      formData.append("date", value?.date.toString() || "");
      try {
        const res = await UPDATE_ACTIVITY_BY_ID(value.id, formData);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! บันทึกข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถบันทึกข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      }
      HandleAllSuccessDoing(resCode, `${value.id}`);
    };

    const onDelete = async (id: number) => {
      let resCode = 0;
      setLoading(true);
      try {
        const res = await DELETE_ITEM_NOTE_BY_ID(id);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! ลบข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถลบข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (error) {
        console.log(error);
      }
      HandleAllSuccessDoing(resCode, "noteField");
    };

    const expendBtn = generateButton({
      Icon: vsb ? CaretUpOutlined : CaretDownOutlined,
      onClick: () => {
        setVsb(!vsb);
      },
    });

    const currentUser = JSON.parse(localStorage.getItem("userInfo") || "");
    const imagepost = {
      src: currentUser?.image,
      name: currentUser?.firstName,
    };

    const HandleSourtNoteList = async (value: string) => {
      const dataInfo = {
        noteOrderby: value,
      };
      setLoading(true);
      try {
        const res = await SOURT_NOTE(boardItem?.id, dataInfo);
        if (res.code === 200) {
          const sourtText =
            value === "DESC" ? "เรียงกิจกรรมล่าสุด" : "เรียงกิจกรรมย้อนหลัง";
          setSourtNote(sourtText);
          const [result] = res.result;
          setDatasource({
            ...dataSource,
            note: result?.note,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถเรียงข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        HandleScroll("noteField" || "");
      }
    };

    const ContentSourtNote = () => {
      return (
        <div>
          <div
            className="btn-sourte-note"
            onClick={() => HandleSourtNoteList("DESC")}
          >
            เรียงกิจกรรมล่าสุด
          </div>
          <div
            className="btn-sourte-note"
            onClick={() => HandleSourtNoteList("ASC")}
          >
            เรียงกิจกรรมย้อนหลัง
          </div>
        </div>
      );
    };

    return (
      <div>
        <div
          className="leads-card-title px-3 mb-3 cursor"
          onClick={() => {
            setVsb(!vsb);
          }}
        >
          <h3>
            <b>{fieldList.fieldTitle}</b>
          </h3>
          <div>{expendBtn}</div>
        </div>
        {vsb && (
          <>
            <Row align="middle" justify="space-between" className="mr-2 ml-4">
              <h3>ประวัติการทำงาน</h3>
              <div>
                {sourtNote}
                <Popover
                  placement="bottom"
                  title={"เลือกการเรียงกิจกรรม"}
                  content={ContentSourtNote}
                  trigger="click"
                >
                  <CaretDownOutlined className="mx-2" />
                </Popover>
              </div>
            </Row>
            {CheckStatusCard(boardItem?.status) && (
              <InputPickerFilePost image={imagepost} onFinish={onPost} />
            )}
            {listField.map((item: any, idx: number) => {
              return (
                <div id={`${item?.note?.id}`} key={idx}>
                  <InputPickerFile
                    srcImg={item?.actionBy?.image || profile}
                    text={item?.note?.note}
                    remark={item.note?.type && true}
                    value={item}
                    id={item?.note?.id}
                    label={`${item?.actionBy?.firstName} ${item?.actionBy?.lastName}`}
                    onFinish={onFinish}
                    onDelete={onDelete}
                    isEdit={item?.isEdit}
                    isBtn={CheckStatusCard(boardItem?.status)}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  };
  ///////////////////////////////////////////////////////////////////////// FieldGenerateNote /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////// FieldGenerateACtivity /////////////////////////////////////////////////////////////
  const FieldGenerateACtivity = ({ fieldList }: { fieldList: any }) => {
    const [vsb, setVsb] = useRecoilState(tab_visible_selector(fieldList.key));
    const list = fieldList?.items?.listField?.actionStep || [];
    const listField = fieldList?.items?.listField || {};
    const resultVsb = list?.map((item: any) => {
      return { id: item.id, value: true };
    });
    const [vsbChild, setVsbChild] = useState(resultVsb);

    const onPost = async (value: any) => {
      let resCode = 0;
      setLoading(true);
      const result = listField?.actionStep.find(
        (item: any) => item.id === value?.activityId
      );
      const inquiryId = boardItem?.id || 0;
      const formData = new FormData();
      for (let i = 0; i < value.files.length; i++) {
        formData.append("fileUpload", value?.files[i].originFileObj || "");
      }
      formData.append("inquiryId", inquiryId.toString());
      formData.append("note", value?.text || "");
      formData.append("actionStepId", value?.activityId || "");
      try {
        const res = await POST_ACTIVITY(formData);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! บันทึกข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถบันทึกข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        HandleAllSuccessDoing(resCode, result?.name || "activityField");
      }
    };

    const onFinish = async (value: any) => {
      let resCode = 0;
      setLoading(true);
      const currentFile = {
        file: value?.currentFile,
      };
      const formData = new FormData();
      for (let i = 0; i < value.files.length; i++) {
        formData.append("fileUpload", value?.files[i].originFileObj || "");
      }
      formData.append("files", JSON.stringify(currentFile));
      formData.append("note", value?.text || "");
      try {
        const res = await UPDATE_ACTIVITY_BY_ID(value.id, formData);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! บันทึกข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถบันทึกข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      }
      HandleAllSuccessDoing(resCode, `${value.id}`);
    };

    const onDelete = async (id: number) => {
      let resCode = 0;
      setLoading(true);
      try {
        const res = await DELETE_ITEM_NOTE_BY_ID(id);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! ลบข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถลบข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (error) {
        console.log(error);
      }
      HandleAllSuccessDoing(resCode, "activityField");
    };

    const onSuccess = async (id: string) => {
      let resCode = 0;
      setLoading(true);
      try {
        const res = await SUCCESS_ACTIVITY(boardItem?.id);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! การดำเนินการเสร็จสิ้น`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถดำเนินการเสร็จสิ้นได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      }
      HandleAllSuccessDoing(resCode, id);
    };

    const expendBtn = generateButton({
      Icon: vsb ? CaretUpOutlined : CaretDownOutlined,
      onClick: () => {
        setVsb(!vsb);
      },
    });

    const expendChildBtn = (id: number, index: number) => {
      return generateButton({
        Icon: vsbChild[index].value ? CaretUpOutlined : CaretDownOutlined,
        onClick: () => {
          const result = vsbChild.map((item: any) => {
            return item.id === id ? { id: item.id, value: !item.value } : item;
          });
          setVsbChild(result);
        },
      });
    };

    const currentUser = JSON.parse(localStorage.getItem("userInfo") || "");
    const imagepost = {
      src: currentUser?.image,
      name: currentUser?.firstName,
    };

    const checkkStatuss = (status: boolean = false) => {
      return status ? "| เสร็จสิ้น" : "| รอดำเนินการ";
    };

    const DateNow = (date: string | number) => {
      const result = Number(date);
      if (result) {
        return _numToDate(result, "DD/MM/YYYY | h:mm:ss");
      } else {
        return date;
      }
    };

    return (
      <div>
        <div
          className="leads-card-title px-3 mb-3 cursor"
          onClick={() => {
            setVsb(!vsb);
          }}
        >
          <h3>
            <b>{fieldList.fieldTitle}</b>
          </h3>
          <div>{expendBtn}</div>
        </div>
        {vsb && (
          <>
            <Row
              className="activity-progress mb-3"
              justify="space-between"
              align="middle"
            >
              <span className="font-activitty">ACTION</span>
              <Col span={22}>
                <Progress
                  percent={listField?.percent || 0}
                  strokeColor="#FFB12E"
                  trailColor="#C7C7C7"
                />
              </Col>
            </Row>

            {listField?.actionStep?.map((item: any, idx: number) => {
              const current = item?.activitySuccess;
              const prev =
                idx - 1 >= 0
                  ? listField?.actionStep[idx - 1].activitySuccess
                  : false;

              return (
                <div key={idx}>
                  <div
                    className="leads-card-title-ativity px-3 mb-3"
                    id={item?.name}
                  >
                    <Row align="middle" justify="space-between">
                      <h3>
                        <b>{item?.name || ""}</b>
                      </h3>
                      <span className="mx-5">
                        อัพเดทล่าสุด
                        <CalendarOutlined
                          className="mx-2"
                          style={{ color: "lightgray" }}
                        />
                        {DateNow(item.dateTime)}
                        <span className="mx-1">
                          {checkkStatuss(item?.activitySuccess)}
                        </span>
                      </span>
                    </Row>
                    {current || prev || idx === 0 ? (
                      <div>{expendChildBtn(item?.id || 0, idx)}</div>
                    ) : null}
                  </div>
                  {current || prev || idx === 0
                    ? vsbChild[idx].value && (
                        <>
                          {!item?.activitySuccess &&
                            CheckStatusCard(boardItem?.status) && (
                              <InputPickerFilePost
                                activityId={item?.id}
                                image={imagepost}
                                onFinish={onPost}
                                onSuccess={() => onSuccess(item?.name)}
                                saveBtn={true}
                                postBtn={false}
                              />
                            )}
                          {item?.note?.map((child: any, index: number) => {
                            return (
                              <div id={`${child?.note?.id}`} key={index}>
                                <InputPickerFile
                                  srcImg={child?.admin?.image || profile}
                                  text={child?.note?.note}
                                  value={child}
                                  id={child?.note?.id}
                                  label={`${child?.admin?.firstName} ${child?.admin?.lastName}`}
                                  onFinish={onFinish}
                                  onDelete={onDelete}
                                  isEdit={true}
                                  isBtn={CheckStatusCard(boardItem?.status)}
                                />
                              </div>
                            );
                          })}
                        </>
                      )
                    : null}
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  };
  ///////////////////////////////////////////////////////////////////////// FieldGenerateACtivity /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////// FieldGeneate /////////////////////////////////////////////////////////////
  const FieldGeneate = ({
    field,
    form,
  }: {
    field: IFieldParent;
    form: { form: FormInstance<any>; id: string };
    isBtn?: boolean;
  }) => {
    const [edit, setEdit] = useRecoilState(tab_edit_selector(field.key));
    const [vsb, setVsb] = useRecoilState(tab_visible_selector(field.key));
    const [visibleField, setVisibleField] = useState({
      child1: false,
      subChild1: false,
      child2: false,
    } as any);

    const editBtn = generateButton({
      text: "แก้ไข",
      Icon: EditOutlined,
      onClick: (e) => {
        e.stopPropagation();
        setEdit(!edit);
      },
    });
    const expendBtn = generateButton({
      Icon: vsb ? CaretUpOutlined : CaretDownOutlined,
      onClick: () => {
        setVsb(!vsb);
      },
    });
    const cancelBtn = generateButton({
      text: "ยกเลิก",
      onClick: (e) => {
        e.stopPropagation();
        setEdit(!edit);
      },
    });
    const saveBtn = generateButton({
      Icon: SaveOutlined,
      text: "บันทึกข้อมูล",
      onClick: async (e) => {
        e.stopPropagation();
        const dataIfo = await form.form.validateFields();
        HandleOnfinish(dataIfo, field.key);
        setEdit(!edit);
      },
    });

    const renderForm = (item: IFieldChild) => {
      const Input = FInput;
      return item.input?.type === "UPLOAD" ? (
        <UploadFile name="file" />
      ) : item.input?.type === "DATE" ? (
        <FDatePicker
          name={item.name}
          label={item.label}
          {...item.input?.props}
          value={
            item?.value
              ? moment(new Date(item?.value)).format("DD/MM/YYYY")
              : moment().format("DD/MM/YYYY")
          }
          options={{
            disabled: item.input?.props.disabled || false,
          }}
          rules={item.input?.props?.rule || { required: false }}
        />
      ) : item.input?.type === "SELECT" ? (
        <FSelect
          options={{
            mode: item.input?.props?.multiple ? "multiple" : "",
            disabled: item.input?.props.disabled || false,
          }}
          rules={item.input?.props?.rule || { required: false }}
          label={item.label || ""}
          name={item.name || ""}
          dataSource={item.input?.props?.menuItem.values || []}
          keyName={item.input?.props?.menuItem?.name}
          keyValue={item.input?.props?.menuItem?.key}
        />
      ) : item.input?.type === "CHECKBOX" ? (
        <Row>
          <Col span={2} style={{ fontWeight: "bold" }}>
            {item.label}
          </Col>
          <Form.Item noStyle name={item.name}>
            <TxCheckBoxGroup
              optionValue={item.input.props?.optionValue}
              value={item.value}
              disabled={false}
            />
          </Form.Item>
        </Row>
      ) : item.input?.type === "TEXTAREA" ? (
        <FTextArea
          label={item.label || ""}
          name={item.name || ""}
          options={{
            rows: item.input.props?.rows,
            disabled: item.input?.props.disabled || false,
          }}
          rules={item.input?.props?.rule || { required: false }}
        />
      ) : (
        <Input
          name={item.name}
          label={item.label}
          {...item.input?.props}
          options={{
            disabled: item.input?.props.disabled || false,
          }}
          rules={item.input?.props?.rule || { required: false }}
        />
      );
    };

    const getFieldLabel = (item: IFieldChild) => {
      switch (item.input?.type) {
        case EInputType.SELECT:
          const ids: number | number[] = item.value;
          const { props } = item?.input;
          let { menuItem } = props;
          if (!menuItem) {
            menuItem = { values: [], name: "", key: "" };
          }
          if (props?.multiple && Array.isArray(ids)) {
            const filterId = menuItem.values.filter(
              (item: { [key: string]: any }) => {
                return ids.some((e) => e === item.id);
              }
            );
            return filterId.map(
              (item: { [key: string]: any }, index: number) => {
                return (
                  <span key={index}>
                    {item[menuItem.name]}
                    {index !== filterId.length - 1 && ", "}
                  </span>
                );
              }
            );
          }
          const value = menuItem.values?.find(
            (e: any) => e[menuItem.key] === ids
          );

          return value ? value[menuItem?.name] : "";
        case EInputType.UPLOAD:
          return (
            <Row>
              {item?.value?.map((child: any, idx: number) => (
                <Col className="mr-1 mt-2" key={idx}>
                  <UploadFileLabel name={child?.name} value={child.url} />
                </Col>
              ))}
            </Row>
          );
        case EInputType.CHECKBOX:
          return (
            <TxCheckBoxGroup
              optionValue={item.input.props.optionValue}
              value={item.value}
              disabled={true}
            />
          );
        default:
          return item.value;
      }
    };

    const renderField = (item: IFieldChild) => {
      const label = getFieldLabel(item);
      return (
        <Row>
          <Col span={24} className="font-label">
            {item.label}
          </Col>
          <Col span={24} className="font-label-value break-word">
            {label}
          </Col>
        </Row>
      );
    };

    const HandleClearData = () => {
      contactForm.resetFields();
      actionForm.resetFields();
      requirmentForm.resetFields();
      onChange();
    };

    const HandleRefacterData = (keyForm: string, dataInfo: any) => {
      const dataAction =
        keyForm === "action"
          ? {
              initialService: dataInfo?.initialService || "",
              otherContact: dataInfo?.otherContact || "",
            }
          : keyForm === "requirement"
          ? {
              fileUpload: dataInfo?.file || [],
              specAlumet: dataInfo?.specAlumet[0] === 1 ? "TRUE" : "FALSE",
              requirement: {
                projectCategory: dataInfo?.projectCategory,
                aluminiumColor: dataInfo?.colorAluminium,
                projectName: dataInfo?.projectName,
                subjectContact: dataInfo?.subjectContact,
                requirementType: dataInfo?.requirementType,
                interestedProduct: dataInfo?.interestedProduct,
                contactChannel: dataInfo?.contactChannel,
                requirement: dataInfo?.requirement,
                userContactType: dataInfo?.userContactType,
                line: dataInfo?.line,
                files: dataInfo?.file
                  ?.filter((item: any) => item?.id)
                  .map((child: any) => child.id),
              },
            }
          : {
              customerContact: {
                firstName: dataInfo?.firstName,
                lastName: dataInfo?.lastName,
                nickname: dataInfo?.nickname,
                position: dataInfo?.position,
              },
              address: {
                companyName: dataInfo?.companyName,
                explainAboutBusiness: dataInfo?.explainAboutBusiness,
                phone: dataInfo?.phone,
                email: dataInfo?.email,
                address: dataInfo?.address,
                subDistrict: dataInfo?.subDistrict,
                district: dataInfo?.district,
                province: dataInfo?.province,
                postalCode: Number(dataInfo?.postalCode),
              },
              contactDate: dataInfo?.contactDate.valueOf(),
            };
      return dataAction;
    };

    const jsonToFormData = (data: any) => {
      const fileList = data?.fileUpload.filter(
        (item: any) => item.originFileObj
      );
      const formData = new FormData();
      fileList.forEach((item: any) => {
        formData.append("fileUpload", item.originFileObj);
      });
      formData.append("specAlumet", data["specAlumet"]);
      formData.append("requirement", JSON.stringify(data["requirement"]));
      return formData;
    };

    const HandleOnfinish = async (dataInfo: any, keyForm: string) => {
      let resCode = 0;
      setLoading(true);
      const dataApi = HandleRefacterData(keyForm, dataInfo);
      const callAPI =
        keyForm === "action"
          ? await UPDATE_INITAIL_SERVICE(boardItem?.id, dataApi)
          : keyForm === "requirement"
          ? await UPDATE_REQUIMENT(boardItem?.id, jsonToFormData(dataApi))
          : await UPDATE_CUSTOMER_CONTACT(boardItem?.id, dataApi);
      try {
        const res = callAPI;
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `บันทึกข้อมูลสำเร็จ`,
          });
          HandleClearData();
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! บันทึกข้อมูลไม่สำเร็จ`,
          });
        }
      } catch (error) {
        console.log(error);
      }
      const targetScroll =
        keyForm === "action"
          ? "actionField"
          : keyForm === "requirement"
          ? "requirmentField"
          : "contactField";
      HandleAllSuccessDoing(resCode, targetScroll);
    };

    const CheckPaddingLeft = (check: boolean | any = false) => {
      return !check ? { paddingLeft: "2rem" } : {};
    };

    const CheckFieldVisible = (items: IFieldChild, idx: number) => {
      return items?.label === "divider" ? (
        <Col {...items?.col} span={items?.col?.span || 12} key={idx}>
          <Divider
            className="m-0 px-0"
            style={{ backgroundColor: "#DCDCDC" }}
          />
        </Col>
      ) : (
        <Col
          {...items?.col}
          span={items?.col?.span || 12}
          key={idx}
          style={CheckPaddingLeft(edit)}
        >
          {edit ? renderForm(items) : renderField(items)}
        </Col>
      );
    };

    return (
      <Form className="mb-4" form={form.form} id={form.id}>
        <div
          className="leads-card-title px-3 mb-3 cursor"
          onClick={() => {
            setVsb(!vsb);
          }}
        >
          <h3>
            <b>{field.fieldTitle}</b>
          </h3>
          {/* {field.key !== "customer_contact" ? (
            <div>
              {CheckStatusCard(boardItem?.status) && vsb && !edit && editBtn}
              {CheckStatusCard(boardItem?.status) && vsb && edit && cancelBtn}
              {CheckStatusCard(boardItem?.status) && vsb && edit && saveBtn}
              {expendBtn}
            </div>
          ) : (
            <div>{expendBtn}</div>
          )} */}
          <div>
            {CheckStatusCard(boardItem?.status) && vsb && !edit && editBtn}
            {CheckStatusCard(boardItem?.status) && vsb && edit && cancelBtn}
            {CheckStatusCard(boardItem?.status) && vsb && edit && saveBtn}
            {expendBtn}
          </div>
        </div>
        {vsb && (
          <div className="px-2 mt-2 mb-4">
            <Row gutter={[24, 24]}>
              {field.items
                .filter((item) =>
                  edit
                    ? item?.name !== "fullName"
                    : item?.name !== "firstName" && item?.name !== "lastName"
                )
                .filter(
                  (item) =>
                    item?.typeSelf !== "child" && item?.typeSelf !== "subChild"
                )
                .map((item, index) => {
                  return CheckFieldVisible(item, index);
                })}
            </Row>
            {field.visibleChildKey && (
              <Col span={24} className="px-0">
                <VisibleDivider
                  visible={visibleField[`${field.visibleChildKey}`]}
                  onClick={() => {
                    setVisibleField({
                      ...visibleField,
                      [`${field.visibleChildKey}`]:
                        !visibleField[`${field.visibleChildKey}`],
                    });
                  }}
                />
              </Col>
            )}
            {visibleField[`${field.visibleChildKey}`] && (
              <Row gutter={[24, 24]} className={edit ? "my-2" : "mb-2"}>
                {field.items
                  .filter((item) => item?.typeSelf === "child")
                  .map((item, index) => {
                    return CheckFieldVisible(item, index);
                  })}
              </Row>
            )}
            {visibleField[`${field.visibleChildKey}`] &&
              field.visibleSubChildKey && (
                <Col span={24} className="px-0">
                  <VisibleDivider
                    visible={visibleField[`${field.visibleSubChildKey}`]}
                    onClick={() => {
                      setVisibleField({
                        ...visibleField,
                        [`${field.visibleSubChildKey}`]:
                          !visibleField[`${field.visibleSubChildKey}`],
                      });
                    }}
                  />
                </Col>
              )}
            {visibleField[`${field.visibleChildKey}`] &&
              visibleField[`${field.visibleSubChildKey}`] && (
                <Row gutter={[24, 24]} className={edit ? "my-2" : "mb-2"}>
                  {field.items
                    .filter((item) => item?.typeSelf === "subChild")
                    .map((item, index) => {
                      return CheckFieldVisible(item, index);
                    })}
                </Row>
              )}
          </div>
        )}
      </Form>
    );
  };
  ///////////////////////////////////////////////////////////////////////// FieldGeneate /////////////////////////////////////////////////////////////
  const ModalHeader = (
    <div style={{ padding: "15px 15px 0px 15px" }}>
      <h2 style={{ color: "#ffc627", fontWeight: "bold" }}>
        {boardItem?.titleEn}
      </h2>
      <h4>{boardItem?.titleTh}</h4>
    </div>
  );

  const pending = (
    <div className="d-flex x-center y-center h-100">
      <HashLoader color="#FCD901" />
      <h3 style={{ color: "#FCD901" }}>ระบบกำลังดำเนินการโปรดรอสักครู่...</h3>
    </div>
  );

  const StatusForwardList = [
    {
      name: "ปกติ",
    },
    {
      name: "ด่วน",
    },
    {
      name: "ด่วนมาก",
    },
  ];

  const ForwardOnFinish = async (value: any) => {
    setLoading(true);
    try {
      const res = await FORWARD_FORM_ITEM(boardItem?.id, value);
      if (res.code === 200) {
        AlertToast({
          icon: "success",
          title: `สำเร็จ! ส่งข้อมูลสำเร็จ`,
        });
        toggle();
        onChange();
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ส่งข้อมูลไม่สำเร็จ`,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const AcceptOnFinish = async (value: any) => {
    setLoading(true);
    try {
      const res = await ACCEPT_FORM_ITEM(boardItem?.id, value);
      if (res.code === 200) {
        AlertToast({
          icon: "success",
          title: `สำเร็จ! ส่งข้อมูลสำเร็จ`,
        });
        toggle();
        onChange();
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ส่งข้อมูลไม่สำเร็จ`,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const ForwardContent = () => {
    return (
      <div style={{ width: 500 }}>
        <Form name="forward" onFinish={ForwardOnFinish}>
          <Row align="top" justify="center" className="mt-1 mb-3">
            <span className="mt-1">TO: </span>
            <Col span={10} className="px-3">
              <Form.Item
                name={"forwardToAdmin"}
                rules={[{ required: true, message: "กรุณาเลือกเเอดมิน" }]}
                className="my-0"
              >
                <Select
                  className="my-select"
                  style={{ width: "100%" }}
                  placeholder="เเอดมิน"
                >
                  {adminList.map((item: any, idx: number) => {
                    return (
                      <Option
                        value={item?.id}
                        key={idx}
                      >{`${item.firstName} ${item.lastName}`}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <span className="mt-1">STATUS: </span>
            <Col span={10} className="px-3">
              <Form.Item
                name={"inquiryType"}
                rules={[{ required: true, message: "กรุณาเลือกสถานะ" }]}
                className="my-0"
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="สถานะ"
                  className="my-select"
                >
                  {StatusForwardList.map((item: any, idx: number) => (
                    <Option value={item.name} key={idx}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <Form.Item
              name={"note"}
              rules={[{ required: false, message: "กรุณาป้อนข้อความ" }]}
              className="my-0"
            >
              <TextArea rows={4} placeholder="ข้อความ" className="p-3" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="end" align="middle">
              <Form.Item className="my-2">
                <Button
                  type="primary"
                  htmlType="submit"
                  danger
                  style={{ width: 80 }}
                >
                  ส่ง
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Form>
      </div>
    );
  };

  const HandleOnClickAuto = async () => {
    setLoading(true);
    let resCode = 0;
    try {
      const res = await AUTO_INQUIRY_BY_ID(boardItem?.id);
      resCode = res.code;
      if (res.code === 200) {
        AlertToast({
          icon: "success",
          title: `สร้างข้อมูลกิจกรรมสำเร็จ!`,
        });
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! สร้างข้อมูลกิจกรรมไม่สำเร็จ`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    HandleAllSuccessDoing(resCode, "activityField");
  };

  const HandleOnClickCreate = async (value: any) => {
    const dataInfo = {
      id: boardItem?.id,
      ...value,
    };
    setLoading(true);
    let resCode = 0;
    const res = await CREATE_INQUIRY_BY_ID(dataInfo);
    resCode = res.code;
    if (res.code === 200) {
      AlertToast({
        icon: "success",
        title: `สร้างข้อมูลกิจกรรมสำเร็จ!`,
      });
    } else {
      AlertToast({
        icon: "error",
        title: `ผิดพลาด! สร้างข้อมูลกิจกรรมไม่สำเร็จ`,
      });
    }
    const lastIndex = value?.actionName[value?.actionName.length - 1];
    HandleAllSuccessDoing(resCode, lastIndex);
  };

  const HandleGetByIdForCast = async () => {
    try {
      const res = await GET_FORCAST_BY_ID(boardItem?.id);
      if (res.code === 200) {
        setForCastField({
          forcastName: res?.result?.map((item: any) => {
            return {
              id: item?.id,
              forecast: item?.forecast,
              date: moment(new Date(Number(item?.date)), "DD/MM/YYYY"),
            };
          }),
        });
      } else {
        setForCastField({
          forcastName: [undefined],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleForeCastUpdate = async (value: any) => {
    const dataInfo = {
      inquiryId: boardItem?.id,
      forecast: value?.forcastName?.map((item: any) => {
        return {
          id: item?.id,
          forecast: Number(item?.forecast),
          date: Number(item?.date).valueOf(),
        };
      }),
    };
    try {
      const res = await UPDATE_FORCAST(dataInfo);
      if (res.code === 200) {
        AlertToast({
          icon: "success",
          title: `บันทึกข้อมูลสำเร็จ!`,
        });
        HandleGetByIdForCast();
      } else {
        AlertToast({
          icon: "error",
          title: `บันทึกข้อมูลไม่สำเร็จ!`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const HandleForeCastFinish = async (value: any) => {
    const dataInfo = {
      inquiryId: boardItem?.id,
      forecast: value?.forcastName?.map((item: any) => {
        return {
          forecast: Number(item?.forecast),
          date: Number(item?.date).valueOf(),
        };
      }),
    };
    try {
      const res = await POST_FORCAST(dataInfo);
      if (res.code === 200) {
        AlertToast({
          icon: "success",
          title: `สร้างข้อมูลสำเร็จ!`,
        });
        HandleGetByIdForCast();
      } else {
        AlertToast({
          icon: "error",
          title: `สร้างข้อมูลไม่สำเร็จ!`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleNoteStepFinish = async (value: any) => {
    let resCode = 0;
    setLoading(true);
    const dataInfo = {
      inquiryId: inquiry_No,
      type: value?.objective || "",
      date: ConvertDateNum(value?.dutedate),
      note: value?.note || "",
    };
    try {
      const res = await NOTE_TRACKING_API(dataInfo);
      resCode = res.code;
      if (res.code === 200) {
        AlertToast({
          icon: "success",
          title: `บันทึกข้อมูลสำเร็จ!`,
        });
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! บันทึกข้อมูลไม่สำเร็จ`,
        });
      }
    } catch (err) {
      console.log(err);
    }
    HandleAllSuccessDoing(resCode, "noteField");
  };

  const HandleDone = async (value: string) => {
    const realKey = Object.keys(value)[0];
    const realValue = Object.values(value)[0];
    const dataInfo = {
      status: realKey,
      notation: realValue,
    };
    setLoading(true);
    try {
      const res = await STEP_DONE_API(inquiry_No, dataInfo);
      if (res.code === 200) {
        AlertToast({
          icon: "success",
          title: `ทำรายการของท่านสำเร็จ!`,
        });
        toggle();
        onChange();
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ทำรายการของท่านไม่สำเร็จ กรุณาลองอีกครั้งในภายหลัง`,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CreateStepContent = () => {
    return (
      <div style={{ width: 300 }}>
        <Form
          name="dynamic_form_item"
          onFinish={HandleOnClickCreate}
          initialValues={{ actionName: [undefined] }}
        >
          <Form.List name="actionName">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      required={false}
                      key={field.key}
                      className="my-2"
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "กรุณาป้อนข้อมูล!",
                          },
                        ]}
                        key={field.key}
                        noStyle
                      >
                        <Input
                          placeholder="ใส่ขั้นตอนการทำงาน"
                          style={{
                            width: fields.length > 1 ? "90%" : "100%",
                            height: 35,
                          }}
                          className={fields.length > 1 ? "mx-2" : ""}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="ghost"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      เพิ่ม
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
          <Form.Item style={{ textAlign: "right" }} className="my-2">
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  const AcceptContent = () => {
    return (
      <div style={{ width: 500 }} className="pt-3">
        <Form name="forward" onFinish={AcceptOnFinish}>
          <Col span={24}>
            <Form.Item
              name={"note"}
              rules={[{ required: false }]}
              className="my-2"
            >
              <TextArea rows={4} placeholder="ข้อความ" className="p-3" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="end" align="middle">
              <Form.Item className="my-2">
                <Button
                  type="primary"
                  htmlType="submit"
                  danger
                  style={{ width: 80 }}
                >
                  ส่ง
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Form>
      </div>
    );
  };

  const ActionStepContent = () => {
    return (
      <div>
        <div className="acton-btn-text" onClick={HandleOnClickAuto}>
          Auto
        </div>
        <Popover
          content={CreateStepContent}
          title={<b>ACTION</b>}
          trigger="click"
        >
          <div className="acton-btn-text">Create New</div>
        </Popover>
      </div>
    );
  };

  const ForeCastContent = () => {
    const config = {
      rules: [
        {
          type: "object" as const,
          required: true,
          message: "กรุณาเลือกวันเวลา",
        },
      ],
    };

    return (
      <div style={{ width: 440 }}>
        <Form
          name="dynamic_form_item"
          onFinish={
            forCastField?.forcastName?.length > 0
              ? HandleForeCastUpdate
              : HandleForeCastFinish
          }
          initialValues={{ forcastName: forCastField?.forcastName }}
        >
          <Form.List name="forcastName">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }: any) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "forecast"]}
                        fieldKey={[fieldKey, "forecast"]}
                        rules={[
                          { required: true, message: "กรุณาป้อนข้อมูล!" },
                        ]}
                        className="my-2"
                      >
                        <Input
                          placeholder="ประมาณการณ์น้ำหนักอลูมิเนียมที่ใช้ในโครงการนี้"
                          style={{
                            width: fields.length > 1 ? 280 : 300,
                            height: 35,
                          }}
                          type="number"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "date"]}
                        fieldKey={[fieldKey, "date"]}
                        {...config}
                        className="my-2"
                      >
                        <DatePicker
                          format={"DD/MM/YYYY"}
                          placeholder="เลือกวันที่"
                          style={{
                            border: "1px solid #d9d9d9",
                            width: 130,
                            height: 35,
                          }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      ) : null}
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="ghost"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      เพิ่ม
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
          <Form.Item style={{ textAlign: "right" }} className="my-2">
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  const NoteContent = () => {
    const objectvieList = [
      { name: "นัดหมาย" },
      { name: "ติดตาม" },
      { name: "ปิดจ็อบ" },
      { name: "อื่นๆ" },
    ];

    return (
      <div style={{ width: 550 }}>
        <Form name="noteFormPop" onFinish={HandleNoteStepFinish}>
          <Row align="top" justify="space-between" className="mt-1 mb-3">
            <span className="mt-1">DUTE DATE :</span>
            <Form.Item
              name={"dutedate"}
              rules={[{ required: true, message: "กรุณาเลือกวันที่" }]}
              className="my-0"
            >
              <DatePicker
                showTime
                placeholder="เลือกวันที่"
                style={{
                  height: 35,
                  border: "1px solid #d9d9d9",
                }}
              />
            </Form.Item>
            <span className="mt-1">OBJECTIVE :</span>
            <Form.Item
              name={"objective"}
              rules={[{ required: true, message: "กรุณาเลือกวัตถุประสงค์" }]}
              className="my-0"
            >
              <Select
                className="my-select"
                style={{ width: 170 }}
                placeholder="เลือก"
              >
                {objectvieList.map((item: any, idx: number) => (
                  <Option value={item.name} key={idx}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name={"note"}
                label=""
                rules={[{ required: true, message: "กรุณาป้อนข้อความ" }]}
                className="my-0"
              >
                <TextArea rows={4} placeholder="โน้ต" className="p-3" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ textAlign: "right" }} className="my-2">
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  const DoneContent = () => {
    return (
      <div>
        <Popover
          placement="bottomRight"
          title={false}
          content={() => DoneContentChild("CLOSE_THE_SALE")}
          trigger="click"
        >
          <div className="btn-done-step">ปิดการขาย</div>
        </Popover>
        <Popover
          placement="bottomRight"
          title={false}
          content={() => DoneContentChild("PENDING")}
          trigger="click"
        >
          <div className="btn-done-step">อยู่ระหว่างการพิจารณา</div>
        </Popover>
        <Popover
          placement="bottomRight"
          title={false}
          content={() => DoneContentChild("DO_NOT_BUY")}
          trigger="click"
        >
          <div className="btn-done-step">ไม่ซื้อ</div>
        </Popover>
        <Popover
          placement="bottomRight"
          title={false}
          content={() => DoneContentChild("COMPLETED")}
          trigger="click"
        >
          <div className="btn-done-step">ได้รับการบริการเรียบร้อยเเล้ว</div>
        </Popover>
        <Popover
          placement="bottomRight"
          title={false}
          content={() => DoneContentChild("CAN_NOT_TACKING")}
          trigger="click"
        >
          <div className="btn-done-step">ไม่สามารทติดตามสถานะได้</div>
        </Popover>
      </div>
    );
  };

  const DoneContentChild = (status: string) => {
    return (
      <div style={{ width: 500 }}>
        <Form name="formDoneChild" onFinish={HandleDone}>
          <Form.Item
            name={status}
            rules={[{ required: true, message: "กรุณาป้อนข้อความ" }]}
            className="my-0"
          >
            <TextArea rows={4} placeholder="หมายเหตุ" className="p-3" />
          </Form.Item>
          <Row justify="end" align="middle">
            <Form.Item className="my-2">
              <Button
                type="primary"
                htmlType="submit"
                danger
                style={{ width: 80 }}
              >
                ส่ง
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    );
  };

  const CheckStatusCard = (cardStatus: string) => {
    if (cardStatus === "send" || cardStatus === "finish") {
      return false;
    } else return true;
  };

  const CardBody = ({
    formInstance,
  }: {
    item: ICurrentItem;
    editAble?: boolean;
    formInstance: IFormInstance;
  }) => {
    const { contactForm, requirmentForm, actionForm } = formInstance;
    const contactField =
      contactForm && FieldGeneate({ field: contact_field, form: contactForm });
    const requirmentField =
      requirmentForm &&
      FieldGeneate({ field: requirment_field, form: requirmentForm });
    const actionField =
      actionForm && FieldGeneate({ field: action_field, form: actionForm });
    const noteField = FieldGenerateNote({ fieldList: note_field });
    const activityField = FieldGenerateACtivity({ fieldList: activity_field });

    const HandleDeleteCard = async () => {
      try {
        const res = await DELETE_CARD_BY_ID(inquiry_No);
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `รายการของท่านถูกลบสำเร็จ!`,
          });
          toggle();
          onChange();
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ทำรายการของท่านไม่สำเร็จ กรุณาลองอีกครั้งในภายหลัง`,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <Row
        // gutter={CheckStatusCard(boardItem?.status) ? [24, 0] : [10, 0]}
        gutter={[10, 0]}
        justify="center"
        style={{ height: "100%" }}
      >
        <Col
          // span={CheckStatusCard(boardItem?.status) ? 20 : 24}
          span={20}
          className="py-3 lead-card-item-detail"
        >
          <div id="contactField">{contactField}</div>
          <div id="requirmentField">{requirmentField}</div>
          <div id="actionField">{actionField}</div>
          <div id="noteField">
            {boardItem?.status !== "inbox" && boardItem?.status !== "newinquiry"
              ? noteField
              : null}
          </div>
          <div id="activityField">
            {boardItem?.status !== "inbox" && boardItem?.status !== "newinquiry"
              ? activityField
              : null}
          </div>
        </Col>
        {/* {CheckStatusCard(boardItem?.status) && ( */}
        <Col span={4} style={{ textAlign: "center" }}>
          {boardItem?.status === "doing" && (
            <>
              <Popover
                placement="bottomRight"
                title={false}
                content={ActionStepContent}
                trigger="click"
              >
                <Button
                  className="btn-card-detail-lead mb-2"
                  size="large"
                  style={{ height: 50 }}
                >
                  ACTION STEP
                </Button>
              </Popover>
              <Popover
                placement="bottomRight"
                title={false}
                content={ForeCastContent}
                trigger="click"
              >
                <Button
                  className="btn-card-detail-lead mb-2"
                  size="large"
                  style={{ height: 50 }}
                >
                  FORECAST
                </Button>
              </Popover>
              <Popover
                placement="bottomRight"
                title={false}
                content={NoteContent}
                trigger="click"
              >
                <Button
                  className="btn-card-detail-lead mb-2"
                  size="large"
                  style={{ height: 50 }}
                >
                  NOTE
                </Button>
              </Popover>
            </>
          )}
          {boardItem?.status !== "finish" && boardItem?.status !== "send" && (
            <Popover
              placement="bottomLeft"
              title={false}
              content={ForwardContent}
              trigger="click"
            >
              <Button
                className="btn-card-detail-lead mb-2"
                size="large"
                style={{ height: 50 }}
              >
                FORWARD
              </Button>
            </Popover>
          )}
          {boardItem?.status === "doing" ? (
            <Popover
              placement="bottomRight"
              title={false}
              content={DoneContent}
              trigger="click"
            >
              <Button
                className="btn-card-detail-lead-done"
                size="large"
                style={{ height: 50 }}
              >
                UPDATE
              </Button>
            </Popover>
          ) : (
            boardItem?.status !== "finish" &&
            boardItem?.status !== "send" && (
              <Popover
                placement="bottomLeft"
                title={false}
                content={AcceptContent}
                trigger="click"
              >
                <Button
                  className="btn-card-detail-lead"
                  size="large"
                  style={{ height: 50 }}
                >
                  ACCEPT
                </Button>
              </Popover>
            )
          )}
          <Popconfirm
            className="pop-faq"
            title={"คุณเเน่ใจหรือไม่"}
            placement="bottomLeft"
            onConfirm={HandleDeleteCard}
            okType="danger"
            okText="ลบ"
            cancelText="ยกเลิก"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okButtonProps={{
              style: {
                width: 50,
              },
            }}
          >
            <Button
              className={`btn-card-detail-delete ${
                boardItem?.status !== "send" && boardItem?.status !== "finish"
                  ? "mt-2"
                  : ""
              }`}
              size="large"
              style={{ height: 50 }}
            >
              DELETE
            </Button>
          </Popconfirm>
        </Col>
        {/* )}*/}
      </Row>
    );
  };

  return (
    <Modal
      centered={true}
      title={ModalHeader}
      open={visible}
      onCancel={toggle}
      footer={null}
      width={1000} // origin {CheckStatusCard(boardItem?.status) ? 1200 : 1000}
      className="py-2 card-lead-detail-modal"
      bodyStyle={{ height: "600px" }}
      destroyOnClose={true}
    >
      <RecoilRoot>
        {loading || !boardItem ? (
          pending
        ) : (
          <CardBody item={boardItem} formInstance={initialForm} />
        )}
      </RecoilRoot>
    </Modal>
  );
};

export default DetailCardModal;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, Col, Popconfirm, Row } from "antd";
import {
  DeleteFilled,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { FormDefault } from "../../../../../../components";
import {
  GETUSERROLE_API,
  DELUSERROLE_API,
} from "../../../../../../services/api/setting/userrole_api";
import { useHistory } from "react-router-dom";
import { CAlertToast } from "../../../../../../components/alert/CAlert";
import TableModifind from "../../../../../../components/table/Table";
import { tableLoading } from "../../../../../../components/loading/loading";
import { useSetRecoilState } from "recoil";
import { loadingTable } from "../../../../../../tools/recoil/recoil-atom";

const RoleMember = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);

  const [roleList, setRoleList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(10);
  const [initialValueSearch, setInitialValueSearch] = useState({
    search: "",
  });

  useEffect(() => {
    getData();
    return undefined;

  }, [initialValueSearch, limitPage, currentPage]);

  const history = useHistory();

  const getData = async () => {
    setLoading(true);
    try {
      const data = {
        page: currentPage,
        limit: limitPage,
        ...initialValueSearch,
      };
      const res = await GETUSERROLE_API(data);
      if (res.code === 200) {
        const dataSet = res.result[0];
        setLimitPage(dataSet.perpage);
        setRoleList(dataSet.item);
        setTotalPage(dataSet.total);
        // setCurrentPage(initialValueSearch ? 1 : currentPage);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  /////////////////////////////////////////////////////////////
  const handleSearch = (value: any) => {
    setCurrentPage(1);
    setInitialValueSearch(value);
  };

  const columns = [
    {
      title: "ลำดับ",
      align: "center",
      width: "10%",
      render: (_value: any, _item: any, index: number) => {
        return <>{(currentPage - 1) * limitPage + (index + 1)}</>;
      },
    },
    {
      title: "บทบาท",
      dataIndex: "name",
      key: "name",
      fixed: false,
    },
    {
      title: "จัดการ",
      key: "operation",
      fixed: "right" as "right",
      width: 100,
      // eslint-disable-next-line
      render: (_: string, item: any) => (
        <div className="action-table">
          <EditOutlined
            onClick={() => handleEdit(item.id)}
            className="faq-btn-edit"
          />
          <Popconfirm
            className="pop-faq"
            placement="top"
            title={"คุณเเน่ใจหรือไม่"}
            onConfirm={() => handleDelete(item.id)}
            okType="danger"
            okText="ลบ"
            cancelText="ยกเลิก"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okButtonProps={{
              style: {
                width: 50,
              },
            }}
          >
            <DeleteFilled className="ml-3 faq-btn" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const field = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา บทบาท",
      initialValue: initialValueSearch.search,
    },
  ];

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      const res = await DELUSERROLE_API(id);
      if (res.code === 200) {
        setRoleList(roleList.filter((item: any) => item.id !== id));
        CAlertToast({ method: "DEL_SUCCESS", text: res.message });
      } else {
        CAlertToast({ method: "DEL_ERROR", text: res.message });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleEdit = async (id: number) => {
    history.push(`/setting/usermanagement/editrole/${id}`);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };
  return (
    <div className="mt-4">
      <div style={{ paddingBottom: "20px" }}>
        <FormDefault HandleOnFinish={handleSearch} Field={field} Action={[]} />
      </div>

      <Card>
        <Row gutter={10}>
          <Col>
            <h1
              style={{
                fontFamily: "Bold",
                color: "#faa41a",
                fontSize: "25px",
              }}
            >
              PERMISSION
            </h1>
          </Col>
          <Col>
            <h1 style={{ fontFamily: "Bold", fontSize: "25px" }}>บทบาท</h1>
          </Col>
        </Row>
        <TableModifind
          cursor={true}
          rowKey="id"
          loading={tableLoading()}
          bordered={false}
          config={{
            total: totalPage,
            pageSize: limitPage,
            currentPage: currentPage,
          }}
          onChangePage={onChangePage}
          columns={columns}
          dataSource={roleList}
        />
      </Card>
    </div>
  );
};
export default RoleMember;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Row,
  Card,
  Button,
  Collapse,
  Form,
  Divider,
  Pagination,
  Empty,
  Popconfirm,
} from "antd";
import { FormDefault } from "../../../components/index";
import {
  CaretDownOutlined,
  DeleteFilled,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";

//-----------------------------API-------------------------------------//
import {
  ADDFAQDATA_API,
  DELETEFAQDATA_API,
  GETFAQDATA_API,
  UPDATE_FAQ_BY_ID_API,
} from "../../../services/api/FAQ_api";
import { AlertToast } from "../../../components/alert/alert";
import A_ICON from "../../../../src/assets/img/icon/A.svg";
import Q_ICON from "../../../../src/assets/img/icon/Q.svg";
import Header from "./header/Header";
import FInput from "../setting/menu/requirement_type/FInput";
import { HandleScroll } from "../../../tools/util";
import { RecoilRoot, useRecoilState } from "recoil";
import { LoadSomeContent } from "../../../components/loading/loading";
import { loadingChildState } from "../../../tools/recoil/recoil-atom";
//------------------------data_test-------------------------------//
type InputForm = {
  questions?: string;
  answer?: string;
};

const Faq = () => {
  const [form] = Form.useForm<InputForm>();
  const { Panel } = Collapse;
  const [loading, setLoading] = useRecoilState(loadingChildState);
  const [dataAsk, setDataAsk] = useState([
    { id: 0, questions: "", answer: "" },
  ]);
  const [isEdit, setIsEdit] = useState(false);
  const [itemId, setItemId] = useState(0);
  const [limitPage, setLimitPage] = useState(10);
  const [searchInfo, setSearchInfo] = useState({
    page: 1,
    search: "",
  } as any);
  const [total, setTotal] = useState<number>();
  const [initailSearch, setInitailSearch] = useState("");
  const [current, setCurrent] = useState(0);

  const field = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา คำถามที่พบบ่อย",
      initialValue: initailSearch,
    },
  ];

  useEffect(() => {
    getData();
    return undefined;
  }, [limitPage, searchInfo]);

  const HandleSetFieldValue = (value: any) => {
    form.setFieldsValue({
      answer: value?.answer || "",
      questions: value?.questions || "",
    });
  };

  const OnCanCel = () => {
    setIsEdit(false);
  };

  const getData = async () => {
    setLoading(true);
    const data = {
      search: searchInfo?.search,
      page: searchInfo.page,
      limit: limitPage,
    };

    try {
      const res = await GETFAQDATA_API(data);
      if (res.code === 200) {
        const listAsk = res.result[0]?.item || [];
        setDataAsk(listAsk);
        setTotal(res.result[0].total);
      } else {
        AlertToast({
          icon: "error",
          title: `เกิดข้อผิดพลาด! ระบบไม่สามารถเเสดงข้อมูลได้ กรุณาลองอีกครั้งในภายหลัง`,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const HandleOnSearch = (value: any) => {
    const data = {
      page: 1,
      search: value?.search,
    };
    setSearchInfo(data);
    setInitailSearch(value?.search);
  };

  const onFinish = async (value: any) => {
    setLoading(true);
    try {
      const res = await ADDFAQDATA_API(value);
      if (res.code === 200) {
        OnCanCel();
        getData();
        AlertToast({
          icon: "success",
          title: `สำเร็จ! เพิ่มข้อมูลเรียบร้อย`,
        });
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! เพิ่มข้อมูลไม่สำเร็จ กรุณาลองอีกครั้ง`,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const onUpdate = async (value: any) => {
    setLoading(true);
    try {
      const res = await UPDATE_FAQ_BY_ID_API(itemId, value);
      if (res.code === 200) {
        OnCanCel();
        getData();
        AlertToast({
          icon: "success",
          title: `สำเร็จ! บันทึกข้อมูลเรียบร้อย`,
        });
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! บันทึกข้อมูลไม่สำเร็จ กรุณาลองอีกครั้ง`,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleEditClick = async (value: {
    id: number;
    questions: string;
    answer: string;
  }) => {
    setItemId(value?.id);
    HandleSetFieldValue(value);
    setIsEdit(true);
    HandleScroll("formfield", true);
  };

  const handleDeleteClick = async (id: number) => {
    setLoading(true);
    try {
      const res = await DELETEFAQDATA_API(id);
      if (res.code === 200) {
        getData();
        AlertToast({
          icon: "success",
          title: `สำเร็จ! ข้อมูลถูกลบเเล้วเรียบร้อย`,
        });
      } else {
        AlertToast({
          icon: "error",
          title: `เกิดข้อผิดพลาด! ไม่สามารถลบข้อมูลได้`,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const FormFaq = () => (
    <Form form={form} onFinish={itemId !== 0 ? onUpdate : onFinish}>
      <div className="mb-3">
        <Header action={form.submit} callBack={OnCanCel} />
      </div>
      <Card>
        <Row gutter={10}>
          <Col>
            <h1
              style={{
                fontFamily: "Bold",
                color: "#faa41a",
                fontSize: "25px",
              }}
            >
              OPERATION STATUS
            </h1>
          </Col>
          <Col>
            <h1 style={{ fontFamily: "Bold", fontSize: "25px" }}>
              สถานะการดำเนินงาน
            </h1>
          </Col>
          <Divider style={{ border: "1px solid #E9E9E9", marginTop: "5px" }} />
        </Row>
        <Col span={24} className="mb-3">
          <FInput
            label="Question"
            name="questions"
            rules={{ required: true }}
          />
        </Col>
        <Col span={24} className="mb-3">
          <FInput label="Answer" name="answer" rules={{ required: true }} />
        </Col>
      </Card>
    </Form>
  );

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setSearchInfo({ ...setSearchInfo, page: page });
  };

  const HandleCheckLength = (value: string, isActive: boolean) => {
    return (
      <Row>
        <Col span={1}>
          <img src={Q_ICON} alt="q_icon" className="q-icon" />
        </Col>
        <Col span={23}>
          <div className="content-collape">{value || ""}</div>
        </Col>
      </Row>
    );
  };

  return (
    <RecoilRoot>
      <div className="h-100 min-vh-100" id="formfield">
        <LoadSomeContent loading={loading}>
          {isEdit && <FormFaq />}
          <Col span={24}>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginTop: "15px", marginBottom: "10px" }}
            >
              <FormDefault Field={field} HandleOnFinish={HandleOnSearch} />
              {!isEdit && (
                <Button
                  type="primary"
                  className="btn-gradient2 mb-2"
                  onClick={() => {
                    setItemId(0);
                    form.resetFields();
                    setIsEdit(true);
                  }}
                >
                  ADD FAQ
                </Button>
              )}
            </Row>
          </Col>
          {dataAsk.length < 1 ? (
            <Card className="mt-3">
              <Empty />
            </Card>
          ) : (
            <Card
              className="setting-cover-card mt-3"
              cover={
                <>
                  <h1 className="mb-0">
                    <span
                      style={{
                        fontFamily: "Bold",
                        color: "#faa41a",
                        fontSize: "23px",
                      }}
                    >
                      FAQ
                    </span>
                    <span
                      className="ml-2"
                      style={{ fontFamily: "Bold", fontSize: "23px" }}
                    >
                      คำถามที่พบบ่อย
                    </span>
                  </h1>
                </>
              }
            >
              <Row>
                <Col span={24}>
                  <Collapse
                    onChange={(e) => {
                      setCurrent(Number(e[e.length - 1]));
                    }}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => {
                      return (
                        <Button
                          className="btn-header-collape"
                          style={{
                            backgroundColor: "#EFEFEF",
                            paddingTop: "2px",
                          }}
                          icon={
                            <CaretDownOutlined rotate={isActive ? 180 : 0} />
                          }
                          size={"small"}
                        />
                      );
                    }}
                  >
                    {dataAsk.length > 0 &&
                      dataAsk?.map((item, idx) => (
                        <Panel
                          header={
                            <Row
                              justify="space-between"
                              className="w-100"
                              align="middle"
                              key={idx}
                            >
                              <Col lg={21}>
                                {HandleCheckLength(
                                  item?.questions,
                                  current === item?.id
                                )}
                              </Col>
                              <Col lg={3}>
                                <Row gutter={5} justify="end" className="mx-2">
                                  <Col
                                    className="mx-2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleEditClick(item);
                                    }}
                                  >
                                    <EditOutlined className="faq-btn-edit" />
                                  </Col>
                                  <Col
                                    className="mx-2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Popconfirm
                                      className="pop-faq"
                                      placement="top"
                                      title={"คุณเเน่ใจหรือไม่"}
                                      onConfirm={() => {
                                        handleDeleteClick(item?.id);
                                      }}
                                      okType="danger"
                                      okText="ลบ"
                                      cancelText="ยกเลิก"
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{ color: "red" }}
                                        />
                                      }
                                      okButtonProps={{
                                        style: {
                                          width: 50,
                                        },
                                      }}
                                    >
                                      <DeleteFilled className="faq-btn" />
                                    </Popconfirm>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          }
                          key={item?.id}
                        >
                          <Row
                            justify="start"
                            align="top"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            <Col>
                              <img src={A_ICON} alt="a_icon" />
                            </Col>
                            <Col
                              span={23}
                              style={{ marginLeft: "10px", fontSize: 17 }}
                              className="w-100"
                            >
                              {item?.answer || ""}
                            </Col>
                          </Row>
                        </Panel>
                      ))}
                  </Collapse>
                </Col>
              </Row>
            </Card>
          )}
        </LoadSomeContent>
        <Row justify="end" className="mt-2">
          <Pagination
            showSizeChanger
            pageSize={limitPage}
            current={searchInfo.page}
            total={total}
            onShowSizeChange={(_current, pageSize) =>
              onChangePage && onChangePage(pageSize, "pageSize")
            }
            onChange={(page) => onChangePage && onChangePage(page, "page")}
            pageSizeOptions={["10", "20", "30", "50"]}
            showTotal={(total) => `Total ${total} items`}
          />
        </Row>
      </div>
    </RecoilRoot>
  );
};

export default Faq;

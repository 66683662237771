import { Upload } from "antd";
import React from "react";

type Props = {
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  setImageRender: React.Dispatch<React.SetStateAction<string>>;
  imageRender: string;
};
const Uploader = ({ setImageUrl, setImageRender, imageRender }: Props) => {
  const getBase64 = (img: any, onFinish: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => onFinish(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info: any) => {
    getBase64(info.file, (imageRender: any) => {
      setImageUrl(info.file);
      setImageRender(imageRender);
    });
  };

  return (
    <div>
      <Upload
        listType="picture-card"
        showUploadList={false}
        onChange={handleChange}
        beforeUpload={() => false}
      >
        {imageRender ? (
          <img
            src={imageRender}
            alt="avatar"
            className="circle-img h-100"
            style={{
              height: "14rem",
              width: "14rem",
              objectFit: "cover",
            }}
          />
        ) : (
          <div>
            <div>+ Upload</div>
          </div>
        )}
      </Upload>
    </div>
  );
};

export default Uploader;

/* eslint-disable react-hooks/exhaustive-deps */

import { DeleteFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { Row, Card, Popconfirm } from "antd";
import Form from "antd/lib/form";
import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { FormDefault } from "../../../../../components";
import { CAlertToast } from "../../../../../components/alert/CAlert";
import CImage from "../../../../../components/custom_image/CImage";
import TableModifind from "../../../../../components/table/Table";
import {
  DELETECUSTOMER_API,
  GETCUSTOMERDATA_API,
} from "../../../../../services/api/setting/customer_api";
import { loadingState } from "../../../../../tools/recoil/recoil-atom";
import HeaderSetting from "../../component/header_setting/HerderSetting";

const Member = () => {
  const [disabledEdit, setDisabledEdit] = useState<boolean>(false);
  const setLoading = useSetRecoilState(loadingState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(12);
  const [initialValueSearch, setInitialValueSearch] = useState({
    search: "",
  });
  const [formHeader] = Form.useForm();

  const history = useHistory();

  const addTechnician = [
    {
      type: "btnPush",
      name: "เพิ่มสมาชิก",
      onClick: () => history.push(`/setting/customermanagement/form-user`),
    },
  ];
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getCustomer();
    return undefined;
  }, [initialValueSearch, limitPage, currentPage]);

  const tiltleHeader = {
    nameEN: "MEMBER CUSTOMER",
    nameTH: "สมาชิกลูกค้า",
  };

  const handleClickEdit = () => {
    setDisabledEdit(!disabledEdit);
  };

  const handleSubmit = (values: any) => {
    setCurrentPage(1);
    setInitialValueSearch({ ...values });
  };

  const getCustomer = async () => {
    setLoading(true);
    try {
      const queryStr = {
        page: currentPage,
        limit: limitPage,
        ...initialValueSearch,
      };
      const res = await GETCUSTOMERDATA_API(queryStr);
      if (res.code === 200) {
        const dataSet = res.result[0];
        setUserData(dataSet.item);
        // setCurrentPage(initialValueSearch ? 1 : currentPage);
        setTotalPage(dataSet.total);
        setLimitPage(dataSet.perpage);
        setUserData(dataSet.item);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDelete = async (id: any) => {
    try {
      const res = await DELETECUSTOMER_API(id);
      if (res.code === 200) {
        CAlertToast({ method: "DEL_SUCCESS", text: res.message });
        setUserData(userData.filter((item: any) => item.id !== id));
      } else {
        CAlertToast({ method: "DEL_ERROR", text: res.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const field = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา ชื่อหรือเบอร์โทร",
      initialValue: initialValueSearch.search,
    },
  ];
  console.log("userData", userData);

  const columns: any = [
    {
      title: "รูปภาพ",
      dataIndex: "image",
      key: "image",
      width: 90,
      render: (value: string, row: any, index: number) => {
        return (
          <Row style={{ height: 60 }} justify="center" align="middle">
            <CImage
              src={value}
              alt="user-member"
              defautImage="user"
              style={{
                width: 60,
                height: 60,
                borderRadius: 6,
                objectFit: "cover",
              }}
            />
          </Row>
        );
      },
    },
    {
      title: "ชื่อ",
      dataIndex: "",
      key: "",
      width: 130,
      render: (value: string, row: any, index: number) => {
        return `${row?.firstName} ${row?.lastName}`;
      },
    },
    {
      title: "ชื่อเล่น",
      dataIndex: "nickname",
      key: "nickname",
      width: 100,
      render: (value: string, row: any, index: number) => {
        return value;
      },
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "phone",
      key: "phone",
      width: 90,
      render: (value: string, row: any, index: number) => {
        return value;
      },
    },
    {
      title: "จัดการ",
      align: "center",
      width: 50,
      render: (value: any) => {
        return (
          <div className="action-table">
            <Popconfirm
              className="pop-faq"
              placement="top"
              title={"คุณเเน่ใจหรือไม่"}
              onConfirm={() => handleDelete(value.id)}
              okType="danger"
              okText="ลบ"
              cancelText="ยกเลิก"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              okButtonProps={{
                style: {
                  width: 50,
                },
              }}
            >
              <DeleteFilled
                className="ml-3 faq-btn"
                style={{
                  fontSize: 20,
                }}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const HandleOnDetail = (value: any) => {
    history.push({
      pathname: `/setting/customermanagement/form-user/` + value?.id,
    });
  };

  return (
    <div>
      <HeaderSetting
        form={formHeader}
        Title={tiltleHeader}
        Action={addTechnician}
        handleClickEdit={handleClickEdit}
        handleClickCancel={handleClickEdit}
        disabledEdit={disabledEdit}
      />
      <Card className="mt-3">
        {/* <DataMember
          pathmenu="customermanagement"
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          dataSource={userData}
          fieldFilter={field}
          pageConfig={{ currentPage, totalPage, limitPage }}
          onChangePage={onChangePage}
        /> */}
        <div className="card-table mb-3 mt-3">
          <FormDefault
            Field={field}
            Action={[]}
            HandleOnFinish={handleSubmit}
            HandleOnReset={handleSubmit}
          />
        </div>
        <TableModifind
          cursor={true}
          rowKey={"index"}
          bordered={true}
          config={{
            total: totalPage,
            pageSize: limitPage,
            currentPage: currentPage,
          }}
          columns={columns}
          dataSource={userData}
          onClickRow={HandleOnDetail}
          onChangePage={onChangePage}
        />
      </Card>
    </div>
  );
};

export default Member;

import { Fetch, FetchFormData } from "../../tools/fecth/fecth";
import { apiVersion } from "../../config/api";

import { createQueryString } from "../../tools/util";

export const USER_FIND_LIST_API = async (data?: {}) => {
  const result = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/user-lead/find-user${result}`,
  });
  return res;
};

export const INQUERY_GET_FORM_API = async () => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/inquiry/form`,
  });
  return res;
};
export const LEAD_GET_DELETE_ITEM_API = async (data?:any) => {
  const queryData = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/inquiry/status/delete${queryData}`,
  });
  return res;
};

export const ADD_NEW_INQUERY_API = async (data?: any) => {
  const res = await FetchFormData({
    method: "POST",
    path: `${apiVersion}/inquiry`,
    data,
  });
  return res;
};

export const GET_STATUS_NEW_INQUERY_API = async (data?: any) => {
  const queryData = createQueryString(data);
  const res = await FetchFormData({
    method: "GET",
    path: `${apiVersion}/inquiry/status/new-inquiry${queryData}`,
  });
  return res;
};
export const GET_STATUS_INBOX_API = async (data?: any) => {
  const queryData = createQueryString(data);
  const res = await FetchFormData({
    method: "GET",
    path: `${apiVersion}/inquiry/status/inbox${queryData}`,
  });
  return res;
};

export const GET_STATUS_DOING_API = async (data?: any) => {
  const queryData = createQueryString(data);
  const res = await FetchFormData({
    method: "GET",
    path: `${apiVersion}/inquiry/status/doing${queryData}`,
  });
  return res;
};

export const GET_STATUS_FINISH_API = async (data?: any) => {
  const queryData = createQueryString(data);
  const res = await FetchFormData({
    method: "GET",
    path: `${apiVersion}/inquiry/status/finish${queryData}`,
  });
  return res;
};

export const GET_STATUS_SEND_API = async (data?: any) => {
  const queryData = createQueryString(data);
  const res = await FetchFormData({
    method: "GET",
    path: `${apiVersion}/inquiry/status/send${queryData}`,
  });
  return res;
};

export const GET_STATUS_FOLLOW_API = async (data?: any) => {
  const queryData = createQueryString(data);
  const res = await FetchFormData({
    method: "GET",
    path: `${apiVersion}/inquiry/status/follow${queryData}`,
  });
  return res;
};

export const GET_INQUIRY_BY_ID = async (id: number) => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/inquiry/${id}`,
  });
  return res;
};

export const UPDATE_INITAIL_SERVICE = async (id: number, data: any) => {
  const res = await Fetch({
    method: "PUT",
    path: `${apiVersion}/inquiry/initial-service/${id}`,
    data,
  });
  return res;
};

export const UPDATE_REQUIMENT = async (id: number, data: any) => {
  const res = await FetchFormData({
    method: "PUT",
    path: `${apiVersion}/inquiry/requirement/${id}`,
    data,
  });
  return res;
};

export const UPDATE_CUSTOMER_CONTACT = async (id: number, data: any) => {
  const res = await Fetch({
    method: "PUT",
    path: `${apiVersion}/inquiry/customer-contact/${id}`,
    data,
  });
  return res;
};

export const GET_ADMIN_ALL = async () => {
  const res = await Fetch({ method: "GET", path: `${apiVersion}/admin/all` });
  return res;
};

export const FORWARD_FORM_ITEM = async (id: number, data: any) => {
  const res = await Fetch({
    method: "PUT",
    path: `${apiVersion}/inquiry/forward/${id}`,
    data,
  });
  return res;
};
export const ACCEPT_FORM_ITEM = async (id: number, data: any) => {
  const res = await Fetch({
    method: "PUT",
    path: `${apiVersion}/inquiry/accept/${id}`,
    data,
  });
  return res;
};

export const DELETE_ITEM_NOTE_BY_ID = async (id: number) => {
  const res = await Fetch({
    method: "DELETE",
    path: `${apiVersion}/note/${id}`,
  });
  return res;
};

export const UPDATE_ACTIVITY_BY_ID = async (id: number, data: any) => {
  const res = await FetchFormData({
    method: "PUT",
    path: `${apiVersion}/note/activity/${id}`,
    data,
  });
  return res;
};

export const POST_NOTE = async (data: any) => {
  const res = await FetchFormData({
    method: "POST",
    path: `${apiVersion}/note`,
    data,
  });
  return res;
};

export const POST_ACTIVITY = async (data: any) => {
  const res = await FetchFormData({
    method: "POST",
    path: `${apiVersion}/note/activity/lead`,
    data,
  });
  return res;
};

export const SUCCESS_ACTIVITY = async (id: any) => {
  const res = await Fetch({
    method: "PUT",
    path: `${apiVersion}/note/success-lead/${id}`,
  });
  return res;
};

export const CREATE_INQUIRY_BY_ID = async (data: any) => {
  const res = await Fetch({
    method: "POST",
    path: `${apiVersion}/inquiry/action/`,
    data,
  });
  return res;
};

export const AUTO_INQUIRY_BY_ID = async (id: any) => {
  const res = await Fetch({
    method: "POST",
    path: `${apiVersion}/inquiry/auto-action/${id}`,
  });
  return res;
};

export const SOURT_NOTE = async (id: number, data: any) => {
  const queryData = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/inquiry/${id}${queryData}`,
    data,
  });
  return res;
};

export const STEP_DONE_API = async (id: number, data: any) => {
  const queryData = createQueryString(data);
  const res = await Fetch({
    method: "PUT",
    path: `${apiVersion}/inquiry/success/${id}${queryData}`,
    data,
  });
  return res;
};

export const NOTE_TRACKING_API = async (data: any) => {
  const res = await Fetch({
    method: "POST",
    path: `${apiVersion}/note/note-tacking/`,
    data,
  });
  return res;
};


export const CHECK_REQUIMENT = async (data: any) => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/subject-contact/check-requirement${data}`
  });
  return res;
};

export const GET_PROVINCE = async () => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/thai-address/province`
  });
  return res;
};

export const GET_PROVINCE_BY_ID = async (id:number) => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/thai-address/suggest-district/${id}`
  });
  return res;
};

export const GET_DISTRICT_BY_ID = async (id:number) => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/thai-address/suggest-sub-district/${id}`
  });
  return res;
};

export const GET_FORCAST_BY_ID = async (id:number) => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/forecast/${id}`
  });
  return res;
};
export const POST_FORCAST = async (data:any) => {
  const res = await Fetch({
    method: "POST",
    path: `${apiVersion}/forecast`,
    data,
  });
  return res;
};
export const UPDATE_FORCAST = async (data:any) => {
  const res = await Fetch({
    method: "PUT",
    path: `${apiVersion}/forecast`,
    data,
  });
  return res;
};

export const DELETE_CARD_BY_ID = async (id:any) => {
  const res = await Fetch({
    method: "DELETE",
    path: `${apiVersion}/inquiry/${id}`,
  });
  return res;
}

export const REVERT_ITEM_DEL_API = async (data: any) => {
  const res = await Fetch({
    method: "PUT",
    path: `${apiVersion}/inquiry/revert`,
    data,
  });
  return res;
};

export const DEL_ITEM_REVERT_API = async (data: any) => {
  const res = await Fetch({
    method: "DELETE",
    path: `${apiVersion}/inquiry/status/delete`,
    data,
  });
  return res;
};
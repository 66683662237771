import { useState } from "react";
import { Col, Row, Radio, Popover } from "antd";
import { Line } from "react-chartjs-3";
import { RamdomColor } from "../../../tools/util";

type Props = {
  listObject: IMultiChart;
};
interface IMultiChart {
  contactChannel: IListsub[];
  subjectContact: IListsub[];
  userContactType: IListsub[];
}
interface IListsub {
  name: string;
  data: IData[];
}
interface IData {
  date: string;
  label: number;
  count: number;
}

const DoughnutChart = ({ listObject }: Props) => {
  const [isValue, setIsValue] = useState("contactChannel");
  const [isLabel] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

  const handleChange = (e: any) => {
    setIsValue(e.target.value);
  };

  const CheckListData = () => {
    const res =
      isValue === "contactChannel"
        ? listObject?.subjectContact
        : isValue === "subjectContact"
        ? listObject?.userContactType
        : listObject?.contactChannel;
    return res;
  };

  const SumCount = (listCount: any) => {
    const sum = listCount.reduce((a: any, b: any) => a + b, 0);
    return sum;
  };
  const DoughData = {
    labels: isLabel,
    datasets: CheckListData()?.map((item: any, idx: number) => {
      return {
        label: item?.name,
        borderColor: RamdomColor(CheckListData()?.length, idx),
        fill: false,
        backgroundColor: RamdomColor(CheckListData()?.length, idx),
        data: item?.data?.map((child: any) => child?.count),
        hidden: false,
        cubicInterpolationMode: "monotone",
        key: idx,
      };
    }),
  };

  const options = {
    legend: {
      display: false,
      position: "bottom",
      align: "start",
      labels: {
        padding: 20,
        usePointStyle: true,
      },
    },
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            // max: 100, //origin 600
          },
        },
      ],
    },
  };

  const CheckLengthNumber = (text: string) => {
    return text?.length > 4 ? (
      <Popover
        placement="topLeft"
        content={
          <div
            style={{
              wordWrap: "break-word",
              color: "#f37d31",
              textAlign: "center",
            }}
          >
            <div style={{ fontWeight: "bold" }}>
              {Number(text).toLocaleString("en-US") || ""}
            </div>
          </div>
        }
        title={false}
      >
        <b>{Number(text).toLocaleString("en-US")}</b>
      </Popover>
    ) : (
      <b>{Number(text).toLocaleString("en-US")}</b>
    );
  };

  return (
    <div>
      <Row>
        <Col lg={24} xs={24}>
          <Radio.Group
            value={isValue}
            onChange={handleChange}
            className="select-line mb-4-5"
          >
            <Radio.Button value="contactChannel">
              เรื่องที่ต้องการติดต่อ
            </Radio.Button>
            <Radio.Button value="subjectContact">ประเภทผู้ติดต่อ</Radio.Button>
            <Radio.Button value="userContactType">
              ช่องทางการติดต่อ
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col lg={24} xs={24}>
          <Line data={DoughData} options={options} />
          <Row gutter={30} className="chart-height">
            {CheckListData()?.map((item: any, idx: number) => {
              return (
                <Col span={12} style={{ color: "black" }} key={idx}>
                  <Row justify="space-between" align="top">
                    <Col span={2} style={{ paddingTop: 2 }}>
                      <button
                        style={{
                          height: 17,
                          width: 17,
                          border: "unset",
                          borderRadius: 5,
                          background: RamdomColor(CheckListData()?.length, idx),
                        }}
                      />
                    </Col>
                    <Col
                      span={19}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      className="fontgrey"
                    >
                      {item?.name}
                    </Col>
                    <Col span={3} className="text-number-legend">
                      {CheckLengthNumber(
                        SumCount(item?.data?.map((child: any) => child?.count))
                      )}
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DoughnutChart;

import { Form, DatePicker } from "antd";
// import moment from "moment";
import { FloatLabel } from "..";
import moment from "moment";
type Props = {
  options?: any;
  label: string;
  name: string | any[];
  rules?: { required: boolean; message?: string };
  type?: "number" | "";

  fieldKey?: any;
};
const FInput = ({
  options,
  label = "",
  name,
  rules = { required: false, message: "" },
  fieldKey,
}: Props) => {
  return (
    <>
      <Form.Item
        className="formlabel"
        name={name}
        fieldKey={fieldKey}
        rules={[
          {
            required: rules && rules.required,
            message:
              rules && rules.message
                ? rules.message
                : `โปรดกรอกข้อมูล${label}!`,
          },
        ]}
      >
        <ChildrenRender {...options} label={label} name={name} />
      </Form.Item>
    </>
  );
};
const ChildrenRender = (propsChild: any) => {
  return (
    <>
      <FloatLabel
        label={propsChild.label ? propsChild.label : ""}
        name={propsChild.restField ? propsChild.id : propsChild.name}
        value={propsChild.value}
      >
        <DatePicker
          onChange={propsChild.onChange}
          disabled={propsChild.disabled}
          defaultValue={moment(propsChild?.value, "DD/MM/YYYY")}
          placeholder={""}
          style={{ height: 50, width: "100%" }}
        />
      </FloatLabel>
    </>
  );
};

export default FInput;

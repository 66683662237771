import { Select } from "antd";

export interface IOptionValue {
  values: object[];
  key: string;
  name: any;
}

const _isObj = (value: any): boolean => {
  return typeof value === "object";
};

const TxSelect = ({
  optionValue = { values: [], key: "", name: "" },
  ...props
}: {
  optionValue: IOptionValue;
}) => {
  const renderOption = (): any => {
    return optionValue.values.map((item: any, index) => {
      return (
        <Select.Option
          key={index}
          value={_isObj(item) ? item[optionValue.key] : item}
        >
          {_isObj(item) ? item[optionValue.name] : item}
        </Select.Option>
      );
    });
  };
  return <Select {...props}>{renderOption()}</Select>;
};

export default TxSelect;

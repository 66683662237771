import { useState } from "react";
import { Col, Row } from "antd";
import List from "./list/CustomerList";
import CustomerDatabase from "./customer_database/CustomerDataBase";

const CustomerService = () => {
  const [menuTab, setMenuTab] = useState(1);

  return (
    <div className="scope-content">
      <Row className="h-100 min-vh-100">
        <Col lg={24}>
          <Row style={{ borderBottom: "1px solid rgb(250, 164, 26)" }}>
            <button
              className={`list-custom-btn ${
                menuTab === 1
                  ? "font-color-btn-tab-active"
                  : "font-color-btn-tab"
              }`}
              onClick={() => setMenuTab(1)}
            >
              รายการ
            </button>
            <button
              className={`customer-btn-custom ${
                menuTab === 2
                  ? "font-color-btn-tab-active"
                  : "font-color-btn-tab"
              }`}
              onClick={() => setMenuTab(2)}
            >
              ฐานข้อมูลลูกค้า
            </button>
          </Row>
          <div style={{ marginTop: "12px" }}>
            {menuTab === 1 ? <List /> : <CustomerDatabase />}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerService;

import { Col, Divider, Row } from "antd";
import CloseBtnTab from "../btn/CloseBtnTab";

const VisibleDivider = ({
  visible = false,
  onClick,
  classDivider = {},
}: {
  visible: boolean;
  onClick: (data?: any) => void;
  classDivider?: any | {};
}) => {
  return visible ? (
    <Row align="middle" className="mt-2">
      <Col flex="auto">
        <Divider
          className={`mx-0 ${classDivider}`}
          style={{ backgroundColor: "#DCDCDC" }}
        />
      </Col>
      <Col flex="4rem">
        <CloseBtnTab on={visible} onClick={onClick} />
      </Col>
    </Row>
  ) : (
    <div className="my-3">
      <CloseBtnTab on={visible} onClick={onClick} />
    </div>
  );
};

export default VisibleDivider;

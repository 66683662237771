import { useRef } from 'react'
import { Col, Row, Modal } from "antd";
import { PDF } from '../../../../assets/img'
import { useReactToPrint } from "react-to-print";
import PrintPDF from './PrintPDF'

const CustomerDetail = ({ onVisible, onChange, dataDetail }: {
    onVisible: boolean;
    onChange: (value: boolean) => void;
    dataDetail: any
}) => {

    const componentRef = useRef<any>(null)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Customer Information",
    });
    return (
        <div>
            <Modal
                title={
                    <div style={{ padding: "5px 0px 0px 0px" }}>
                        <h3 style={{ color: "#FAA41A", fontWeight: "bold" }}>Customer Information</h3>
                        <span style={{ fontSize: 14 }}>ข้อมูลลูกค้า</span>
                    </div>
                }
                open={onVisible}
                onOk={() => onChange(false)}
                onCancel={() => onChange(false)}
                width={700}
                footer={false}
            >
                <div className="card-modal-customer-database">
                    <Row className="header-modal-customer-database" justify='space-between' align='middle'>
                        <Col>CUSTOMER CONTACT I ข้อมูลการติดต่อลูกค้า</Col>
                        <div onClick={handlePrint} className='PDF-btn-modal'>
                            <Row align='middle'>
                                <img src={PDF} style={{ width: 15 }} alt="Logo" className='mr-1 ml-2' />
                                <span style={{ fontWeight: 'bold', color: 'black', fontSize: 10 }} className='mr-2 ml-1'>PDF</span>
                            </Row>
                        </div>
                    </Row>
                    <div className='m-2 mr-3 ml-3'>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>รหัสลูกค้า</Col>
                                    <Col className='text-value-card-modal'>{dataDetail.userCode && dataDetail.userCode}</Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>ประเภทลูกค้า</Col>
                                    <Col className='text-value-card-modal'>{dataDetail.type && dataDetail.type}</Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>ชื่อบริษัท</Col>
                            <Col className='text-value-card-modal'>{dataDetail.companyName && dataDetail.companyName}</Col>
                        </Row>
                    </div>
                    {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                    <div style={{ border: '1px solid lightgray', margin: 10, borderRadius: 8 }} >
                        <div style={{ backgroundColor: '#F1F1F2', padding: 8, borderTopRightRadius: 8, borderTopLeftRadius: 8 }}>
                            สาขา / ที่ตั้ง
                        </div>
                        <Row className='m-2'>
                            <Col span={24}>
                                <Row>
                                    <Col span={4}>ที่อยู่</Col>
                                    <Col className='text-value-card-modal'>{dataDetail.customerAddress}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                    <Row className='mr-3 ml-3'>
                        <Col span={24}>
                            <Row>
                                <Col span={6}>เว็บไซต์</Col>
                                <Col className='text-value-card-modal'>{dataDetail.companyWebsite && dataDetail.companyWebsite}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>Facebook</Col>
                                <Col className='text-value-card-modal'>{dataDetail.companyFacebook && dataDetail.companyFacebook}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>Line ID</Col>
                                <Col className='text-value-card-modal'>{dataDetail.companyLine && dataDetail.companyLine}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>เบอร์โทรสำนักงาน</Col>
                                <Col className='text-value-card-modal'>{dataDetail.officeTel && dataDetail.officeTel}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>เเฟ็กซ์</Col>
                                <Col className='text-value-card-modal'>{dataDetail.fax && dataDetail.fax}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>อีเมลล์</Col>
                                <Col className='text-value-card-modal'>{dataDetail.companyEmail && dataDetail.companyEmail}</Col>
                            </Row>
                            <Row>
                                <Col span={6}>Location</Col>
                                <Col className='text-value-card-modal'>{dataDetail.companyLocation && dataDetail.companyLocation}</Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                    <div style={{ border: '1px solid lightgray', margin: 10, borderRadius: 8 }} >
                        <div style={{ backgroundColor: '#F1F1F2', padding: 8, borderTopRightRadius: 8, borderTopLeftRadius: 8 }}>
                            ชื่อสามารถติดต่อได้
                        </div>
                        {dataDetail.customerContactPersons && dataDetail.customerContactPersons.map((item: any, index: number) =>
                            <Row key={index} className='p-2' style={{ borderBottom: dataDetail.customerContactPersons.length - 1 !== index ? '1px solid lightgray' : ""}}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={8}>ชื่อผู้ติดต่อ</Col>
                                        <Col className='text-value-card-modal'>{item.contactName && item.contactName}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>เบอร์โทร</Col>
                                        <Col className='text-value-card-modal'>{item.tel && item.tel}</Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={8}>ตำเเหน่ง/เเผนก</Col>
                                        <Col className='text-value-card-modal'>{item.position && item.position}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>อีเมลล์</Col>
                                        <Col className='text-value-card-modal'>{item.email && item.email}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <Row className='pr-3 pl-3 mb-3'>
                        <Col span={12}>
                            <Row>
                                <Col span={8}>ผู้ดูเเล (AE)</Col>
                                <Col className='text-value-card-modal'>{dataDetail.aE?.firstName} {dataDetail.aE?.lastName}</Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={8}>ผู้ดูเเล (AO)</Col>
                                <Col className='text-value-card-modal'>{dataDetail.aO?.firstName} {dataDetail.aO?.lastName}</Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <div className="d-none" >
                <PrintPDF Ref={componentRef} dataPrint={dataDetail}/>
            </div>
        </div>
    )
}

export default CustomerDetail;
import LoginPage from "../views/login/LoginPage";
import Dashboard from "../views/dashboard/Dashboard";
import CustomerService from "../views/content/customer_service/CustomerService";
import DirectCustomer from "../views/content/direct_customer/DirectCustomer";
import DirectCustomerDetail from "../views/content/direct_customer/DirectDetail";
import Faq from "../views/content/faq/Faq";
import Leads from "../views/content/leads/Leads";
import Project from "../views/content/project/Project";
import ProjectDetail from "../views/content/project/ProjectDetail";
import Report from "../views/content/report/Report";
import Setting from "../views/content/setting/Setting";
import Technician from "../views/content/technician/Technician";
import TechnicianDetail from "../views/content/technician/TechnicianDetail";
import ReportDetial from "../views/content/customer_service/list/Report/Report";
import UserManageMent from "../views/content/setting/menu/user_management/UserManageMent";


import ProfileManageMent from "../views/content/setting/menu/manage_profile/ManageProfile";
import FormUser from "../views/content/setting/component/user_form/UserForm";

import SubReportAoAe from "../views/content/customer_service/list/Report/SubReportAoAe";
import SubReportCustomer from "../views/content/customer_service/list/Report/SubReportCustomer";
import SubReportService from "../views/content/customer_service/list/Report/SubReportService";
import AddList from "../views/content/customer_service/list/Add";
import DetailList from "../views/content/customer_service/list/CustomerDetail";
import ManageRole from "../views/content/setting/menu/user_management/manage_role/ManageRole";
import AddNewQuery from "../views/content/leads/add_newquery/AddInNewQuery";
import RycycleBin from "../views/content/leads/RycicleBin";
import SubReportRequirement from "../views/content/report/SubReportRequirement";
import SubReportContact from "../views/content/report/SubReportContact";
import SubReportContactType from "../views/content/report/SubReportContactType";
import SubReportForecast from "../views/content/report/SubReportForecast";
import SubReportProducts from "../views/content/report/SubReportInterestProduct";
import SubReportQualifyLead from "../views/content/report/SubReportQualifyLead";
import SubReportNewInquiry from "../views/content/report/SubReportNewInquiry";
import TechnicianManagementMember from "../views/content/setting/menu/technician_management/Member";
import CustomerManagementMember from "../views/content/setting/menu/customer_management/Member";
import ContactType from "../views/content/setting/menu/contact_type/ContactType";
import ProjectType from "../views/content/setting/menu/project_type/ProjectType";
import AluminiumColor from "../views/content/setting/menu/aluminium_color/AluminiumColor";
import ContactChanel from "../views/content/setting/menu/contact_channel/ContactChannel";
import ContactMatter from "../views/content/setting/menu/contact_matter/ContactMatter";
import InterestProduct from "../views/content/setting/menu/interest_product/InterestProduct";
import RequirementType from "../views/content/setting/menu/requirement_type/RequirementType";
import ProjectTransfer from "../views/content/setting/menu/project_transfer/ProjectTransfer";
import Postion from "../views/content/setting/menu/position/Position";
import ColorType from "../views/content/setting/menu/color_type/ColorType";
import Goal from "../views/content/setting/menu/goal/Goal";
import NotFound from "../views/404/NotFound";


export interface IRouter {
  path: string;
  name: string;
  authentMenuName?: string;
  exact: boolean;
  component: React.FC;
}

export const routers: IRouter[] = [
  {
    path: "*",
    name: "404",
    exact: true,
    component: NotFound,
  },
  {
    path: "/",
    name: "home",
    exact: true,
    component: Dashboard,
    authentMenuName: "dashboard",
  },
  {
    path: "/login",
    name: "login",
    exact: true,
    component: LoginPage,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/customerservice",
    name: "customerservice",
    exact: true,
    component: CustomerService,
  },
  {
    path: "/customerservice/list/report",
    name: "report_detail",
    exact: true,
    component: ReportDetial,
  },
  {
    path: "/customerservice/list/report/subreportaoae",
    name: "report_detail",
    exact: true,
    component: SubReportAoAe,
  },
  {
    path: "/customerservice/list/report/subreportcustomer",
    name: "report_detail",
    exact: true,
    component: SubReportCustomer,
  },
  {
    path: "/customerservice/list/report/subreportservice",
    name: "report_detail",
    exact: true,
    component: SubReportService,
  },
  {
    path: "/customerservice/list/add",
    name: "add_list",
    exact: true,
    component: AddList,
  },
  {
    path: "/customerservice/list/detail/edit/:id",
    name: "edit_list",
    exact: true,
    component: AddList,
  },
  {
    path: "/customerservice/list/detail/:id",
    name: "detail_list",
    exact: true,
    component: DetailList,
  },
  {
    path: "/directcustomer",
    name: "directcustomer",
    exact: true,
    component: DirectCustomer,
  },
  {
    path: "/directcustomer/detail/:id",
    name: "directcustomer_detail",
    exact: true,
    component: DirectCustomerDetail,
  },
  {
    path: "/faq",
    name: "faq",
    exact: true,
    component: Faq,
  },
  {
    path: "/leads",
    name: "leads",
    exact: true,
    component: Leads,
  },
  {
    path: "/leads/addnewinquery",
    name: "inquery",
    exact: true,
    component: AddNewQuery,
  },
  {
    path: "/leads/ryciclebin",
    name: "ryciclebin",
    exact: true,
    component: RycycleBin,
  },
  {
    path: "/project",
    name: "project",
    exact: true,
    component: Project,
  },
  {
    path: "/project/detail/:id",
    name: "project_detail",
    exact: true,
    component: ProjectDetail,
  },
  {
    path: "/report",
    name: "report",
    exact: true,
    component: Report,
  },

  {
    path: "/report/requirement",
    name: "report",
    exact: true,
    component: SubReportRequirement,
  },
  {
    path: "/report/qualifylead",
    name: "report",
    exact: true,
    component: SubReportQualifyLead,
  },
  {
    path: "/report/contact",
    name: "report",
    exact: true,
    component: SubReportContact,
  },
  {
    path: "/report/contacttype",
    name: "report",
    exact: true,
    component: SubReportContactType,
  },
  {
    path: "/report/products",
    name: "report",
    exact: true,
    component: SubReportProducts,
  },
  {
    path: "/report/forecast",
    name: "report",
    exact: true,
    component: SubReportForecast,
  },
  {
    path: "/report/newinquiry",
    name: "report",
    exact: true,
    component: SubReportNewInquiry,
  },
  {
    path: "/setting",
    name: "setting",
    exact: true,
    component: Setting,
  },
  {
    path: "/technician",
    name: "technician",
    exact: true,
    component: Technician,
  },
  {
    path: "/technician/detail/:id",
    name: "technician_detail",
    exact: true,
    component: TechnicianDetail,
  },
  {
    path: "/setting/usermanagement/member",
    name: "user member ",
    exact: true,
    component: UserManageMent,
  },
  {
    path: "/setting/usermanagement/form-user",
    name: "create user",
    exact: true,
    component: FormUser,
  },
  {
    path: "/setting/usermanagement/form-user/:id",
    name: "edit user",
    exact: true,
    component: FormUser,
  },
  {
    path: "/setting/technicianmanagement/form-user",
    name: "create technician",
    exact: true,
    component: FormUser,
  },
  {
    path: "/setting/technicianmanagement/form-user/:id",
    name: "edit user",
    exact: true,
    component: FormUser,
  },
  {
    path: "/setting/customermanagement/form-user",
    name: "create customer",
    exact: true,
    component: FormUser,
  },
  {
    path: "/setting/customermanagement/form-user/:id",
    name: "edit customer",
    exact: true,
    component: FormUser,
  },
  {
    path: "/setting/usermanagement/createrole",
    name: "createrole",
    exact: true,
    component: ManageRole,
  },
  {
    path: "/setting/usermanagement/editrole/:id",
    name: "createrole",
    exact: true,
    component: ManageRole,
  },
  {
    path: "/setting/usermanagement/edituser/:id",
    name: "edituser",
    exact: true,
    component: ProfileManageMent,
  },
  {
    path: "/setting/profile",
    name: "profile",
    exact: true,
    component: ProfileManageMent,
  },
  {
    path: "/setting/technicianmanagement/member",
    name: "technician member",
    exact: true,
    component: TechnicianManagementMember,
  },
  {
    path: "/setting/customermanagement/member",
    name: "customer member ",
    exact: true,
    component: CustomerManagementMember,
  },
  {
    path: "/setting/contact-type",
    name: "contact type",
    exact: true,
    component: ContactType,
  },
  {
    path: "/setting/project-type",
    name: "project type",
    exact: true,
    component: ProjectType,
  },
  {
    path: "/setting/aluminium-color",
    name: "aluminium color",
    exact: true,
    component: AluminiumColor,
  },
  {
    path: "/setting/position",
    name: "position",
    exact: true,
    component: Postion,
  },
  {
    path: "/setting/contact-channel",
    name: "contact channel",
    exact: true,
    component: ContactChanel,
  },
  {
    path: "/setting/contact-matter",
    name: "contact matter",
    exact: true,
    component: ContactMatter,
  },
  {
    path: "/setting/interest-product",
    name: "interest product",
    exact: true,
    component: InterestProduct,
  },
  {
    path: "/setting/requirement-type",
    name: "requirement type",
    exact: true,
    component: RequirementType,
  },
  {
    path: "/setting/project-transfer",
    name: "project transfer",
    exact: true,
    component: ProjectTransfer,
  },
  {
    path: "/setting/color-type",
    name: "color type",
    exact: true,
    component: ColorType,
  },
  {
    path: "/setting/goal",
    name: "goal",
    exact: true,
    component: Goal,
  },

];

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
// @ts-ignore //ใส่เพื่อปิด warning เพราะใส่ ผิดสตรัคเจอร์ typescript
import DIS1 from "../../../../assets/img/icon/icon_card_customer/DIS–1.png";
// @ts-ignore
import DIS_ac_1 from "../../../../assets/img/icon/icon_card_customer/DIS-ac–1.png";
// @ts-ignore
import DIS2 from "../../../../assets/img/icon/icon_card_customer/FAB–2.png";
// @ts-ignore
import DIS_ac_2 from "../../../../assets/img/icon/icon_card_customer/FAB-ac–2.png";
// @ts-ignore
import DIS3 from "../../../../assets/img/icon/icon_card_customer/IND–3.png";
// @ts-ignore
import DIS_ac_3 from "../../../../assets/img/icon/icon_card_customer/IND-ac–3.png";
// @ts-ignore
import arrow_suc from "../../../../assets/img/icon/icon_card_customer/arrow_suc.png";
// @ts-ignore
import arrow from "../../../../assets/img/icon/icon_card_customer/arrow.png";
import { FormDefault } from "../../../../components/index";
import CustomerDetail from "./CustomerDetail";
import {
  CUSTOMER_DATA_BASE_GET_DATA_API,
  CUSTOMER_SERVICE_ERP_EXCEL_API,
  GET_SEARCH_ERP_USER_API_BY_ID,
} from "../../../../services/api/customer_api";
import TableModifind from "../../../../components/table/Table";
import { typeFilter } from "../../../../interface/ICustomerService.interface";
import { loadingTable } from "../../../../tools/recoil/recoil-atom";
import { useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import { _isEmpty } from "@yim/util-tool-ts";

const CustomerDataBase = () => {
  const [menuTab, setMenuTab] = useState(0);
  const [dataSource, setDataSource] = useState({
    dataSource: [] as any,
    dataFind: [] as any,
    dataDetail: [] as any,
  });
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [limitPage, setLimitPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>();
  const [initailValue, setInitailValue] = useState({} as any);
  const [initailLength, setInitailLength] = useState({} as any);

  const datainfoTabCard = [
    {
      img: arrow,
      imgAct: arrow_suc,
      text: "รวมทั้งหมด",
      count: initailLength?.all,
    },
    {
      img: DIS_ac_1,
      imgAct: DIS1,
      text: "DIS",
      count: initailLength?.distributor,
    },
    {
      img: DIS_ac_2,
      imgAct: DIS2,
      text: "FAB",
      count: initailLength?.fabricator,
    },
    {
      img: DIS_ac_3,
      imgAct: DIS3,
      text: "IND",
      count: initailLength?.industrial,
    },
  ];

  /////////////////////////////////////////Function of Form Default///////////////////////////////////////////////////
  const field = [
    {
      type: "input",
      name: "find",
      initialValue: initailValue?.find,
      childrenProps: {
        style: { width: "400px" },
      },
    },
  ];

  const action = [
    {
      type: "btnExport",
      onClick: () => handleGetExcelAPI(),
    },
  ];

  useEffect(() => {
    HandleGetDataInfo({
      type: ChecktypeFilter(),
      page: currentPage,
      limit: limitPage,
    });
    return undefined;
  }, [menuTab, limitPage, currentPage]);

  const ChecktypeFilter = () => {
    return menuTab === 0
      ? "all"
      : menuTab === 1
      ? "Distributor"
      : menuTab === 2
      ? "Fabricator"
      : "Industrial";
  };

  const HandleGetDataInfo = async (typeFilter?: typeFilter) => {
    setLoading(true);
    try {
      const res = await CUSTOMER_DATA_BASE_GET_DATA_API(typeFilter);
      if (res.code === 200) {
        const dataTable = RefacterData(res.result[0].item);
        if (_isEmpty(initailLength)) {
          GetCardStatusLength(res.result[0]);
        }
        setTotal(res.result[0]?.total);
        setDataSource({
          ...dataSource,
          dataSource: dataTable,
          dataFind: res.result[0].item,
        });
      } else {
        Swal.fire(
          "ผิดพลาด!",
          `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด ${
            res.message === "Not Found" ? "ไม่พบข้อมูล!" : "!" + res.message
          }`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const GetCardStatusLength = (all: any) => {
    setInitailLength({
      all: all.total,
      industrial: all.ind,
      fabricator: all.fab,
      distributor: all.dis,
    });
  };

  const CheckFilterValue = (value: any) => {
    const res = Number(value);
    if (res) {
      const result = dataSource.dataFind.find(
        (item: any, idx: number) => Number(item.userCode) === res
      );
      const dataSearch = {
        code: value,
        name: !_isEmpty(result) ? result?.companyName : "",
      };
      return dataSearch;
    } else {
      const result = dataSource.dataFind.find(
        (item: any, idx: number) => item.companyName === value
      );
      const dataSearch = {
        code: !_isEmpty(result) ? result?.userCode : "",
        name: value,
      };
      return dataSearch;
    }
  };

  const HandleOnFinish = async (value: any) => {
    setLoading(true);
    const result = CheckFilterValue(value?.find);
    setInitailValue(value);
    if (_isEmpty(value?.find)) {
      HandleGetDataInfo({
        type: ChecktypeFilter(),
        page: currentPage,
        limit: limitPage,
      });
    } else {
      try {
        const res = await GET_SEARCH_ERP_USER_API_BY_ID(result);
        if (res.code === 200) {
          const dataTable = RefacterData(res.result);
          setDataSource({
            ...dataSource,
            dataSource: dataTable,
            dataFind: res.result,
          });
        } else {
          Swal.fire(
            "ผิดพลาด!",
            `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด ${
              res.message === "Not Found" ? "ไม่พบข้อมูล!" : "!" + res.message
            }`,
            "error"
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };

  /////////////////////////////////////////Function of Form Default///////////////////////////////////////////////////

  const RefacterData = (dataInfo?: []) => {
    const result =
      dataInfo &&
      dataInfo?.map((item: any, idx: any) => {
        return item?.customerContactPersons.length > 0
          ? item?.customerContactPersons?.map((child: any, index: any) => {
              return {
                uniqKey: item?.userCode,
                type: index === 0 ? item?.type || "" : "empty",
                userCode: index === 0 ? item?.userCode || "" : "empty",
                companyName: index === 0 ? item?.companyName || "" : "empty",
                ///////////////////////////////////////child value/////////////////////////
                contactPersons: child?.contactName,
                position: child?.position,
                email: child?.email,
                tel: child?.tel,
                ///////////////////////////////////////child value/////////////////////////
                officeTel: index === 0 ? item?.officeTel || "" : "empty",
                fax: index === 0 ? item?.fax || "" : "empty",
                companyEmail: index === 0 ? item?.companyEmail || "" : "empty",
                companyFacebook:
                  index === 0 ? item?.companyFacebook || "" : "empty",
                companyLine: index === 0 ? item?.companyLine || "" : "empty",
                companyWebsite:
                  index === 0 ? item?.companyWebsite || "" : "empty",
                companyLocation:
                  index === 0 ? item?.companyLocation || "" : "empty",
                adress: index === 0 ? item?.customerAddress : "empty",
                aE: index === 0 ? item?.aE?.firstName || "" : "empty",
                aO: index === 0 ? item?.aO?.firstName || "" : "empty",
                aE_ssn: item?.aE?.ssn || "",
                aO_ssn: item?.aO?.ssn || "",
                row_span: item?.customerContactPersons?.length,
              };
            })
          : {
              uniqKey: item?.userCode,
              type: item?.type || "",
              userCode: item?.userCode || "",
              companyName: item?.companyName || "",
              ///////////////////////////////////////child value/////////////////////////
              contactPersons: "",
              position: "",
              email: "",
              tel: "",
              ///////////////////////////////////////child value/////////////////////////
              officeTel: item?.officeTel || "",
              fax: item?.fax || "",
              companyEmail: item?.companyEmail || "",
              companyFacebook: item?.companyFacebook || "",
              companyLine: item?.companyLine || "",
              companyWebsite: item?.companyWebsite || "",
              companyLocation: item?.companyLocation || "",
              adress: item?.customerAddress || "",
              aE: item?.aE?.firstName || "",
              aO: item?.aO?.firstName || "",
              aE_ssn: item?.aE?.ssn || "",
              aO_ssn: item?.aO?.ssn || "",
              row_span: item?.customerContactPersons?.length | 1,
            };
      });
    const resultres = result && result.flat(Infinity);
    const dataRefacter =
      resultres &&
      resultres.map((item, index) => {
        return { ...item, index: index };
      });
    return dataRefacter;
  };

  const columns: any = [
    {
      title: "ประเภทลูกค้า",
      dataIndex: "type",
      key: "type",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "รหัส",
      dataIndex: "userCode",
      key: "userCode",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "ชื่อบริษัท",
      dataIndex: "companyName",
      key: "companyName",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "ผู้ติดต่อ",
      dataIndex: "contactPersons",
      key: "contactPersons",
      render: (value: any, row: any, index: any) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "ตำเเหน่ง",
      dataIndex: "position",
      key: "position",
      render: (value: any, row: any, index: any) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "เบอร์โทร",
      dataIndex: "tel",
      key: "tel",
      render: (value: any, row: any, index: any) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "อีเมลล์",
      dataIndex: "email",
      key: "email",
      render: (value: any, row: any, index: any) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "เบอร์โทรสำนักงาน",
      dataIndex: "officeTel",
      key: "officeTel",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "เเฟ็กซ์",
      dataIndex: "fax",
      key: "fax",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "อีเมลล์บริษัท",
      dataIndex: "companyEmail",
      key: "companyEmail",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "เว็บไซต์",
      dataIndex: "companyWebsite",
      key: "companyWebsite",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "facebook",
      dataIndex: "companyFacebook",
      key: "companyFacebook",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "Line",
      dataIndex: "companyLine",
      key: "companyLine",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "location",
      dataIndex: "companyLocation",
      key: "companyLocation",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "ที่อยู่",
      dataIndex: "adress",
      key: "adress",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "ผู้ดูเเล (AE)",
      dataIndex: "aE",
      key: "aE",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "ผู้ดูเเล (AO)",
      dataIndex: "aO",
      key: "aO",
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "empty") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
  ];

  const handleGetExcelAPI = async () => {
    const dataInfo = {
      type: ChecktypeFilter(),
    };
    setLoading(true);
    try {
      const res = await CUSTOMER_SERVICE_ERP_EXCEL_API(dataInfo);
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `customer-database.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        Swal.fire(
          "ผิดพลาด!",
          `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const HandleOnDetail = (reacordValue: any) => {
    const result = dataSource.dataFind.find(
      (item: any) => item.userCode && item.userCode === reacordValue.uniqKey
    );
    setDataSource({
      ...dataSource,
      dataDetail: !_isEmpty(result) ? result : [],
    });
    setIsModalVisible(true);
  };

  const OnChange = (value: boolean) => {
    setIsModalVisible(value);
  };

  const HeaderCard = () => (
    <Row align="middle" className="box-row-card">
      {datainfoTabCard.map((item, idx) => (
        <Col
          key={idx}
          span={6}
          className={menuTab === idx ? `card-menu-tab-active` : `card-menu-tab`}
          style={{
            backgroundColor: menuTab === idx ? "#FFC627" : "white",
            borderTopLeftRadius: idx === 0 ? "5px" : 0,
            borderBottomLeftRadius: idx === 0 ? "5px" : 0,
            borderTopRightRadius: idx === 3 ? "5px" : 0,
            borderBottomRightRadius: idx === 3 ? "5px" : 0,
            // borderRight: idx === 3 ? 0 : "2px solid #f7f7f7",
            borderRight: idx === 3 ? 0 : "1px solid #d9d9d9",
          }}
        >
          <div onClick={() => setMenuTab(idx)}>
            <Row justify="space-between" align="middle">
              <img
                src={menuTab === idx ? item.imgAct : item.img}
                style={{ width: "80px" }}
                alt="upToYou"
              />
              <Col>
                <div
                  style={{
                    fontFamily: "semiBold",
                    textAlign: "right",
                    fontSize: "18px",
                  }}
                >
                  {item.text}
                </div>
                <div
                  style={{
                    fontFamily: "Bold",
                    fontSize: "40px",
                    textAlign: "right",
                  }}
                >
                  {item.count}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      ))}
    </Row>
  );

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  return (
    <div>
      <HeaderCard />
      <Row justify="space-between" align="middle">
        <div style={{ marginTop: "15px" }}>
          <FormDefault
            Field={field}
            Action={action}
            HandleOnFinish={HandleOnFinish}
          />
        </div>
      </Row>
      <Card
        className="setting-cover-card mt-3"
        cover={
          <>
            <h1 className="mb-0">
              <span
                style={{
                  fontFamily: "Bold",
                  color: "#faa41a",
                  fontSize: "23px",
                }}
              >
                CUSTOMER DATABASE
              </span>
              <span
                className="ml-2"
                style={{ fontFamily: "Bold", fontSize: "23px" }}
              >
                ฐานข้อมูลลูกค้า
              </span>
            </h1>
          </>
        }
      >
        <TableModifind
          cursor={true}
          rowKey={"index"}
          bordered={true}
          scroll={{ x: 2500, y: 500 }}
          config={{
            total: total,
            pageSize: limitPage,
            currentPage: currentPage,
          }}
          columns={columns}
          dataSource={dataSource.dataSource}
          onClickRow={HandleOnDetail}
          onChangePage={onChangePage}
        />
      </Card>
      <CustomerDetail
        onVisible={isModalVisible}
        onChange={OnChange}
        dataDetail={dataSource.dataDetail}
      />
    </div>
  );
};

export default CustomerDataBase;

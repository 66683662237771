/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "antd";
import { useState, useEffect } from "react";
import PageTitile from "../../../../../../components/PageTitle";
import { Row, Col, Divider, Transfer, Form } from "antd";

/////////////////////API////////////////////////
import {
  ADDUSERROLE_API,
  EDITUSERROLE_API,
  GETMENU_API,
  GETUSERROLEID_API,
} from "../../../../../../services/api/setting/userrole_api";
import { useHistory, useRouteMatch } from "react-router-dom";
import FInput from "../../../../../../components/form_floatlabel/FInput";
import { useSetRecoilState } from "recoil";
import { loadingTable } from "../../../../../../tools/recoil/recoil-atom";
import { AlertToast } from "../../../../../../components/alert/alert";

const ManageRole = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [menuList, setMenuList] = useState<any[]>([]);

  const [selectKeys, setSelectKey] = useState<any[]>([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const { params } = useRouteMatch();
  const { id }: any = params;
  const [form] = Form.useForm();
  const history = useHistory();

  const onSelectChange = (sourceSelectKeys: any, targetSelectKeys: any) => {
    setSelectKey([...sourceSelectKeys, ...targetSelectKeys]);
  };

  useEffect(() => {
    if (id !== undefined) {
      getRoleByID();
    }
    handleGetMenu();
    return undefined;

  }, []);

  const handleGetMenu = async () => {
    setLoading(true);
    try {
      const res = await GETMENU_API();
      const mapKeyTransfer = res.result[0].item.map((item: any) => {
        return { key: `${item.id}`, name: item.name };
      });
      if (res.code === 200) {
        setMenuList(mapKeyTransfer);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getRoleByID = async () => {
    setLoading(true);
    try {
      const res = await GETUSERROLEID_API(id);
      const menuSelected = res.result[0].menu;

      if (res.code === 200) {
        form.setFieldsValue({ roleName: res.result[0].name });
        const onlyMenuID = menuSelected.map((item: any) => {
          return `${item.id}`;
        });
        setTargetKeys(onlyMenuID);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onChange = (nextTargetKeys: any, direction: any, moveKeys: any) => {
    setTargetKeys(nextTargetKeys);
  };

  const onFinish = async (value: any) => {
    if (id === undefined) handleCreate(value);
    else handleUpdate(value);
  };
  const handleCreate = async (value: any) => {
    setLoading(true);

    try {
      const data: any = {
        name: value.roleName,
        menu: value.transferChosen,
      };
      const res = await ADDUSERROLE_API(data);
      if (res.code === 200) {
        AlertToast({
          icon: "success",
          title:`สร้างข้อมูลสำเร็จ!`,
        });
        history.push("/setting/usermanagement/member");
      } else {
        AlertToast({
          icon: "error",
          title:`สร้างข้อมูลไม่สำเร็จ! เนื่องจาก ${res.message}`,
        });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleUpdate = async (value: any) => {
    setLoading(true);
    try {
      const data = {
        name: value.roleName,
        menu: value.transferChosen,
      };
      const res = await EDITUSERROLE_API({ data, id });
      if (res.code === 200) {
        history.push("/setting/usermanagement/member");
        AlertToast({
          icon: "success",
          title:`อัปเดตข้อมูลสำเร็จ!`,
        });
      } else {
        AlertToast({
          icon: "error",
          title:`อัปเดตข้อมูลไม่สำเร็จ! เนื่องจาก ${res.message}`,
        });
      }
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div className="mt-4">
      <Form onFinish={onFinish} form={form}>
        <Card>
          <PageTitile
            titleEN={id ? "EDIT PERMISSION" : "ADD PERMISSION"}
            titleTH={id ? "แก้ไขบทบาท" : "สร้างบทบาท"}
          />
          <Divider style={{ border: "1px solid #E9E9E9" }} />
          <Row>
            <Col span={9} offset={1}>
              <FInput
                label="ชื่อบทบาท"
                name="roleName"
                rules={{ required: true }}
              />
            </Col>
            <Col md={22} offset={1}>
              <Form.Item
                name="transferChosen"
                valuePropName="check"
                className="mt-3"
              >
                <Transfer
                  selectAllLabels={[
                    ({ selectedCount }) => (
                      <span>
                        <div className="ml-3" style={{ color: "#000000" }}>
                          <b>หัวข้อบทบาท</b>
                        </div>
                        <div className="ml-3">{selectedCount} เลือก</div>
                      </span>
                    ),
                    ({ selectedCount }) => (
                      <span>
                        <div className="ml-3" style={{ color: "#000000" }}>
                          <b>เลือกหัวข้อบทบาท</b>
                        </div>
                        <div className="ml-3">{selectedCount} เลือก</div>
                      </span>
                    ),
                  ]}
                  dataSource={menuList}
                  selectedKeys={selectKeys}
                  targetKeys={targetKeys}
                  onSelectChange={onSelectChange}
                  onChange={onChange}
                  render={(item: any) => item.name}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};
export default ManageRole;

import { Radio, Row, Col } from "antd";
import { IOptionValue } from "./Select";

const TxRadioGroup = ({
  optionValue = { values: [], key: "", name: "" },
  ...props
}: {
  optionValue: IOptionValue;
}) => {
  const renderOption = (): any => {
    return optionValue.values.map((item: any, index) => {
      return (
        <Col key={index} span={'auto'}>
          <Radio value={item[optionValue.key]}>
            {item[optionValue.name]}
          </Radio>
        </Col>
      );
    });
  };

  return (
    <Radio.Group {...props}>
      <Row>{renderOption()}</Row>
    </Radio.Group>
  );
};

export default TxRadioGroup;

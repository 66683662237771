import moment from "moment";
export const toDateStandard = (dateTime: number) => {
  const dateStandard = moment(dateTime).format("DD/MM/YYYY");
  return dateStandard;
};
export const toDateTimeStandard = (dateTime: number) => {
  const dateStandard = dateTime
    ? moment(dateTime).format("DD/MM/YYYY | HH:MM")
    : "";
  return dateStandard;
};

import { Form, Input } from "antd";
import { FloatLabel } from "..";
type Props = {
  options?: any;
  label: string;
  name: string;
  rules?: { required: boolean; message?: string };
  rulesConfirm?: boolean;
};
const FInputPassword = ({
  options,
  label = "",
  name,
  rules = { required: false, message: "" },
  rulesConfirm = false,
}: Props) => {
  return (
    <Form.Item
      className="formlabel"
      name={name}
      rules={
        rulesConfirm
          ? [
              { message: `โปรดกรอกข้อมูล${label}!`, required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("รหัสผ่านไม่ตรง"));
                },
              }),
            ]
          : [
              {
                required: rules && rules.required,
                message:
                  rules && rules.message
                    ? rules.message
                    : `โปรดกรอกข้อมูล${label}!`,
              },
            ]
      }
    >
      <ChildrenRender {...options} label={label} />
    </Form.Item>
  );
};
const ChildrenRender = (propsChild: any) => (
  <>
    <FloatLabel
      label={propsChild.label ? propsChild.label : ""}
      name={propsChild.name}
      value={propsChild.value}
    >
      <Input.Password {...propsChild} />
    </FloatLabel>
  </>
);

export default FInputPassword;

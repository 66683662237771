/* eslint-disable react-hooks/exhaustive-deps */

import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { _isEmpty } from "@yim/util-tool-ts";
import { Card, Col, Form, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { FormDefault } from "../../../../../components";
import { CAlertToast } from "../../../../../components/alert/CAlert";
import { tableLoading } from "../../../../../components/loading/loading";
import TableModifind from "../../../../../components/table/Table";
import { ISettingList } from "../../../../../interface/ISetting.interface";
import {
  ADDREQUIREMENTTYPE_API,
  DELETEREQUIREMENTTYPE_API,
  EDITREQUIREMENTTYPE_API,
  GETREQUIREMENTTYPEALL_API,
  GETREQUIREMENTTYPEID_API,
  GETREQUIREMENTTYPE_API,
} from "../../../../../services/api/setting/requirement_type_api";
import { loadingTable } from "../../../../../tools/recoil/recoil-atom";
import { HandleScroll } from "../../../../../tools/util";
import HeaderRequirement from "./HeaderRequirement";

const RequirementType = () => {
  const setLoading = useSetRecoilState(loadingTable);
  const [isActioned, setIsActioned] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(10);
  const [initialValueSearch, setInitialValueSearch] = useState({
    search: "",
  });
  const [requirementTypeList, setRequirementTypeList] = useState<
    ISettingList[]
  >([]);

  const [requirementTypeSource, setRequirementTypeSource] = useState<
    ISettingList[]
  >([]);
  const [form] = Form.useForm();
  useEffect(() => {
    handleGetData();
    return undefined;

  }, [currentPage, limitPage, initialValueSearch]);

  const handleGetData = () => {
    handleGetRequirementType();
    handleGetRequirementTypes();
  };

  const handleGetRequidByID = async (id: number) => {
    setLoading(true);

    try {
      const res = await GETREQUIREMENTTYPEID_API(id);
      if (res.code === 200) {
        HandleScroll("setting-header");
        setId(id);
        setIsActioned(true);
        const data = res.result[0];
        form.setFieldsValue({
          name: data.name,
          requirementType: data.requirementType,
          // actionStep: data.actionStep,
        });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleClickAction = () => {
    setIsActioned(!isActioned);
    setId(0);
    form.resetFields();
  };

  const onFinish = (values: any) => {
    if (id !== 0) handleUpdate(values);
    else handleCreate(values);
  };

  const handleGetRequirementTypes = async () => {
    setLoading(true);
    try {
      const res = await GETREQUIREMENTTYPEALL_API();
      if (res.code === 200) {
        const data = res.result[0].item;
        setRequirementTypeSource(data);
      }
    } catch (error) {}
    setLoading(false);
  };
  const handleCreate = async (data: any) => {
    setLoading(true);
    try {
      const res = await ADDREQUIREMENTTYPE_API(data);
      if (res.code === 200) {
        handleClickAction();
        form.resetFields();
        CAlertToast({ method: "POST_SUCCESS", text: res.message });
        handleGetRequirementType();
        setId(0);
      } else {
        CAlertToast({ method: "POST_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleUpdate = async (data: any) => {
    setLoading(true);
    try {
      const res = await EDITREQUIREMENTTYPE_API({ id, data: data });
      if (res.code === 200) {
        form.resetFields();
        handleClickAction();
        CAlertToast({ method: "PUT_SUCCESS", text: res.message });
        handleGetRequirementType();
        setId(0);
      } else {
        CAlertToast({ method: "PUT_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      const res = await DELETEREQUIREMENTTYPE_API(id);
      if (res.code === 200) {
        CAlertToast({ method: "DEL_SUCCESS", text: res.message });
        setRequirementTypeList(
          requirementTypeList.filter((item) => item.id !== id)
        );
      } else {
        CAlertToast({ method: "DEL_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleGetRequirementType = async () => {
    setLoading(true);
    try {
      const res = await GETREQUIREMENTTYPE_API({
        page: currentPage,
        limit: limitPage,
        ...initialValueSearch,
      });
      if (res.code === 200) {
        setRequirementTypeList(res.result[0].item);
        const dataSet = res.result[0];
        setTotalPage(dataSet.total);
        setLimitPage(dataSet.perpage);
      }
    } catch (error) {}

    setLoading(false);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };
  const handleSearch = (values: any) => {
    setCurrentPage(1);
    setInitialValueSearch({ ...values });
  };

  const fieldFilter = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา ชื่อประเภทความต้องการ",
      initialValue: initialValueSearch.search,
    },
  ];

  const columns: any = [
    {
      title: "ลำดับ",
      align: "center",
      width: "10%",
      render: (_value: any, _item: any, index: number) => {
        return <>{(currentPage - 1) * limitPage + (index + 1)}</>;
      },
    },
    {
      title: "ชื่อประเภทความต้องการ",
      dataIndex: "name",
      key: "name",
      width: "35%",
    },
    {
      title: "ประเภทความต้องการ",
      dataIndex: "requirementType",
      key: "requirementType",
      width: "35%",
      render: (value: string) => {
        return (
          <>
            {!_isEmpty(value) ? (
              value
            ) : (
              <span>ไม่มี</span>
            )}
          </>
        );
      },
    },
    {
      title: "จัดการ",
      align: "center",
      render: (value: ISettingList,row:any) => {
        return (
          <div className="action-table">
            <EditOutlined
              onClick={() => handleGetRequidByID(value.id)}
              className="faq-btn-edit"
            />
            <Popconfirm
              className="pop-faq"
              placement="top"
              title={"คุณเเน่ใจหรือไม่"}
              onConfirm={() => handleDelete(value.id)}
              okType="danger"
              okText="ลบ"
              cancelText="ยกเลิก"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              okButtonProps={{
                style: {
                  width: 50,
                },
              }}
            >
              <DeleteOutlined className="ml-3 faq-btn" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div id="setting-header">
      <HeaderRequirement
        isActioned={isActioned}
        handleClickAction={handleClickAction}
        actionName={id !== 0 ? "EDIT" : "ADD"}
        title={{ nameEn: "REQUIREMENT TYPE", nameTh: "ประเภทความต้องการ" }}
        handleSubmit={onFinish}
        form={form}
        requirementTypeSource={requirementTypeSource}
      />
      <div className="form-tab-bg">
        <FormDefault
          HandleOnFinish={handleSearch}
          Field={fieldFilter}
          Action={[]}
        />
      </div>
      <Row>
        <Col lg={24} md={24} className="mt-3">
          <Card
            className="setting-cover-card"
            cover={
              <>
                <h1 className="mb-0">
                  <span
                    style={{
                      fontFamily: "Bold",
                      color: "#faa41a",
                      fontSize: "23px",
                    }}
                  >
                    REQUIREMENT TYPE
                  </span>
                  <span
                    className="ml-2"
                    style={{ fontFamily: "Bold", fontSize: "23px" }}
                  >
                    ประเภทความต้องการ
                  </span>
                </h1>
              </>
            }
          >
            <TableModifind
              cursor={true}
              rowKey="id"
              loading={tableLoading()}
              bordered={false}
              config={{ currentPage, total: totalPage, pageSize: limitPage }}
              columns={columns}
              dataSource={requirementTypeList}
              onChangePage={onChangePage}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RequirementType;

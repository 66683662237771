import { Fetch, FetchExcel } from "../../../tools/fecth/fecth";
import { apiVersion } from '../../../config/api'
import { createQueryString } from "../../../tools/util";

export const GETNEWINQURYREPORT_API = async (query: any) => {
    const qrs = createQueryString(query);
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/report/new-inquiry${qrs}` });
    return res;
}

export const NEWINQURY_EXCEL_API = async (query: any) => {
    const qrs = createQueryString(query);
    const res = await FetchExcel({
        method: "GET",
        path: `${apiVersion}/report/new-inquiry/excel${qrs}`,
    });
    return res;
};

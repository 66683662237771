import { Fetch } from "../../../tools/fecth/fecth";
import { apiVersion } from '../../../config/api'
import { createQueryString } from "../../../tools/util";

export const GETPROJECTTRANSFER = async (data: {}) => {
    const qrs = createQueryString(data);
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/inquiry${qrs}` });
    return res;
}

export const EDITPROJECTTRANSFER = async (data: any) => {
    const res = await Fetch({ method: 'PUT', path: `${apiVersion}/inquiry/transfer`, data });
    return res;
}

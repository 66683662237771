/* eslint-disable react-hooks/rules-of-hooks */
import { Col, Spin } from "antd";
import HashLoader from "react-spinners/HashLoader";
import { useRecoilState } from "recoil";
import { loadingTable } from "../../tools/recoil/recoil-atom";

const antIcon = (
  <div style={{ marginTop: 10 }}>
    <HashLoader color="#FCD901" size={30} />
  </div>
);
const indicatorContent = (
  <Col span={24}>
    <div style={{ position: "fixed", top: 460, left: 0, right: 0 }}>
      <HashLoader color="#FCD901" size="50px" />
      <div
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 510,
          color: "#FCD901",
        }}
      >
        ระบบกำลังดำเนินการโปรดรอสักครู่...
      </div>
    </div>
  </Col>
);

export const tableLoading = () => {
  const [loading] = useRecoilState(loadingTable);
  const itemLoading = {
    spinning: loading,
    indicator: antIcon,
    tip: "ระบบกำลังดำเนินการโปรดรอสักครู่...",
  };
  return itemLoading;
};

export const LoadSomeContent = (props: { children: any; loading: boolean }) => {
  const { children, loading } = props;

  return (
    <div className="d-flex x-center y-center">
      <Col span={24}>
        <Spin spinning={loading} indicator={indicatorContent}>
         {children}
        </Spin>
      </Col>
    </div>
  );
};

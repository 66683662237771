import { Row } from "antd";

const NotFound = () => {
  const Text = () => {
    return (
      <div className="content404">
        <div className="t404-text">404</div>
        <div className="t404-text">404</div>
      </div>
    );
  };
  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: "100%", marginTop: "26vh" }}
    >
      <div>
        <Text />
        <Row className="Iam" align="middle">
          <p>This is</p>
          <b>
            <div className="innerIam">
              Error
              <br />
              Page
              <br />
              Not
              <br />
              Found
            </div>
          </b>
        </Row>
      </div>
    </Row>
  );
};

export default NotFound;

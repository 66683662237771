/* eslint-disable react-hooks/exhaustive-deps */

import { Form, Table } from "antd";
import { useEffect, useState } from "react";
import {  useSetRecoilState } from "recoil";
import { AlertToast } from "../../../components/alert/alert";
import { tableLoading } from "../../../components/loading/loading";
import {
  FORECAST_EXCEL_API,
  GETFORECASTREPORT_API,
} from "../../../services/api/report/forecast";
import { IResStatus } from "../../../tools/fecth/interface";
import { loadingTable } from "../../../tools/recoil/recoil-atom";
import Header from "./Header/Header";

const SubReportForecast = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [searchYears, setSearchYears] = useState(new Date().getFullYear().toString());
  const [isFirstWork, setisFirstWork] = useState<boolean>(true);
  const [forecasts, setForecast] = useState<any[]>([]);

  const [form] = Form.useForm();
  const currentYear = new Date();

  useEffect(() => {
    if (isFirstWork) {
      form.setFieldsValue({ years: `${currentYear.getFullYear()}` });
      handleGetReportForecast();
      setisFirstWork(false);
    } else {
      handleGetReportForecast();
    }
    return undefined;

  }, [searchYears]);

  const handleGetReportForecast = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await GETFORECASTREPORT_API({
        year: searchYears,
      });
      if (res.code === 200) {
        const itemList = res.result[0].item;
        const totalList = res.result[0].total;
        let totalObj = {};
        totalList.forEach((item: any, idTotal: number) => {
          totalObj = {
            key: `total ${idTotal + 1}`,
            name: "TOTAL INQUIRY (ยอดรวมน้ำหนักในการขายสินค้า หน่วย : kg)",
            avr: res.result[0].average.toFixed(2),
            ...totalObj,
            [item.label]: item.sum || 0,
          };
        });

        const contactTypeDataFormat = itemList.map(
          (item: any, idMain: number) => {
            let mainObj = {};

            item.header.forEach((header: any) => {
              mainObj = {
                key: `main ${idMain + 1}`,
                name: `${idMain + 1}. ${item.firstName} ${item.lastName}`,
                avr: item.average.toFixed(2),
                ...mainObj,
                [header.label]: header.sum || 0,
              };
            });
            let childrenList: any[] = [];

            item.subHeader.forEach((subHeader: any, idChild: number) => {
              let children = {};
              if (subHeader.data) {
                subHeader.data.forEach((child: any) => {
                  child.subData.forEach((childData: any) => {
                    children = {
                      name: `${child.firstName} ${child.lastName}`,
                      key: `child ${idChild + 1}`,
                      ...children,
                      [childData.label]: 1,
                      avr: child.average.toFixed(2),
                    };
                  });
                });
              }
              if (subHeader.data) {
                childrenList = [...childrenList, children];
              }
            });
            if (childrenList.length > 0)
              mainObj = { ...mainObj, children: [...childrenList] };
            return mainObj;
          }
        );
        const mergeList = [...contactTypeDataFormat, totalObj];
        setForecast(mergeList);
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleExportExcel = async () => {
    setLoading(true);
    try {
      const res = await FORECAST_EXCEL_API({
        year: searchYears,
      });
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `forecast.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถส่งออกข้อมูลได้`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const filterCoulumnToCurrentMount = (columns: any) => {
    const dateTime = new Date();
    const currentMount = dateTime.getMonth();
    const toCurrentMount = Number(searchYears) === dateTime.getFullYear()
    ? columns.filter(
      (item: any) =>
        item.key === "name" ||
        item.key === "avr" ||
        Number(item.key) <= currentMount + 1
    ) : columns;
    return toCurrentMount;
  };

  const columns = [
    {
      title: "ช่องทางการติดต่อ",
      dataIndex: "name",
      key: "name",
      width: 400,
      fixed: true,
    },

    {
      title: "ม.ค.",
      dataIndex: "1",
      key: "1",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "ก.พ.",
      dataIndex: "2",
      key: "2",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "มี.ค.",
      dataIndex: "3",
      key: "3",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "เม.ย.",
      dataIndex: "4",
      key: "4",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "พ.ค.",
      dataIndex: "5",
      key: "5",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "มิ.ย.",
      dataIndex: "6",
      key: "6",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "ก.ค.",
      dataIndex: "7",
      key: "7",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "ส.ค.",
      dataIndex: "8",
      key: "8",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "ก.ย.",
      dataIndex: "9",
      key: "9",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "ต.ค.",
      dataIndex: "10",
      key: "10",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "พ.ย.",
      dataIndex: "11",
      key: "11",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "ธ.ค.",
      dataIndex: "12",
      key: "12",
      width: 100,
      align: 'center' as 'center'
    },
    {
      title: "ค่าเฉลี่ย",
      dataIndex: "avr",
      key: "avr",
      align: 'center' as 'center',
      width: 100,
    },
  ];
  const handleFilter = (value: string) => {
    setSearchYears(value);
  };
  return (
    <div>
      <Header
        form={form}
        handleExportExcel={handleExportExcel}
        handleFilter={handleFilter}
        data={{
          item: {
            key: 1,
            text: "รายงานสรุปยอด Forecast เป้าการขายในโปรเจคที่ได้รับมอบหมาย แยกตามรายชื่อ AE และ รายชื่อแต่ละโปรเจค",
          },
        }}
      >
        <Table
          rowKey={(row) => row.key}
          style={{ marginTop: "20px" }}
          scroll={{y: 500 }}
          dataSource={forecasts}
          columns={filterCoulumnToCurrentMount(columns)}
          bordered
          expandRowByClick
          loading={tableLoading()}
          pagination={false}
        />
      </Header>
    </div>
  );
};

export default SubReportForecast;

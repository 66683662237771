/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Card } from "antd";
import Doughnut from "./chart/Doughnut";
import PolarArea from "./chart/PolarArea";
import Line from "./chart/Line";
import CardStatus from "./Card";
import Card1 from "../../assets/img/dashboard/card1.svg";
import Card2 from "../../assets/img/dashboard/card2.svg";
import Card3 from "../../assets/img/dashboard/card3.svg";
import Card4 from "../../assets/img/dashboard/card4.svg";
import Spac from "../../assets/img/dashboard/spac.svg";
import FormField from "./FormField";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { loadingChildState } from "../../tools/recoil/recoil-atom";
import { AlertToast } from "../../components/alert/alert";
import { GEN_DASHBOARD_INFO } from "../../services/api/Dashboard";
import { LoadSomeContent } from "../../components/loading/loading";

const Dashboard = () => {
  const [loading, setLoading] = useRecoilState(loadingChildState);
  const [dashBoardInfo, setDashBoardInfo] = useState({
    interestedProduct: [{ thName: "", enName: "", count: "0" }],
    multiChart: {
      contactChannel: [],
      subjectContact: [],
      userContactType: [],
    },
    requirementType: [{ name: "", count: "0" }],
    specAlumet: 0,
    status: {
      all: "0",
      closeTheSale: "0",
      doNotBy: "0",
      onPlan: "0",
    },
  } as any);

  useEffect(() => {
    getData({
      date: new Date().valueOf(),
      type: "All",
    });
    return undefined;

  }, []);

  const getData = async (datInfo?: any) => {
    setLoading(true);
    try {
      const res = await GEN_DASHBOARD_INFO(datInfo);
      if (res.code === 200) {
        const result = res.result[0];
        setDashBoardInfo(result);
      } else {
        AlertToast({
          icon: "error",
          title: `เกิดข้อผิดพลาด! ระบบไม่สามารถเเสดงข้อมูลได้ กรุณาลองอีกครั้งในภายหลัง`,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const HandleOnFinish = (value: any) => {
    const data = {
      subjectRecipient: value?.subjectRecipient || "",
      date: value?.date?.valueOf() || new Date().valueOf(),
      type: value?.type || "All",
    };
    getData(data);
  };

  return (
    <LoadSomeContent loading={loading}>
      <Row className="h-100 min-vh-100">
        <Col lg={24} className="mt-3 mb-2">
          <Row gutter={10}>
            <Col lg={6} xs={24}>
              <Row gutter={10}>
                <Col>
                  <h1
                    style={{
                      fontFamily: "Bold",
                      color: "#faa41a",
                      fontSize: "30px",
                    }}
                  >
                    DASBOARD
                  </h1>
                </Col>
                <Col>
                  <h1 style={{ fontFamily: "Bold", fontSize: "30px" }}>
                    แผงบริหาร
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col lg={18} xs={24}>
              <div className="d-flex x-end">
                <FormField HandleOnFinish={HandleOnFinish} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={24} xs={24}>
          <Row gutter={18}>
            <CardStatus
              btColor={"card-bt-orange"}
              title={"สถานะทั้งหมด"}
              quantity={Number(dashBoardInfo?.status?.all)}
              image={Card1}
            />
            <CardStatus
              btColor={"card-bt-blue"}
              title={"กำลังดำเนินการ"}
              quantity={Number(dashBoardInfo?.status?.onPlan)}
              image={Card2}
            />
            <CardStatus
              btColor={"card-bt-yellow"}
              title={"ปิดการขาย"}
              quantity={Number(dashBoardInfo?.status?.closeTheSale)}
              image={Card3}
            />
            <CardStatus
              btColor={"card-bt-red"}
              title={"ไม่ซื้อ"}
              quantity={Number(dashBoardInfo?.status?.doNotBy)}
              image={Card4}
            />
            {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}

              {/* requirementType */}
            <Col lg={12} xs={24} className="mb-3">
              <Card className="card-dashboard pb-4">
                <Doughnut list={dashBoardInfo?.requirementType}/>
              </Card>
            </Col>
              {/* interestedProduct */}
            <Col lg={12} xs={24} className="mb-3">
              <Card className="card-dashboard pb-4">
                <PolarArea list={dashBoardInfo?.interestedProduct}/>
              </Card>
            </Col>
              {/* multiChart  and it have Sub List 3 List*/}
            <Col lg={16} xs={24} className="d-flex direction-col x-around mb-3">
              <Card className="card-dashboard h-100">
                <Line listObject={dashBoardInfo?.multiChart}/>
              </Card>
            </Col>
            {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
            <Col lg={8} xs={24} className="d-flex direction-col x-around mb-3">
              <Card className="card-bottom h-100">
                <Row>
                  <Col lg={24} xs={24}>
                    <h3>สเป็คสินค้าอลูเม็ท</h3>
                    <div className="border-bottom-5rem" />
                    <div className="text-center mt-5">
                      <h1
                        className="mb-0"
                        style={{ fontFamily: "Bold", fontSize: "50px" }}
                      >
                        {Number(dashBoardInfo?.specAlumet)}
                      </h1>

                      <h4 className="mb-0"> สินค้าทั้งหมด </h4>
                    </div>
                  </Col>
                  <Col lg={24} xs={24}>
                    <img src={Spac} width="100%" alt="สถานะทั้งหมด" />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </LoadSomeContent>
  );
};

export default Dashboard;

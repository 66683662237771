/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import { FormDefault } from "../../../components/index";
import CardFilter from "../../../components/CardFilter";
import CloseSale from "../../../assets/img/icon-direc-customer/CloseSale.svg";
import DontBuy from "../../../assets/img/icon-direc-customer/DontBuy.svg";
import OnPlan from "../../../assets/img/icon-direc-customer/OnPlan.svg";
import money from "../../../assets/img/icon-direc-customer/money.svg";
import condo from "../../../assets/img/icon-direc-customer/condo.svg";
import condoWhite from "../../../assets/img/icon-direc-customer/icon_hover/condowhite.svg";
import moneywhite from "../../../assets/img/icon-direc-customer/icon_hover/moneywhite.svg";
import { GET_PROJECT_FILTER_API } from "../../../services/api/project_api";
import { CUSTOMER_GET_DATA_ADMIN_FILTER_API } from "../../../services/api/customer_api";
import {
  DIRECT_EXCEL_API,
  GET_DIRECT_API,
} from "../../../services/api/DirectCustomer_api";
import { AlertToast } from "../../../components/alert/alert";
import TableModifind from "../../../components/table/Table";
import { tableLoading } from "../../../components/loading/loading";
import { useSetRecoilState } from "recoil";
import { loadingTable } from "../../../tools/recoil/recoil-atom";
import { ConvertDateNum, _numToDate } from "../../../tools/util";
import { useHistory } from "react-router-dom";
import { _isEmpty } from "@yim/util-tool-ts";
import CloseBtnTab from "../../../components/btn/CloseBtnTab";
import StatusColumn from "../../../components/column/StatusColumn";

interface IDirectCustomerList {
  adminName: string;
  companyName: string;
  date: string;
  firstName: string;
  id: number;
  lastName: string;
  projectName: string;
  status: string;
  subjectContact: [string, string];
  userContactType: string;
}

export const RefactDataBeforeGet = (data: any) => {
  let result = {};
  for (const [key, value] of Object.entries(data)) {
    if (value === null || value === 0 || _isEmpty(value)) {
      result = { ...result, [key]: "" };
    } else {
      result = {
        ...result,
        [key]:
          key === "admin"
            ? data.admin[1]
            : key === "endDate"
            ? ConvertDateNum(data.endDate)
            : key === "startDate"
            ? ConvertDateNum(data.startDate)
            : key === "typeTab"
            ? value === "all"
              ? ""
              : value
            : value,
      };
    }
  }
  return result;
};

export const HandleCheckName = (list?: any) => {
  return list?.firstName !== "ไม่มี"
    ? `${list?.firstName} ${list?.lastName}`
    : list?.nickname !== "ไม่มี"
    ? `${list?.nickname}`
    : `${list?.socialName}`;
};

const DirectCustomer = () => {
  let history = useHistory();
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [dataCardfilter, setDataCardfilter] = useState({
    default: [],
    cardSpecial: {},
  } as any);
  const [dataSource, setDataSource] = useState<IDirectCustomerList[]>([]);
  const [initialValue, setInitialValue] = useState({
    date: [],
    admin: [],
    status: null,
    searchName: "",
    statusTab: 0,
    typeTab: 0,
  });
  const [listAdmin, setListAdmin] = useState([] as any);
  const [limitPage, setLimitPage] = useState(10);
  const [total, setTotal] = useState<number>();
  const [searchInfo, setSearchInfo] = useState({
    page: 1,
    search: "",
    startDate: "",
    endDate: "",
    admin: null,
    statusTab: 0,
    typeTab: 0,
  } as any);
  const [closeBtn, setCloseBtn] = useState(false);

  useEffect(() => {
    getData();
    return undefined;
  }, [searchInfo, limitPage]);

  useEffect(() => {
    HandleGetDataAdminList();
    HandleGetFilterCard();
    return undefined;
  }, []);

  const getData = async () => {
    setLoading(true);
    const dataInfo: any = RefactDataBeforeGet(searchInfo);
    const result = {
      admin: dataInfo?.admin,
      search: dataInfo?.search,
      startDate: dataInfo?.startDate,
      endDate: dataInfo?.endDate,
      page: dataInfo?.page,
      limit: limitPage,
      status: dataInfo?.statusTab,
      contactType: dataInfo?.typeTab,
    };
    try {
      const res = await GET_DIRECT_API(result);
      if (res.code === 200) {
        setTotal(res.result[0]?.total);
        const refacter = HandleRefacter(res.result[0].item);
        setDataSource(refacter);
      } else {
        AlertToast({
          icon: "error",
          title:
            res.code === 404
              ? `ไม่พบข้อมูล กรุณาลองใหม่อีกครั้ง`
              : `ผิดพลาด! ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด (${res.message})`,
        });
        setDataSource([]);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleCheckStatus = (status: number) => {
    return status === 0
      ? ""
      : status === 1
      ? "CLOSE_THE_SALE"
      : status === 2
      ? "DO_NOT_BUY"
      : "ON_PLAN";
  };

  const HandleRefacter = (arr: IDirectCustomerList[] = []) => {
    const result = arr.map((item: any, _idx: number) => {
      const dataRefacter = {
        ...item,
        customerName: HandleCheckName(item),
        date: _numToDate(Number(item?.date)),
      };
      return dataRefacter;
    });
    return result;
  };

  const HandleGetFilterCard = async () => {
    try {
      const info = {
        filterFor: "LEAD",
      };
      const res = await GET_PROJECT_FILTER_API(info);
      if (res.code === 200) {
        const dataList = res.result;
        setDataCardfilter({
          default: [
            ...dataList
              ?.filter((fil: any) => fil?.contactTypeId)
              ?.map((item: any) => {
                return {
                  key: item?.contactTypeId,
                  img: condo,
                  img_hover: condoWhite,
                  nameEn: item?.contactTypeName?.split("(")[0],
                  nameTh: `(${item?.contactTypeName?.split("(")[1]}`,
                  amount: item?.total,
                };
              }),
            {
              key: "all",
              img: money,
              img_hover: moneywhite,
              nameEn: "Total",
              nameTh: "(รวมยอด)",
              amount: dataList[dataList.length - 1]?.total,
            },
          ],
          cardSpecial: dataList[dataList.length - 1],
        });
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด (${res.message})`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleGetDataAdminList = async () => {
    setLoading(true);
    try {
      const res = await CUSTOMER_GET_DATA_ADMIN_FILTER_API();
      if (res.code === 200) {
        const result = RefacterOptionValueCascader(res.result);
        setListAdmin(result);
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด (${res.message})`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const RefacterOptionValueCascader = (arrValue: []) => {
    const result = arrValue?.map((item: any, index: number) => {
      return {
        value: item.abbreviation,
        label: item.abbreviation,
        children: item?.admin?.map((child: any, idx: number) => {
          return {
            value: child.id,
            label: child.name,
          };
        }),
      };
    });
    return result;
  };

  const columns: any = [
    {
      title: "สถานะ",
      width: 100,
      dataIndex: "status",
      key: "status",
      render: (value: any, data: any) => {
        return <StatusColumn status={value} />;
      },
    },
    {
      title: "วันที่ติดต่อ",
      dataIndex: "date",
      key: "date",
      width: 80,
      render: (value: any, _row: any) => {
        return (
          <Row justify="center" align="middle">
            {value}
          </Row>
        );
      },
    },
    {
      title: "ผู้รับผิดชอบ",
      dataIndex: "adminName",
      key: "adminName",
      width: 100,
    },
    {
      title: "ประเภทผู้ติดต่อ",
      dataIndex: "userContactType",
      key: "userContactType",
      width: 150,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customerName",
      key: "customerName",
      width: 100,
      render: (value: any, _row: any) => {
        return <Row align="middle">{value}</Row>;
      },
    },
    {
      title: "ชื่อบริษัท",
      dataIndex: "companyName",
      key: "companyName",
      width: 120,
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "projectName",
      key: "projectName",
      width: 100,
    },
    {
      title: "ความต้องการ",
      dataIndex: "subjectContact",
      key: "subjectContact",
      width: 270,
      render: (value: {}[]) => {
        return value?.map((item: any, idx: number) => (
          <div
            key={idx}
            style={{ paddingBottom: idx === value.length - 1 ? "" : "5px" }}
          >
            {item}
          </div>
        ));
      },
    },
  ];

  const listStatus = [
    {
      value: "ON_PLAN",
      label: "สถานะ: ดำเนินการ",
    },
    {
      value: "DO_NOT_BUY",
      label: "สถานะ: ไม่ซื้อ",
    },
    {
      value: "CLOSE_THE_SALE",
      label: "สถานะ: ปิดการขาย",
    },
  ];

  const field = [
    {
      type: "rangepicker",
      name: "date",
      initialValue: initialValue.date,
    },
    {
      type: "selectCuscader",
      name: "admin",
      placeholder: "กรุณาเลือกเเอดมิน",
      options: listAdmin,
      initialValue: initialValue.admin,
    },
    {
      type: "select",
      name: "status",
      options: listStatus,
      keyName: "label",
      keyValue: "value",
      placeholder: "เลือกสถานะ",
      initialValue: initialValue.status,
    },
    {
      type: "input",
      name: "searchName",
      placeholder: "ค้นหาชื่อลูกค้า",
      initialValue: initialValue.searchName,
    },
  ];

  const HandleExportExcel = async () => {
    setLoading(true);
    const dataInfo: any = RefactDataBeforeGet(searchInfo);
    const result = {
      admin: dataInfo?.admin,
      search: dataInfo?.search,
      startDate: dataInfo?.startDate,
      endDate: dataInfo?.endDate,
      status: dataInfo?.statusTab,
      contactType: dataInfo?.typeTab,
    };
    try {
      const res = await DIRECT_EXCEL_API(result);
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `project.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถส่งออกข้อมูลได้`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const action = [
    {
      type: "btnExport",
      onClick: HandleExportExcel,
    },
  ];

  const dataFilter = [
    {
      key: 1,
      type: "type",
      data: [...dataCardfilter?.default],
    },
    {
      key: 2,
      type: "status",
      data: [
        {
          key: 1,
          img: CloseSale,
          nameEn: "Close the Sale",
          nameTh: "(ปิดการขาย)",
          amount: dataCardfilter?.cardSpecial?.closeTheSale,
        },
        {
          key: 2,
          img: DontBuy,
          nameEn: "Don't buy",
          nameTh: "(ไม่ซื้อ)",
          amount: dataCardfilter?.cardSpecial?.doNotBuy,
        },
        {
          key: 3,
          img: OnPlan,
          nameEn: "on Plan",
          nameTh: "(ดำเนินการ)",
          amount: dataCardfilter?.cardSpecial?.onPlan,
        },
      ],
    },
  ];

  const HandleOnFinish = (value: any) => {
    setInitialValue({
      ...initialValue,
      statusTab: 0,
      typeTab: 0,
      date: value.date,
      admin: value?.admin,
      status: value.status,
      searchName: value.searchName,
    });

    const dataInfo = {
      ...searchInfo,
      page: 1,
      search: value?.searchName,
      startDate: value?.date ? value?.date[0] : "",
      endDate: value?.date ? value?.date[1] : "",
      admin: value?.admin,
      statusTab: value?.status,
      typeTab: 0,
    };
    setSearchInfo(dataInfo);
  };

  const handleClickFilterType = (value: number) => {
    setSearchInfo({
      search: "",
      startDate: "",
      endDate: "",
      admin: null,
      page: 1,
      statusTab: 0,
      typeTab: value === initialValue.typeTab ? 0 : value,
    });
    setInitialValue({
      date: [],
      admin: [],
      status: null,
      searchName: "",
      typeTab: value === initialValue.typeTab ? 0 : value,
      statusTab: 0,
    });
  };

  const handleClickFilterStatus = (value: number) => {
    setSearchInfo({
      search: "",
      startDate: "",
      endDate: "",
      admin: null,
      page: 1,
      statusTab:
        value === initialValue.statusTab ? 0 : handleCheckStatus(value),
      typeTab: 0,
    });
    setInitialValue({
      date: [],
      admin: [],
      status: null,
      searchName: "",
      typeTab: 0,
      statusTab: value === initialValue.statusTab ? 0 : value,
    });
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setSearchInfo({ ...searchInfo, page: page });
  };

  const HandleOnDetail = (id: number) => {
    history.push({
      pathname: `/directcustomer/detail/${id}`,
    });
  };

  return (
    <Row className="h-100 min-vh-100">
      <Col lg={24}>
        {closeBtn && (
          <>
            <CardFilter
              dataset={dataFilter}
              handle={handleClickFilterType}
              handle2={handleClickFilterStatus}
              typeTab={initialValue.typeTab}
              statusTab={initialValue.statusTab}
            />
            <Row className="mt-3">
              <Col lg={24} md={24}>
                <FormDefault
                  Field={field}
                  Action={action}
                  HandleOnFinish={HandleOnFinish}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className={!closeBtn ? "mt-3" : ""}>
          <CloseBtnTab on={closeBtn} onClick={() => setCloseBtn(!closeBtn)} />
        </Row>
        <Row>
          <Col lg={24} md={24} className="mt-3">
            <Card
              className="setting-cover-card"
              cover={
                <>
                  <h1 className="mb-0">
                    <span
                      style={{
                        fontFamily: "Bold",
                        color: "#faa41a",
                        fontSize: "23px",
                      }}
                    >
                      DIRECT CUSTOMER
                    </span>
                    <span
                      className="ml-2"
                      style={{ fontFamily: "Bold", fontSize: "23px" }}
                    >
                      ลูกค้าซื้อตรง
                    </span>
                  </h1>
                </>
              }
            >
              <TableModifind
                cursor={true}
                rowKey="id"
                loading={tableLoading()}
                bordered={true}
                config={{
                  total: total,
                  pageSize: limitPage,
                  currentPage: searchInfo.page,
                }}
                onClickRow={(row) => HandleOnDetail(row?.id || null)}
                columns={columns}
                dataSource={dataSource}
                onChangePage={onChangePage}
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DirectCustomer;

import { Fetch } from "../../../tools/fecth/fecth";
import { apiVersion } from '../../../config/api'
import { createQueryString } from "../../../tools/util";

export const GETSUBJECTCONTACT_API = async (data: {}) => {
    const qrs = createQueryString(data);
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/subject-contact${qrs}` });
    return res;
}

export const ADDSUBJECTCONTACT_API = async (data: any) => {
    const res = await Fetch({ method: 'POST', path: `${apiVersion}/subject-contact`, data });
    return res;
}

export const EDITSUBJECTCONTACT_API = async ({ data, id }: { data: any, id: number }) => {
    const res = await Fetch({ method: 'PUT', path: `${apiVersion}/subject-contact/${id}`, data });
    return res;
}

export const DELETESUBJECTCONTACT_API = async (id: number) => {
    const res = await Fetch({ method: 'DELETE', path: `${apiVersion}/subject-contact/${id}` });
    return res;
}
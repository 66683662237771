/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import HeaderTab from "./header_tab/HeaderTab";
import {
  Card,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Radio,
  Select,
  Checkbox,
  Form,
  Spin,
} from "antd";
import {
  visibleFlagState,
  fieldValueState,
  checkedFlagState,
  useFlagState,
  descFlagState,
} from "./store/_store";
import { useRecoilState, RecoilRoot, useSetRecoilState } from "recoil";
import { FloatLabel } from "../../../../components/index";
import { oldSearch, DateNew } from "../../../../assets/img/index";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import CreateForm, { IFormItem, EInputType } from "./FormGenerate";
import {
  CUSTOMER_SERVICE_CREATE_API,
  GEN_INQUERYNO_OF_FORM_API,
  GET_CUSTOMER_SERVICE_EDIT_API,
  GET_SEARCH_ERP_USER_API_BY_ID,
  GET_CONTACT_CHANEL_API,
  CUSTOMER_SERVICE_EDIT_API,
} from "../../../../services/api/customer_api";
import moment from "moment";
import { _isEmpty } from "@yim/util-tool-ts";
import { useHistory, useRouteMatch } from "react-router-dom";
import { mixList } from "./listData";
import { loadingChildState } from "../../../../tools/recoil/recoil-atom";
import {
  IcontactChanelList,
  IformInput,
} from "../../../../interface/ICustomerService.interface";
import { AlertToast } from "../../../../components/alert/alert";
import { filterUndefine } from "../../../../tools/util";
import { LoadSomeContent } from "../../../../components/loading/loading";
import { HashLoader } from "react-spinners";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

const AddListState = () => {
  let history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [form] = Form.useForm();
  const setDescFlag = useSetRecoilState(descFlagState);
  const [useFlag, setUseFlag] = useRecoilState(useFlagState);
  const [visibleFlag, setVisibleFlag] = useRecoilState(visibleFlagState);
  const [fieldValue, setFieldValue] = useRecoilState(fieldValueState);
  const [checkedFlag, setCheckedFlag] = useRecoilState(checkedFlagState);
  const [listSeachInfo, setSearchInfoList] = useState({} as any);
  const [listUserCs, setListUserCs] = useState({} as any);
  const [inqueryNoGen, setInqueryNoGen] = useState("");
  const [loadingForm, setLoadingForm] = useRecoilState(loadingChildState);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [contactChanelList, setContactChanelList] =
    useState<IcontactChanelList>([]);
  const [formInput, setFormInput] = useState<IformInput>({
    idCustomer: "",
    companyName: "",
    contactName: "",
    contactDate: moment(),
    contact: null,
  });

  useEffect(() => {
    if (pathID) {
      HandleCheckIntialValue();
      setInqueryNoGen(pathID);
    } else {
      HandleGenInqueryNo();
    }
    HandlegetContactChanel();
    return undefined;

  }, []);

  const HandlegetContactChanel = async () => {
    try {
      const res = await GET_CONTACT_CHANEL_API();
      if (res.code === 200) {
        setContactChanelList(res.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleGenInqueryNo = async () => {
    setLoadingForm(true);
    try {
      const res = await GEN_INQUERYNO_OF_FORM_API();
      if (res.code === 200) {
        setInqueryNoGen(res.result[0]);
        setFieldValue({
          ch11: "สอบถาม",
          ch12: "เช็ค",
          ch13: "สอบถาม",
          ch14: "สอบถาม",
          ch21: "ติดตาม",
          ch22: "ติดตาม",
          ch23: "ติดตาม",
          ch31: "แจ้ง",
          ch32: "แจ้ง",
          ch33: "แจ้ง",
          ch41: "สอบถาม",
          ch42: "สอบถาม",
          ch43: "สอบถาม",
          ch51: "แจ้ง",
          ch52: "แจ้ง",
          ch53: "แจ้ง",
          ch54: "แจ้ง",
        });
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถสร้างหมายเลข INQUERY ได้ เนื่องจาก ข้อผิดพลาด !${res.message}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  const GetDateInitialValue = (date: string) => {
    const changeDate = new Date(Number(date));
    const resDate = moment(changeDate, "DD-MM-YYYY");
    return resDate;
  };

  const HandleCheckIntialValue = async () => {
    setLoadingForm(true);
    try {
      const res = await GET_CUSTOMER_SERVICE_EDIT_API(pathID);
      if (res.code === 200) {
        const result = res.result[0];
        setListUserCs(result);
        const choiceInfo = result.answer;
        const field1: any = HandleRefacterData(
          choiceInfo?.productsPurchase?.choices
        );
        const field2: any = HandleRefacterData(
          choiceInfo?.orderManagement?.choices
        );
        const field3: any = HandleRefacterData(
          choiceInfo?.requestManagement?.choices
        );
        const field4: any = HandleRefacterData(
          choiceInfo?.technicalInformation?.choices
        );
        const field5: any = HandleRefacterData(choiceInfo?.feedback?.choices);
        const field6: any = HandleRefacterData(choiceInfo.other?.choices);

        const initailObject = {
          ...field1,
          ...field2,
          ...field3,
          ...field4,
          ...field5,
          ...field6,
        };
        setFieldValue(initailObject);
        handleSetDescData(initailObject);
        setUseFlag({
          field1: handleSetUseFlag(field1),
          field2: handleSetUseFlag(field2),
          field3: handleSetUseFlag(field3),
          field4: handleSetUseFlag(field4),
          field5: handleSetUseFlag(field5),
          field6: handleSetUseFlag(field6),
        });
        setCheckedFlag({
          field1: !_isEmpty(field1),
          field2: !_isEmpty(field2),
          field3: !_isEmpty(field3),
          field4: !_isEmpty(field4),
          field5: !_isEmpty(field5),
          field6: !_isEmpty(field6),
        });
        ////////////////////////
        const initailValue = res.result[0];
        const initailData = {
          idCustomer: initailValue?.userCS?.code,
          companyName: initailValue?.userCS?.companyName,
          contactName: initailValue?.userCS?.contactName,
          contactDate: GetDateInitialValue(initailValue?.contactDate),
          contact: initailValue.contactChannel,
        };
        const dataSearch = {
          code: initailValue?.userCS?.code,
          name: initailValue?.userCS?.companyName,
        };
        handleOnSearch(dataSearch, initailData);
        ////////////////////////
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถสร้างหมายเลข INQUERY ได้ เนื่องจาก ข้อผิดพลาด !${res.message}`,
        });
        history.push(`/customerservice`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingForm(false);
    }
  };

  const handleSetUseFlag = (field: any = {}) => {
    var objectValue = Object.keys(field).map((key, indx) => {
      const refacKey = key.split("_");
      return refacKey.length === 1
        ? {
            [refacKey[0] === "ch16"
              ? `${refacKey[0]}_subValue`
              : `${refacKey[0]}`]: true,
          }
        : null;
    });
    const arrayfilter = handleFilterPopNull(objectValue);
    return arrayfilter;
  };

  const handleSetDescData = (initailObject: any = []) => {
    var objectValue = Object.keys(initailObject).map((key) => {
      const refacKey = key.split("_");
      return refacKey.length === 2 &&
        (refacKey[1] === "desc" || refacKey[1] === "operation")
        ? initailObject[key] !== ""
          ? {
              [refacKey[0] === "ch11" || refacKey[0] === "ch16"
                ? `${refacKey[0]}_subValue`
                : `${refacKey[0]}`]: true,
            }
          : null
        : refacKey.length === 3 &&
          (refacKey[1] === "desc" || refacKey[1] === "operation")
        ? initailObject[key].length > 0
          ? {
              [refacKey[0] === "ch11" || refacKey[0] === "ch16"
                ? `${refacKey[0]}_subValue`
                : `${refacKey[0]}`]: true,
            }
          : null
        : null;
    });
    const arrayfilter = handleFilterPopNull(objectValue);
    setDescFlag(arrayfilter);
  };

  const handleFilterPopNull = (obj: any = {}) => {
    const arrayfilter = obj.flat().filter((item: any) => item !== null);
    const dataReDesc = arrayfilter.reduce((a: any, b: any) => {
      return { ...a, ...b };
    }, {});
    return dataReDesc;
  };

  const HandleRefacterData = (arr: any = []) => {
    const result = arr.map((item: any, idx: number) => {
      const refacKey = item.key.split(".");
      return {
        [`ch${refacKey[0]}${refacKey[1]}`]:
          `${refacKey[0]}${refacKey[1]}` === "15" ||
          `${refacKey[0]}${refacKey[1]}` === "61"
            ? true
            : item.value,
        [`ch${refacKey[0]}${refacKey[1]}_subValue`]: item.subValue,
        [`ch${refacKey[0]}${refacKey[1]}_desc`]: item.fillDetail,
        [`ch${refacKey[0]}${refacKey[1]}_operation`]: item.operationDetail,
        [`ch${refacKey[0]}${refacKey[1]}_desc_File`]:
          item.fillDetailFile?.map((i: any, idx: number) => {
            return { ...i, uid: `desc_${idx}` };
          }) || [],
        [`ch${refacKey[0]}${refacKey[1]}_operation_File`]:
          item.operationDetailFile?.map((i: any, idx: number) => {
            return { ...i, uid: `operation_${idx}` };
          }) || [],
        [`ch${refacKey[0]}${refacKey[1]}_option`]: item.option,
      };
    });
    const res = result.reduce((a: any, b: any) => {
      return { ...a, ...b };
    }, {});

    return res;
  };

  const indicatorContent = (
    <Col span={24}>
      <div style={{ marginBottom: 25 }}>
        <HashLoader color="#FCD901" size="35px" />
      </div>
      <Row
        justify="center"
        align="bottom"
        style={{
          fontSize: 15,
          color: "#FCD901",
        }}
      >
        Loading...
      </Row>
    </Col>
  );

  const field1: IFormItem[] = [
    {
      label: { label: "ข้อมูลสินค้า", align: "right" },
      name: "ch11",
      type: EInputType.SELECT,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_11_action,
          key: "value",
          name: "value",
        },
        // initialValue: 'ติดตาม',
      },
      childrenField: [
        {
          label: { label: "5555", align: "55555" },
          name: "ch11_subValue",
          type: EInputType.SELECT,
          hasAddition: true,
          fieldProps: {
            mode: "multiple",
            style: { width: "80%" },
            placeholder: "เลือก",
            optionValue: {
              values: mixList.list_11_sub,
              key: "value",
              name: "value",
            },
          },
        },
      ],
    },
    {
      label: { label: "สินค้าในสต็อก", align: "right" },
      name: "ch12",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_12_action,
          key: "value",
          name: "value",
        },
      },
    },
    {
      label: { label: "ราคาและโปรโมชั่น", align: "right" },
      name: "ch13",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_13_action,
          key: "value",
          name: "value",
        },
      },
    },
    {
      label: { label: "เงื่อนไขการชำระเงิน", align: "right" },
      name: "ch14",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_14_action,
          key: "value",
          name: "value",
        },
      },
      // colProps: { span: 24 },
    },
    {
      label: { label: "ติดตามออเดอร์ลูกค้า", align: "right" },
      name: "ch15",
      type: EInputType.BLANK,
      hasAddition: true,
      fieldProps: {
        title: "ติดตามออเดอร์ลูกค้า",
      },
    },
    {
      label: { label: "สั่งซื้อสินค้า", align: "right" },
      name: "ch16_subValue",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        mode: "multiple",
        style: { width: "80%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_16_sub,
          key: "value",
          name: "value",
        },
      },
    },
  ];

  const field2: IFormItem[] = [
    {
      label: { label: "สถานะการผลิต", align: "right" },
      name: "ch21",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_21_action,
          key: "value",
          name: "value",
        },
      },
    },
    {
      label: { label: "กำหนดส่ง", align: "right" },
      name: "ch22",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_22_action,
          key: "value",
          name: "value",
        },
      },
      childrenField: [
        {
          label: { label: "", align: "right" },
          name: "ch22_option",
          type: EInputType.RADIO,
          fieldProps: {
            style: { width: "100%" },
            optionValue: {
              values: [
                { id: 1, value: "ยืนยัน" },
                { id: 2, value: "เลื่อนส่ง" },
              ],
              key: "value",
              name: "value",
            },
          },
        },
      ],
    },
    {
      label: { label: "รายการค้างส่ง", align: "right" },
      name: "ch23",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_23_action,
          key: "value",
          name: "value",
        },
      },
    },
  ];

  const field3: IFormItem[] = [
    {
      label: { label: "ข้อร้องเรียนด้านคุณภาพ", align: "right" },
      name: "ch31",
      type: EInputType.SELECT,
      hasAddition: false,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_31_action,
          key: "value",
          name: "value",
        },
      },
      childrenField: [
        {
          label: { label: "", align: "" },
          name: "ch31_subValue",
          type: EInputType.SELECT,
          hasAddition: true,
          fieldProps: {
            mode: "multiple",
            style: { width: "80%" },
            placeholder: "เลือก",
            optionValue: {
              values: mixList.list_31_sub,
              key: "value",
              name: "value",
            },
          },
          childrenField: [
            {
              label: { label: "", align: "right" },
              name: "ch31_option",
              type: EInputType.RADIO,
              fieldProps: {
                style: { width: "100%" },
                optionValue: {
                  values: [
                    { id: 1, value: "คืนสินค้า" },
                    { id: 2, value: "ร้องเรียน" },
                  ],
                  key: "value",
                  name: "value",
                },
              },
            },
          ],
        },
      ],
    },
    {
      label: { label: "ข้อร้องเรียนด้านการจัดส่ง", align: "right" },
      name: "ch32",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_32_action,
          key: "value",
          name: "value",
        },
      },
      childrenField: [
        {
          label: { label: "", align: "right" },
          name: "ch32_subValue",
          type: EInputType.SELECT,
          fieldProps: {
            mode: "multiple",
            style: { width: "80%" },
            placeholder: "เลือก",
            optionValue: {
              values: mixList.list_32_sub,
              key: "value",
              name: "value",
            },
          },
        },
      ],
    },
    {
      label: { label: "ข้อร้องเรียนด้านเอกสาร", align: "right" },
      name: "ch33",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_33_action,
          key: "value",
          name: "value",
        },
      },
      childrenField: [
        {
          label: { label: "", align: "right" },
          name: "ch33_subValue",
          type: EInputType.SELECT,
          fieldProps: {
            mode: "multiple",
            style: { width: "80%" },
            placeholder: "เลือก",
            optionValue: {
              values: mixList.list_33_sub,
              key: "value",
              name: "value",
            },
          },
          childrenField: [
            {
              label: { label: "สถานะการผลิต", align: "right" },
              name: "ch33_option",
              type: EInputType.RADIO,
              fieldProps: {
                style: { width: "100%" },
                optionValue: {
                  values: [
                    { id: 1, value: "ล่าช้า" },
                    { id: 2, value: "ข้อมูลไม่ถูกต้อง" },
                    { id: 3, value: "อื่น ๆ" },
                  ],
                  key: "value",
                  name: "value",
                },
              },
            },
          ],
        },
      ],
    },
  ];

  const field4: IFormItem[] = [
    {
      label: { label: "วิธีการใช้งาน", align: "right" },
      name: "ch41",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_41_action,
          key: "value",
          name: "value",
        },
      },
    },
    {
      label: { label: "การถอดของรายการเส้นและอุปกรณ์", align: "right" },
      name: "ch42",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_42_action,
          key: "value",
          name: "value",
        },
      },
    },
    {
      label: { label: "ข้อมูลอื่นๆ", align: "right" },
      name: "ch43",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_43_action,
          key: "value",
          name: "value",
        },
      },
    },
  ];

  const field5: IFormItem[] = [
    {
      label: { label: "ข้อมูลตลาดและราคาคู่แข่ง", align: "right" },
      name: "ch51",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_51_action,
          key: "value",
          name: "value",
        },
      },
    },
    {
      label: { label: "ข้อมูลสินค้าคู่แข่ง", align: "right" },
      name: "ch52",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_52_action,
          key: "value",
          name: "value",
        },
      },
    },
    {
      label: { label: "ข้อเสนอในการพัฒนาสินค้า", align: "right" },
      name: "ch53",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_53_action,
          key: "value",
          name: "value",
        },
      },
    },
    {
      label: { label: "ข้อเสนอแนะอื่นๆ", align: "right" },
      name: "ch54",
      type: EInputType.SELECT,
      hasAddition: true,
      fieldProps: {
        style: { width: "15%" },
        placeholder: "เลือก",
        optionValue: {
          values: mixList.list_54_action,
          key: "value",
          name: "value",
        },
      },
    },
  ];

  const field6: IFormItem[] = [
    {
      label: { label: "อื่น ๆ", align: "right" },
      name: "ch61",
      type: EInputType.BLANK,
      hasAddition: true,
      fieldProps: {
        title: "อื่น ๆ",
        value: false,
      },
    },
  ];

  // *********************************************************** funtion submit*********************************** //

  const handleOnSearch = async (dataInfo?: any, initailData?: any) => {
    setLoadingSearch(true);
    const dataSearch = !_isEmpty(dataInfo)
      ? dataInfo
      : {
          code: formInput.idCustomer,
          name: formInput.companyName,
        };
    try {
      const res = await GET_SEARCH_ERP_USER_API_BY_ID(dataSearch);
      if (res.code === 200) {
        setSearchInfoList(res.result[0]);
        const resultSearch = !_isEmpty(initailData)
          ? initailData
          : {
              ...formInput,
              companyName: res.result[0]?.companyName,
              contactName:
                res.result[0]?.customerContactPersons[0]?.contactName,
              idCustomer: res.result[0]?.userCode,
            };
        setFormInput(resultSearch);
        AlertToast({
          icon: "success",
          title: "ค้นหาสำเร็จ! ท่านสามารถเลือกชื่อผู้ติดต่อได้เเล้ว",
        });
      } else {
        AlertToast({
          icon: "error",
          title: `ค้นหาไม่สำเร็จ! เนื่องจาก ข้อผิดพลาด !${res.message}`,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSearch(false);
    }
  };
  // *********************************************************** funtion submit*********************************** //

  const toggleFieldValue = (fieldKey: string) => {
    if (visibleFlag[fieldKey]) {
      const values = form.getFieldsValue(true);
      setFieldValue(values);
    } else {
      form.setFieldsValue(fieldValue);
    }
  };

  const Header = ({ title, fieldKey }: { title: string; fieldKey: string }) => {
    const Icon = visibleFlag[fieldKey] ? CaretUpOutlined : CaretDownOutlined;
    return (
      <Card
        className="customer-add-header mt-3"
        onClick={() => {
          toggleFieldValue(fieldKey);
          setVisibleFlag({
            ...visibleFlag,
            [fieldKey]: !visibleFlag[fieldKey],
          });
        }}
      >
        <Row align="middle" justify="space-between">
          <Row>
            <Radio checked={checkedFlag[fieldKey]} className="my-radio" />
            <b className="fontDark">{title}</b>
          </Row>
          <Button
            className="btn-vsb-header"
            icon={<Icon className="icon-custom" />}
          />
        </Row>
      </Card>
    );
  };

  const Body = ({
    children,
    fieldKey,
  }: {
    children: any;
    fieldKey: string;
  }): any => {
    if (!visibleFlag[fieldKey]) return null;
    return (
      <Card
        style={{
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderColor: "lightgray",
        }}
      >
        {children}
      </Card>
    );
  };

  const CheckBoxWithTitle = ({
    title,
    fieldKey,
  }: {
    title: string;
    fieldKey: string;
  }) => {
    return (
      <div
        style={{ marginBottom: "10px" }}
        onClick={() => {
          toggleFieldValue(fieldKey);
          setVisibleFlag({
            ...visibleFlag,
            [fieldKey]: !visibleFlag[fieldKey],
          });
          HandleScroll(fieldKey);
        }}
      >
        <Checkbox checked={checkedFlag[fieldKey]}>
          <p
            style={{
              color: checkedFlag[fieldKey] ? "black" : "#cbcbcb",
              fontWeight: checkedFlag[fieldKey] ? "bold" : "normal",
              fontSize: 16,
            }}
          >
            {title}
          </p>
        </Checkbox>
      </div>
    );
  };

  const onFinish = async (values: any) => {
    const currentFormValue = filterUndefine(values);
    const result = { ...fieldValue, ...currentFormValue };

    const reGroupValue = Object.keys(result).reduce(
      (pre: { [p: string]: string }[], current) => {
        const x = current.split("_")[0];
        let match = false;
        pre.forEach((item: any, idx: number) => {
          const findValue = Object.keys(item).find(
            (key: any) => key.indexOf(x) === 0
          );
          if (findValue !== undefined) {
            pre[idx] = { ...pre[idx], [current]: result[current] };
            match = true;
          }
        });

        if (match === false) {
          pre = [...pre, { [current]: result[current] }];
        }

        return pre;
      },
      []
    );

    const valueChangeKey = reGroupValue.map((item: any, _index: number) => {
      const mainKey = Object.keys(item);
      const SubmainKey = mainKey[0].split("_")[0];
      const realKey = SubmainKey.split("");
      const actionValue = item[`${SubmainKey}`];
      const keyDataInfo = `${realKey[2]}.${realKey[3]}`;
      const data =
        keyDataInfo === "6.1"
          ? {
              firstAnswer: "6",
              option: handlechangeActionValue(actionValue, SubmainKey)
                ? "อื่น"
                : "",
              secondAnswer: "6.1",
              thirdAnswer: [],
            }
          : {
              firstAnswer: realKey[2],
              secondAnswer: keyDataInfo,
              thirdAnswer: handleReGroupThirdValue(
                item[`${SubmainKey}_subValue`],
                SubmainKey
              ),
            };

      const optionData =
        keyDataInfo === "1.5" || keyDataInfo === "1.6"
          ? {}
          : keyDataInfo === "6.1"
          ? {
              option: handlechangeActionValue(actionValue, SubmainKey)
                ? "อื่น"
                : "",
            }
          : {
              action: handlechangeActionValue(actionValue, SubmainKey),
              option: item[`${SubmainKey}_option`],
            };
      const mixData = {
        ...data,
        ...optionData,
        fillDetail: item[`${SubmainKey}_desc`],
        operationDetail: item[`${SubmainKey}_operation`],
        fillDetailFiles:
          item[`${SubmainKey}_desc_File`]
            ?.filter((item: any) => item !== "undefined" || undefined)
            .map((item: any) => item?.id) || [],
        operationDetailFiles:
          item[`${SubmainKey}_operation_File`]
            ?.filter((item: any) => item !== "undefined" || undefined)
            ?.map((item: any) => item?.id) || [],
      };

      let result = {};
      for (const [key, value] of Object.entries(mixData)) {
        let CheckFlag =
          SubmainKey === "ch16"
            ? useFlag[`field${realKey[2]}`][`${SubmainKey}_subValue`]
            : useFlag[`field${realKey[2]}`][SubmainKey];

        if (CheckFlag) {
          if (value || value === false || value === 0) {
            result = { ...result, [key]: value };
          } else {
            result = { ...result, [key]: "" };
          }
        }
      }
      return result;
    });

    const HandleCheckObjEmpty = (arr: any = []) => {
      const res = arr.filter((item: any) => !_isEmpty(item));
      return res;
    };

    const contactNameInfo = listSeachInfo?.customerContactPersons?.find(
      (item: any) => item.contactName === formInput?.contactName
    );

    const dataRefacter = {
      contactDate: formInput.contactDate.valueOf(),
      status: !_isEmpty(listUserCs?.status)
        ? listUserCs?.status
        : "ACKNOWLEDGE",
      contact: Number(formInput.contact),
      answerCS: HandleCheckObjEmpty(valueChangeKey),
      userCS: {
        companyName: listSeachInfo?.companyName,
        branch: listSeachInfo?.branch ? listSeachInfo?.branch : "",
        contactName: contactNameInfo?.contactName,
        type: listSeachInfo?.type,
        code: formInput?.idCustomer,
      },
      aE: listSeachInfo?.aE,
      aO: listSeachInfo?.aO,
    };

    if (_isEmpty(dataRefacter.answerCS)) {
      AlertToast({
        icon: "warning",
        title: "เเจ้งเตือน! ท่านไม่ได้ทำการกรอกข้อมูลใดๆ",
      });
      history.push({
        pathname: `/customerservice`,
      });
    } else {
      const sendApi = !_isEmpty(pathID)
        ? CUSTOMER_SERVICE_EDIT_API(pathID, dataRefacter)
        : CUSTOMER_SERVICE_CREATE_API(dataRefacter);
      try {
        const res = await sendApi;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! บันทึกข้อมูลสำเร็จ`,
          });
          if (_isEmpty(pathID)) {
            form.resetFields();
            setUseFlag({
              field1: {},
              field2: {},
              field3: {},
              field4: {},
              field5: {},
              field6: {},
            });
            history.push({
              pathname: `/customerservice/list/detail/${inqueryNoGen}`,
            });
          } else {
            history.push({
              pathname: `/customerservice/list/detail/${pathID}`,
            });
          }
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! บันทึกข้อมูลไม่สำเร็จ เนื่องจาก ข้อมูลของท่านไม่สมบูรญ์
            กรุณาตรวจสอบ ข้อมูลผู้ติดต่อของท่าน`,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlechangeActionValue = (
    data: string | boolean,
    keyMain: string = ""
  ) => {
    if (typeof data === "string") {
      const currentKey = `list_${keyMain.split("ch")[1]}_action`;
      const mixKey = Object.keys(mixList).find((key) => key === currentKey);
      if (mixKey === undefined) {
        return "";
      }
      const value = mixList[mixKey];
      if (Object.keys(value).length === 0) {
        return "";
      }
      const match = value.find(
        (item: { value: string }) => item.value === data
      );
      if (match !== undefined) {
        return match?.id;
      }
    } else return data;
  };

  const handleReGroupThirdValue = (arr: any = [], keyMain: string = "") => {
    const currentKey = keyMain.split("ch");
    const result = arr.map((item: any, idx: number) => {
      let res: any = {};
      for (const [key, value] of Object.entries(mixList)) {
        if (key === `list_${currentKey[1]}_sub`) {
          res = value.find(
            (keyValue: any, index: number) => keyValue.value === item
          );
        }
      }
      return res.id;
    });
    return result;
  };

  const HandleChangeDate = (value: moment.Moment | null) => {
    setFormInput({
      ...formInput,
      contactDate: value,
    });
  };

  const HandleScroll = (key: string) => {
    var elmnt = document.getElementById(key);
    elmnt?.scrollIntoView();
  };

  return (
    <div>
      <LoadSomeContent loading={loadingForm}>
        <HeaderTab
          title="CUSTOMER SERVICE"
          message="การบริการลูกค้า"
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            position: "fixed",
            top: 0,
          }}
          isButton
          onFinish={() => {
            form.submit();
          }}
        />
        <Card
          style={{
            marginTop: "15px",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              color: "black",
              marginBottom: 10,
            }}
          >
            กรอกข้อมูลการติดต่อลูกค้า
          </div>
          <Row gutter={15}>
            <Col lg={5}>
              <Card
                style={{ position: "sticky" }}
                className="customer-add-body-side side-card-sticky"
              >
                <p
                  style={{
                    textAlign: "center",
                    paddingBottom: "15px",
                    color: "black",
                    fontSize: "17px",
                    fontWeight: "bolder",
                  }}
                >
                  REQUIREMENT | ความต้องการของลูกค้า
                </p>
                <CheckBoxWithTitle title="การซื้อขายสินค้า" fieldKey="field1" />
                <CheckBoxWithTitle
                  title="การจัดการออร์เดอร์ค้างส่ง"
                  fieldKey="field2"
                />
                <CheckBoxWithTitle
                  title="การจัดการข้อร้องเรียน"
                  fieldKey="field3"
                />
                <CheckBoxWithTitle title="ข้อมูลเทคนิค" fieldKey="field4" />
                <CheckBoxWithTitle title="ข้อเสนอแนะ" fieldKey="field5" />
                <CheckBoxWithTitle title="อื่นๆ" fieldKey="field6" />
              </Card>
            </Col>
            <Col lg={19} className="col-scroll">
              <Card className="customer-add-header-not">
                <b className="fontDark">REQUIREMENT | ความต้องการของลูกค้า</b>
              </Card>
              <Card
                style={{
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  borderColor: "lightgray",
                }}
              >
                <Spin spinning={loadingSearch} indicator={indicatorContent}>
                  <Row gutter={15}>
                    <Col lg={8}>
                      <p style={{ fontWeight: 600 }}>INQUIRY NO.</p>
                      <h3>{inqueryNoGen}</h3>
                    </Col>
                    <Col lg={9}>
                      <p style={{ fontWeight: 600 }}>วันที่ | เวลา</p>
                      <h3>{moment().format("DD/MM/YYYY | h:mm:ss")}</h3>
                    </Col>
                    <Col lg={7}>
                      <div>
                        <p style={{ marginBottom: 0, fontWeight: 600 }}>
                          วันที่ติดต่อ
                        </p>
                        <DatePicker
                          style={{
                            width: "100%",
                            height: "50px",
                            marginBottom: "15px",
                          }}
                          value={formInput?.contactDate}
                          format={dateFormat}
                          allowClear={false}
                          placeholder="เลือกวันที่ติดต่อ"
                          onChange={HandleChangeDate}
                          suffixIcon={
                            <img
                              src={DateNew}
                              style={{ width: "25px" }}
                              alt="pdf-btn"
                            />
                          }
                        />
                      </div>
                    </Col>

                    <Col lg={4}>
                      <FloatLabel
                        label="รหัสลูกค้า"
                        name="idCustomer"
                        value={formInput.idCustomer}
                      >
                        <Input
                          size="large"
                          style={{ height: "50px" }}
                          value={formInput.idCustomer}
                          onChange={(e) =>
                            setFormInput({
                              ...formInput,
                              idCustomer: e.target.value,
                            })
                          }
                        />
                      </FloatLabel>
                    </Col>
                    <Col lg={16}>
                      <FloatLabel
                        label="ชื่อบริษัท"
                        name="companyName"
                        value={formInput.companyName}
                      >
                        <Input
                          size="large"
                          style={{ height: "50px" }}
                          value={formInput.companyName}
                          onChange={(e) =>
                            setFormInput({
                              ...formInput,
                              companyName: e.target.value,
                            })
                          }
                        />
                      </FloatLabel>
                    </Col>
                    <Col lg={4}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "100%",
                          height: "50px",
                          fontSize: "18px",
                          marginBottom: "15px",
                          background: "#4D4D4D",
                          border: "unset",
                        }}
                        onClick={() => handleOnSearch()}
                      >
                        <img
                          className="black-to-white"
                          src={oldSearch}
                          style={{
                            width: "20px",
                            marginRight: "10px",
                          }}
                          alt="pdf-btn"
                        />
                        ค้นหา
                      </Button>
                    </Col>
                    <Col lg={20}>
                      <FloatLabel
                        label="ชื่อผู้ติดต่อ"
                        name="contactName"
                        value={formInput.contactName}
                      >
                        <Select
                          size="large"
                          value={formInput.contactName}
                          onChange={(e: any) => {
                            setFormInput({
                              ...formInput,
                              contactName: e,
                            });
                          }}
                        >
                          {listSeachInfo?.customerContactPersons?.map(
                            (item: any, idx: number) => (
                              <Option key={idx} value={item?.contactName}>
                                {item?.contactName}
                              </Option>
                            )
                          )}
                        </Select>
                      </FloatLabel>
                    </Col>
                  </Row>
                </Spin>
              </Card>
              <Card className="customer-add-header-not mt-3">
                <b className="my-0 fontDark">ช่องทางการติดต่อ</b>
              </Card>
              <Card
                style={{
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  borderColor: "lightgray",
                }}
              >
                <Row gutter={15}>
                  <Col lg={12}>
                    <FloatLabel
                      label="ช่องทางการติดต่อ"
                      name="contact"
                      value={formInput.contact}
                    >
                      <Select
                        size="large"
                        value={formInput.contact}
                        onChange={(value) => {
                          setFormInput({
                            ...formInput,
                            contact: value,
                          });
                        }}
                        className="select-field-custom"
                      >
                        {contactChanelList?.map((item, idx) => (
                          <Option value={item?.id} key={idx}>
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </FloatLabel>
                  </Col>
                </Row>
              </Card>
              <Form form={form} onFinish={onFinish}>
                <div id="field1">
                  <Header fieldKey="field1" title="การซื้อขายสินค้า" />
                  <Body fieldKey="field1">
                    <CreateForm
                      field={field1}
                      parentKey="field1"
                      toggleFieldValue={toggleFieldValue}
                    />
                  </Body>
                </div>

                <div id="field2">
                  <Header fieldKey="field2" title="การจัดการออเดอร์ค้างส่ง" />
                  <Body fieldKey="field2">
                    <CreateForm
                      field={field2}
                      parentKey="field2"
                      toggleFieldValue={toggleFieldValue}
                    />
                  </Body>
                </div>

                <div id="field3">
                  <Header fieldKey="field3" title="การจัดการข้อร้องเรียน" />
                  <Body fieldKey="field3">
                    <CreateForm
                      field={field3}
                      parentKey="field3"
                      toggleFieldValue={toggleFieldValue}
                    />
                  </Body>
                </div>

                <div id="field4">
                  <Header fieldKey="field4" title="ข้อมูลเทคนิค" />
                  <Body fieldKey="field4">
                    <CreateForm
                      field={field4}
                      parentKey="field4"
                      toggleFieldValue={toggleFieldValue}
                    />
                  </Body>
                </div>

                <div id="field5">
                  <Header fieldKey="field5" title="ข้อเสนอแนะ" />
                  <Body fieldKey="field5">
                    <CreateForm
                      field={field5}
                      parentKey="field5"
                      toggleFieldValue={toggleFieldValue}
                    />
                  </Body>
                </div>

                <div id="field6">
                  <Header fieldKey="field6" title="อื่น ๆ" />
                  <Body fieldKey="field6">
                    <CreateForm
                      field={field6}
                      parentKey="field6"
                      toggleFieldValue={toggleFieldValue}
                    />
                  </Body>
                </div>
              </Form>
            </Col>
          </Row>
        </Card>
      </LoadSomeContent>
    </div>
  );
};

const AddList = () => {
  return (
    <RecoilRoot>
      <AddListState />
    </RecoilRoot>
  );
};

export default AddList;

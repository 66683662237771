import { Col, Row,Checkbox } from "antd";
import { ReactElement } from "react";
import FInput from "../../../../components/form_floatlabel/FInput";
import FSelect from "../../../../components/form_floatlabel/FSelect";
import FTextArea from "../../../../components/form_floatlabel/FTextArea";
import { IDefaultField } from "../../../../interface/ICustomerService.interface";
export interface IFormItem {
  fieldProps: {
    label: string;
    name: string;
    options: object;
    initialValue?: any;
    dataSource?: [];
    keyValue?: string;
    keyName?: string;
    rules?: object;
  };
  type: EInputType;
  colProps?: object;
}

export enum EInputType {
  INPUT,
  SELECT,
  TEXTAREA,
  CHECKBOX,
}
const defFormField: IDefaultField = {
  [EInputType.INPUT]: FInput,
  [EInputType.SELECT]: FSelect,
  [EInputType.TEXTAREA]: FTextArea,
  [EInputType.CHECKBOX]: Checkbox,
};

const generateField = (_field: IFormItem[]): ReactElement[] => {
  return _field.map((item, index: number) => {
    const Child = defFormField[item.type];
    return (
      <Col key={index} {...item.colProps} className="my-2">
        <Child {...item.fieldProps}/>
      </Col>
    );
  });
};

const CreateForm = ({ field }: { field: IFormItem[] }) => {
  return <Row gutter={30}>{generateField(field)}</Row>;
};

export default CreateForm;

import { atom } from "recoil";

export type TUseFlagKey =
  | "field1"
  | "field2"
  | "field3"
  | "field4"
  | "field5"
  | "field6";

interface IUseFlag {
  [key: string]: { [index: string]: boolean };
}

// เก็บ state ว่า มี field ไหนถูกติ้กบ้าง
export const useFlagState = atom<IUseFlag>({
  key: "used_flag",
  default: {
    field1: {},
    field2: {},
    field3: {},
    field4: {},
    field5: {},
    field6: {},
  },
});

// เก็บ state ว่า field add description ถูกแสดงไหม
export const descFlagState = atom({
  key: "desc_flag",
  default: {} as any,
});

export const visibleFlagState = atom({
  key: "visible_flag",
  default: {} as any,
});

export const fieldValueState = atom({
  key: "field_value",
  default: {} as any,
});

export const checkedFlagState = atom({
  key: "checked_flag",
  default: {} as any,
});

/* eslint-disable react-hooks/exhaustive-deps */

import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { _isEmpty } from "@yim/util-tool-ts";
import { Card, Col, Form, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSetRecoilState } from "recoil";
import { FormDefault } from "../../../../../components";
import { CAlertToast } from "../../../../../components/alert/CAlert";
import { tableLoading } from "../../../../../components/loading/loading";
import TableModifind from "../../../../../components/table/Table";
import { ISettingList } from "../../../../../interface/ISetting.interface";
import {
  ADDPROJECTCATEGORY_API,
  DELETEPROJECTCATEGORY_API,
  EDITPROJECTCATEGORY_API,
  GETPROJECTCATEGORY_API,
} from "../../../../../services/api/setting/project_category_api";
import { loadingTable } from "../../../../../tools/recoil/recoil-atom";
import { HandleScroll } from "../../../../../tools/util";
import HeaderSetting from "../../component/header_setting/HerderSetting";

const ProjectType = () => {
  const setLoading = useSetRecoilState(loadingTable);

  const history = useHistory();
  const [manageContent, setManageContent] = useState<boolean>(false);
  const [valueProjectType, setValueProjectType] = useState<string>("");
  const [id, setId] = useState<number>(0);
  const [projectTypeList, setProjectTypeList] = useState<ISettingList[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(10);
  const [initialValueSearch, setInitialValueSearch] = useState({
    search: "",
  });
  const [formHeader] = Form.useForm();

  useEffect(() => {
    handleGetData();
    return undefined;

  }, [currentPage, limitPage, initialValueSearch]);

  const handleGetData = () => {
    handleGetProjectType();
  };

  const handleSetValue = (value: ISettingList) => {
    HandleScroll("setting-header");
    setManageContent(true);
    setId(value.id);
    setValueProjectType(value.name);
    formHeader.setFieldsValue({ name: value.name });
  };

  const handleClickEdit = () => {
    setManageContent(!manageContent);
    setValueProjectType("");
    setId(0);
  };

  const onFinish = (value: any) => {
    if (id !== 0) handleUpdate(value);
    else handleCreate(value);
  };

  const handleCreate = async (data: any) => {
    setLoading(true);
    try {
      const res = await ADDPROJECTCATEGORY_API(data);
      if (res.code === 200) {
        handleClickEdit();
        formHeader.resetFields();
        CAlertToast({ method: "POST_SUCCESS", text: res.message });
        handleGetProjectType();
      } else {
        CAlertToast({ method: "POST_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleUpdate = async (data: any) => {
    setLoading(true);
    try {
      const res = await EDITPROJECTCATEGORY_API({ id, data: data });
      if (res.code === 200) {
        handleClickEdit();
        formHeader.resetFields();
        CAlertToast({ method: "PUT_SUCCESS", text: res.message });
        handleGetProjectType();
        setId(0);
      } else {
        CAlertToast({ method: "PUT_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);

    try {
      const res = await DELETEPROJECTCATEGORY_API(id);
      if (res.code === 200) {
        CAlertToast({ method: "DEL_SUCCESS", text: res.message });
        setProjectTypeList(projectTypeList.filter((item) => item.id !== id));
      } else {
        CAlertToast({ method: "DEL_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleSubmit = (values: any) => {
    setCurrentPage(1);
    setInitialValueSearch({ ...values });
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const addButton = [
    {
      type: "btnPush",
      name: "เพิ่ม",
      onClick: () => history.push(`/setting/contact-type`),
    },
  ];

  const fieldFilter = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา ประเภทโครงการ",
      initialValue: initialValueSearch.search,
    },
  ];

  const columns: any = [
    {
      title: "ลำดับ",
      align: "center",
      width: "10%",
      render: (_value: any, _item: any, index: number) => {
        return <>{(currentPage - 1) * limitPage + (index + 1)}</>;
      },
    },
    {
      title: "ประเภทโครงการ",
      dataIndex: "name",
      key: "name",
      width: "70%",
    },
    {
      title: "จัดการ",
      align: "center",
      render: (value: ISettingList) => {
        return (
          <div className="action-table">
          <EditOutlined
            onClick={() => handleSetValue(value)}
            className="faq-btn-edit"
          />
          <Popconfirm
            className="pop-faq"
            placement="top"
            title={"คุณเเน่ใจหรือไม่"}
            onConfirm={() => handleDelete(value.id)}
            okType="danger"
            okText="ลบ"
            cancelText="ยกเลิก"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okButtonProps={{
              style: {
                width: 50,
              },
            }}
          >
            <DeleteOutlined className="ml-3 faq-btn" />
          </Popconfirm>
        </div>
        );
      },
    },
  ];

  const handleGetProjectType = async () => {
    setLoading(true);
    try {
      const res = await GETPROJECTCATEGORY_API({
        page: currentPage,
        limit: limitPage,
        ...initialValueSearch,
      });
      if (res.code === 200) {
        const dataSet = res.result[0];
        setProjectTypeList(res.result[0].item);
        setTotalPage(dataSet.total);
        setLimitPage(dataSet.perpage);
      }
    } catch (error) {}
    setLoading(false);
  };
  return (
    <div id="setting-header">
      <HeaderSetting
        form={formHeader}
        Title={tiltleHeader}
        Action={addButton}
        handleClickEdit={handleClickEdit}
        handleClickCancel={handleClickEdit}
        disabledEdit={false}
        editOnPage={true}
        manageContent={manageContent}
        actionName={!_isEmpty(valueProjectType) ? "EDIT" : "ADD"}
        onFinish={onFinish}
        fieldKey={[{ key: "name", label: "ชื่อประเภทโครงการ" }]}
      />
      <div className="form-tab-bg">
        <FormDefault
          HandleOnFinish={handleSubmit}
          Field={fieldFilter}
          Action={[]}
        />
      </div>
      <Row>
        <Col lg={24} md={24} className="mt-3">
          <Card
            className="setting-cover-card"
            cover={
              <>
                <h1 className="mb-0">
                  <span
                    style={{
                      fontFamily: "Bold",
                      color: "#faa41a",
                      fontSize: "23px",
                    }}
                  >
                     PROJECT TYPE
                  </span>
                  <span
                    className="ml-2"
                    style={{ fontFamily: "Bold", fontSize: "23px" }}
                  >
                    ประเภทโครงการ
                  </span>
                </h1>
              </>
            }
          >
             <TableModifind
          cursor={true}
          rowKey="id"
          loading={tableLoading()}
          bordered={false}
          config={{
            total: totalPage,
            pageSize: limitPage,
            currentPage: currentPage,
          }}
          columns={columns}
          dataSource={projectTypeList}
          onChangePage={onChangePage}
        />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const tiltleHeader = {
  nameEN: "PROJECT TYPE",
  nameTH: "ประเภทโครงการ",
};

export default ProjectType;

import { Col } from "antd";
import FInput from "../../../../../components/form_floatlabel/FInput";
import FSelect from "../../../../../components/form_floatlabel/FSelect";
import FTextArea from "../../../../../components/form_floatlabel/FTextArea";

type Props = {
  disabledEdit: boolean;
  isCustomer: boolean;
  onChangeProvince?: (data: any) => void;
  onChangeDistrict?: (data: any) => void;
  onChangeSubDistrict?: (data: any) => void;
  loading?: boolean;
  provinceList?: any[];
  districtList?: any[];
  subDistrictList?: any[];
};
const AddressCompanyForm = ({
  disabledEdit,
  isCustomer,
  onChangeProvince,
  onChangeDistrict,
  onChangeSubDistrict,
  provinceList = [],
  districtList = [],
  subDistrictList = [],
  loading,
}: Props) => {
  return (
    <>
      <Col md={12}>
        <FInput
          label="ชื่อบริษัท"
          name="companyName"
          rules={{ required: true }}
          options={{ disabled: disabledEdit }}
        />
      </Col>
      {!isCustomer && (
        <Col md={12}>
          <FInput
            label="เลขผู้เสียภาษี"
            name="taxpayerNumber"
            rules={{ required: true }}
            options={{ disabled: disabledEdit }}
          />
        </Col>
      )}
      {!isCustomer && (
        <Col md={12}>
          <FInput
            label="เบอร์โทรศัพท์ที่ทำงาน"
            name="companyPhone"
            rules={{ required: true }}
            options={{ disabled: disabledEdit }}
            type="number"
          />
        </Col>
      )}
      <Col md={12}>
        <FInput
          label="อีเมล"
          name="email"
          rules={{ required: true }}
          options={{ disabled: disabledEdit }}
        />
      </Col>
      <Col md={24}>
        <FTextArea
          label="ที่อยู่"
          name="address"
          rules={{ required: true }}
          options={{ disabled: disabledEdit }}
        />
      </Col>
      <Col md={12}>
        <FSelect
          label={"จังหวัด"}
          name={"province"}
          dataSource={provinceList}
          rules={{ required: true }}
          options={{
            style: { width: "100%" },
            loading: loading,
            disabled: disabledEdit
          }}
          onChange={onChangeProvince}
          keyName="nameTh"
          keyValue="id"
        />
      </Col>
      <Col md={12}>
        <FSelect
          label={"อำเภอ"}
          name={"district"}
          dataSource={districtList}
          rules={{ required: true }}
          options={{
            style: { width: "100%" },
            loading: loading,
            disabled: disabledEdit
          }}
          onChange={onChangeDistrict}
          keyName="nameTh"
          keyValue="id"
        />
      </Col>
      <Col md={12}>
        <FSelect
          label={"ตำบล"}
          name={"subDistrict"}
          dataSource={subDistrictList}
          rules={{ required: true }}
          options={{
            style: { width: "100%" },
            loading: loading,
            disabled: disabledEdit
          }}
          onChange={onChangeSubDistrict}
          keyName="nameTh"
          keyValue="id"
        />
      </Col>
      <Col md={12}>
        <FInput
          label="รหัสไปรษณีย์"
          name="postalCode"
          rules={{ required: true }}
          options={{
            style: { width: "100%" },
            disabled: disabledEdit
          }}
        />
      </Col>
    </>
  );
};

export default AddressCompanyForm;

import Contact from "./assets/img/icon/contact.png";
import Customer from "./assets/img/icon/customer.png";
import Dashboard from "./assets/img/icon/dashbord.png";
import Fix from "./assets/img/icon/fix.png";
import Project from "./assets/img/icon/project.png";
import Question from "./assets/img/icon/question.png";
import Report from "./assets/img/icon/report.png";
import Service from "./assets/img/icon/service.png";
import Setting from "./assets/img/icon/setting.png";

const items = [
  {
    keyName: "DASHBOARD",
    id: 1,
    name: {
      th: "DASHBOARD",
      en: "เเผงการบริหาร",
    },
    icon: Dashboard,
    exact: true,
    link: "/dashboard",
    sublink: [],
  },
  {
    keyName: "CUSTOMER_SERVICE",
    id: 2,
    name: {
      th: "CUSTOMER SERVICE",
      en: "การบริการลูกค้า",
    },
    icon: Service,
    exact: true,
    link: "/customerservice",
    sublink: [
      { link: "/customerservice/list/report" },
      { link: "/customerservice/list/report/subreportaoae" },
      { link: "/customerservice/list/report/subreportcustomer" },
      { link: "/customerservice/list/report/subreportservice" },
    ],
  },
  {
    keyName: "LEAD",
    id: 3,
    name: {
      th: "LEADS",
      en: "ผู้ติดต่อเข้ามา",
    },
    icon: Contact,
    exact: true,
    link: "/leads",
    sublink: [],
  },
  {
    keyName: "DIRECT_CUSTOMER",
    id: 4,
    name: {
      th: "DIRECT CUSTOMER",
      en: "ลูกค้าซื้อตรง",
    },
    icon: Customer,
    exact: true,
    link: "/directcustomer",
    sublink: [],
  },
  {
    keyName: "PROJECT",
    id: 5,
    name: {
      th: "PROJECT",
      en: "งานโครงการ",
    },
    icon: Project,
    exact: true,
    link: "/project",
    sublink: [],
  },
  {
    keyName: "TECHNICIAN",
    id: 6,
    name: {
      th: "TECHNICIAN",
      en: "ช่างสมาร์ท",
    },
    icon: Fix,
    exact: true,
    link: "/technician",
    sublink: [],
  },
  {
    keyName: "FAQ",
    id: 7,
    name: {
      th: "FAQ",
      en: "คำถามที่พบบ่อย",
    },
    icon: Question,
    exact: true,
    link: "/faq",
    sublink: [],
  },
  {
    keyName: "REPORT",
    id: 8,
    name: {
      th: "REPORT",
      en: "สรุปรายงาน",
    },
    icon: Report,
    exact: true,
    link: "/report",
    sublink: [],
  },
  {
    keyName: "SETTING",
    id: 9,
    name: {
      th: "SETTING",
      en: "กำหนดตั้งค่า",
    },
    icon: Setting,
    exact: true,
    link: "/setting",
    sublink: [
      { link: "/setting/usermanagement" },
      { link: "/setting/usermanagement/createuser" },
    ],
  },
];
export default items;

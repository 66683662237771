import { Fetch, FetchExcel } from "../../tools/fecth/fecth";
import { apiVersion } from "../../config/api";

import { createQueryString } from "../../tools/util";

export const GET_TECHNICIAN_API = async (data?: any) => {
  const result = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/project/technician${result}`,
  });
  return res;
};

export const TECHNICIAN_EXCEL_API = async (data?:any) => {
  const result = createQueryString(data);
  const res = await FetchExcel({
    method: "GET",
    path: `${apiVersion}/project/technician/excel${result}`,
  });
  return res;
};

export const GET_TECHNICIAN_BY_ID_API = async (id?: any) => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/inquiry/project/technician/${id}`,
  });
  return res;
};
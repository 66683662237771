/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { Col, Row, Button, Popover } from "antd";

import { FormDefault } from "../../../components/index";
import { useHistory } from "react-router-dom";
import {
  GET_STATUS_NEW_INQUERY_API,
  GET_STATUS_INBOX_API,
  GET_STATUS_DOING_API,
  GET_STATUS_FINISH_API,
  GET_STATUS_SEND_API,
  GET_STATUS_FOLLOW_API,
} from "../../../services/api/Lead_api";
import DetailCardModal from "./DetailCard";
import { CUSTOMER_GET_DATA_ADMIN_FILTER_API } from "../../../services/api/customer_api";
import Swal from "sweetalert2";
import { _isEmpty } from "@yim/util-tool-ts";
import {
  ConvertDateNum,
  onImgErrorProfile,
  _numToDate,
} from "../../../tools/util";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";
import HashLoader from "react-spinners/HashLoader";

import {
  Alumet,
  face,
  Gmail,
  Line,
  Tel,
  clock,
  profile,
  noImageStop,
} from "../../../assets/img/index";
import { IBoardItem } from "../../../interface/ILead.interface";

export interface ICurrentItem {
  status: string;
  titleTh: string;
  titleEn: string;
  id: number;
}

interface IDataSourceList {
  status: string;
  id: number;
  platform: {
    name: string;
    type: string;
    icon: string;
  }[];
  remark: string;
  // name: string;
  fisrtName: string;
  lastName: string;
  nickname: string;
  socialName: string;
  discription: string;
  date: string;
  user_icon: {
    icon: string;
    username: string;
  }[];
  image?: string;
  state_doing?: string;
  note_traking?: string;
  note_date?: string;
  state_send?: string;
  rate?: "ปกติ" | "ด่วน";
  activity?: string;
  projectStatus?: string;
  inboxType?: string;
  note_notaion?: string;
}
interface IDataSource {
  status: string;
  totalLength: number;
  list: IDataSourceList[];
}

interface IBoardProps {
  status: TBoardStatus;
  titleTh: string;
  titleEn: string;
  color: string;
  fetchFunction: (
    data?: any
  ) => Promise<{ code: number; result: { item: any; total: number }[] }>;
}

type TBoardStatus =
  | "newinquiry"
  | "inbox"
  | "doing"
  | "finish"
  | "send"
  | "follow";

const _boards: IBoardProps[] = [
  {
    status: "newinquiry",
    titleTh: "ผู้ติดต่อรายใหม่",
    titleEn: "NEW INQUIRY",
    color: "#FF6B0B",
    fetchFunction: GET_STATUS_NEW_INQUERY_API,
  },
  {
    status: "inbox",
    titleTh: "กล่องขาเข้า",
    titleEn: "INBOX",
    color: "#FDB714",
    fetchFunction: GET_STATUS_INBOX_API,
  },
  {
    status: "doing",
    titleTh: "อยู่ระหว่างการดำเนินการ",
    titleEn: "DOING",
    color: "#16BAFF",
    fetchFunction: GET_STATUS_DOING_API,
  },
  {
    status: "finish",
    titleTh: "ปิดจบการให้บริการเรียบร้อย",
    titleEn: "FINISHED",
    color: "#05D6DE",
    fetchFunction: GET_STATUS_FINISH_API,
  },
  {
    status: "send",
    titleTh: "กล่องส่งออก",
    titleEn: "SENT",
    color: "#4EDE05",
    fetchFunction: GET_STATUS_SEND_API,
  },
  {
    status: "follow",
    titleTh: "รอการดำเนินการ",
    titleEn: "FOLLOW",
    color: "#054E99",
    fetchFunction: GET_STATUS_FOLLOW_API,
  },
];

const _platforms: any = {
  facebook: face,
  line: Line,
  tel: Tel,
  web: Alumet,
  gmail: Gmail,
};

const defaultClass = {
  margin: 5,
  borderRadius: 5,
  padding: 10,
  border: "1px solid #EFEFEF",
};

const DoingClass = {
  margin: 5,
  border: "1px solid #EFEFEF",
  padding: 10,
  borderRadius: 5,
};

const Leads = () => {
  const history = useHistory();
  const [isCardDetailVisible, setIsCardDetailVisible] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [initialValue, setInitialValue] = useState({
    cascarder: [],
    date: "",
  });
  const [current, setCurrent] = useState<ICurrentItem>({
    status: "",
    titleTh: "",
    titleEn: "",
    id: 0,
  });

  const field = [
    {
      type: "rangepicker",
      name: "date",
      placeholder: ["วันที่เริ่ม", "วันที่สิ้นสุด"],
      initialValue: initialValue.date,
    },
    {
      type: "selectCuscader",
      name: "cascarder",
      placeholder: "เลือก",
      options: list,
      initialValue: initialValue.cascarder,
    },
  ];

  //-------------------------------------------GET DATA API-------------------------------------------

  useEffect(() => {
    HandleGetDataAdminList();
    return undefined;
  }, []);

  const HandleGetDataAdminList = async () => {
    try {
      const res = await CUSTOMER_GET_DATA_ADMIN_FILTER_API();
      if (res.code === 200) {
        const result = RefacterOptionValueCascader(res.result);
        setList(result);
      } else {
        Swal.fire(
          "ผิดพลาด!",
          `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const RefacterOptionValueCascader = (arrValue: []) => {
    const result = arrValue.map((item: any, index: number) => {
      return {
        value: item.abbreviation,
        label: item.abbreviation,
        children: item?.admin?.map((child: any, idx: number) => {
          return {
            value: child.id,
            label: child.name,
          };
        }),
      };
    });
    return result;
  };
  //-------------------------------------------GET DATA API-------------------------------------------

  const HandleOnFinish = (value: any) => {
    setInitialValue(value);
  };

  const HandleOnClick = async (data: IBoardItem, id: number) => {
    setIsCardDetailVisible(true);
    setCurrent({ ...data, id });
  };

  return (
    <div className="h-100 min-vh-100">
      <Row
        justify="space-between"
        align="middle"
        style={{ marginTop: "25px", marginBottom: "7px" }}
      >
        <Row align="middle" gutter={8}>
          <Col>
            <Button
              type="primary"
              className="btn-gradient2 mb-2"
              onClick={() => history.push("/leads/addnewinquery")}
            >
              ADD NEW INQUIRY
            </Button>
          </Col>
          <Col span={9}>
            <Button
              type="primary"
              className="btn-gradient-rycicle mb-2"
              onClick={() => history.push("/leads/ryciclebin")}
            >
              RYCICLE BIN
            </Button>
          </Col>
        </Row>
        <FormDefault
          Field={field}
          Action={[]}
          HandleOnFinish={HandleOnFinish}
        />
      </Row>
      <Boards
        initialValue={initialValue}
        HandleOnClick={HandleOnClick}
        triggerRefetch={triggerRefetch}
      />
      <DetailCardModal
        visible={isCardDetailVisible}
        toggle={() => {
          setIsCardDetailVisible(false);
        }}
        onChange={() => {
          setTriggerRefetch(!triggerRefetch);
        }}
        boardItem={current}
      />
    </div>
  );
};

const Boards = ({
  initialValue,
  HandleOnClick,
  triggerRefetch,
}: {
  initialValue: any;
  HandleOnClick: any;
  triggerRefetch: boolean;
}) => {
  return (
    <div className={isMobile ? "tab-board-mobile" : "tab-board"}>
      <div
        style={{
          width: 2450,
          display: "flex",
        }}
      >
        {_boards.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                width: "30%",
                marginRight: index !== _boards?.length - 1 ? 8 : 0,
              }}
            >
              <BoardTab
                source={item}
                initialValue={initialValue}
                HandleOnClick={HandleOnClick}
                triggerRefetch={triggerRefetch}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const usePreviousValue = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const BoardTab = ({
  source,
  initialValue,
  HandleOnClick,
  triggerRefetch,
}: {
  source: IBoardProps;
  initialValue: any;
  HandleOnClick: any;
  triggerRefetch: boolean;
}) => {
  const { color, titleEn, titleTh, status, fetchFunction } = source;
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{ page: number; limit: 10 }>({
    page: 1,
    limit: 10,
  });
  const [dataSource, setDataSource] = useState<IDataSource>({
    status: status,
    totalLength: 0,
    list: [],
  });
  const [fetched, setFetched] = useState<boolean>(false);
  const prev: any = usePreviousValue(initialValue);
  const prevTrigger = usePreviousValue(triggerRefetch);

  useEffect(() => {
    GET_DATA();
    return undefined;
  }, [pagination.page, initialValue, triggerRefetch]);

  const shouldClear = (): boolean => {
    const date = initialValue.date;
    const cascarder = initialValue.cascarder;

    const prevDate = prev?.date;
    const prevCascarder = prev?.cascarder;

    if (date !== prevDate || cascarder !== prevCascarder) {
      return true;
    }

    if (prevTrigger !== triggerRefetch) {
      return true;
    }
    return false;
  };

  const GET_DATA = async () => {
    try {
      const { date, cascarder } = initialValue;
      const [start, end] = date;
      const [, admin] = cascarder;

      setLoading(true);
      const shoC = shouldClear();
      if (shoC) {
        setFetched(false);
        setPagination({ ...pagination, page: 1 });
      }
      const res = await fetchFunction({
        ...pagination,
        admin: admin || "",
        startDate: ConvertDateNum(start) || "",
        endDate: ConvertDateNum(end) || "",
      });
      if (res.code === 200) {
        const { total, item } = res.result[0];
        setDataSource({
          ...dataSource,
          list: shoC
            ? HandleRefacterData(item)
            : [...dataSource.list, ...HandleRefacterData(item)],
          totalLength: total,
        });

        //
      } else {
        throw new Error(`failed with code ${res.code}`);
      }
    } catch (err) {
      //keep some error!
    } finally {
      setLoading(false);
      if (!fetched) {
        setFetched(true);
      }
    }
  };

  const HandleRefacterData = (dataList: any) => {
    const refacterData = dataList.map((item: any, _idx: number) => {
      const DateValue = Number(item.dateTime)
        ? _numToDate(Number(item.dateTime))
        : "";
      const noteTracking = Number(item.noteTacking.date)
        ? _numToDate(Number(item.noteTacking.date))
        : "";
      const result = {
        status: item.inquiryStatus,
        id: item.inquiryId,
        platform: item.contactChannel || [],
        platformIcon: item?.contactChannel[0]?.icon || "",
        fisrtName: item?.user?.firstName || "ไม่มี",
        lastName: item?.user?.lastName || "ไม่มี",
        nickname: item?.user?.nickname || "ไม่มี",
        socialName: item?.user?.socialName || "ไม่มี",
        discription: `${
          item.subjectContact?.map(
            (child: any, _idnx: number) => `${child.name}`
          ) || ""
        }`,
        date: DateValue,
        state_doing: item.requirementType,
        user_icon: item.admin?.map((adminItem: any) => {
          return {
            icon: adminItem?.image,
            username: adminItem?.name,
          };
        }),
        note_traking: item.noteTacking.type,
        note_notaion: item.noteTacking.note,
        note_date: noteTracking,
        state_send: item.requirementType,
        remark: `${
          item.contactChannel?.map(
            (child: any, idnx: number) => `${child.name}`
          ) || ""
        }`,
        rate: item.inboxType,
        activity: item.activity,
        projectStatus: item?.projectStatus || "",
      };
      return result;
    });
    return refacterData;
  };

  const LOAD_MORE = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  return (
    <div
      style={{ padding: 5, backgroundColor: "white", borderRadius: 5 }}
      className="table-board"
    >
      {/* head */}
      <div
        className="card-item-header-board"
        style={{ borderTop: `8px solid ${color}` }}
      >
        <div style={{ fontSize: 20, fontWeight: "bold" }}>
          {titleEn} ({dataSource.totalLength})
        </div>
        {titleTh}
      </div>
      {/* head */}

      {/* body */}
      <div className="scroll-item-card-board">
        <InfiniteScroll
          dataLength={dataSource.list.length}
          next={LOAD_MORE}
          hasMore={Math.ceil(dataSource.totalLength / 10) > pagination.page}
          loader={
            <h4 className="p-2" style={{ fontWeight: "bold" }}>
              Loading...
            </h4>
          }
          height={"65vh"}
          className="scroll-item-card-board"
        >
          {loading && !fetched ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "SemiBold",
                color: "#FCD901",
              }}
            >
              <HashLoader color="#FCD901" size="50px" />
              LOADING...
            </div>
          ) : (
            dataSource.list.map((item, index) => {
              return (
                <BoardCard
                  item={item}
                  key={index}
                  boardType={source}
                  HandleOnClick={HandleOnClick}
                />
              );
            })
          )}
        </InfiniteScroll>
      </div>
      {/* body */}
    </div>
  );
};

const BoardCard = ({
  item,
  boardType,
  HandleOnClick,
}: {
  item: IDataSourceList;
  boardType: IBoardProps;
  HandleOnClick: any;
}) => {
  const {
    platform,
    rate,
    fisrtName,
    lastName,
    nickname,
    remark,
    date,
    image,
    state_doing,
    socialName,
  } = item;

  const _getRateColor = (_status?: "ปกติ" | "ด่วน") => {
    if (!_status) return "";

    if (_status === "ด่วน") return "rgb(253, 183, 20)";
    if (_status === "ปกติ") return "green";
    return "red";
  };

  const _getPlatformIcon = (_platform?: { icon: string }[]) => {
    if (!_platform || !_platform[0]?.icon) return _platforms["web"];
    return _platform[0].icon;
  };

  const _platformIcon = _getPlatformIcon(platform);
  const _rateColor = _getRateColor(rate);

  const CheckEmptyName = () => {
    return fisrtName !== "ไม่มี" && !_isEmpty(fisrtName)
      ? `${fisrtName} ${lastName}`
      : nickname !== "ไม่มี" && !_isEmpty(nickname)
      ? nickname
      : socialName;
  };

  return (
    <div
      onClick={() => HandleOnClick(boardType, item.id)}
      style={{ cursor: "pointer" }}
    >
      <div
        style={state_doing ? { ...DoingClass } : { ...defaultClass }}
        className="card-item-board"
      >
        <Row justify="space-between" align="top">
          <Row align="top">
            <Row align="middle">
              <img
                src={_platformIcon || noImageStop}
                style={{ width: 35, height: 35, objectFit: "cover" }}
                alt="userImage"
              />
              <Col style={{ margin: "0 10px" }}>
                <Row>
                  <div
                    style={{
                      fontSize: 16,
                      color: "black",
                      fontFamily: "SemiBold",
                      width: 150,
                    }}
                    className="text-overflow-csm"
                  >
                    {CheckEmptyName()}
                  </div>

                  {boardType.status === "inbox" && rate && (
                    <div
                      style={{
                        borderRadius: 15,
                        backgroundColor: _rateColor,
                        height: 20,
                        color: "white",
                        padding: "0px 12px",
                      }}
                      className="mx-2"
                    >
                      {rate}
                    </div>
                  )}
                </Row>
                <div
                  style={{
                    color: "#000000",
                    opacity: "0.6",
                    width: 200,
                    marginTop: 1,
                  }}
                  className="text-overflow-csm"
                >
                  {remark}
                </div>
              </Col>
            </Row>
          </Row>
          <div
            style={{
              fontFamily: "SemiBold",
              color: "#000000",
              opacity: "0.6",
            }}
          >
            {date}
          </div>
        </Row>
        {image && (
          <Row justify="center">
            <img
              src={image || noImageStop}
              style={{
                width: 250,
                height: 150,
                borderRadius: 3,
                margin: "5px 0",
                objectFit: "cover",
              }}
              alt="userImage"
            />
          </Row>
        )}
        <div style={{ marginLeft: "11.5%" }}>
          {boardType.status === "doing" ||
          boardType.status === "send" ||
          boardType.status === "finish"
            ? CheckDiscription(
                boardType.status === "send"
                  ? item.activity
                  : boardType.status === "finish"
                  ? item?.projectStatus
                  : boardType.status === "doing"
                  ? item?.projectStatus === "PENDING"
                    ? item?.projectStatus
                    : item.state_send
                  : item.state_send,
                item.note_traking,
                item.note_date,
                item.note_notaion
              )
            : null}
        </div>
        <Row
          justify="space-between"
          align="bottom"
          style={{ marginBottom: 5, marginLeft: "11%" }}
        >
          <span
            className="text-overflow-csm mt-3"
            style={{
              width: 250,
              color: "black",
              fontSize: 15,
            }}
          >
            {item.discription}
          </span>
          <Row align="middle">
            {item.user_icon?.map((children, indx) => {
              if (indx > 1) {
                if (indx === 2)
                  return (
                    <Popover
                      key={indx}
                      content={() => content(item.user_icon)}
                      title={<b>รายชื่อผู้ใช้งานทั้งหมด</b>}
                    >{`+${indx - 1}`}</Popover>
                  );
                else if (indx > 2) return null;
              }
              return (
                <Popover
                  key={indx}
                  content={() => Singlecontent(children)}
                  title={<b>ชื่อผู้ใช้งาน</b>}
                >
                  <img
                    src={children.icon || profile}
                    onError={(e: any) => {
                      onImgErrorProfile(e);
                    }}
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 30,
                      margin: "2px 2px",
                      objectFit: "cover",
                    }}
                    alt="userImage"
                  />
                </Popover>
              );
            })}
          </Row>
        </Row>
      </div>
    </div>
  );
};

const content = (value?: { icon?: string; username?: string }[]) => {
  return (
    <div style={{ height: 150, overflowY: "auto" }}>
      {value?.map((item, idx) => (
        <Row align="middle" key={idx}>
          <img
            src={item?.icon || profile}
            style={{
              width: 20,
              height: 20,
              borderRadius: 30,
              margin: "2px 5px",
              objectFit: "cover",
            }}
            onError={(e: any) => {
              onImgErrorProfile(e);
            }}
            alt="userImage"
          />
          <b className="px-1">{item?.username || ""}</b>
        </Row>
      ))}
    </div>
  );
};

const Singlecontent = (value?: { icon?: string; username?: string }) => {
  return (
    <div>
      {value && (
        <Row align="middle">
          <img
            src={value?.icon || profile}
            style={{
              width: 20,
              height: 20,
              borderRadius: 30,
              margin: "2px 5px",
              objectFit: "cover",
            }}
            onError={(e: any) => {
              onImgErrorProfile(e);
            }}
            alt="userImage"
          />
          <b className="px-1">{value?.username || ""}</b>
        </Row>
      )}
    </div>
  );
};

const CheckValueText = (text: string) => {
  return text === "DO_NOT_BUY"
    ? "ไม่ซื้อ"
    : text === "PENDING"
    ? "อยู่ระหว่างการพิจารณา"
    : text === "CLOSE_THE_SALE"
    ? "ปิดการขาย"
    : text === "CAN_NOT_TACKING"
    ? "ไม่สามารทติดตามสถานะได้"
    : text === "COMPLETED"
    ? "ได้รับการบริการเรียบร้อยเเล้ว"
    : text;
};
const CheckDiscription = (
  value?: string,
  note?: string,
  date?: string,
  notaion?: string
) => {
  if (value) {
    return (
      <Row justify="space-between" align="middle">
        <div
          style={{
            backgroundColor:
              value === "Information" ||
              value === "DO_NOT_BUY" ||
              value === "CAN_NOT_TACKING"
                ? "#fe6b0b"
                : value === "Qualify Lead" ||
                  value === "PENDING" ||
                  value === "COMPLETED"
                ? "#FFC627"
                : value === "ปิดการขาย" || value === "CLOSE_THE_SALE"
                ? "rgb(78, 222, 5)"
                : "#16BAFF",
          }}
          className="card-status-tag"
        >
          <b>{CheckValueText(value)}</b>
        </div>
        {note && (
          <Col span={24}>
            <Row
              align="middle"
              style={{
                marginTop: 5,
              }}
            >
              <img
                src={clock}
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 30,
                  marginRight: 3,
                  objectFit: "cover",
                }}
                alt="userImage"
              />
              <Row style={{ marginTop: 2 }}>
                <b style={{ fontSize: 12 }}>{note}</b>
                <b style={{ fontSize: 12, margin: "0 5px" }}>{date}</b>
              </Row>
            </Row>
            <b
              style={{
                fontSize: 12,
                marginLeft: 20,
                width: 150,
                display: "flex",
              }}
            >
              <span>หมายเหตุ</span>
              <span className="text-overflow-ms ml-1">{notaion}</span>
            </b>
          </Col>
        )}
      </Row>
    );
  } else {
    return null;
  }
};

export default Leads;

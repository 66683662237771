import { Form, Input } from "antd";
import { FloatLabel } from "../../../../../components/index";
type Props = {
  options?: any;
  label: string;
  name: string | any[];
  rules?: { required: boolean; message?: string };
  type?: "number";
  restField?: any;
  fieldKey?: any;
  isNo?: boolean;
};
const FInput = ({
  options,
  label = "",
  name,
  rules = { required: false, message: "" },
  type,
  restField,
  fieldKey,
  isNo = false,
}: Props) => {
  return (
    <>
      <Form.Item
        className="formlabel"
        name={name}
        fieldKey={fieldKey}
        {...restField}
        rules={[
          {
            required: rules && rules.required,
            message:
              rules && rules.message
                ? rules.message
                : `โปรดกรอกข้อมูล${label}!`,
          },
        ]}
      >
        <ChildrenRender
          {...options}
          label={label}
          type={type}
          name={name}
          // restField={restField}
        />
      </Form.Item>
    </>
  );
};
const ChildrenRender = (propsChild: any) => (
  <>
    <FloatLabel
      label={propsChild.label ? propsChild.label : ""}
      name={propsChild.name[1] !== "no" ? propsChild.id : propsChild.name}
      value={
        propsChild.name[1] !== "no" ? propsChild.value : propsChild.name[0] + 1
      }
    >
      <Input
        {...propsChild}
        type={propsChild.type}
        value={
          propsChild.name[1] !== "no"
            ? propsChild.value
            : propsChild.name[0] + 1
        }
      />
    </FloatLabel>
  </>
);

export default FInput;

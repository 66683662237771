import UserForm from "../../component/user_form/UserForm";

const ManageProfile = () => {
  return (
    <div>
      <UserForm />
    </div>
  );
};

export default ManageProfile;

import React from "react";
import { Card, Col, Row, Button } from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

type Props = {
  action: (value: any) => void;
  callBack?: (() => void);
};

const Header = ({ action,callBack }: Props) => {
  const history = useHistory();

  return (
    <Card className="border-header-diy">
      <Row className="page-heder">
        <div style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
          <Row gutter={12} align="middle" className="pb-2">
            <Col>
              <div className="title-menu-text">
                FAQ
              </div>
              <div className="title-message-text">
                คำถามที่พบบ่อย
              </div>
            </Col>
          </Row>
        </div>
        <Row gutter={10}>
          <Col>
            <Button
              className="btn-gray"
              type="primary"
              onClick={callBack}
            >
              <CloseOutlined />
              ยกเลิก
            </Button>
          </Col>
          <Col>
            <Button className="btn-save" type="primary" onClick={action}>
              <SaveOutlined />
              บันทึก
            </Button>
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

export default Header;

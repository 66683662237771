import { _isEmpty } from "@yim/util-tool-ts";
import { Form, Input } from "antd";
import { FloatLabel } from "..";
type Props = {
  options?: any;
  label: string;
  name: string | any[];
  rules?: { required: boolean; message?: string };
  pattern?: { pattern?: any; message?: string };
  type?: string;
  fieldKey?: any;
};
const FInput = ({
  options,
  label = "",
  name,
  rules = { required: false, message: "" },
  type = "",
  pattern = {},
  fieldKey,
}: Props) => {
  const ruleObj = {
    required: rules && rules.required,
    message: rules && rules.message ? rules.message : `โปรดกรอกข้อมูล${label}!`,
  };

  return (
    <>
      <Form.Item
        className="formlabel"
        name={name}
        fieldKey={fieldKey}
        rules={!_isEmpty(pattern) ? [ruleObj, pattern] : [ruleObj]}
      >
        <ChildrenRender {...options} label={label} type={type} name={name} />
      </Form.Item>
    </>
  );
};
const ChildrenRender = (propsChild: any) => (
  <>
    <FloatLabel
      label={propsChild.label ? propsChild.label : ""}
      name={propsChild.restField ? propsChild.id : propsChild.name}
      value={propsChild.value}
    >
      <Input {...propsChild} type={propsChild.type} />
    </FloatLabel>
  </>
);

export default FInput;

export const generateButton = ({
    text,
    Icon,
    onClick,
  }: {
    text?: string;
    Icon?: any;
    onClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
  }) => {
    return (
      <span className={text === "ยกเลิก" ? "lead-card-title-btn-cancel" : "lead-card-title-btn"} onClick={onClick}>
        {Icon && <Icon style={{ padding: "0 5px" }} />}
        {<span>{text}</span>}
      </span>
    );
  };
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { AlertToast } from "../../../components/alert/alert";
import DetailCardProject from "../../../components/DetailCardProject/DetailCardProject";
import HeaderTabMenu from "../../../components/header_tab_menu/HeaderTabMenu";
import { GET_TECHNICIAN_BY_ID_API } from "../../../services/api/Technician_api";
import { loadingChildState } from "../../../tools/recoil/recoil-atom";
import { HandleScroll } from "../../../tools/util";

const TechnicianDetail = () => {
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [dataSource, setDataSource] = useState({
    customerContact: {
      address: "",
      companyName: "",
      district: "",
      email: "",
      explainAboutBusiness: "",
      firstName: "",
      inquiryNO: "",
      lastName: "",
      nickname: "",
      phone: "",
      position: "",
      postalCode: "",
      province: "",
      specAlumet: null,
      subDistrict: "",
      line:""
    },
    action: {
      admin: "",
      dateTime: "",
      initialService: null,
      otherContact: { id: 0, name: "" },
    },
    requirement: {
      aluminiumColor: [{ id: 0, name: "" }],
      contactChannel: [{ id: 0, name: "" }],
      file: [
        {
          name: "",
          url: "",
        },
      ],
      interestedProduct: [{ id: 0, name: "" }],
      projectCategory: { id: 0, name: "" },
      projectName: "",
      requirement: null,
      requirementType: { id: 0, name: "" },
      subjectContact: [{ id: 0, name: "" }],
      userContactType: { id: 0, name: "" },
      specAlumet: [0],
    },
    note: [],
    activity: {},
    status:"",
    contactDate: "",
    date: "",
    isEdit:false
  });
  const setLoading = useSetRecoilState<boolean>(loadingChildState);

  useEffect(() => {
    GET_DATA();
    return undefined;

  }, []);

  const GoBack = () => {
    history.goBack();
  }

  const GET_DATA = async (scroll?: string) => {
    setLoading(true);
    try {
      const res = await GET_TECHNICIAN_BY_ID_API(pathID);
      if (res.code === 200) {
        const [result] = res.result;
        setDataSource({
          ...dataSource,
          customerContact: result?.customerContact,
          action: result?.action,
          requirement: {
            ...result?.requirement,
            file: result?.requirement?.file,
            specAlumet: result?.requirement?.specAlumet ? [1] : [0],
            interestedProduct:
              result?.requirement?.interestedProduct?.map(
                (item: any) => item.id
              ) || [],
          },
          activity: result?.activity,
          note: result?.note,
          status:result?.status,
          contactDate: result?.contactDate,
          date: result?.createdAt,
        });
      } else {
        AlertToast({
          icon: "error",
          title: `เกิดข้อผิดพลาด! ระบบไม่สามารถเรียกข้อมูลได้ กรุณาลองอีกครั้งในภายหลัง`,
        });
        GoBack();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      HandleScroll(scroll || "top");
    }
  };

  return (
    <div id="top">
      <HeaderTabMenu title="TECHNICIAN" message="ช่างสมาร์ท" style={{borderRadius:5}}/>
      <div className="my-3 p-5" style={{backgroundColor:'white',borderRadius:5}}>
        <DetailCardProject
          onUpdate={(data: any) => {
            //do someting
            setDataSource(data);
          }}
          onChange={GET_DATA}
          dataSource={dataSource}
          id={pathID}
          isForm={dataSource.status}
          isEdit={dataSource?.isEdit}
        />
      </div>
    </div>
  );
};

export default TechnicianDetail;

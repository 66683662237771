/* eslint-disable react-hooks/exhaustive-deps */

import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { _isEmpty } from "@yim/util-tool-ts";

import { Card, Col, Divider, Dropdown, Form, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSetRecoilState } from "recoil";
import { FormDefault } from "../../../../../components";
import { CAlertToast } from "../../../../../components/alert/CAlert";
import { tableLoading } from "../../../../../components/loading/loading";
import TableModifind from "../../../../../components/table/Table";

import {
  ISettingList,
  ISettingRequirementType,
} from "../../../../../interface/ISetting.interface";
import {
  ADDSUBJECTCONTACT_API,
  DELETESUBJECTCONTACT_API,
  EDITSUBJECTCONTACT_API,
  GETSUBJECTCONTACT_API,
} from "../../../../../services/api/setting/contact_matter_api";
import { GETREQUIREMENTTYPEALL_API } from "../../../../../services/api/setting/requirement_type_api";

import { loadingTable } from "../../../../../tools/recoil/recoil-atom";
import { HandleScroll } from "../../../../../tools/util";
import HeaderSetting from "./HerderSetting";

const ContactMatter = () => {
  const setLoading = useSetRecoilState(loadingTable);
  const history = useHistory();
  const [manageContent, setManageContent] = useState<boolean>(false);
  const [isFirstWork, setIsFirstWork] = useState<boolean>(true);
  const [valueContactMatter, setValueContactMatter] = useState<string>("");
  const [id, setId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(10);
  const [initialValueSearch, setInitialValueSearch] = useState({
    search: "",
    requirementType: null,
  });
  const [contactChanelList, setContactMatterList] = useState<ISettingList[]>(
    []
  );
  const [requirementTypeList, setRequirementTypeList] = useState<
    ISettingList[]
  >([]);
  const [on, setOn] = useState({
    id: 0,
    action: false,
  });

  const [formHeader] = Form.useForm();

  useEffect(() => {
    if (isFirstWork) {
      handleGetData();
    } else {
      handleGetContactMatter();
    }
    return undefined;

  }, [currentPage, limitPage, initialValueSearch]);

  const handleGetData = () => {
    handleGetContactMatter();
    handleGetColorTypes();
    setIsFirstWork(false);
  };

  const handleSetValue = (value: ISettingRequirementType) => {
    HandleScroll("setting-header");
    setManageContent(true);
    setId(value.id);
    setValueContactMatter(value.name);
    formHeader.setFieldsValue({
      name: value.name,
      requirementType:
        requirementTypeList.find(
          (item) => item?.name === value?.requirementType?.name
        )?.id || "",
      actionStep: value?.actionStep?.map((item: any) => {
        return { name: item?.name };
      }),
    });
  };

  const handleClickEdit = () => {
    setManageContent(!manageContent);
    setValueContactMatter("");
    setId(0);
  };

  const onFinish = (value: any) => {
    if (id !== 0) handleUpdate(value);
    else handleCreate(value);
  };

  const handleCreate = async (data: any) => {
    setLoading(true);
    try {
      const dataMock = {
        ...data,
      };
      const res = await ADDSUBJECTCONTACT_API(dataMock);
      if (res.code === 200) {
        handleClickEdit();
        formHeader.resetFields();
        CAlertToast({ method: "POST_SUCCESS", text: res.message });
        handleGetContactMatter();
      } else {
        CAlertToast({ method: "POST_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleUpdate = async (data: any) => {
    setLoading(true);
    try {
      const dataMock = {
        ...data,
      };
      const res = await EDITSUBJECTCONTACT_API({ id, data: dataMock });
      if (res.code === 200) {
        handleClickEdit();
        formHeader.resetFields();
        CAlertToast({ method: "PUT_SUCCESS", text: res.message });
        handleGetContactMatter();
      } else {
        CAlertToast({ method: "PUT_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      const res = await DELETESUBJECTCONTACT_API(id);
      if (res.code === 200) {
        CAlertToast({ method: "DEL_SUCCESS", text: res.message });
        handleClickEdit();
        formHeader.resetFields();
        handleGetContactMatter();
      } else {
        CAlertToast({ method: "DEL_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleGetColorTypes = async () => {
    setLoading(true);
    try {
      const res = await GETREQUIREMENTTYPEALL_API();
      if (res.code === 200) {
        const data = res.result[0].item;
        setRequirementTypeList(data);
      }
    } catch (error) {}
    setLoading(false);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };
  const handleSearch = (values: any) => {
    setCurrentPage(1);
    setInitialValueSearch({ ...values });
  };

  const addButton = [
    {
      type: "btnPush",
      name: "เพิ่ม",
      onClick: () => history.push(`/setting/contact-type`),
    },
  ];

  const fieldFilter = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา เรื่องที่ต้องการติดต่อ",
      initialValue: initialValueSearch.search,
    },
    {
      type: "select",
      name: "requirementType",
      placeholder: "เลือกประเภทความต้องการ",
      options: requirementTypeList,
      keyName: "name",
      keyValue: "id",
      initialValue: initialValueSearch.requirementType,
    },
  ];

  const columns: any = [
    {
      title: "ลำดับ",
      align: "center",
      width: "10%",
      render: (_value: any, _item: any, index: number) => {
        return <>{(currentPage - 1) * limitPage + (index + 1)}</>;
      },
    },
    {
      title: "เรื่องที่ต้องการติดต่อ",
      dataIndex: "name",
      key: "name",
      width: "35%",
    },
    {
      title: "ประเภทความต้องการ",
      dataIndex: "requirementType",
      key: "requirementType",
      width: "20%",
      render: (value: ISettingRequirementType) => {
        return (
          <>
            {value ? (
              value.name
            ) : (
              <span>ไม่มี</span>
            )}
          </>
        );
      },
    },
    {
      title: "ACTION STEP",
      dataIndex: "actionStep",
      key: "actionStep",
      width: "20%",
      align: "center" as "center",
      render: (value: any, row: any) => {
        return (
          <div>
            {value?.length > 0 ? (
              <Dropdown
                overlay={
                  <div className="action-step-content py-2">
                    {value?.length > 0 &&
                      value?.map((item: any, idx: number) => (
                        <div key={idx}>
                          <div className="px-2">{item?.name}</div>
                          {idx !== value.length - 1 && (
                            <Divider className="my-2" />
                          )}
                        </div>
                      ))}
                  </div>
                }
                trigger={["click"]}
              >
                <Row
                  justify="center"
                  align="middle"
                  className="cursor"
                  onClick={() =>
                    setOn({
                      id: row?.id,
                      action: !on?.action,
                    })
                  }
                >
                  <span className="mr-2" style={{ color: "#FE6B0B" }}>
                    Action Step
                  </span>
                  {on?.action && on?.id === row?.id ? (
                    <CaretUpOutlined style={{ color: "#FE6B0B" }} />
                  ) : (
                    <CaretDownOutlined style={{ color: "#FE6B0B" }} />
                  )}
                </Row>
              </Dropdown>
            ) : (
              <span style={{ color: "#FE6B0B" }}>ไม่มีข้อมูล</span>
            )}
          </div>
        );
      },
    },
    {
      title: "จัดการ",
      align: "center",
      render: (value: ISettingRequirementType) => {
        return (
          <div className="action-table">
            <EditOutlined
              onClick={() => handleSetValue(value)}
              className="faq-btn-edit"
            />
            <Popconfirm
              className="pop-faq"
              placement="top"
              title={"คุณเเน่ใจหรือไม่"}
              onConfirm={() => handleDelete(value.id)}
              okType="danger"
              okText="ลบ"
              cancelText="ยกเลิก"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              okButtonProps={{
                style: {
                  width: 50,
                },
              }}
            >
              <DeleteOutlined className="ml-3 faq-btn" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleGetContactMatter = async () => {
    setLoading(true);
    try {
      const res = await GETSUBJECTCONTACT_API({
        page: currentPage,
        limit: limitPage,
        ...initialValueSearch,
      });
      if (res.code === 200) {
        setContactMatterList(res?.result[0]?.item);
        const dataSet = res?.result[0];
        setTotalPage(dataSet?.total);
        setLimitPage(dataSet?.perpage);
      }
    } catch (error) {}

    setLoading(false);
  };
  return (
    <div id="setting-header">
      <HeaderSetting
        form={formHeader}
        Title={tiltleHeader}
        Action={addButton}
        handleClickEdit={handleClickEdit}
        handleClickCancel={handleClickEdit}
        disabledEdit={false}
        editOnPage={true}
        manageContent={manageContent}
        actionName={!_isEmpty(valueContactMatter) ? "EDIT" : "ADD"}
        onFinish={onFinish}
        requirementTypeList={requirementTypeList}
        generateField
      />
      <div className="form-tab-bg">
        <FormDefault
          HandleOnFinish={handleSearch}
          Field={fieldFilter}
          Action={[]}
        />
      </div>
      <Row>
        <Col lg={24} md={24} className="mt-3">
          <Card
            className="setting-cover-card"
            cover={
              <>
                <h1 className="mb-0">
                  <span
                    style={{
                      fontFamily: "Bold",
                      color: "#faa41a",
                      fontSize: "23px",
                    }}
                  >
                    SUBJECT TO CONTACT
                  </span>
                  <span
                    className="ml-2"
                    style={{ fontFamily: "Bold", fontSize: "23px" }}
                  >
                    เรื่องที่ต้องการติดต่อ
                  </span>
                </h1>
              </>
            }
          >
            <TableModifind
              cursor={false}
              rowKey="id"
              loading={tableLoading()}
              bordered={false}
              config={{ currentPage, total: totalPage, pageSize: limitPage }}
              columns={columns}
              dataSource={contactChanelList}
              onChangePage={onChangePage}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const tiltleHeader = {
  nameEN: "SUBJECT TO CONTACT",
  nameTH: "เรื่องที่ต้องการติดต่อ",
};

export default ContactMatter;

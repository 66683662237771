import { _isEmpty } from "@yim/util-tool-ts";
import UserDefault from "../../assets/img/default_image/user.svg";
type Props = {
  src: string;
  alt: string;
  className?: string;
  style?: any;
  defautImage?: "user" | "generalImage";
};

const CImage = ({
  src,
  alt,
  className,
  style,
  defautImage = "generalImage",
}: Props) => {
  const validateUrl = (url: string) => {
    const regex =
      /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\\/]))?/;
    if (regex.test(url)) {
      return src;
    } else if (url.startsWith("data:")) {
      return url;
    } else {
      return process.env.PUBLIC_URL + url;
    }
  };

  const findDefaultImage = () => {
    if (defautImage === "user") {
      return UserDefault;
    } else {
      return "";
    }
  };
  return (
    <>
      <img
        src={!_isEmpty(src) ? validateUrl(src) : findDefaultImage()}
        alt={alt}
        className={className}
        onError={(e: any) => {
          e.target.src! = validateUrl(findDefaultImage());
        }}
        style={style}
      />
    </>
  );
};

export default CImage;

import { Fetch, FetchExcel } from "../../tools/fecth/fecth";
import { apiVersion } from "../../config/api";

import { createQueryString } from "../../tools/util";

export const GET_PROJECT_API = async (data?: any) => {
  const result = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/project${result}`,
  });
  return res;
};

export const GET_PROJECT_FILTER_API = async (data?: any) => {
  const result = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/project/filter${result}`,
  });
  return res;
};

export const EXCEL_PROJECT_API = async (data?: any) => {
  const result = createQueryString(data);
  const res = await FetchExcel({
    method: "GET",
    path: `${apiVersion}/project/excel${result}`,
  });
  return res;
};

export const GET_PROJECT_BY_ID_API = async (id?: any) => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/inquiry/project/${id}`,
  });
  return res;
};

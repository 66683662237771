// @ts-ignore
import { Route, Switch } from "react-router-dom";
import { IRouter } from "../routes/router";
import { Col, Row, Layout, Spin } from "antd";
import { loadingState } from "../tools/recoil/recoil-atom";
import { useRecoilState } from "recoil";
import HashLoader from "react-spinners/HashLoader";

type Props = {
  children?: React.ReactNode;
  router: IRouter[];
};
const { Content } = Layout;

const Contentlayout: React.FC<Props> = ({ router }) => {
  const [loading] = useRecoilState(loadingState);
  const antIcon = <HashLoader color="#FCD901" />;

  return (
    // style={{ overflow: "auto" }}
    <Content
      className="my-content"
      style={{ marginLeft: 135, marginTop: "11vh" }}
    >
      <Spin
        className="spiner-custom"
        style={{ position: "unset" }}
        spinning={loading}
        tip="ระบบกำลังดำเนินการโปรดรอสักครู่..."
        size={"large"}
        indicator={antIcon}
      >
        <Row justify="center" style={{ paddingBottom: 30 }}>
          <Col span={23}>
            <Switch>
              {router.map((route, ind) => (
                <Route
                  exact={route.exact}
                  key={ind}
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Switch>
          </Col>
        </Row>
      </Spin>
    </Content>
  );
};

export default Contentlayout;

import { Col, Row, Card } from "antd";
type Props = {
  btColor: string;
  title: string;
  quantity: number;
  image: string;
};
const CardStatus = ({ btColor, title, quantity, image }: Props) => {
  return (
    <>
      <Col lg={6} xs={12} className="mb-1">
        <Card className={"card-dashboard " + btColor} actions={[""]}>
          <Row>
            <Col lg={12}>
              <h3>{title}</h3>
              <div className="border-bottom-5rem" />
              <div className="d-flex y-center mt-3">
                <h1
                  className="mb-0"
                  style={{ fontFamily: "Bold", fontSize: "30px" }}
                >
                  {quantity}
                </h1>

                <h4 className="ml-2 mb-0"> โครงการ </h4>
              </div>
            </Col>
            <Col lg={12} className="text-right">
              <img src={image} height={100} alt={title} />
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default CardStatus;

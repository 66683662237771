/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Col, Row, Button, Modal, Form, Checkbox, DatePicker } from "antd";
import Search from "../../../../assets/img/icon/Search.png";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
// @ts-ignore
// import Board from "react-trello";
import {
  INQUERY_GET_FORM_API,
  ADD_NEW_INQUERY_API,
  USER_FIND_LIST_API,
  CHECK_REQUIMENT,
  GET_PROVINCE,
  GET_PROVINCE_BY_ID,
  GET_DISTRICT_BY_ID,
} from "../../../../services/api/Lead_api";
import HeaderLead from "../HeaderLead";
import CreateForm, { IFormItem, EInputType } from "./CreateForm";
import FSelect from "../../../../components/form_floatlabel/FSelect";
import { Head, Time, DateNew } from "../../../../assets/img";
import moment from "moment";
import FInput from "../../../../components/form_floatlabel/FInput";
import { AlertToast } from "../../../../components/alert/alert";
import { useHistory } from "react-router";
import { RecoilRoot, useSetRecoilState } from "recoil";
import { loadingState } from "../../../../tools/recoil/recoil-atom";
import { filterUndefine } from "../../../../tools/util";
import UploadFile from "../../../../components/upload_file/UploadFile";
import { _isEmpty } from "@yim/util-tool-ts";
const dateFormat = "DD/MM/YYYY";
interface UserDataInterface {
  firstName: string | any;
  lastName: string | any;
  username: string | any;
}

type IformHeader = {
  contactDate: moment.Moment | null;
  inquiryNo: string;
};

const Leads = () => {
  const setLoading = useSetRecoilState<boolean>(loadingState);
  const [loadingWant, setLoadingWant] = useState(false);
  let history = useHistory();
  const [formFinish] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formInput, setFormInput] = useState<IformHeader>({
    contactDate: moment(),
    inquiryNo: "",
  });

  const [listItem, setListItem] = useState({
    projectList: [] as any,
    colorList: [] as any,
    interestedList: [] as any,
    contactChannelList: [] as any,
    requirementTypeList: [] as any,
    subjectContactList: [] as any,
    userContactTypeList: [] as any,
    findList: [] as any,
    userInfo: {} as any,
    findTypeList: [
      {
        name: "ชื่อ-สกุล",
        value: "user",
      },
      {
        name: "ชื่อบริษัท",
        value: "company",
      },
    ] as any,
  } as any);

  const [listProvince, setListProvince] = useState([] as any);
  const [listDistrict, setListDistrict] = useState([] as any);
  const [listSubDistrict, setListSubDistrict] = useState([] as any);
  const [itemSuccessFind, setItemSuccessFind] = useState({} as any);
  const [fieldSearch, setFieldSearch] = useState({
    typeToFind: "",
    nameTofind: "",
    checkEmptyValue: false,
  });

  const [HeaderOpen, setHeaderOpen] = useState({
    header1: false,
    header2: false,
    header3: false,
  } as any);

  const [HeaderOpenChild, setHeaderOpenChild] = useState({
    headerChild1: false,
    headerChild11: false,
    headerChild2: false,
  } as any);

  //-------------------------------------------GET DATA API-------------------------------------------

  useEffect(() => {
    HandleGetProvince();
    HandleGetAllDatalist();
    return undefined;
  }, []);

  const HandleGetProvince = async () => {
    try {
      const res = await GET_PROVINCE();
      if (res.code === 200) {
        setListProvince(res.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const HandleChangeDate = (value: moment.Moment | null) => {
    setFormInput({
      ...formInput,
      contactDate: value,
    });
  };

  const handleChangeProvince = async (data: any) => {
    setLoadingWant(true);
    try {
      const res = await GET_PROVINCE_BY_ID(data);
      if (res.code === 200) {
        formFinish.setFieldsValue({
          province: listProvince?.find((item: any) => item?.id === data)
            ?.nameTh,
        });
        setListDistrict(res?.result);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingWant(false);
  };

  const handleChangeDistrict = async (data: any) => {
    setLoadingWant(true);
    try {
      const res = await GET_DISTRICT_BY_ID(data);
      if (res.code === 200) {
        formFinish.setFieldsValue({
          district: listDistrict?.find((item: any) => item?.id === data)
            ?.nameTh,
        });
        setListSubDistrict(res?.result);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingWant(false);
  };

  const HandleChangeSubDistrict = (data: any) => {
    const result = listSubDistrict?.find((item: any) => item?.id === data);
    formFinish.setFieldsValue({
      subDistrict: result?.nameTh,
      postCode: result?.zipCode,
    });
  };

  const HandleSetValueInForm = (item: any) => {
    setIsModalVisible(false);
    setItemSuccessFind(item);
    //when user search is success it can open tab more
    setHeaderOpenChild({
      headerChild1: true,
      headerChild11: true,
    });
    formFinish.setFieldsValue({
      userId: item.userContact.userId,
      firstName: item.userContact.firstName,
      lastName: item.userContact.lastName,
      nickname: item.userContact.nickname,
      tel: item.address.phone,
      companyName: item.address.companyName,
      rank: item.userContact.position,
      address: item.address.address,
      province: item.address.province,
      postCode: item.address.postalCode,
      district: item.address.district,
      subDistrict: item.address.subDistrict,
      email: item.address.email,
      user_contact_type: item.userContact.userContactType,
      aboutBusiness: item.address.explainAboutBusiness,
      contactType: item?.contactType?.id,
    });
  };

  const HandleGetAllDatalist = async () => {
    setLoading(true);
    const userdata = localStorage.getItem("userInfo");
    let userInfo: UserDataInterface = JSON.parse(userdata || "");
    try {
      const res = await INQUERY_GET_FORM_API();
      if (res.code === 200) {
        const result = res.result[0];
        GetInitialValue(result);
        setListItem({
          ...listItem,
          projectList: result?.projectCategory || [],
          colorList: result?.aluminiumColor || [],
          interestedList: result?.interestedProduct || [],
          contactChannelList: result?.contactChannel || [],
          requirementTypeList: result?.requirementType || [],
          subjectContactList: result?.subjectContact || [],
          userContactTypeList: result?.userContactType || [],
          userInfo: userInfo || {},
        });
        setFormInput({
          ...formInput,
          inquiryNo: result?.generateInquiryNo || "",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const GetInitialValue = async (listAll: any) => {
    let term = "N/A";

    const contactType = listAll?.userContactType.find((i: any) => {
      return i.name.toLowerCase().includes(term.toLowerCase());
    });
    const projectType = listAll?.projectCategory.find((i: any) => {
      return i.name.toLowerCase().includes(term.toLowerCase());
    });
    const colorAluminium = listAll?.aluminiumColor.find((i: any) => {
      return i.name.toLowerCase().includes(term.toLowerCase());
    });
    const contactChanel = listAll?.contactChannel.find((i: any) => {
      return i.name.toLowerCase().includes(term.toLowerCase());
    });
    const interested = listAll?.interestedProduct.find((i: any) => {
      return i.nameTh.toLowerCase().includes(term.toLowerCase());
    });
    const contactSubject = listAll?.subjectContact.find((i: any) => {
      return i.name.toLowerCase().includes(term.toLowerCase());
    });
    const requirementType = listAll?.requirementType.find((i: any) => {
      return i.name.toLowerCase().includes(term.toLowerCase());
    });

    const CheckEmptyDataMultiple = (data: number) => {
      return data !== undefined ? [data] : data;
    };

    formFinish.setFieldsValue({
      typeToFind: "user",
      contactType: contactType?.id,
      projectType: projectType?.id,
      colorAluminium: CheckEmptyDataMultiple(colorAluminium?.id),
      contactChanel: CheckEmptyDataMultiple(contactChanel?.id),
      interested: CheckEmptyDataMultiple(interested?.id),
      contactSubject: CheckEmptyDataMultiple(contactSubject?.id),
      requirementType: requirementType?.id,
      projectName: "N/A",
    });
  };

  const handleOnSearch = async () => {
    setLoading(true);
    try {
      const nameTofind = formFinish.getFieldValue("nameTofind");
      const typeToFind = formFinish.getFieldValue("typeToFind");
      const dataToSearch = {
        filter: typeToFind,
        search: nameTofind,
      };
      const userFind = await USER_FIND_LIST_API(dataToSearch);
      if (userFind.code === 200) {
        showModal();
        if (userFind.result.length !== 0) {
          const result = userFind.result.flat(Infinity);
          setListItem({
            ...listItem,
            findList: result,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ไม่พบข้อมูล! กรุณาลองใหม่อีกครั้ง`,
          });
        }
      } else {
        AlertToast({
          icon: "error",
          title: `ไม่พบข้อมูล! กรุณาลองใหม่อีกครั้ง`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const convertJsonToFormData = (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "fileUpload") {
        data[key].forEach((item: any, idx: number) => {
          formData.append(key, data[key][idx] || "");
        });
      } else {
        if (typeof data[key] === "object") {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }
    });
    return formData;
  };

  const onFinish = async (value: any) => {
    setLoading(true);
    const my_data = {
      contactDate: formInput?.contactDate?.valueOf() || "",
      fileUpload: value?.file_upload
        ?.filter((item: any) => item.originFileObj)
        ?.map((child: any) => child.originFileObj),
      companyId: itemSuccessFind?.address?.companyId,
      userId: itemSuccessFind?.userContact?.userId,
      specAlumet: value?.specAlumet ? "TRUE" : "FALSE",
      customerContact: {
        firstName: value?.firstName || "",
        lastName: value?.lastName || "",
        nickname: value?.nickname || "",
        position: value?.rank || "",
        socialName: value?.socialName || "",
      },
      address: {
        companyName: value?.companyName || "",
        phone: value?.tel || "",
        email: value?.email || "",
        address: value?.address || "",
        subDistrict: value?.subDistrict || "",
        district: value?.district || "",
        province: value?.province || "",
        postalCode: value?.postCode || "",
        explainAboutBusiness: value?.aboutBusiness || "",
        line: value?.line || "",
      },
      requirement: {
        userContactType: value?.contactType || "",
        projectCategory: value?.projectType || "",
        aluminiumColor: value?.colorAluminium || "",
        projectName: value?.projectName || "",
        subjectContact: value?.contactSubject || "",
        requirementType: value?.requirementType || "",
        interestedProduct: value?.interested || [],
        contactChannel: value.contactChanel || "",
        requirement: value?.customerRequest || "",
      },
      initialService: value.initialService || "",
      otherContact: value.otherContact || "",
    };

    const dataFinish = filterUndefine(my_data);
    try {
      const convertFormData = convertJsonToFormData(dataFinish);
      const answer = await ADD_NEW_INQUERY_API(convertFormData);
      if (answer.code === 200) {
        AlertToast({
          icon: "success",
          title: `เพิ่มข้อมูลสำเร็จ`,
        });
        history.push("/leads");
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! เพิ่มข้อมูลไม่สำเร็จ ${answer.message} กรุณาลองอีกครั้ง`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const HeaderCollape = ({
    title,
    onChange,
    keyItem,
  }: {
    title: string;
    onChange?: boolean;
    keyItem: string;
  }) => {
    return (
      <Col
        span={24}
        style={{
          backgroundColor: "#DDDEE3",
          padding: "15px 15px",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          marginBottom: onChange ? 0 : 15,
          cursor: "pointer",
          transition: "all 3ms",
        }}
        onClick={() =>
          setHeaderOpen({
            ...HeaderOpen,
            [`${keyItem}`]: !onChange,
          })
        }
      >
        <Row justify="space-between" align="middle">
          <Col style={{ color: "black", fontWeight: "bold", fontSize: "16px" }}>
            {title}
          </Col>
          <Button
            className="btn-vsb-header"
            icon={
              HeaderOpen[`${keyItem}`] ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownOutlined />
              )
            }
            onClick={() =>
              setHeaderOpen({
                ...HeaderOpen,
                [`${keyItem}`]: !onChange,
              })
            }
          />
        </Row>
      </Col>
    );
  };

  const HeaderChildCollape = ({
    onChange,
    keyItem,
  }: {
    onChange?: boolean;
    keyItem: string;
  }) => {
    return (
      <Row justify="end" align="middle" className="my-2">
        <Col style={{ color: "black", fontWeight: "bold", fontSize: "16px" }}>
          {!HeaderOpenChild[`${keyItem}`] ? "เพิ่มเติม" : "ปิด"}
        </Col>
        <Button
          className="btn-header-collape mx-2"
          style={{
            backgroundColor: "#EFEFEF",
            paddingTop: "2px",
          }}
          icon={
            HeaderOpenChild[`${keyItem}`] ? (
              <CaretUpOutlined />
            ) : (
              <CaretDownOutlined />
            )
          }
          size={"small"}
          onClick={() =>
            setHeaderOpenChild({
              ...HeaderOpenChild,
              [`${keyItem}`]: !onChange,
            })
          }
        />
      </Row>
    );
  };
  //-------------------------------------------GET DATA API-------------------------------------------
  const FormInquery = () => (
    <div
      style={{ backgroundColor: "white", borderRadius: 10 }}
      className="inquery-form"
    >
      <div
        style={{
          borderBottom: "1px solid lightgray",
          fontWeight: "bold",
          fontSize: 20,
          color: "black",
          padding: "15px 25px",
        }}
      >
        กรอกข้อมูลความต้องการของลูกค้า
      </div>
      <div style={{ margin: "15px 20px" }}>
        <Form
          form={formFinish}
          name="submit"
          onFinish={onFinish}
          scrollToFirstError
        >
          <HeaderCollape
            title="CUSTOMER CONTACT | ข้อมูลการติดต่อลูกค้า"
            onChange={HeaderOpen.header1}
            keyItem={"header1"}
          />
          {HeaderOpen.header1 && (
            <div className="layout-detail-form">
              <Row gutter={15}>
                <Col lg={8}>
                  <p style={{ fontWeight: 600 }}>INQUIRY NO.</p>
                  <h3>{formInput.inquiryNo}</h3>
                </Col>
                <Col lg={9}>
                  <p style={{ fontWeight: 600 }}>วันที่ | เวลา</p>
                  <h3>{moment().format("DD/MM/YYYY | h:mm:ss")}</h3>
                </Col>
                <Col lg={7}>
                  <div>
                    <p style={{ marginBottom: 0, fontWeight: 600 }}>
                      วันที่ติดต่อ
                    </p>
                    <DatePicker
                      style={{
                        width: "100%",
                        height: "50px",
                        marginBottom: "15px",
                      }}
                      value={formInput?.contactDate}
                      format={dateFormat}
                      allowClear={false}
                      placeholder="เลือกวันที่ติดต่อ"
                      onChange={HandleChangeDate}
                      suffixIcon={
                        <img
                          src={DateNew}
                          style={{ width: "25px" }}
                          alt="pdf-btn"
                        />
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col span={4}>
                  <FSelect
                    label="ประเภท"
                    name="typeToFind"
                    dataSource={listItem.findTypeList}
                    keyName="name"
                    keyValue="value"
                    rules={{ required: false }}
                  />
                </Col>
                <Col span={20}>
                  <Row gutter={15}>
                    <Col lg={20}>
                      <FInput
                        label="ป้อนชื่อที่ต้องการค้นหา"
                        name="nameTofind"
                        rules={{ required: false }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setFieldSearch({
                            ...fieldSearch,
                            checkEmptyValue: true,
                          });
                          handleOnSearch();
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                          fontSize: "18px",
                          marginBottom: "15px",
                          background: "#4D4D4D",
                          border: "unset",
                        }}
                      >
                        <img
                          className="black-to-white"
                          src={Search}
                          style={{ width: "25px", marginRight: "10px" }}
                          alt="pdf-btn"
                        />
                        ค้นหา
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={30} align="middle" className="mt-2 mb-2">
                <Col span={12}>
                  <FInput
                    label="ชื่อบัญชีโซเชียลมีเดีย"
                    name="socialName"
                    rules={{ required: false }}
                    options={{
                      style: { width: "100%" },
                      disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
                    }}
                  />
                </Col>
              </Row>
              <CreateForm field={field1} />
              <HeaderChildCollape
                onChange={HeaderOpenChild.headerChild1}
                keyItem={"headerChild1"}
              />
              {HeaderOpenChild.headerChild1 && (
                <>
                  <CreateForm field={field1_child} />
                  <Row gutter={30} align="middle" className="mt-2 mb-2">
                    <Col span={12}>
                      <FSelect
                        label={"จังหวัด"}
                        name={"province"}
                        dataSource={listProvince}
                        rules={{ required: false }}
                        options={{
                          style: { width: "100%" },
                          loading: loadingWant,
                          disabled:
                            !_isEmpty(itemSuccessFind?.userContact) && true,
                          //itemSuccessFind?.address?.province
                        }}
                        onChange={handleChangeProvince}
                        keyName="nameTh"
                        keyValue="id"
                      />
                    </Col>
                    <Col span={12}>
                      <FSelect
                        label={"อำเภอ"}
                        name={"district"}
                        dataSource={listDistrict}
                        rules={{ required: false }}
                        options={{
                          style: { width: "100%" },
                          loading: loadingWant,
                          disabled:
                            (_isEmpty(formFinish?.getFieldValue("province")) &&
                              true) ||
                            (!_isEmpty(itemSuccessFind?.userContact) && true),
                        }}
                        onChange={handleChangeDistrict}
                        keyName="nameTh"
                        keyValue="id"
                      />
                    </Col>
                  </Row>
                  <Row gutter={30} align="middle" className="my-3">
                    <Col span={12}>
                      <FSelect
                        label={"ตำบล"}
                        name={"subDistrict"}
                        dataSource={listSubDistrict}
                        rules={{ required: false }}
                        options={{
                          style: { width: "100%" },
                          loading: loadingWant,
                          disabled:
                            (_isEmpty(formFinish?.getFieldValue("district")) &&
                              true) ||
                            (!_isEmpty(itemSuccessFind?.userContact) && true),
                        }}
                        onChange={HandleChangeSubDistrict}
                        keyName="nameTh"
                        keyValue="id"
                      />
                    </Col>
                    <Col span={12}>
                      <FInput
                        label="รหัสไปรษณีย์"
                        name="postCode"
                        rules={{ required: false }}
                        options={{
                          style: { width: "100%" },
                          disabled:
                            !_isEmpty(itemSuccessFind?.userContact) && true,
                        }}
                      />
                    </Col>
                  </Row>
                  <HeaderChildCollape
                    onChange={HeaderOpenChild.headerChild11}
                    keyItem={"headerChild11"}
                  />
                  {HeaderOpenChild.headerChild11 && (
                    <CreateForm field={field11_child} />
                  )}
                </>
              )}
            </div>
          )}
          <HeaderCollape
            title="REQUIREMENT | ความต้องการลูกค้า"
            onChange={HeaderOpen.header2}
            keyItem={"header2"}
          />
          {HeaderOpen.header2 && (
            <div className="layout-detail-form">
              <CreateForm field={field2} />
              <Row gutter={30} align="middle" className="my-2">
                <Col span={12}>
                  <FSelect
                    label={"เรื่องที่ต้องการติดต่อ"}
                    name={"contactSubject"}
                    dataSource={listItem.subjectContactList}
                    rules={{ required: true }}
                    options={{
                      style: { width: "100%" },
                      mode: "multiple",
                      loading: loadingWant,
                    }}
                    onChange={handleChange}
                    keyName="name"
                    keyValue="id"
                  />
                </Col>
                <Col span={12}>
                  <FSelect
                    label={"ประเภทความต้องการ"}
                    name={"requirementType"}
                    dataSource={listItem.requirementTypeList}
                    rules={{ required: true }}
                    options={{
                      style: { width: "100%" },
                      loading: loadingWant,
                    }}
                    keyName="name"
                    keyValue="id"
                  />
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="top"
                gutter={15}
                className="mt-3 mb-2"
              >
                <Col span={20}>
                  <UploadFile name="file_upload" />
                </Col>
                <Col>
                  <Form.Item
                    name={"specAlumet"}
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox>สเปคอลูเม็ท</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <HeaderChildCollape
                onChange={HeaderOpenChild.headerChild2}
                keyItem={"headerChild2"}
              />
              {HeaderOpenChild.headerChild2 && (
                <CreateForm field={field2_child} />
              )}
            </div>
          )}
          <HeaderCollape
            title="ACTION | การให้บริการเบื้องต้น"
            onChange={HeaderOpen.header3}
            keyItem="header3"
          />
          {HeaderOpen.header3 && (
            <div className="layout-detail-form">
              <Row
                gutter={15}
                justify="space-between"
                align="middle"
                style={{ marginBottom: "10px" }}
              >
                <Col>
                  <Row justify="space-between" align="middle">
                    <img src={Head} style={{ width: "30px" }} alt="Row" />
                    <span className="pl-2 pr-2">ผู้ให้บริการ</span>
                    <span style={{ color: "black" }}>
                      {listItem.userInfo.firstName} {listItem.userInfo.lastName}
                    </span>
                  </Row>
                </Col>
                <Col>
                  <Row justify="space-between" align="middle">
                    <img src={Time} style={{ width: "30px" }} alt="Row" />
                    <span className="pl-2 pr-2">วันที่เวลา</span>
                    <span style={{ color: "black" }}>
                      {moment().add(543, "year").format("DD/MM/YYYY h:mm:ss")}
                    </span>
                  </Row>
                </Col>
              </Row>
              <CreateForm field={field3} />
            </div>
          )}
          <Row
            gutter={15}
            justify="end"
            align="bottom"
            style={{ padding: "15px 0" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ height: "50px", width: "200px" }}
            >
              SUBMIT
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );

  const ModalListItem = () => (
    <Modal
      title={
        <div style={{ padding: "5px 0px 0px 0px" }}>
          <h3 style={{ color: "black", fontWeight: "bold" }}>ชื่อผู้ติดต่อ</h3>
        </div>
      }
      open={isModalVisible}
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}
      width={500}
      footer={null}
    >
      <div className="card-find-list">
        {listItem.findList.map((item: any, idx: number) => {
          return (
            <div
              key={idx}
              className="item-name-find-list"
              onClick={() => HandleSetValueInForm(item)}
            >
              <Row
                style={{
                  padding: 10,
                  color: "black",
                  fontSize: 16,
                }}
                align="middle"
              >
                <Col
                  span={12}
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    paddingRight: 10,
                  }}
                >
                  {item.userContact.firstName}
                </Col>
                <Col
                  span={12}
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    paddingRight: 10,
                  }}
                >
                  {item.userContact.lastName}
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </Modal>
  );

  const handleChange = async (data: any) => {
    const resultitem = listItem.subjectContactList?.find(
      (item: any) => item?.id === data[data.length - 1]
    );
    setLoadingWant(true);
    let query = "";
    Object.entries(data).forEach(([key, value], index) => {
      if (index !== data.length - 1) {
        if (value || value === 0 || value === false) {
          query += `${value}%2C`;
        }
      } else {
        query += `${value}`;
      }
    });

    let subjectContact = "?ids=" + query;
    try {
      const res = await CHECK_REQUIMENT(subjectContact);
      if (res.code === 200) {
        formFinish.setFieldsValue({ requirementType: res?.result[0]?.id });
        if (resultitem?.actionStep) {
          setListItem({
            ...listItem,
            subjectContactList: listItem.subjectContactList?.filter(
              (item: any) =>
                item?.actionStep === false || item?.id === data[data.length - 1]
            ),
            requirementTypeList: res?.result,
          });
        } else {
          setListItem({ ...listItem, requirementTypeList: res?.result });
        }
      } else {
        AlertToast({
          icon: "error",
          title: `ไม่พบข้อมูล! กรุณาลองใหม่อีกครั้ง`,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingWant(false);
    }
  };

  const field1: IFormItem[] = [
    {
      type: EInputType.INPUT,
      fieldProps: {
        label: "ชื่อจริง",
        name: "firstName",
        rules: {
          required: false,
        },
        options: {
          style: { width: "100%" },
          disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.INPUT,
      fieldProps: {
        label: "นามสกุล",
        name: "lastName",
        rules: {
          required: false,
        },
        options: {
          style: { width: "100%" },
          disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.INPUT,
      fieldProps: {
        label: "ชื่อเล่น",
        name: "nickname",
        rules: {
          required: false,
        },
        options: {
          style: { width: "100%" },
          disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.INPUT,
      fieldProps: {
        label: "เบอร์โทรศัพท์",
        name: "tel",
        rules: { required: itemSuccessFind?.address?.phone && !true },
        options: {
          style: { width: "100%" },
          disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.INPUT,
      fieldProps: {
        label: "อีเมล",
        name: "email",
        rules: { required: false },
        options: {
          style: { width: "100%" },
          disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.INPUT,
      fieldProps: {
        label: "ไลน์",
        name: "line",
        rules: { required: false },
        options: {
          style: { width: "100%" },
          disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
        },
      },
      colProps: { span: 12 },
    },
  ];

  const field1_child: IFormItem[] = [
    {
      type: EInputType.INPUT,
      fieldProps: {
        label: "ชื่อบริษัท",
        name: "companyName",
        rules: { required: false },
        options: {
          style: { width: "100%" },
          disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.INPUT,
      fieldProps: {
        label: "ตำเเหน่งงาน หรือ เเผนก",
        name: "rank",
        rules: { required: false },
        options: {
          style: { width: "100%" },
          disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.INPUT,
      fieldProps: {
        label: "ที่อยู่",
        name: "address",
        rules: { required: false },
        options: {
          style: { width: "100%" },
          disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
        },
      },
      colProps: { span: 24 },
    },
  ];

  const field11_child: IFormItem[] = [
    {
      type: EInputType.TEXTAREA,
      fieldProps: {
        label: "อธิบายเกี่ยวกับธุรกิจ",
        name: "aboutBusiness",
        rules: { required: false },
        options: {
          style: { width: "100%" },
          rows: 4,
          disabled: !_isEmpty(itemSuccessFind?.userContact) && true,
        },
      },
      colProps: { span: 24 },
    },
  ];

  const field2: IFormItem[] = [
    {
      type: EInputType.SELECT,
      fieldProps: {
        label: "ประเภทผู้ติดต่อ",
        name: "contactType",
        dataSource: listItem.userContactTypeList || [],
        keyValue: "id",
        keyName: "name",
        rules: { required: true },
        options: {
          style: { width: "100%" },
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.SELECT,
      fieldProps: {
        label: "ประเภทโครงการ",
        name: "projectType",
        dataSource: listItem.projectList || [],
        keyValue: "id",
        keyName: "name",
        rules: { required: true },
        options: {
          style: { width: "100%" },
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.SELECT,
      fieldProps: {
        label: "สีอลูมิเนียม",
        name: "colorAluminium",
        dataSource: listItem.colorList || [],
        keyValue: "id",
        keyName: "name",
        rules: { required: true },
        options: {
          style: { width: "100%" },
          mode: "multiple",
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.SELECT,
      fieldProps: {
        label: "ช่องทางการติดต่อ",
        name: "contactChanel",
        dataSource: listItem.contactChannelList || [],
        keyValue: "id",
        keyName: "name",
        rules: { required: true },
        options: {
          style: { width: "100%" },
          mode: "multiple",
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.INPUT,
      fieldProps: {
        label: "ชื่อโครงการ",
        name: "projectName",
        rules: { required: true },
        options: {
          style: { width: "100%" },
        },
      },
      colProps: { span: 12 },
    },
    {
      type: EInputType.SELECT,
      fieldProps: {
        label: "สินค้าที่สนใจ",
        name: "interested",
        dataSource: listItem.interestedList || [],
        keyValue: "id",
        keyName: "nameTh",
        rules: { required: true },
        options: {
          style: { width: "100%" },
          mode: "multiple",
        },
      },
      colProps: { span: 12 },
    },
  ];

  const field2_child: IFormItem[] = [
    {
      type: EInputType.TEXTAREA,
      fieldProps: {
        label: "ความต้องการของลูกค้า",
        name: "customerRequest",
        rules: { required: false },
        options: {
          style: { width: "100%" },
          rows: 4,
          disabled: false,
        },
      },
      colProps: { span: 24 },
    },
  ];

  const field3: IFormItem[] = [
    {
      type: EInputType.TEXTAREA,
      fieldProps: {
        label: "การดำเนินการ",
        name: "initialService",
        options: {
          style: { width: "100%" },
          rows: 4,
        },
      },
      colProps: { span: 24 },
    },
    {
      type: EInputType.SELECT,
      fieldProps: {
        label: "เเจ้งลูกค้าติดต่อต่อช่องทางอื่น",
        name: "otherContact",
        dataSource: listItem.contactChannelList,
        keyValue: "id",
        keyName: "name",
        options: {
          style: { width: "100%" },
        },
      },
      colProps: { span: 12 },
    },
  ];

  return (
    <RecoilRoot>
      <Row className="h-100 min-vh-100">
        <Col span={24}>
          <HeaderLead />
          <FormInquery />
          {ModalListItem()}
        </Col>
      </Row>
    </RecoilRoot>
  );
};

export default Leads;

import { atom, } from 'recoil'

export const loadingState = atom({
    key: 'loading',
    default: false
})

export const loadingChildState = atom({
    key: 'loadingSomeContent',
    default: false
})

export const loadingTable = atom({
    key: 'loadingTable',
    default: false
})

export const userTabState = atom({
    key: 'userTab',
    default: 1
})



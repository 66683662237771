/* eslint-disable react-hooks/exhaustive-deps */
import { CheckOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Popover, Row } from "antd";
import { useState } from "react";
import { profile } from "../../assets/img";
import { onImgErrorProfile } from "../../tools/util";
import { generateButton } from "../../components/button_generate";
import UploadFileLead from "../upload_file/UploadFileLead";

type Props = {
  image?: {
    src: string;
    name: string;
  };
  activityId?: number;
  onFinish: (value: any) => void;
  onSuccess?: () => void;
  postBtn?: boolean;
  saveBtn?: boolean;
};

const InputPickerFile = ({
  onFinish,
  activityId,
  image = { src: profile, name: "" },
  postBtn = true,
  saveBtn = false,
  onSuccess,
}: Props) => {
  const [showPop, setShowPop] = useState<boolean>(false);
  const [form] = Form.useForm();

  const Onfinish = (value: any) => {
    onFinish({
      files: value.file?.filter((item: any) => item.originFileObj) || [],
      text: value.text,
      activityId: activityId,
    });
  };

  const OnSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const saveButton = generateButton({
    Icon: SaveOutlined,
    text: "บันทึก",
    onClick: () => form.submit(),
  });

  const successButton = generateButton({
    text: "เสร็จสิ้น",
    Icon: CheckOutlined,
    onClick: () => setShowPop(!showPop),
  });

  const contentSuccessPop = () => {
    return (
      <Row justify="space-between" align="middle">
        <Button onClick={() => setShowPop(false)}>ยกเลิก</Button>
        <Button
          type="primary"
          onClick={() => (saveBtn ? OnSuccess() : "")}
          style={{ width: 90, padding: 5 }}
          className="ml-2"
        >
          เสร็จสิ้น
        </Button>
      </Row>
    );
  };

  return (
    <Form form={form} onFinish={Onfinish}>
      <Row align="top" className="my-3" justify="center">
        <img
          src={image.src}
          onError={(e: any) => {
            onImgErrorProfile(e);
          }}
          alt={image.name}
          width={30}
          height={30}
          className="mx-2 img-item-note"
        />
        <Col span={22}>
          <div style={{ border: "1px solid #CCCCCC", borderRadius: "5px" }}>
            <Row
              justify="space-between"
              align="middle"
              className="p-2"
              style={{
                backgroundColor: "#F6F7F8",
                borderRadius: "5px 5px 0 0",
              }}
            >
              <span>กรอกรายละเอียด</span>
            </Row>
            <Form.Item
              name="text"
              rules={[{ required: true, message: "!กรุณาป้อนข้อความ" }]}
              className="px-2"
            >
              <Input.TextArea
                bordered={false}
                autoSize={{ minRows: 5, maxRows: 5 }}
                className="p-1"
              />
            </Form.Item>
            <UploadFileLead name="file" />
          </div>
          <Row align="middle" className="pt-3">
            <Col span={24} style={{ textAlign: "end" }}>
              {postBtn && (
                <Button
                  style={{
                    backgroundColor: "#7070702E",
                    width: 100,
                    fontWeight: "bold",
                  }}
                  htmlType="submit"
                >
                  โพสต์
                </Button>
              )}
              {saveBtn && (
                <>
                  {saveButton}
                  <Popover
                    placement="top"
                    title={"คุณเเน่ใจหรือไม่!"}
                    content={contentSuccessPop}
                    trigger="click"
                    open={showPop}
                  >
                    {successButton}
                  </Popover>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default InputPickerFile;

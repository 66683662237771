/* eslint-disable react-hooks/exhaustive-deps */

import { Card, Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { FormDefault } from "../../../../../components";
import { CAlertToast } from "../../../../../components/alert/CAlert";
import { tableLoading } from "../../../../../components/loading/loading";
import TableModifind from "../../../../../components/table/Table";
import {
  EDITPROJECTTRANSFER,
  GETPROJECTTRANSFER,
} from "../../../../../services/api/setting/project_transfer";
import { GETUSERALLDATA_API } from "../../../../../services/api/setting/usermanagement_api";

import { loadingTable } from "../../../../../tools/recoil/recoil-atom";
import HeaderProjectTransfer from "./HeaderProjectTransfer";

const ProjectTransfer = () => {
  const setLoading = useSetRecoilState(loadingTable);
  const [isActioned, setIsActioned] = useState<boolean>(false);
  const [isFirstWork, setIsFirstWork] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(10);
  const [initialValueSearch, setInitialValueSearch] = useState({
    admin: null,
  });
  const [projectTransferList, setProjectTransferList] = useState<any[]>([]);
  const [adminList, setAdminList] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [filteredResponsiblePerson, setFilteredResponsiblePerson] = useState<
    any[]
  >([]);
  const [form] = Form.useForm();
  useEffect(() => {
    handleGetData();
    if (isFirstWork) {
      handleGetUserAdmin();
    }
    setIsFirstWork(false);
    return undefined;

  }, [currentPage, limitPage, initialValueSearch]);

  const handleGetData = () => {
    handleGetProjectTransfer();
  };

  const handleClickAction = () => {
    setIsActioned(!isActioned);
    if (!isActioned) {
      setProjectTransferList([]);
    } else {
      handleGetProjectTransfer();
    }
    form.resetFields();
  };

  const onFinish = (values: any) => {
    handleUpdate(values);
    handleClickAction();
  };

  const handleUpdate = async (data: any) => {
    setLoading(true);
    const dataFormat = {
      projectId: selectedRowKeys,
      ...data,
    };

    try {
      const res = await EDITPROJECTTRANSFER(dataFormat);
      if (res.code === 200) {
        handleClickAction();
        form.resetFields();
        CAlertToast({ method: "TRANSFER_SUCCESS", text: res.message });
        handleGetProjectTransfer();
      } else {
        CAlertToast({ method: "TRANSFER_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };
  const handleSearch = (values: any) => {
    setCurrentPage(1);
    setInitialValueSearch({ ...values });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKey: any[]) => {
      setSelectedRowKeys(rowKey);
    },
  };

  const fieldFilter = [
    {
      type: "select",
      name: "admin",
      options: adminList,
      keyName: "firstName",
      keyName2: "lastName",
      keyValue: "id",
      placeholder: "ค้นหา ผู้รับผิดชอบ",
      initialValue: initialValueSearch.admin,
    },
  ];

  const columns: any = [
    {
      title: "ลำดับ",
      align: "center",
      width: "10%",
      render: (_value: any, _item: any, index: number) => {
        return <>{(currentPage - 1) * limitPage + (index + 1)}</>;
      },
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: "ชื่อบริษัท",
      dataIndex: "companyName",
      key: "companyName",
      render: (value: any) => {
        return (
          <>
            {value ? (
              value
            ) : (
              <span style={{ color: "lightgrey" }}>ไม่มีข้อมูล</span>
            )}
          </>
        );
      },
    },

    {
      title: "ประเภทลูกค้า",
      dataIndex: "user",
      key: "user",
      render: (value: any) => {
        return (
          <>
            {value.userContactType ? (
              value.userContactType
            ) : (
              <span style={{ color: "lightgrey" }}>ไม่มีข้อมูล</span>
            )}
          </>
        );
      },
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "user",
      key: "user",
      render: (value: {
        firstName: string;
        lastName: string;
        nickname: string;
        userContactType: "";
      }) => {
        return (
          <>
            {value.firstName !== "" ? (
              <>
                {value.firstName} {value.lastName}
              </>
            ) : value.nickname ? (
              <>{value.nickname}</>
            ) : (
              <span style={{ color: "lightgrey" }}>ไม่มีข้อมูล</span>
            )}
          </>
        );
      },
    },
  ];
  const handleGetUserAdmin = async () => {
    setLoading(true);
    try {
      const res = await GETUSERALLDATA_API();
      if (res.code === 200) {
        setAdminList(res.result[0].item);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleGetProjectTransfer = async (id?: number) => {
    setLoading(true);
    try {
      const filterOnlyAdmin = { admin: id };
      const filterDefault = {
        ...initialValueSearch,
        page: currentPage,
        limit: limitPage,
      };
      const res = await GETPROJECTTRANSFER(
        id !== undefined ? filterOnlyAdmin : filterDefault
      );

      if (res.code === 200) {
        setProjectTransferList(res.result[0].item);

        const dataSet = res.result[0];
        setTotalPage(dataSet.total);
        setLimitPage(dataSet.perpage);
      }
    } catch (error) {}

    setLoading(false);
  };

  const handleChageFirstResponsiblePerson = (id: any) => {
    setLoading(true);
    const filterAdmin = adminList.filter((item) => item.id !== id);
    setFilteredResponsiblePerson(filterAdmin);
    setLoading(false);
    handleGetProjectTransfer(id);
  };

  return (
    <div>
      <HeaderProjectTransfer
        rowSelectLength={selectedRowKeys.length}
        isActioned={isActioned}
        handleClickAction={handleClickAction}
        title={{ nameEn: "PROJECT TRANSFER", nameTh: "โอนย้ายโครงการ" }}
        handleSubmit={onFinish}
        form={form}
        adminList={adminList}
        handleChageFirstResponsiblePerson={handleChageFirstResponsiblePerson}
        filteredResponsiblePerson={filteredResponsiblePerson}
      />
      <div className="form-tab-bg">
        {!isActioned && (
          <FormDefault
            HandleOnFinish={handleSearch}
            Field={fieldFilter}
            Action={[]}
          />
        )}
      </div>
      <Row>
        <Col lg={24} md={24} className="mt-3">
          <Card
            className="setting-cover-card"
            cover={
              <>
                <h1 className="mb-0">
                  <span
                    style={{
                      fontFamily: "Bold",
                      color: "#faa41a",
                      fontSize: "23px",
                    }}
                  >
                    PROJECT TRANSFER
                  </span>
                  <span
                    className="ml-2"
                    style={{ fontFamily: "Bold", fontSize: "23px" }}
                  >
                    โอนย้ายโครงการ
                  </span>
                </h1>
              </>
            }
          >
            <TableModifind
              rowSelection={isActioned ? rowSelection : null}
              cursor={true}
              rowKey="id"
              loading={tableLoading()}
              bordered={false}
              config={{ currentPage, total: totalPage, pageSize: limitPage }}
              columns={columns}
              dataSource={projectTransferList}
              onChangePage={onChangePage}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectTransfer;

import { apiURL } from '../../config/api'
import {
  fecthConfig, fecthConFormdata
} from './interface'

export const Fetch = async (fecthConfig: fecthConfig) => {
  try {
    const token = localStorage.getItem("token");
    const url = `${apiURL}${fecthConfig.path}`;
    const res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: fecthConfig.method,
      body: fecthConfig.method !== "GET" ? JSON.stringify(fecthConfig.data) : null,
    });
    const _data = await res.json();
    if (_data.code === 401) {
      localStorage.clear();
      localStorage.setItem("session", "true");
      let url: string = window.location.origin;
      window.location.replace(url + "/login")
    }
    return _data;
  } catch (err: any) {
    console.log(err, "err");
    return { success: false, message: err.message };
  }
};

export const FetchFormData = async (fecthConfig: fecthConFormdata) => {
  try {
    const token = localStorage.getItem("token");
    const url = `${apiURL}${fecthConfig.path}`;

    const res = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
      method: fecthConfig.method,
      body: fecthConfig.data
    });
    const _data = await res.json();
    return _data;
  } catch (err: any) {
    return { success: false, message: err.message };
  }
};

export const FetchExcel = async (fecthConfig: fecthConfig) => {
  try {
    const token = localStorage.getItem("token")
    const url = `${apiURL}${fecthConfig.path}`;
    const res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: fecthConfig.method || "POST",
      body: fecthConfig.data ? JSON.stringify(fecthConfig.data) : null,
    });
    const _data = await parseResponse(res);
    return _data;
  } catch (err: any) {
    return { success: false, message: err.message };
  }
};

const parseResponse = async (res: any) => {
  if (res.ok) {
    const _res = await res.blob();
    return { success: true, data: _res };
  } else {
    const _res = await res.json();
    return { success: false, message: _res.message };
  }
};

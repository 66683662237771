/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import HeaderTab from "../header_tab/HeaderTab";
import FromDefault from "../../../../../components/FormDefalut";
import { Card, Row, Table } from "antd";
import {
  CUSTOMER_SUB_REPORT_AO_AE,
  CUSTOMER_GET_DATA_ADMIN_FILTER_API,
  CUSTOMER_SUB_REPORT_AO_AE_EXCEL_API,
} from "../../../../../services/api/customer_api";
import { _isEmpty } from "@yim/util-tool-ts";
import Swal from "sweetalert2";
import { useSetRecoilState } from "recoil";
import { loadingTable } from "../../../../../tools/recoil/recoil-atom";
import { tableLoading } from "../../../../../components/loading/loading";
import moment from "moment";
import { _numToDate } from "../../../../../tools/util";

interface IReportAoAeInitial {
  cascarder: any[];
  date: moment.Moment[];
}

const SubReportAoAe = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [dataSource, setDataSource] = useState([] as any);
  const [list, setList] = useState([] as any);
  const [initialValue, setInitialValue] = useState<IReportAoAeInitial>({
    cascarder: [],
    date: [],
  });
  const [dataUserInfo, setDataUserInfo] = useState({
    dateTime: "",
    aE: "",
    aO: "",
  });

  const field = [
    {
      type: "selectCuscader",
      name: "cascarder",
      placeholder: "กรุณาเลือกเเอดมิน",
      options: list,
      initialValue: initialValue.cascarder,
    },
    {
      type: "rangepicker",
      name: "date",
      initialValue: initialValue.date,
    },
  ];

  const action = [
    {
      type: "btnExport",
      onClick: () => handleGetExcelAPI(),
    },
  ];

  useEffect(() => {
    HandleGetDataAdminList();
    return undefined;

  }, []);

  const RefacterOptionValueCascader = (arrValue: []) => {
    const result = arrValue?.map((item: any, index: number) => {
      return {
        value: item.abbreviation,
        label: item.abbreviation,
        children: item?.admin?.map((child: any, idx: number) => {
          return {
            value: child.id,
            label: child.name,
          };
        }),
      };
    });
    return result;
  };

  const HandleGetDataAdminList = async () => {
    setLoading(true);
    try {
      const res = await CUSTOMER_GET_DATA_ADMIN_FILTER_API();
      if (res.code === 200) {
        const result = RefacterOptionValueCascader(res.result);
        setList(result);
      } else {
        Swal.fire(
          "ผิดพลาด!",
          `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleGetExcelAPI = async () => {
    setLoading(true);
    const dataInsert = {
      startDate: !_isEmpty(initialValue.date) && Number(initialValue.date[0]),
      endDate: !_isEmpty(initialValue.date) && Number(initialValue.date[1]),
      admin: !_isEmpty(initialValue.cascarder) && initialValue.cascarder[1],
    };
    try {
      const result = filterFalseValue(dataInsert);
      const res = await CUSTOMER_SUB_REPORT_AO_AE_EXCEL_API(result);
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `customer-service-report-admin-Ao-Ae.xlsx`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        Swal.fire(
          "ผิดพลาด!",
          `ไม่สามารถส่งออกข้อมูลได้ เนื่องจาก ${res.message} 
          หรือ ท่านยังไม่เลือกข้อมูล (AE) หรือ (AO) กรุณาเลือกข้อมูลที่ท่านต้องการก่อนทำการส่งออกข้อมูล`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const filterFalseValue = (values: any) => {
    let result = {};
    for (const [key, value] of Object.entries(values)) {
      if (value || value !== false) {
        result = { ...result, [key]: value };
      }
    }
    return result;
  };

  const RefacterDataSource = (dataInfo: []) => {
    return dataInfo.map((item: any, index: number) => {
      const dataRefacter = {
        companyName: item.companyName,
        dataTable: item.customerService?.map((child: any, idx: number) => {
          const data = {
            index: idx,
            companyName: idx === 0 ? item.companyName : "duplicate",
            aOName:
              idx === 0
                ? `${child.aOFirstName} ${child.aOLastName}`
                : "duplicate",
            aEName:
              idx === 0
                ? `${child.aEFirstName} ${child.aELastName}`
                : "duplicate",
            inquiryNO: child.inquiryNO,
            contactDate: child.contactDate,
            contactChannel: child.contactChannel,
            contactName: child.contactName,
            // aEName: `${child.aEFirstName} ${child.aELastName}`,
            answer: child.answer,
            row_span: item.customerService.length,
          };
          return data;
        }),
      };
      return dataRefacter;
    });
  };

  const HandleOnFinish = async (value: any) => {
    setLoading(true);
    setInitialValue({
      ...initialValue,
      cascarder: value.cascarder,
      date: value.date,
    });
    try {
      const dataInsert = {
        page: 1,
        limit: 10,
        orderby: "DESC",
        startDate: !_isEmpty(value.date) ? Number(value.date[0]) : "",
        endDate: !_isEmpty(value.date) ? Number(value.date[1]) : "",
        admin: !_isEmpty(value.cascarder) ? value.cascarder[1] : "",
        department: !_isEmpty(value.cascarder)
          ? value.cascarder[0] === "AE"
            ? "aE"
            : "aO"
          : "",
      };
      const result = filterFalseValue(dataInsert);
      const res = await CUSTOMER_SUB_REPORT_AO_AE(result);
      if (res.code === 200) {
        const result = res.result[0];
        setDataUserInfo({
          dateTime: !_isEmpty(result?.dateTime)
            ? `${_numToDate(
                Number(result?.dateTime?.startDate)
              )} ถึง ${_numToDate(Number(result?.dateTime?.endDate))}`
            : "ไม่พบข้อมูล",
          aE: !_isEmpty(result?.aE)
            ? `${result?.aE?.firstName} ${result?.aE?.lastName}`
            : "ไม่พบข้อมูล",
          aO: !_isEmpty(result?.aO)
            ? `${result?.aO?.firstName} ${result?.aO?.lastName}`
            : "ไม่พบข้อมูล",
        });
        const dataNew = RefacterDataSource(res.result);
        setDataSource(dataNew);
      } else {
        Swal.fire(
          "ผิดพลาด!",
          `ไม่พบข้อมูล เนื่องจาก ข้อผิดพลาด !${res.message}`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  ///////////////////////////////////////////////table////////////////////////////////////////////////////
  const columns = [
    {
      title: "ประเภทธุรกิจ/ชื่อบริษัท",
      dataIndex: "companyName",
      key: "companyName",
      width: 300,
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "duplicate") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: `ผู้ดูเเล ${
        !_isEmpty(initialValue?.cascarder)
          ? initialValue?.cascarder[0] === "AE"
            ? "(AO)"
            : "(AE)"
          : ""
      } `,
      dataIndex: !_isEmpty(initialValue?.cascarder)
        ? initialValue?.cascarder[0] === "AE"
          ? "aOName"
          : "aEName"
        : "aOName",
      key: !_isEmpty(initialValue?.cascarder)
        ? initialValue?.cascarder[0] === "AE"
          ? "aOName"
          : "aEName"
        : "aOName",
      width: 250,
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: <div>{value}</div>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (value === "duplicate") {
          obj.props.colSpan = 1;
          obj.props.rowSpan = 0;
        } else {
          obj.props.colSpan = 1;
          obj.props.rowSpan = row.row_span;
        }
        return obj;
      },
    },
    {
      title: "หมายเลขที่ติดต่อ",
      dataIndex: "inquiryNO",
      key: "inquiryNO",
      width: 150,
    },
    {
      title: "วันที่ติดต่อ",
      dataIndex: "contactDate",
      key: "contactDate",
      width: 150,
      render: (value: string) => {
        var momentObj = moment(value, "DD/MM/YYYY");
        var momentString = momentObj.format("DD/MM/YYYY");
        return momentString;
      },
    },
    {
      title: "ผู้ติดต่อ",
      dataIndex: "contactName",
      key: "contactName",
      width: 250,
    },
    {
      title: "ช่องทาง",
      dataIndex: "contactChannel",
      key: "contactChannel",
      width: 200,
    },
    {
      title: "ผู้รับเรื่อง",
      dataIndex: "aEName",
      key: "aEName",
      width: 250,
    },
    {
      title: "หัวข้อหลัก",
      dataIndex: "",
      key: "",
      width: 250,
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: row.answer?.map((item: any, idx: number) => (
            <div className="row-same-item-table" key={idx}>
              {item.firstAnswer}
            </div>
          )),
        };
        return obj;
      },
    },
    {
      title: "เรื่องที่ติดต่อ",
      dataIndex: "address",
      key: "address",
      width: 250,
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: row.answer?.map((item: any, idx: number) => (
            <div
              className={
                row.answer?.length - 1 === idx
                  ? "row-same-item-p10"
                  : "row-same-item-table"
              }
              key={idx}
            >
              {item.secondAnswer}
            </div>
          )),
        };
        return obj;
      },
    },
    {
      title: "รายละเอียด",
      dataIndex: "address",
      key: "address",
      width: 250,
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: row.answer?.map((item: any, idx: number) => (
            <div className="row-same-item-table" key={idx}>
              {item.fillDetail}
            </div>
          )),
        };
        return obj;
      },
    },
    {
      title: "การดำเนินการ",
      dataIndex: "address",
      key: "address",
      width: 250,
      render: (value: string, row: any, index: number) => {
        const obj = {
          children: row.answer?.map((item: any, idx: number) => (
            <div className="row-same-item-table" key={idx}>
              {item.operationDetail}
            </div>
          )),
        };
        return obj;
      },
    },
  ];

  const HandleCheckTable = () => {
    return !_isEmpty(dataSource) ? (
      dataSource?.map((item: any, index: number) => (
        <Table
          key={index}
          rowKey={(row) => row.index}
          style={{ marginTop: "10px" }}
          scroll={{ x: 2500,y:500  }}
          dataSource={item.dataTable}
          columns={columns}
          loading={tableLoading()}
          bordered
          pagination={false}
          footer={() => (
            <div>
              รวมจำนวนครั้งที่บริษัท {item.companyName} ติดต่อ{" "}
              <b style={{ color: "black" }}>{item.dataTable?.length} ครั้ง</b>
            </div>
          )}
        />
      ))
    ) : (
      <Table
        rowKey={(row) => ""}
        style={{ marginTop: "20px" }}
        scroll={{ x: 1500,y:500  }}
        dataSource={[]}
        columns={columns}
        loading={tableLoading()}
        bordered
        pagination={false}
      />
    );
  };
  ///////////////////////////////////////////////table////////////////////////////////////////////////////

  return (
    <div>
      <HeaderTab
        message="รายงานสรุปแยกตาม AO หรือ AE ที่ดูแล"
        style={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      />
      <Card
        style={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
        className="header-setting"
      >
        <FromDefault
          justify="space-between"
          Field={field}
          Action={action}
          HandleOnFinish={HandleOnFinish}
        />
      </Card>
      <div style={{ marginTop: "10px" }}>
        <Card className="title-menu-card">
          <Row align="middle">
            <span className="title-menu-text">REPORT</span>
            <span className="title-message-text">
              รายงานสรุปแยกตาม AO หรือ AE ที่ดูแล
            </span>
          </Row>
        </Card>
        <Card
          style={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          <Row className="my-1">
            ผู้ดูเเลลูกค้า :
            <span style={{ fontWeight: "bold", padding: "0 5px" }}>
              {initialValue?.cascarder[0] === "AE"
                ? dataUserInfo.aE
                : dataUserInfo.aO}
            </span>
            <span style={{ paddingLeft: "25px" }}>ตำเเหน่ง :</span>
            <span style={{ fontWeight: "bold", padding: "0 5px" }}>
              {!_isEmpty(initialValue?.cascarder)
                ? initialValue?.cascarder[0]
                : ""}
            </span>
          </Row>
          <Row className="my-3">
            ข้อมูล ณ{" "}
            <span className="pr-2 pl-2" style={{ fontWeight: "bold" }}>
              วันที่ {dataUserInfo?.dateTime}
            </span>
          </Row>
          <HandleCheckTable />
        </Card>
      </div>
    </div>
  );
};

export default SubReportAoAe;

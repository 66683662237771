import { Fetch, FetchFormData } from "../../../tools/fecth/fecth";
import { apiVersion } from '../../../config/api'
import { createQueryString } from "../../../tools/util";

export const GETCONTACTCHANNEL_API = async (data: {}) => {
    const qrs = createQueryString(data);
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/contact-channel${qrs}` });
    return res;
}

export const ADDCONTACTCHANNEL_API = async (data: any) => {
    const res = await FetchFormData({ method: 'POST', path: `${apiVersion}/contact-channel`, data });
    return res;
}

export const EDITCONTACTCHANNEL_API = async ({ data, id }: { data: any, id: number }) => {
    const res = await FetchFormData({ method: 'PUT', path: `${apiVersion}/contact-channel/${id}`, data });
    return res;
}

export const DELETECONTACTCHANNEL_API = async (id: number) => {
    const res = await Fetch({ method: 'DELETE', path: `${apiVersion}/contact-channel/${id}` });
    return res;
}
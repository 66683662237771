/* eslint-disable react-hooks/exhaustive-deps */

import { _isEmpty } from "@yim/util-tool-ts";
import { Form, Table } from "antd";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { AlertToast } from "../../../components/alert/alert";
import { tableLoading } from "../../../components/loading/loading";
import {
  GETNEWINQURYREPORT_API,
  NEWINQURY_EXCEL_API,
} from "../../../services/api/report/new_inquiry";
import { toDateTimeStandard } from "../../../tools/dateformat";
import { IResStatus } from "../../../tools/fecth/interface";
import { loadingTable } from "../../../tools/recoil/recoil-atom";
import Header from "./Header/Header";

const SubReportNewInquiry = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [searchYears, setSearchYears] = useState(
    new Date().getFullYear().toString()
  );
  const [isFirstWork, setisFirstWork] = useState<boolean>(true);
  const [newInquiry, setNewInquiry] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(10);

  const [form] = Form.useForm();
  const currentYear = new Date();

  useEffect(() => {
    if (isFirstWork) {
      form.setFieldsValue({ years: `${currentYear.getFullYear()}` });
      handleGetNewInquery();
      setisFirstWork(false);
    } else {
      handleGetNewInquery();
    }
    return undefined;

  }, [searchYears, currentPage, limitPage]);

  const handleGetNewInquery = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await GETNEWINQURYREPORT_API({
        year: searchYears,
        limit: limitPage,
        page: currentPage,
      });
      if (res.code === 200) {
        setNewInquiry(res.result[0].items);
        setTotalPage(res.result[0].total);
      }
    } catch (error) {}
    setLoading(false);
  };

  const columns = [
    {
      title: "เลขที่ติดต่อ",
      dataIndex: "inquiryNO",
      key: "inquiryNO",
      width: 100,
    },

    {
      title: "วันที่ติดต่อ",
      dataIndex: "contactDate",
      key: "contactDate",
      width: 140,
      align: "center" as "center",
      render: (val: string) => {
        return <>{toDateTimeStandard(Number(val))}</>;
      },
    },
    {
      title: "ชื่อจริง",
      dataIndex: "customer",
      key: "customer",
      width: 150,
      render: (val: {
        firstName: string;
        lastName: string;
        nickname: string;
      }) => {
        return <>{val.firstName}</>;
      },
    },
    {
      title: "นามสกุล",
      dataIndex: "customer",
      key: "customer",
      width: 130,
      render: (val: {
        firstName: string;
        lastName: string;
        nickname: string;
      }) => {
        return <>{val.lastName}</>;
      },
    },
    {
      title: "ชื่อเล่น",
      dataIndex: "customer",
      key: "customer",
      width: 100,
      align: "center" as "center",
      render: (val: {
        firstName: string;
        lastName: string;
        nickname: string;
      }) => {
        return <>{val.nickname}</>;
      },
    },
    {
      title: "ชื่อบัญชีโซเชียลมีเดีย",
      dataIndex: "customer",
      key: "customer",
      width: 130,
      align: "center" as "center",
      render: (val: {
        firstName: string;
        lastName: string;
        socialName: string;
      }) => {
        return <>{val.socialName}</>;
      },
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "phone",
      key: "phone",
      width: 130,
      align: "center" as "center",
    },

    {
      title: "ชื่อบริษัท",
      dataIndex: "companyName",
      key: "companyName",
      width: 200,
    },
    {
      title: "ตำแหน่ง/แผนก",
      dataIndex: "position",
      key: "position",
      width: 150,
    },
    {
      title: "ที่อยู่",
      dataIndex: "address",
      key: "address",
      width: 200,
    },
    {
      title: "อีเมล์",
      dataIndex: "email",
      key: "email",
      width: 150,
    },
    {
      title: "ไอดีไลน์",
      dataIndex: "line",
      key: "line",
      width: 90,
    },
    {
      title: "อธิบายเกี่ยวกับธุรกิจ",
      dataIndex: "explainAboutBusiness",
      key: "explainAboutBusiness",
      width: 200,
      render: (val: string[]) => {
        return (
          <>
            {!_isEmpty(val) ? (
              <>{val}</>
            ) : (
              <span>ไม่มี</span>
            )}
          </>
        );
      },
    },
    {
      title: "ประเภทผู้ติดต่อ",
      dataIndex: "contactType",
      key: "contactType",
      width: 150,
    },
    {
      title: "ช่องทางการติดต่อ",
      dataIndex: "contactChannel",
      key: "contactChannel",
      width: 150,
      render: (val: string[]) => {
        return (
          <>
            {val.length > 0 &&
              val.map((item) => {
                return <span className="pr-1">{item},</span>;
              })}
          </>
        );
      },
    },
    {
      title: "เรื่องที่ต้องการติดต่อ",
      dataIndex: "subjectContact",
      key: "subjectContact",
      width: 150,
      render: (val: string[]) => {
        return (
          <>
            {val.length > 0 &&
              val.map((item) => {
                return <span className="pr-1">{item}</span>;
              })}
          </>
        );
      },
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "projectName",
      key: "projectName",
      width: 150,
    },
    {
      title: "ประเภทโครงการ",
      dataIndex: "projectCategory",
      key: "projectCategory",
      width: 120,
    },
    {
      title: "ประเภทความต้องการ",
      dataIndex: "requirementType",
      key: "requirementType",
      width: 150,
    },
    {
      title: "สเปคอลูเม็ท",
      dataIndex: "specAlumet",
      key: "specAlumet",
      width: 100,
      render: (val: boolean) => {
        return <>{val ? "YES" : "NO"}</>;
      },
    },
    {
      title: "สินค้าที่สนใจ",
      dataIndex: "interestedProduct",
      key: "interestedProduct",
      width: 200,
      render: (val: string[]) => {
        return (
          <>
            {val.length > 0 &&
              val.map((item) => {
                return <span className="pr-1">{item},</span>;
              })}
          </>
        );
      },
    },
    {
      title: "สีที่ต้องการ",
      dataIndex: "aluminiumColor",
      key: "aluminiumColor",
      width: 200,
      render: (val: string[]) => {
        return (
          <>
            {val.length > 0 &&
              val.map((item) => {
                return <span className="pr-1">{item},</span>;
              })}
          </>
        );
      },
    },
    {
      title: "ความต้องการของลูกค้า",
      dataIndex: "requirement",
      key: "requirement",
      width: 250,
    },
    {
      title: "ผู้ให้บริการ",
      dataIndex: "admin",
      key: "admin",
      width: 100,
      render: (val: { firstName: string; lastName: string }) => {
        return (
          <>
            {val.firstName} {val.lastName}
          </>
        );
      },
    },
    {
      title: "การดำเนินการเบื้องต้น",
      dataIndex: "initialService",
      key: "initialService",
      width: 200,
    },
    {
      title: "ส่งต่อ",
      dataIndex: "forward",
      key: "forward",
      width: 100,
      render: (val: { firstName: string; lastName: string }) => {
        return (
          <>
            {!_isEmpty(val.firstName) ? (
              <>
                {val.firstName} {val.lastName}
              </>
            ) : (
              <span>ไม่มี</span>
            )}
          </>
        );
      },
    },
    {
      title: "วันที่ส่งต่อ",
      dataIndex: "forwardDate",
      key: "forwardDate",
      width: 150,
      align: "center" as "center",
      render: (val: string) => {
        return <>{toDateTimeStandard(Number(val))}</>;
      },
    },
    {
      title: "Note | กิจกรรมระหว่างดำเนินการ",
      dataIndex: "note",
      key: "note",
      width: 250,
      render: (val: string[]) => {
        return (
          <>
            {val.length > 0 &&
              val.map((item) => {
                return <span className="pr-1">{item},</span>;
              })}
          </>
        );
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      width: 150,
      align: "center" as "center",
      render: (val: string, row: any) => {
        return (
          <>
            {val === "ON_PLAN"
              ? `อยู่ระหว่างดำเนินการ ${
                  row?.notation ? `: ${row?.notation}` : ""
                }`
              : val === "DO_NOT_BUY"
              ? `ยกเลิกโครงการ ${row?.notation ? `: ${row?.notation}` : ""}`
              : val === "CLOSE_THE_SALE"
              ? `ปิดการขาย ${row?.notation ? `: ${row?.notation}` : ""}`
              : val === "OTHER"
              ? `อื่นๆ ${row?.notation ? `: ${row?.notation}` : ""}`
              : ""}
          </>
        );
      },
    },
  ];

  const handleExportExcel = async () => {
    setLoading(true);
    try {
      const res = await NEWINQURY_EXCEL_API({
        year: searchYears,
      });
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `new_inquiry.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถส่งออกข้อมูลได้`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onChangePage = (page: number, pageSize?: number) => {
    if (pageSize) setLimitPage(pageSize);
    setCurrentPage(page);
  };

  const handleFilter = (value: string) => {
    setSearchYears(value);
  };
  return (
    <div>
      <Header
        form={form}
        handleExportExcel={handleExportExcel}
        handleFilter={handleFilter}
        data={{
          item: {
            key: 1,
            text: "รายงานสรุปข้อมูลการดําเนินการให้บริการลูกค้าใหม่ New Inquiry",
          },
        }}
      >
        <Table
          rowKey={(row) => row.key}
          style={{ marginTop: "20px" }}
          scroll={{ x: 5000, y: 500 }}
          dataSource={newInquiry}
          columns={columns}
          bordered
          expandRowByClick
          loading={tableLoading()}
          pagination={{
            pageSize: limitPage,
            current: currentPage,
            total: totalPage,
            onChange: onChangePage,
          }}
        />
      </Header>
    </div>
  );
};

export default SubReportNewInquiry;

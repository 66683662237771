/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import HeaderTab from "../header_tab/HeaderTab";
import FromDefault from "../../../../../components/FormDefalut";
import { Card, Row, Table } from "antd";
import {
  CUSTOMER_SERVICE_REPORT_SERVICE_API,
  CUSTOMER_SERVICE_REPORT_SERVICE_EXCEL_API,
} from "../../../../../services/api/customer_api";
import Swal from "sweetalert2";
import { useSetRecoilState } from "recoil";
import { loadingTable } from "../../../../../tools/recoil/recoil-atom";
import { tableLoading } from "../../../../../components/loading/loading";

const SubReportService = () => {
  const currentMount = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [year, setYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
    handleGetAPI({ year: year });
    return undefined;

  }, []);

  const handleGetAPI = async (data?: any) => {
    setLoading(true);
    try {
      const res = await CUSTOMER_SERVICE_REPORT_SERVICE_API(data);
      if (res.code === 200) {
        let dataRefacter: any[] = [];
        const result = res.result?.map((item: any, idx: any) => {
          return {
            NO: item.NO,
            topic:
              item.NO.length < 5 ? (
                item.NO + " " + item.description
              ) : (
                <ul>
                  <li>{item.description}</li>
                </ul>
              ),
            average: item.average,
            all: item.count[0].all,
            jan: item.count[0].jan,
            feb: item.count[0].feb,
            mar: item.count[0].mar,
            apr: item.count[0].apr,
            may: item.count[0].may,
            jun: item.count[0].jun,
            jul: item.count[0].jul,
            aug: item.count[0].aug,
            sep: item.count[0].sep,
            oct: item.count[0].oct,
            nov: item.count[0].nov,
            dec: item.count[0].dec,
          };
        });

        result?.map((item: any, idx: any) => {
          if (item.NO.length < 2) {
            return dataRefacter.push(item);
          } else if (item.NO.length < 5) {
            return dataRefacter[dataRefacter.length - 1].children
              ? dataRefacter[dataRefacter.length - 1].children.push(item)
              : (dataRefacter[dataRefacter.length - 1].children = [item]);
          } else {
            return dataRefacter[dataRefacter.length - 1].children[
              dataRefacter[dataRefacter.length - 1].children.length - 1
            ].children
              ? dataRefacter[dataRefacter.length - 1].children[
                  dataRefacter[dataRefacter.length - 1].children.length - 1
                ].children.push(item)
              : (dataRefacter[dataRefacter.length - 1].children[
                  dataRefacter[dataRefacter.length - 1].children.length - 1
                ].children = [item]);
          }
        });
        setDataTable(dataRefacter);
      } else {
        Swal.fire(
          "ผิดพลาด!",
          `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleGetExcelAPI = async (data?: any) => {
    setLoading(true);
    try {
      const res = await CUSTOMER_SERVICE_REPORT_SERVICE_EXCEL_API(data);
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `customer-service-report-${year}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        Swal.fire(
          "ผิดพลาด!",
          `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const d = new Date();
  const n = d.getFullYear();
  const optionSelect = [];
  for (let i = 0; i < 5; i++) {
    optionSelect.push({
      value: Number(n) - i,
      // label: `${Number(n) - i + 543}`,
      label: `${Number(n) - i}`,
    });
  }

  const field = [
    {
      type: "selectCuscader",
      name: "cascarder",
      initialValue: [year],
      placeholder: "เลือก",
      options: optionSelect,
    },
  ];

  const action = [
    {
      type: "btnExport",
      onClick: () => handleGetExcelAPI({ year: year }),
    },
  ];

  const HandleOnFinish = (value: any) => {
    handleGetAPI({ year: value.cascarder[0].toString() });
    setYear(value.cascarder[0].toString());
  };

  const columnMount = [
    {
      title: "ม.ค.",
      dataIndex: "jan",
      key: "jan",
      width: 100,
      number: 1,
    },
    {
      title: "ก.พ.",
      dataIndex: "feb",
      key: "feb",
      width: 100,
      number: 2,
    },
    {
      title: "มี.ค.",
      dataIndex: "mar",
      key: "mar",
      width: 100,
      number: 3,
    },
    {
      title: "เม.ย.",
      dataIndex: "apr",
      key: "apr",
      width: 100,
      number: 4,
    },
    {
      title: "พ.ค.",
      dataIndex: "may",
      key: "may",
      width: 100,
      number: 5,
    },
    {
      title: "มิ.ย.",
      dataIndex: "jun",
      key: "jun",
      width: 100,
      number: 6,
    },
    {
      title: "ก.ค.",
      dataIndex: "jul",
      key: "jul",
      width: 100,
      number: 7,
    },
    {
      title: "ส.ค.",
      dataIndex: "aug",
      key: "aug",
      width: 100,
      number: 8,
    },
    {
      title: "ก.ย.",
      dataIndex: "sep",
      key: "sep",
      width: 100,
      number: 9,
    },
    {
      title: "ต.ค.",
      dataIndex: "oct",
      key: "oct",
      width: 100,
      number: 10,
    },
    {
      title: "พ.ย.",
      dataIndex: "nov",
      key: "nov",
      width: 100,
      number: 11,
    },
    {
      title: "ธ.ค.",
      dataIndex: "dec",
      key: "dec",
      width: 100,
      number: 12,
    },
  ];

  const columns = [
    {
      title: "เรื่องที่ลูกค้าติดต่อ",
      dataIndex: "topic",
      key: "topic",
      fixed: true,
      width: 400,
    },
    {
      // title: `สรุปยอดรวมการติดต่อลูกค้า ปี ${Number(year) + 543}`,
      title: `สรุปยอดรวมการติดต่อลูกค้า ปี ${Number(year)}`,
      dataIndex: "summary",
      key: "summary",
      width: 500,
      children:
        year === currentYear.toString()
          ? columnMount.filter((item: any) => item.number <= currentMount + 1)
          : columnMount,
    },
    {
      title: "ทั้งหมด",
      dataIndex: "all",
      key: "all",
      width: 150,
    },
    {
      title: "ค่าเฉลี่ย",
      dataIndex: "average",
      key: "average",
      width: 150,
      render: (value: any) => value.toFixed(2),
    },
  ];

  return (
    <div>
      <HeaderTab
        message="รายงานสรุปการบริการลูกค้า"
        style={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      />
      <Card
        style={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
        className="header-setting"
      >
        <FromDefault
          justify="space-between"
          Field={field}
          Action={action}
          HandleOnFinish={HandleOnFinish}
        />
      </Card>
      <div style={{ marginTop: "10px" }}>
        <Card className="title-menu-card">
          <Row align="middle">
            <span className="title-menu-text">REPORT</span>
            <span className="title-message-text">
              รายงานสรุปการบริการลูกค้า
            </span>
          </Row>
        </Card>
        <Card
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <Table
            loading={tableLoading()}
            rowKey={(i) => i.NO}
            style={{ marginTop: "20px" }}
            scroll={{ x: 1500,y:500  }}
            dataSource={dataTable}
            columns={columns}
            pagination={false}
            bordered
            expandRowByClick
          />
        </Card>
      </div>
    </div>
  );
};

export default SubReportService;

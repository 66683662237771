import CloseSale from "../../assets/img/icon-direc-customer/CloseSale.svg";
import DontBuy from "../../assets/img/icon-direc-customer/DontBuy.svg";
import OnPlan from "../../assets/img/icon-direc-customer/OnPlan.svg";

const StatusColumn = ({ status }: { status: string }) => {
  const RenderStatusColumn = (src: string, text: string) => {
    const Colors = () => {
      switch (text) {
        case "ปิดการขาย":
          return "#18C318";
        case "ดำเนินการ":
          return "#16BAFF";
        case "อยู่ระหว่างพิจารณา":
          return "#16BAFF";
        case "ชะลอการติดตั้ง":
          return "#16BAFF";
        case "ติดตั้งต่อ":
          return "#16BAFF";
        default:
          return "#DB4B28";
      }
    };

    return (
      <div style={{ textAlign: "center" }}>
        <img
          src={src}
          alt={text}
          style={{
            border: "1px solid #EDEDED",
            backgroundColor: "white",
            padding: 3,
            borderRadius: 10,
            textAlign: "center",
          }}
        />
        <div
          style={{
            paddingTop: 5,
            fontWeight: "bold",
            color: Colors(),
          }}
        >
          {text}
        </div>
      </div>
    );
  };

  const StatusCase = () => {
    switch (status) {
      case "DO_NOT_BUY":
        return RenderStatusColumn(DontBuy, "ไม่ซื้อ");
      case "ON_PLAN":
        return RenderStatusColumn(OnPlan, "ดำเนินการ");
      case "PENDING":
        return RenderStatusColumn(OnPlan, "อยู่ระหว่างพิจารณา");
      case "SLOWER_INSTALL":
        return RenderStatusColumn(OnPlan, "ชะลอการติดตั้ง");
      case "CONTINUE_INSTALL":
        return RenderStatusColumn(OnPlan, "ติดตั้งต่อ");
      case "CAN_NOT_TACKING":
        return RenderStatusColumn(DontBuy, "ไม่สามารถติดต่อได้");
      case "COMPLETED":
        return RenderStatusColumn(DontBuy, "ได้รับบริการเรียบร้อยเเล้ว");
      default:
        return RenderStatusColumn(CloseSale, "ปิดการขาย");
    }
  };

  return <div>{StatusCase()}</div>;
};

export default StatusColumn;

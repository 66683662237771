import { Fetch, FetchExcel, FetchFormData } from "../../tools/fecth/fecth";
import { apiVersion } from "../../config/api";
import { createQueryString } from "../../tools/util";

export const GEN_INQUERYNO_OF_FORM_API = async () => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/customer-service/inquiryno`,
  });
  return res;
};

export const GET_SEARCH_ERP_USER_API_BY_ID = async (data?: any) => {
  const value = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/erp/user/customerservice${value}`,
  });
  return res;
};

export const CUSTOMER_SERVICE_CREATE_API = async (data?: any) => {
  const res = await Fetch({
    method: "POST",
    path: `${apiVersion}/customer-service`,
    data: data,
  });
  return res;
};
export const CUSTOMER_SERVICE_EDIT_API = async (
  inquiryNo: string,
  data?: any
) => {
  const res = await Fetch({
    method: "PUT",
    path: `${apiVersion}/customer-service/${inquiryNo}`,
    data: data,
  });
  return res;
};

export const GET_CUSTOMER_SERVICE_API = async (data?: any) => {
  const value = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/customer-service${value}`,
  });
  return res;
};

export const GET_CONTACT_CHANEL_API = async (data?: any) => {
  const value = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/contact-channel/customer-service${value}`,
  });
  return res;
};

export const GET_CUSTOMER_SERVICE_DETAIL_API = async (data: any) => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/customer-service/detail/${data}`,
  });
  return res;
};

export const ACKNOWLEDGE_CUSTOMER_SERVICE_DETAIL_API = async (data: any) => {
  const res = await Fetch({
    method: "PUT",
    path: `${apiVersion}/customer-service/status/${data}`,
  });
  return res;
};

export const ACKNOWLEDGE_CUSTOMER_SERVICE_DELETE_API = async (data: any) => {
  const res = await Fetch({
    method: "DELETE",
    path: `${apiVersion}/customer-service/${data}`,
  });
  return res;
};

export const GET_CUSTOMER_SERVICE_EDIT_API = async (data: any) => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/customer-service/${data}`,
  });
  return res;
};

export const CUSTOMER_SERVICE_EXCEL_API = async (dataSearch?: any) => {
  const value =  createQueryString(dataSearch)
  const res = await FetchExcel({
    method: "GET",
    path: `${apiVersion}/customer-service/excel${value}`,
  });
  return res;
};

export const CUSTOMER_SERVICE_ERP_EXCEL_API = async (data?:any) => {
  const value = createQueryString(data);
  const res = await FetchExcel({
    method: "GET",
    path: `${apiVersion}/erp/excel${value}`,
  });
  return res;
};

export const CUSTOMER_SERVICE_REPORT_USERCS_API = async (data: any) => {
  const value = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/customer-service-report/usercs${value}`,
  });
  return res;
};
export const CUSTOMER_SERVICE_REPORT_USERCS_EXCEL_API = async (data: any) => {
  const value = createQueryString(data);
  const res = await FetchExcel({
    method: "GET",
    path: `${apiVersion}/customer-service-report/usercs/excel${value}`,
  });
  return res;
};
export const CUSTOMER_SERVICE_REPORT_SERVICE_API = async (data: any) => {
  const value = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/customer-service-report/customerservice${value}`,
  });
  return res;
};
export const CUSTOMER_SERVICE_REPORT_SERVICE_EXCEL_API = async (data: any) => {
  const value = createQueryString(data);
  const res = await FetchExcel({
    method: "GET",
    path: `${apiVersion}/customer-service-report/customerservice/excel${value}`,
  });
  return res;
};

export const CUSTOMER_DATA_BASE_GET_DATA_API = async (data?: {}) => {
  const queryData = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/erp/user${queryData}`,
  });
  return res;
};

export const CUSTOMER_GET_DATA_ADMIN_FILTER_API = async () => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/admin/filter`,
  });
  return res;
};
export const GET_TECHNICIAN_FILTER = async () => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/technician/all`,
  });
  return res;
};

export const CUSTOMER_SUB_REPORT_AO_AE = async (data: any) => {
  const valueQuery = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/customer-service-report/admin${valueQuery}`,
  });
  return res;
};

export const UPLOADFILE_BY_ID = async (data: any) => {
  const res = await FetchFormData({
    method: "POST",
    path: `${apiVersion}/customer-service/file`,
    data,
  });
  return res;
};

export const CUSTOMER_SUB_REPORT_AO_AE_EXCEL_API = async (data: any) => {
  const value = createQueryString(data);
  const res = await FetchExcel({
    method: "GET",
    path: `${apiVersion}/customer-service-report/admin/excel${value}`,
  });
  return res;
};

import { Fetch } from "../../../tools/fecth/fecth";
import { apiVersion } from '../../../config/api'
import { createQueryString } from "../../../tools/util";

export const GETALUMINIUMCOLOR_API = async (data: {}) => {
    const qrs = createQueryString(data);
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/aluminium-color${qrs}` });
    return res;
}

export const ADDALUMINIUMCOLOR_API = async (data: any) => {
    const res = await Fetch({ method: 'POST', path: `${apiVersion}/aluminium-color`, data });
    return res;
}

export const EDITALUMINIUMCOLOR_API = async ({ data, id }: { data: any, id: number }) => {
    const res = await Fetch({ method: 'PUT', path: `${apiVersion}/aluminium-color/${id}`, data });
    return res;
}

export const DELETEALUMINIUMCOLOR_API = async (id: number) => {
    const res = await Fetch({ method: 'DELETE', path: `${apiVersion}/aluminium-color/${id}` });
    return res;
}
import { Col, Row, Card } from "antd";
import HeaderTab from "../header_tab/HeaderTab";
import { useHistory } from "react-router";
import { report } from "../../../../../assets/img/icon-setting/icon-seting";

const Report = () => {
  const history = useHistory();

  const dataMocks = [
    {
      text: "REPORT เเยกตาม AO หรือ AE ที่ดูเเล",
      img: report,
      to: "subreportaoae",
    },
    {
      text: "REPORT เเยกตามรายชื่อลูกค้า",
      img: report,
      to: "subreportcustomer",
    },
    {
      text: "REPORT การบริการลูกค้า",
      img: report,
      to: "subreportservice",
    },
  ];

  return (
    <div>
      <HeaderTab />
      <div style={{ marginBottom: "15px", paddingTop: 10 }}>
        <Card 
        className="setting-cover-card"
        cover={
          <>
            <h1 className="mb-0">
              <span
                style={{
                  fontFamily: "Bold",
                  color: "#faa41a",
                  fontSize: "23px",
                }}
              >
                REPORT
              </span>
              <span
                className="ml-2"
                style={{ fontFamily: "Bold", fontSize: "23px" }}
              >
                Cutomer Service Report
              </span>
            </h1>
          </>
        }>
          {dataMocks.map((item, index) => (
            <div
              key={index}
              onClick={() =>
                history.push(`/customerservice/list/report/${item.to}`)
              }
            >
              <Card
                className={"card-menu-item"}
                style={{
                  backgroundColor: index % 2 === 0 ? "#FAFAFA" : "white",
                  cursor: "pointer",
                  borderTopRightRadius: index === 0 ? "10px" : 0,
                  borderTopLeftRadius: index === 0 ? "10px" : 0,
                  borderBottomLeftRadius:
                    index === dataMocks.length - 1 ? "10px" : 0,
                  borderBottomRightRadius:
                    index === dataMocks.length - 1 ? "10px" : 0,
                }}
              >
                <Row align="middle" gutter={24}>
                  <Col>
                    <img src={item.img} alt="icon" height={40} />
                  </Col>
                  <Col>
                    <h2
                      style={{
                        fontFamily: "SemiBold",
                        margin: "0px",
                        fontSize: "18px",
                      }}
                    >
                      {item.text}
                    </h2>
                  </Col>
                </Row>
              </Card>
            </div>
          ))}
        </Card>
      </div>
    </div>
  );
};

export default Report;

import { Fetch } from "../../tools/fecth/fecth";

export const userLoginAPT = async (data: any) => {
  const res = await Fetch({
    method: "POST",
    path: `/auth/login`,
    data: data
  })
  return res;
};

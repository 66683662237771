import { PaperClipOutlined } from "@ant-design/icons";
import { Form, Upload } from "antd";
import { AlertToast } from "../alert/alert";

type Props = {
  name: string;
  label?: string;
  option?: any;
};

const UploadFile = ({ name, option, label = "" }: Props) => {
  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const HandleCheckFileType = (type: string) => {
    if (
      type === "application/pdf" ||
      type === "image/jpeg" ||
      type === "image/png" ||
      type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return true;
    } else return false;
  };

  const normFile = (e: any) => {
    if (HandleCheckFileType(e.file.type)) {
      const { file, fileList } = e;
      const duplicate = fileList.filter((item: any) => {
        return file.name === item.name && file.size === item.size && file.type === item.type;
      });
      if (duplicate.length >= 2) {
        AlertToast({
          icon: "warning",
          title: `คุณอัปโหลดไฟล์ซ้ำ`,
        });
        const resCheck = fileList.filter(
          (item: any) => item.status !== "uploading"
        );
        return resCheck;
      } 
      else {
        if (e.file.status !== "removed") {
          AlertToast({
            icon: "success",
            title: `อัปโหลดไฟล์สำเร็จ`,
          });
        } 
      }
      // return e && e.fileList?.map((item:any)=>item?.originFileObj || item);
      return e && e.fileList;
    } else {
      if (e.file.status === "removed") {
        return e && e.fileList?.filter((item:any)=>item?.id !== e.file?.id);
      } else {
        AlertToast({
          icon: "error",
          title:
            "อัปโหลดไฟล์ไม่สำเร็จ! กรุณาอัปโหลดเฉพาะไฟล์นามสกุล (.pdf | .jpeg | .png | .xlxs) เท่านั้น",
        });
        return e && e.fileList?.filter((item:any)=>item?.status !== "uploading");
      }
    }
  };

  return (
    <div id={name || "upload"}>
      <Form.Item
        name={name || "upload"}
        label={label}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        style={{
          marginBottom: 0,
        }}
      >
        <Upload
          {...option}
          beforeUpload={false}
          multiple={false}
          name={name}
          customRequest={dummyRequest}
          listType="picture"
          className="my-upload-customer_sv upload-list-inline"
        >
          <PaperClipOutlined className="mx-2 cursor"/>
        </Upload>
      </Form.Item>
    </div>
  );
};

export default UploadFile;

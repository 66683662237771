import { Col } from "antd";
import FInput from "../../../../../components/form_floatlabel/FInput";
import FSelect from "../../../../../components/form_floatlabel/FSelect";
type Props = {
  disabledEdit: boolean;
  departmentList: any[];
  roleList: any[];
};
const CompanyForm = ({ disabledEdit, departmentList, roleList }: Props) => {
  return (
    <>
      <Col md={12}>
        <FInput
          label="อีเมล​"
          name="email"
          rules={{ required: true }}
          pattern={{
            pattern: new RegExp(
              "[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z]{2,4}"
            ),
            message: "โปรดป้อนอีเมลเป็นภาษาอังกฤษ (Ex. admin_123@gamil.com)",
          }}
          options={{ disabled: disabledEdit }}
        />
      </Col>
      <Col md={12}>
        <FInput
          label="เลขบัตรประชาชน"
          name="ssn"
          rules={{ required: false }}
          options={{ disabled: disabledEdit }}
          type="number"
          pattern={{
            pattern: new RegExp(
              "[0-9]{13,13}"
            ),
            message: "โปรดป้อนเลขบัตรประชาชนให้ครบ 13 หลัก",
          }}

        />
      </Col>
      <Col md={12}>
        <FSelect
          label="ตำแหน่ง"
          name="department"
          dataSource={departmentList}
          rules={{ required: true }}
          options={{ disabled: disabledEdit }}
          keyName="name"
          keyValue="id"
        />
      </Col>
      <Col md={12}>
        <FSelect
          label="บทบาท"
          name="role"
          dataSource={roleList}
          rules={{ required: true }}
          options={{ disabled: disabledEdit }}
          keyName="name"
          keyValue="id"
        />
      </Col>
    </>
  );
};

export default CompanyForm;

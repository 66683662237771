import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
interface Props {
  titleEN: string;
  titleTH: string;
  line?: boolean;
}
const PageTitile = ({ line = false, titleEN, titleTH }: Props) => {
  const history = useHistory();
  return (
    <div className="page-heder">
      <Row gutter={10}>
        <Col>
          <h1
            style={{
              fontFamily: "Bold",
              color: "#faa41a",
              fontSize: "25px",
            }}
          >
            {titleEN}
          </h1>
        </Col>
        <Col>
          <h1 style={{ fontFamily: "Bold", fontSize: "25px" }}> {titleTH}</h1>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col>
          <Button
            className="btn-gray"
            type="primary"
            onClick={() => history.goBack()}
          >
            <CloseOutlined />
            ยกเลิก
          </Button>
        </Col>
        <Col>
          <Button className="btn-save" type="primary" htmlType="submit">
            <SaveOutlined />
            บันทึก
          </Button>
        </Col>
      </Row>

      {/* <Divider style={{ border: "1px solid #E9E9E9", marginTop: "-5px" }} /> */}
    </div>
  );
};
export default PageTitile;

interface IYears {
    key: string,
}
export const years: IYears[] = [
    {
        key: "1990"
    }, {
        key: "1991"
    }, {
        key: "1992"
    }, {
        key: "1993"
    }, {
        key: "1994"
    }, {
        key: "1995"
    }, {
        key: "1996"
    }, {
        key: "1997"
    }, {
        key: "1998"
    }, {
        key: "1999"
    }, {
        key: "2000"
    }, {
        key: "2001"
    }, {
        key: "2002"
    }, {
        key: "2003"
    }, {
        key: "2004"
    }, {
        key: "2005"
    }, {
        key: "2006"
    }, {
        key: "2007"
    }, {
        key: "2008"
    }, {
        key: "2009"
    }, {
        key: "2010"
    }, {
        key: "2011"
    }, {
        key: "2012"
    }, {
        key: "2013"
    }, {
        key: "2014"
    }, {
        key: "2015"
    }, {
        key: "2016"
    }, {
        key: "2017"
    }, {
        key: "2018"
    }, {
        key: "2019"
    }, {
        key: "2020"
    }, {
        key: "2021"
    }, {
        key: "2022"
    }, {
        key: "2023"
    }, {
        key: "2024"
    }, {
        key: "2025"
    }, {
        key: "2026"
    }, {
        key: "2027"
    }, {
        key: "2028"
    }, {
        key: "2029"
    }, {
        key: "2030"
    }, {
        key: "2031"
    }, {
        key: "2032"
    }, {
        key: "2033"
    }, {
        key: "2034"
    }, {
        key: "2035"
    }, {
        key: "2036"
    }, {
        key: "2037"
    }, {
        key: "2038"
    }, {
        key: "2039"
    }, {
        key: "2040"
    }, {
        key: "2041"
    }, {
        key: "2042"
    }, {
        key: "2043"
    }, {
        key: "2044"
    }, {
        key: "2045"
    }, {
        key: "2046"
    }, {
        key: "2047"
    }, {
        key: "2048"
    }, {
        key: "2049"
    }, {
        key: "2050"
    }, {
        key: "2051"
    }, {
        key: "2052"
    }, {
        key: "2053"
    }, {
        key: "2054"
    }, {
        key: "2055"
    }, {
        key: "2056"
    }, {
        key: "2057"
    }, {
        key: "2058"
    }, {
        key: "2059"
    }, {
        key: "2060"
    }, {
        key: "2061"
    }, {
        key: "2062"
    }, {
        key: "2063"
    }, {
        key: "2064"
    }, {
        key: "2065"
    }, {
        key: "2066"
    }, {
        key: "2067"
    }, {
        key: "2068"
    }, {
        key: "2069"
    }, {
        key: "2070"
    }, {
        key: "2071"
    }, {
        key: "2072"
    }, {
        key: "2073"
    }, {
        key: "2074"
    }, {
        key: "2075"
    }, {
        key: "2076"
    }, {
        key: "2077"
    }, {
        key: "2078"
    }, {
        key: "2079"
    }, {
        key: "2080"
    }, {
        key: "2081"
    }, {
        key: "2082"
    }, {
        key: "2083"
    }, {
        key: "2084"
    }, {
        key: "2085"
    }, {
        key: "2086"
    }, {
        key: "2087"
    }, {
        key: "2088"
    }, {
        key: "2089"
    }, {
        key: "2090"
    }]
/* eslint-disable react-hooks/exhaustive-deps */

import { Form, Table } from "antd";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { AlertToast } from "../../../components/alert/alert";
import { tableLoading } from "../../../components/loading/loading";
import {
  GETINTERESTREPORT_API,
  INTEREST_EXCEL_API,
} from "../../../services/api/report/interested";
import { IResStatus } from "../../../tools/fecth/interface";
import { loadingTable } from "../../../tools/recoil/recoil-atom";
import Header from "./Header/Header";

const SubReportInterestProduct = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [isFirstWork, setisFirstWork] = useState<boolean>(true);
  const [searchYears, setSearchYears] = useState(
    new Date().getFullYear().toString()
  );
  const [interestProducts, setInterestedProducts] = useState<any[]>([]);

  const [form] = Form.useForm();
  const currentYear = new Date();

  useEffect(() => {
    if (isFirstWork) {
      form.setFieldsValue({ years: `${currentYear.getFullYear()}` });
      handleGetReportInterestedProduct();
      setisFirstWork(false);
    } else {
      handleGetReportInterestedProduct();
    }
    return undefined;

  }, [searchYears]);

  const handleGetReportInterestedProduct = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await GETINTERESTREPORT_API({
        year: searchYears,
      });
      if (res.code === 200) {
        const itemList = res.result[0].item;
        const totalList = res.result[0].total;
        let totalObj = {};
        totalList.forEach((item: any, idTotal: number) => {
          totalObj = {
            key: `total ${idTotal + 1}`,
            name: "TOTAL INQUIRY (ยอดรวมผู้ติดต่อเข้ามาทั้งหมด)",
            avr: res.result[0].average.toFixed(2),
            ...totalObj,
            [item.label]: item.count,
          };
        });

        const contactTypeDataFormat = itemList.map(
          (item: any, idMain: number) => {
            let mainObj = {};
            item.header.forEach((header: any) => {
              mainObj = {
                key: `main ${idMain + 1}`,
                name: `${idMain + 1}. ${item.name}`,
                avr: item.average.toFixed(2),
                ...mainObj,
                [header.label]: header.count,
              };
            });
            let childrenList: any[] = [];

            item?.subHeader?.forEach((subHeader: any, idChild: number) => {
              let children = {};
              children = { name: subHeader?.name, key: `child ${idChild + 1}` };

              subHeader?.data?.forEach((child: any) => {
                children = {
                  ...children,
                  [child.label]: child?.count,
                  avr: subHeader?.average?.toFixed(2) || (0).toFixed(2),
                  children: subHeader?.color?.map((x: any, ind: number) => {
                    let ListMount = {};
                    x?.data.forEach((s: any) => {
                      ListMount = {
                        ...ListMount,
                        [s.label]: s.count,
                      };
                    });
                    return {
                      name: x?.name,
                      key: `sub ${idChild + 1}${ind + 1}`,
                      avr: x?.average?.toFixed(2) || (0).toFixed(2),
                      ...ListMount,
                    };
                  }),
                };
              });
              childrenList = [...childrenList, children];
            });

            mainObj = {
              ...mainObj,
              children: [...childrenList],
            };
            return mainObj;
          }
        );
        const mergeList = [...contactTypeDataFormat, totalObj];
        setInterestedProducts(mergeList);
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleExportExcel = async () => {
    setLoading(true);
    try {
      const res = await INTEREST_EXCEL_API({
        year: searchYears,
      });
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `interested.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถส่งออกข้อมูลได้`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const columns = [
    {
      title: "ช่องทางการติดต่อ",
      dataIndex: "name",
      key: "name",
      width: 300,
      fixed: true,
    },

    {
      title: "ม.ค.",
      dataIndex: "1",
      key: "1",
      width: 80,
    },
    {
      title: "ก.พ.",
      dataIndex: "2",
      key: "2",
      width: 80,
    },
    {
      title: "มี.ค.",
      dataIndex: "3",
      key: "3",
      width: 80,
    },
    {
      title: "เม.ย.",
      dataIndex: "4",
      key: "4",
      width: 80,
    },
    {
      title: "พ.ค.",
      dataIndex: "5",
      key: "5",
      width: 80,
    },
    {
      title: "มิ.ย.",
      dataIndex: "6",
      key: "6",
      width: 80,
    },
    {
      title: "ก.ค.",
      dataIndex: "7",
      key: "7",
      width: 80,
    },
    {
      title: "ส.ค.",
      dataIndex: "8",
      key: "8",
      width: 80,
    },
    {
      title: "ก.ย.",
      dataIndex: "9",
      key: "9",
      width: 80,
    },
    {
      title: "ต.ค.",
      dataIndex: "10",
      key: "10",
      width: 80,
    },
    {
      title: "พ.ย.",
      dataIndex: "11",
      key: "11",
      width: 80,
    },
    {
      title: "ธ.ค.",
      dataIndex: "12",
      key: "12",
      width: 80,
    },
    {
      title: "ค่าเฉลี่ย",
      dataIndex: "avr",
      key: "avr",
      width: 80,
    },
  ];
  const handleFilter = (value: string) => {
    setSearchYears(value);
  };

  const filterCoulumnToCurrentMount = (columns: any) => {
    const dateTime = new Date();
    const currentMount = dateTime.getMonth();
    const toCurrentMount =
      Number(searchYears) === dateTime.getFullYear()
        ? columns.filter(
            (item: any) =>
              item.key === "name" ||
              item.key === "avr" ||
              Number(item.key) <= currentMount + 1
          )
        : columns;
    return toCurrentMount;
  };
  return (
    <div>
      <Header
        form={form}
        handleExportExcel={handleExportExcel}
        handleFilter={handleFilter}
        data={{
          item: {
            key: 1,
            text: "รายงานสรุปยอดรวมสินค้าที่ลูกค้าสนใจแยกตามประเภทสินค้า",
          },
        }}
      >
        <Table
          rowKey={(row) => row.key}
          style={{ marginTop: "20px" }}
          scroll={{ y: 500 }}
          dataSource={interestProducts}
          columns={filterCoulumnToCurrentMount(columns)}
          bordered
          expandRowByClick
          loading={tableLoading()}
        />
      </Header>
    </div>
  );
};

export default SubReportInterestProduct;

/* eslint-disable jsx-a11y/alt-text */
import { Layout, Row } from "antd";
// @ts-ignore
import { NavLink } from "react-router-dom";

const { Sider } = Layout;

export const _isEmpty = (data: any) => {
  const obj =
    data === null ||
    data === "null" ||
    data === "undefined" ||
    data === undefined
      ? []
      : data;
  return Object.entries(obj).length === 0;
};

type NavProps = {
  navMenu: {
    keyName: string;
    id: number;
    name: {
      th: string;
      en: string;
    };
    icon: string;
    exact: boolean;
    link: string;
    sublink: { link: string }[];
  }[];
};

const SidebarLayout = ({ navMenu }:NavProps) => {
  return (
      <Sider>
        <div>
          {navMenu.map((item: any, idx: number) => (
            <NavLink
              strict
              to={item.link}
              key={idx}
              activeClassName="bg-item-menu-active"
              className="bg-item-menu"
            >
              <div>
                {" "}
                <Row justify="center">
                  <img src={item.icon} style={{ width: "35.7%" }} alt="Logo" />
                </Row>
                <Row
                  justify="center"
                  style={{ textAlign: "center", fontFamily: "Bold" }}
                >
                  {item.name.th}
                </Row>
                <Row
                  justify="center"
                  style={{ textAlign: "center", fontSize: "12px" }}
                >
                  {item.name.en}
                </Row>
              </div>
            </NavLink>
          ))}
        </div>
      </Sider>
  );
};

export default SidebarLayout;

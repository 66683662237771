/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import HeaderTab from "../header_tab/HeaderTab";
import FromDefault from "../../../../../components/FormDefalut";
import { Card, Row, Col } from "antd";
import {
  CUSTOMER_SERVICE_REPORT_USERCS_API,
  CUSTOMER_SERVICE_REPORT_USERCS_EXCEL_API,
} from "../../../../../services/api/customer_api";
import { _isEmpty } from "@yim/util-tool-ts";
import Swal from "sweetalert2";
import { loadingTable } from "../../../../../tools/recoil/recoil-atom";
import { useSetRecoilState } from "recoil";
import TableModifind from "../../../../../components/table/Table";
import { _numToDate } from "../../../../../tools/util";

const SubReportCustomer = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [dataTable, setDataTable] = useState<any[]>([]);
  let sum = 0;
  const [limitPage, setLimitPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>();
  const [initailValue, setInitailValue] = useState({} as any);
  const [dataUserInfo, setDataUserInfo] = useState({
    dateTime: "",
    companyName: "",
    code: "",
    aE: "",
    aO: "",
    type: "",
  });

  useEffect(() => {
    handleGetAPI({ page: currentPage, limit: limitPage });
    return undefined;

  }, [limitPage, currentPage]);

  const handleGetAPI = async (data: any) => {
    setLoading(true);
    try {
      const res = await CUSTOMER_SERVICE_REPORT_USERCS_API(data);
      if (res.code === 200) {
        const result = res.result[0];
        setDataUserInfo({
          dateTime: `${_numToDate(
            Number(result?.dateTime.startDate)
          )} ถึง ${_numToDate(Number(result?.dateTime.endDate))}`,
          companyName: result?.companyName,
          code: result?.code,
          aE: `${result?.aE.firstName} ${result?.aE.lastName}`,
          aO: `${result?.aO.firstName} ${result?.aO.lastName}`,
          type: result?.type,
        });
        setDataTable(result?.item);
        setTotal(result?.total);
      } else {
        Swal.fire(
          "ผิดพลาด!",
          `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const Refacter = () => {
    const result = dataTable?.map((item: any, idx: any) => {
      return {
        inquiryNO: item?.inquiryNO,
        contactDate: item?.contactDate,
        recipient:
          item.createdBy?.firstName +
          "." +
          item.createdBy?.lastName.substring(0, 2),
        contactName: item?.userCS?.contactName,
        answer: item?.answerCS,
        contact: item?.contactChannel?.name,
      };
    });
    const resultres = result.flat(Infinity);
    const dataRefacter = resultres.map((item, index) => {
      return { ...item, index: index };
    });
    sum = dataRefacter.length;

    return dataRefacter;
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const handleGetExcelAPI = async () => {
    const dataInfo = {
      usercs: initailValue.search,
      startDate: !_isEmpty(initailValue.date)
        ? Number(initailValue.date[0])
        : "",
      endDate: !_isEmpty(initailValue.date) ? Number(initailValue.date[1]) : "",
    };
    setLoading(true);
    try {
      const res = await CUSTOMER_SERVICE_REPORT_USERCS_EXCEL_API(dataInfo);
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `customer-service-report-usercs.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        Swal.fire(
          "ผิดพลาด!",
          `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const field = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา ชื่อบริษัท รหัสลูกค้า",
      initialValue: initailValue?.search,
    },
    {
      type: "rangepicker",
      name: "date",
      initialValue: initailValue?.date,
    },
  ];

  const action = [
    {
      type: "btnExport",
      onClick: () => handleGetExcelAPI(),
    },
  ];

  const filterFalseValue = (values: any) => {
    let result = {};
    for (const [key, value] of Object.entries(values)) {
      if (value || value !== false) {
        result = { ...result, [key]: value };
      }
    }
    return result;
  };

  const HandleOnFinish = (value: any) => {
    setInitailValue(value);
    const data = {
      page: 1,
      limit: limitPage,
      usercs: value.search,
      startDate: !_isEmpty(value.date) ? Number(value.date[0]) : "",
      endDate: !_isEmpty(value.date) ? Number(value.date[1]) : "",
    };
    const result = filterFalseValue(data);
    handleGetAPI(result);
  };

  const columns = [
    {
      title: "หมายเลขที่ติดต่อ",
      dataIndex: "inquiryNO",
      key: "inquiryNO",
      fixed: true,
      width: 300,
    },
    {
      title: "วันที่ติดต่อ",
      dataIndex: "contactDate",
      key: "contactDate",
      width: 250,
      render: (value: string) => {
        var momentString = _numToDate(Number(value));
        return momentString;
      },
    },
    {
      title: "ผู้รับเรื่อง",
      dataIndex: "recipient",
      key: "recipient",
      width: 280,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "contactName",
      key: "contactName",
      width: 280,
    },
    {
      title: "หัวข้อหลัก",
      dataIndex: "answer",
      key: "answer",
      width: 320,
      render: (value: []) => {
        return value.map((item: any, idx: number) => {
          return (
            <div
              className="row-same-item-table"
              // value.length - 1 === idx ? "row-same-item-p10" : "row-same-item-table"
              key={idx}
            >
              {item.firstAnswer.description}
            </div>
          );
        });
      },
    },
    {
      title: "เรื่องที่ติดต่อ",
      dataIndex: "answer",
      key: "answer",
      width: 350,
      render: (value: []) => {
        return value.map((item: any, idx: number) => {
          return (
            <div className="row-same-item-table" key={idx}>
              {item?.secondAnswer?.description}
            </div>
          );
        });
      },
    },
    {
      title: "รายละเอียด",
      dataIndex: "answer",
      key: "answer",
      width: 450,
      render: (value: []) => {
        return value.map((item: any, idx: number) => {
          return (
            <div className="row-same-item-table" key={idx}>
              {item?.fillDetail}
            </div>
          );
        });
      },
    },
    {
      title: "การดำเนินงาน",
      dataIndex: "answer",
      key: "answer",
      width: 450,
      render: (value: []) => {
        return value.map((item: any, idx: number) => {
          return (
            <div className="row-same-item-table" key={idx}>
              {item?.operationDetail}
            </div>
          );
        });
      },
    },
    {
      title: "ช่องทางติดต่อ",
      dataIndex: "contact",
      key: "contact",
      width: 350,
    },
  ];
  return (
    <div>
      <HeaderTab
        message="รายงานสรุปแยกตามรายชื่อลูกค้า"
        style={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      />
      <Card
        style={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
        className="header-setting"
      >
        <FromDefault
          justify="space-between"
          Field={field}
          Action={action}
          HandleOnFinish={HandleOnFinish}
        />
      </Card>
      <div style={{ marginTop: "10px" }}>
        <Card className="title-menu-card">
          <Row align="middle">
            <span className="title-menu-text">REPORT</span>
            <span className="title-message-text">
              รายงานสรุปแยกตามรายชื่อลูกค้า
            </span>
          </Row>
        </Card>
        <Card
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <Row>
            <Col lg={9}>
              <p>
                ประเภทธุรกิจ / บริษัท : <b>{dataUserInfo.companyName}</b>
              </p>
              <p>
                ผู้ดูแล (AE) : <b>{dataUserInfo.aE}</b>
              </p>
            </Col>
            <Col lg={6}>
              <p>
                รหัสลูกค้า : <b>{dataUserInfo.code}</b>
              </p>
              <p>
                ผู้ดูแล (AO) : <b>{dataUserInfo.aO}</b>
              </p>
            </Col>
            <Col lg={9}>
              <p>
                ประเภท : <b>{dataUserInfo.type}</b>
              </p>
              <p>
                ข้อมูล ณ วันที่ : <b>{dataUserInfo.dateTime}</b>
              </p>
            </Col>
          </Row>
          <TableModifind
            cursor={false}
            rowKey={"index"}
            bordered={true}
            scroll={{ x: 2450,y:500  }}
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
            columns={columns}
            dataSource={Refacter()}
            onChangePage={onChangePage}
            footer={() => (
              <h5>
                รวมจำนวนครั้งที่ {dataUserInfo.companyName}
                {<span style={{ fontWeight: "bold" }}>ติดต่อ {sum} ครั้ง</span>}
              </h5>
            )}
          />
        </Card>
      </div>
    </div>
  );
};

export default SubReportCustomer;

/* eslint-disable react-hooks/rules-of-hooks */
import Swal, { SweetAlertIcon } from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

export const AlertToast = ({
  icon = "success",
  title = "สำเร็จ!",
  text,
}: {
  icon: SweetAlertIcon;
  title: string;
  text?: string;
}) => {
  text
    ? Toast.fire({
        icon: icon,
        title: title,
        text: text,
      })
    : Toast.fire({
        icon: icon,
        title: title,
      });
};

import { FormInstance } from "antd/lib/form";
import { EFieldsProject } from "../components/DetailCardProject/_store";
import { IDataDetailCard } from "./ILead.interface";

export interface ISortComlumnType {
  order: string,
  columnKey: string,
  value: string
}

export interface ISorterComlumn {
  type:ISortComlumnType,
  status:ISortComlumnType
}


export interface ICardProject {
  dataSource: IDataDetailCard;
  onUpdate: (data:any) => void;
  onChange: (scroll:string) => void;
  id:number;
  isForm:string;
  isEdit?:boolean;
  byForm?:string;
}

export interface IFieldParent {
  fieldTitle: string;
  key: EFieldsProject;
  items: IFieldChild[];
  child?: IFieldChild[];
  subChild?: IFieldChild[];
  visibleChildKey?: string;
  visibleSubChildKey?: string;
}
export interface ICol {
  span?: number;
  className?: string;
  style?: {};
}
export interface IFieldChild {
  label: string;
  name: string;
  value: any;
  input?: {
    type?: EInputType;
    props?: any;
  };
  col?: ICol;
  typeSelf?:String;
}

export interface IFormInstance {
  contactForm: { form: FormInstance<any>; id: string; field: IFieldParent };
  requirmentForm: { form: FormInstance<any>; id: string; field: IFieldParent };
  actionForm: { form: FormInstance<any>; id: string; field: IFieldParent };
}

export enum EInputType {
  INPUT = "INPUT",
  CHECKBOX = "CHECKBOX",
  SELECT = "SELECT",
  UPLOAD = "UPLOAD",
  TEXTAREA = "TEXTAREA",
  DATE = "DATE",
}
/* eslint-disable react-hooks/exhaustive-deps */

import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { _isEmpty } from "@yim/util-tool-ts";

import { Card, Col, Form, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSetRecoilState } from "recoil";
import { FormDefault } from "../../../../../components";
import { CAlertToast } from "../../../../../components/alert/CAlert";
import FSelect from "../../../../../components/form_floatlabel/FSelect";
import { tableLoading } from "../../../../../components/loading/loading";
import TableModifind from "../../../../../components/table/Table";
import { years } from "../../../../../default_data/years";
import {
  ISettingColorType,
  ISettingList,
} from "../../../../../interface/ISetting.interface";
import {
  ADDGOAL_API,
  DELETEGOAL_API,
  EDITGOAL_API,
  GETGOAL_API,
} from "../../../../../services/api/setting/goal_api";

import { GETREQUIREMENTTYPEALL_API } from "../../../../../services/api/setting/requirement_type_api";
import { loadingTable } from "../../../../../tools/recoil/recoil-atom";
import { HandleScroll } from "../../../../../tools/util";

import HeaderSetting from "./HerderSetting";

const ColorType = () => {
  const setLoading = useSetRecoilState(loadingTable);
  const history = useHistory();
  const [manageContent, setManageContent] = useState<boolean>(false);
  const [isFirstWork, setIsFirstWork] = useState<boolean>(true);

  const [valueAluminumColor, setValueAluminumColor] = useState<string>("");
  const [id, setId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(10);
  const [initialValueSearch, setInitialValueSearch] = useState({
    search: "",
    colorType: null,
  });
  const [goalList, setGoalList] = useState<ISettingList[]>([]);
  const [requirementTypeSource, setRequirementTypeSource] = useState<
    ISettingList[]
  >([]);
  const [formHeader] = Form.useForm();

  const currentYear = new Date();
  const [searchYears, setSearchYears] = useState(currentYear.getFullYear());
  const [form] = Form.useForm();

  useEffect(() => {
    if (isFirstWork) {
      form.setFieldsValue({ years: `${currentYear.getFullYear()}` });
      handleGetData();
    } else {
      handleGetGoal();
    }
    return undefined;

  }, [currentPage, limitPage, initialValueSearch, searchYears]);

  const handleGetData = () => {
    handleGetRequirementTypes();
    handleGetGoal();
    setIsFirstWork(false);
  };

  const handleSetValue = (value: any) => {
    HandleScroll("setting-header");
    setManageContent(true);
    setId(value.id);
    setValueAluminumColor(value.name);
    formHeader.setFieldsValue({
      requirementType: requirementTypeSource.find(
        (item) => item?.name === value.name
      )?.id,
      goal: value.goal,
      description: value?.description,
    });
  };

  const handleClickEdit = () => {
    setManageContent(!manageContent);
    setValueAluminumColor("");
    setId(0);
  };

  const onFinish = (value: any) => {
    if (id !== 0) handleUpdate(value);
    else handleCreate(value);
  };

  const handleGetRequirementTypes = async () => {
    setLoading(true);
    try {
      const res = await GETREQUIREMENTTYPEALL_API();
      if (res.code === 200) {
        const data = res.result[0].item;
        const defaultRequirement = [
          { id: -1, name: "สเปคอลูเมท" },
          { id: -2, name: "Indirect ปิดการขาย" },
        ];
        setRequirementTypeSource([...defaultRequirement, ...data]);
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleCreate = async (data: any) => {
    setLoading(true);
    try {
      const res = await ADDGOAL_API({ ...data, year: searchYears.toString() });
      if (res.code === 200) {
        handleClickEdit();
        formHeader.resetFields();
        CAlertToast({ method: "POST_SUCCESS", text: res.message });
        handleGetGoal();
      } else {
        CAlertToast({ method: "POST_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleUpdate = async (data: any) => {
    setLoading(true);
    try {
      const res = await EDITGOAL_API({
        id,
        data: { ...data, year: searchYears.toString() },
      });
      if (res.code === 200) {
        handleClickEdit();
        formHeader.resetFields();
        CAlertToast({ method: "PUT_SUCCESS", text: res.message });
        handleGetGoal();
        setId(0);
      } else {
        CAlertToast({ method: "PUT_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    setLoading(false);
    try {
      const res = await DELETEGOAL_API(id);
      if (res.code === 200) {
        CAlertToast({ method: "DEL_SUCCESS", text: res.message });
        // setGoalList(goalList.filter((item) => item.id !== id));
        handleGetGoal();
      } else {
        CAlertToast({ method: "DEL_ERROR", text: res.message });
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleGetGoal = async () => {
    setLoading(true);
    try {
      const res = await GETGOAL_API({
        page: currentPage,
        limit: limitPage,
        year: searchYears,
        ...initialValueSearch,
      });
      if (res.code === 200) {
        const dataSet = res.result[0];
        setGoalList(res.result[0].item);
        setTotalPage(dataSet.total);
        setLimitPage(dataSet.perpage);
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleSubmit = (values: any) => {
    setCurrentPage(1);
    setInitialValueSearch({ ...values });
  };

  const addButton = [
    {
      type: "btnPush",
      name: "เพิ่ม",
      onClick: () => history.push(`/setting/contact-type`),
    },
  ];

  const fieldFilter = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา ชื่อเป้าหมาย",
      initialValue: initialValueSearch.search,
    },
  ];

  const columns: any = [
    {
      title: "ลำดับ",
      align: "center",
      width: "5%",
      render: (_value: any, _item: any, index: number) => {
        return <>{(currentPage - 1) * limitPage + (index + 1)}</>;
      },
    },
    {
      title: "รายละเอียด",
      dataIndex: "description",
      key: "description",
      width: "25%",
    },
    {
      title: "ประเภทความต้องการ",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "เป้าหมาย",
      dataIndex: "goal",
      key: "goal",
      width: "15%",
    },
    {
      title: "จัดการ",
      align: "center",
      width: "10%",
      render: (value: ISettingColorType, row: any) => {
        return (
          <div className="action-table">
            <EditOutlined
              onClick={() => handleSetValue(value)}
              className="faq-btn-edit"
            />
            <Popconfirm
              className="pop-faq"
              placement="top"
              title={"คุณเเน่ใจหรือไม่"}
              onConfirm={() => handleDelete(value.id)}
              okType="danger"
              okText="ลบ"
              cancelText="ยกเลิก"
              okButtonProps={{
                style: {
                  width: 50,
                },
              }}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteOutlined className="ml-3 faq-btn" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };
  const handleFilter = (value: string) => {
    setSearchYears(Number(value));
  };

  return (
    <div id="setting-header">
      <HeaderSetting
        Title={tiltleHeader}
        Action={addButton}
        handleClickEdit={handleClickEdit}
        handleClickCancel={handleClickEdit}
        disabledEdit={false}
        editOnPage={true}
        manageContent={manageContent}
        actionName={!_isEmpty(valueAluminumColor) ? "EDIT" : "ADD"}
        onFinish={onFinish}
        form={formHeader}
        fieldKey={[
          { key: "name", label: "ชื่อประเภท", col: 12 },
          { key: "number", label: "เป้าหมาย", col: 12, type: "number" },
        ]}
        requirementTypeSource={requirementTypeSource}
      />
      <div className="form-tab-bg">
        <Form form={form}>
          <Row>
            <Col lg={4} className="mr-4">
              <FSelect
                label={"ปี ค.ศ."}
                name={"years"}
                dataSource={years}
                keyName={"key"}
                keyValue={"key"}
                onChange={(e: any) => handleFilter(e)}
              />
            </Col>
            <Col>
              <FormDefault
                HandleOnFinish={handleSubmit}
                Field={fieldFilter}
                Action={[]}
              />
            </Col>
          </Row>
        </Form>
      </div>
      <Row>
        <Col lg={24} md={24} className="mt-3">
          <Card
            className="setting-cover-card"
            cover={
              <>
                <h1 className="mb-0">
                  <span
                    style={{
                      fontFamily: "Bold",
                      color: "#faa41a",
                      fontSize: "23px",
                    }}
                  >
                    GOAL
                  </span>
                  <span
                    className="ml-2"
                    style={{ fontFamily: "Bold", fontSize: "23px" }}
                  >
                    เป้าหมาย
                  </span>
                </h1>
              </>
            }
          >
            <TableModifind
              cursor={true}
              rowKey="id"
              loading={tableLoading()}
              bordered={false}
              config={{ currentPage, total: totalPage, pageSize: limitPage }}
              columns={columns}
              dataSource={goalList}
              onChangePage={onChangePage}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const tiltleHeader = {
  nameEN: "GOAL",
  nameTH: "เป้าหมาย",
};

export default ColorType;

import { Fetch } from "../../../tools/fecth/fecth";
import { apiVersion } from '../../../config/api'

import { createQueryString } from "../../../tools/util";

////////////////role//////////////////////////

export const GETUSERROLEALL_API = async () => {
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/role/all` });
    return res;
}

export const GETUSERROLE_API = async (data?: {}) => {
    const result = createQueryString(data)
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/role${result}` });
    return res;
}
export const GETUSERROLEID_API = async (id?: any) => {
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/role/${id}` });
    return res;
}

export const DELUSERROLE_API = async (id: number) => {
    const res = await Fetch({ method: 'DELETE', path: `${apiVersion}/role/${id}` });
    return res;
}

export const EDITUSERROLE_API = async ({ data, id }: any) => {
    const res = await Fetch({ method: 'PUT', path: `${apiVersion}/role/${id}`, data });
    return res;
}

export const ADDUSERROLE_API = async (data?: {}) => {
    const res = await Fetch({ method: 'POST', path: `${apiVersion}/role`, data });
    return res;
}

////////////////menu//////////////////////////

export const GETMENU_API = async () => {
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/menu/` });
    return res;
}


/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Row, Image } from "antd";
import { useRef, useReducer, Reducer, useEffect, useState } from "react";
import { UPLOADFILE_BY_ID } from "../../services/api/customer_api";
import { onImgErrorProfile } from "../../tools/util";
import { Excel,PDF } from "../../assets/img/index";

const generateUniqueId = (source?: string[]): string => {
  if (source) {
    const random = `id_${Math.random().toString(16).slice(2)}`;
    if (source.some((e) => e === random)) {
      return generateUniqueId(source);
    }
    return random;
  }
  return `id_${Math.random().toString(16).slice(2)}`;
};

type Props = {
  onChange?: any;
  value?: any;
  suffixKey: "operation" | "desc";
};

interface IFileDisplay {
  id?: number;
  name: string;
  url: string;
  loading?: boolean;
  uniqueId: string;
}

type TFileDisplayAction = {
  key: "delete" | "update" | "add" | "set";
  setData?: IFileDisplay[];
  data?: IFileDisplay;
  uniqueId: string;
};

type TMentionReducer = Reducer<IFileDisplay[], TFileDisplayAction>;

const reducerFunc = (
  state: IFileDisplay[],
  action: TFileDisplayAction
): IFileDisplay[] => {
  const { key, uniqueId, data, setData } = action;

  switch (key) {
    case "add":
      if (!data) return state;
      return [...state, data];
    case "delete":
      const del = state.filter((e) => e.uniqueId !== uniqueId);
      return del;
    case "update":
      const next = state.map((item) => {
        if (item.uniqueId === uniqueId) {
          return data || item;
        }
        return item;
      });
      return next;
    case "set":
      if (!setData) return state;
      return setData.map((i) => {
        return { ...i, uniqueId: `${i.id}` };
      });
  }
};

const UploadFileFormGenerate = ({ value, onChange, suffixKey }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [state, dispatch] = useReducer<TMentionReducer>(reducerFunc, []);
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      dispatch({ key: "set", setData: value, uniqueId: "" });
    }
    return undefined;

  }, [value]);

  useEffect(() => {
    onChange(state);
    return undefined;

  }, [state]);

  const uploadImage = async (file: any): Promise<IFileDisplay> => {
    const key = {
      operation: "operationDetailFiles",
      desc: "fillDetailFiles",
    };
    const formData = new FormData();
    formData.append(key[suffixKey], file);
    const res = await UPLOADFILE_BY_ID(formData);
    if (res.code === 200) {
      const { fillDetailFiles, operationDetailFiles } = res.result[0];

      if (suffixKey === "operation") {
        return operationDetailFiles[0];
      }
      return fillDetailFiles[0];
    }

    throw new Error(
      `อัปโหลดไฟล์ไม่สำเร็จ เนื่องจาก ${res.message} กรุณาลองอีกครั้ง`
    );
  };

  const _onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files?.length <= 0) return;
    const unq = generateUniqueId();
    try {
      const mock: IFileDisplay = {
        name: "",
        url: "",
        loading: true,
        uniqueId: unq,
      };
      dispatch({ key: "add", data: mock, uniqueId: unq });

      const { id, url, name } = await uploadImage(files[0]);

      dispatch({
        key: "update",
        uniqueId: unq,
        data: { uniqueId: unq, loading: false, name, url, id },
      });
    } catch (err) {
      dispatch({ key: "delete", uniqueId: unq });
    }
  };

  const _openFileDialog = () => {
    ref.current?.click();
  };

  const _clear = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const onRemove = (uniqueId: string) => {
    dispatch({ key: "delete", uniqueId: uniqueId });
  };

  return (
    <div className="px-2 py-2">
      <PaperClipOutlined onClick={_openFileDialog} />
      <input
        accept=".jpg,.jpeg,.png,.xlsx,.xls,.pdf"
        multiple={false}
        type="file"
        ref={ref}
        style={{ display: "none" }}
        onChange={_onFileChange}
        onClick={_clear}
      />
      <Row>
        {state.map((item, index) => {
          return <FileDisplay onRemove={onRemove} file={item} key={index} />;
        })}
      </Row>
    </div>
  );
};

const FileDisplay = ({
  file,
  onRemove,
}: {
  file: IFileDisplay;
  onRemove: (unique: string) => void;
}) => {
  const { url, loading, name, uniqueId } = file;
  if (loading || !url)
    return (
      <Row justify="center" align="middle">
        Uploading...
      </Row>
    );

  const _getFileExtension = (_url: string): string => {
    return _url.split(".").pop() || "";
  };

  const _getImageHolder = (extension: string, url: string) => {
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <Image
            src={url}
            onError={(e: any) => {
              onImgErrorProfile(e);
            }}
            className='img-upload-multi'
            alt={url}
            preview={false}
          />
        );
      case "pdf":
        // return "PDF";
        return (
          <Image
            src={PDF}
            onError={(e: any) => {
              onImgErrorProfile(e);
            }}
            alt={"PDF"}
            className='img-upload-multi'
            preview={false}
          />
        );
      default:
        // return "EXCEL";
        return (
          <Image
            src={Excel}
            onError={(e: any) => {
              onImgErrorProfile(e);
            }}
            alt={"Excel"}
            className='img-upload-multi'
            preview={false}
          />
        );
    }
  };

  const extension = _getFileExtension(url);
  const holder = _getImageHolder(extension, url);

  return (
    <div>
      <div
        className="mt-2 mx-2"
        style={{
          width: 70,
          height: 70,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid rgb(204, 204, 204)",
          borderRadius: 5,
          position: "relative",
        }}
      >
        <Row
          justify="center"
          align="middle"
          style={{ fontSize: 20 }}
          className="p-1"
        >
          {holder}
        </Row>
        <CloseOutlined
          onClick={onRemove.bind(null, uniqueId)}
          style={{
            position: "absolute",
            right: -5,
            top: -5,
            backgroundColor: "orangered",
            borderRadius: "50%",
            padding: 4,
            fontSize: 7,
            color: "white",
            cursor: "pointer",
          }}
        />
      </div>
      <div className="text-name-file-upload">{name}</div>
    </div>
  );
};

export default UploadFileFormGenerate;

import moment from "moment";

import Swal from "sweetalert2";
import { profile } from "../assets/img";

export const _numToDate = (date: any = 0, formatDate: string = 'DD/MM/YYYY') => {
  const DateValue = new Date(date);
  const result = moment(DateValue).format(formatDate);
  return result;
};

export const checkCurrentURL = (): string | null => {
  const current_url = localStorage.getItem("current_url");
  return current_url;
};
export const _RESValidation = (res: any, message: string) => {
  if (!res) {
    Swal.fire("Failed to connect! ", message, "error");
    return false;
  }
  return true;
};
export const _RESValidationCode = (res: any, message: string) => {
  if (res["status"]["code"] === "F203") {
    return false;
  }
  return true;
};

export const createQueryString = (values: any) => {
  if (!values) {
    return "";
  }
  let query = "";
  let result = {};
  for (const [key, value] of Object.entries(values)) {
    if (value || value === 0 || value === false) {
      result = { ...result, [key]: value };
    }
  }
  Object.entries(result).forEach(([key, value], index) => {
    if (index === Object.keys(result).length - 1) {
      if (value || value === 0 || value === false) {
        query += `${key}=${value}`;
      }
    } else {
      if (value || value === 0 || value === false) {
        query += `${key}=${value}&`;
      }
    }
  });
  return "?" + query;
};

export const createFormDataFields = (data: any) => {
  let formData = new FormData();
  Object.keys(data).forEach((key: any) => {
    formData.append(key, data[key]);
  });
  return formData;
};

export const filterUndefine = (values: any) => {
  let result = {};
  for (const [key, value] of Object.entries(values)) {
    if (value || value === false || value === 0) {
      result = { ...result, [key]: value };
    }
  }
  return result;
};


export const onImgErrorProfile = (e: any) => {
  e.target.onerror = null;
  e.target.src = profile;
};

export const HandleScroll = (key: string, smooth?: boolean) => {
  // field1Ref.current?.scrollIntoView()
  var elmnt = document.getElementById(key);
  elmnt?.scrollIntoView(smooth && {
    behavior: 'smooth'
  });
};

export const ConvertDateNum = (value: any) => {
  // value should type date not yet pass function moment()
  if (value) {
    const res = value.valueOf()
    return res ? res : ""
  } else return ""
}

export const numberToCommasNumber = (num: number) => {
  const internationalNumberFormat = new Intl.NumberFormat('en-US')
  return internationalNumberFormat.format(num)

}

export const toFormData = (data: any) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return formData
}

export const RamdomColor = (numOfSteps:number, step:number) => {
  let r = 0,
    g = 0,
    b = 0;
  let h = step / (numOfSteps / step + 3);
  let i = ~~(h * 6);
  let f = h * 6 - i;
  let q = 1 - f;
  switch (i % 6) {
    case 0:
      r = 1;
      g = f;
      b = 0;
      break;
    case 1:
      r = q;
      g = 1;
      b = 0;
      break;
    case 2:
      r = 0;
      g = 1;
      b = f;
      break;
    case 3:
      r = 0;
      g = q;
      b = 1;
      break;
    case 4:
      r = f;
      g = 0;
      b = 1;
      break;
    case 5:
      r = 1;
      g = 0;
      b = q;
      break;
  }
  let c =
    "#" +
    ("00" + (~~(r * 255)).toString(16)).slice(-2) +
    ("00" + (~~(g * 255)).toString(16)).slice(-2) +
    ("00" + (~~(b * 255)).toString(16)).slice(-2);

  return c;
};
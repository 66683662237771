/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CAlertToast } from "../../../../../components/alert/CAlert";
import { GETDEPARTMENTALL_API } from "../../../../../services/api/setting/department_api";
import {
  DELETEUSER_API,
  GETUSERDATA_API,
} from "../../../../../services/api/setting/usermanagement_api";
import {
  loadingState,
  userTabState,
} from "../../../../../tools/recoil/recoil-atom";

import HeaderSetting from "../../component/header_setting/HerderSetting";
import DataMember from "../../component/memberlists/DataMember";
import RoleMember from "./role_member/RoleMember";

const UserManageMent = () => {
  const setLoading = useSetRecoilState<boolean>(loadingState);
  const [usertab, setUserTab] = useRecoilState<number>(userTabState);

  const history = useHistory();

  const [manageRole] = useState(1);
  const [disabledEdit, setDisabledEdit] = useState<boolean>(false);
  const [disabledEditPassword] = useState(false);
  const [userData, setUserData] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(12);
  const [isFirstWork, setIsFirstWork] = useState<boolean>(true);

  const [initialValue, setInitialValue] = useState({
    search: "",
    department: null,
  });
  const [formHeader] = Form.useForm();

  useEffect(() => {
    GetUserData();
    if (isFirstWork) {
      handleGetData();
    }

    setIsFirstWork(false);
    return undefined;

  }, [initialValue, currentPage, limitPage]);

  const handleGetData = () => {
    getDepartment();
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const addUser = [
    {
      type: "btnPush",
      name: "เพิ่มพนักงาน",
      onClick: () => history.push(`/setting/usermanagement/form-user`),
    },
  ];
  const addRole = [
    {
      type: "btnPush",
      name: "เพิ่มบทบาท",
      // onClick: () => setRoleManage(2),
      onClick: () => history.push(`/setting/usermanagement/createrole`),
    },
  ];

  const field = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา ชื่อหรือเบอร์โทร",
      initialValue: initialValue.search,
    },
    {
      type: "select",
      name: "department",
      placeholder: "ค้นหา ตำแหน่ง",
      initialValue: initialValue.department,
      options: departmentList,
      keyName: "name",
      keyValue: "id",
    },
  ];

  const handleClickEdit = () => {
    setDisabledEdit(!disabledEditPassword);
  };

  const titleUser = { nameEN: "USER MANAGEMENT", nameTH: "จัดการผู้ใช้" };
  const titleRole = { nameEN: "PERMISSION", nameTH: "บทบาท" };
  const handleSubmit = (values: any) => {
    setCurrentPage(1);
    setInitialValue({ ...values });
  };

  const GetUserData = async () => {
    setLoading(true);
    try {
      const queryStr = {
        page: currentPage,
        limit: limitPage,
        ...initialValue,
      };
      const res = await GETUSERDATA_API(queryStr);
      if (res.code === 200) {
        const dataSet = res.result[0];
        // setCurrentPage(initialValue ? 1 : currentPage);
        setTotalPage(dataSet.total);
        setLimitPage(dataSet.perpage);
        setUserData(dataSet.item);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getDepartment = async () => {
    setLoading(true);
    try {
      const res = await GETDEPARTMENTALL_API();
      if (res.code === 200) {
        setDepartmentList(res.result[0].item);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);

    try {
      const res = await DELETEUSER_API(id);
      if (res.code === 200) {
        CAlertToast({ method: "DEL_SUCCESS", text: res.message });
        setUserData(userData.filter((item: any) => item.id !== id));
      } else {
        CAlertToast({ method: "DEL_ERROR", text: res.message });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="header-setting">
        <HeaderSetting
          form={formHeader}
          Action={usertab === 1 ? addUser : manageRole === 1 ? addRole : []}
          Title={usertab === 1 ? titleUser : titleRole}
          handleClickEdit={handleClickEdit}
          handleClickCancel={handleClickEdit}
          disabledEdit={disabledEdit}
        />

        <Row className="h-100 min-vh-100">
          <Col
            lg={24}
            className="d-flex justify-content-center align-items-center w-100"
          >
            <Row>
              <button
                className={`user-custom-btn ${
                  usertab === 1
                    ? "custom-color-btn-tab-active"
                    : "custom-color-btn-tab"
                }`}
                onClick={() => setUserTab(1)}
              >
                ผู้ใช้
              </button>
              <button
                className={`role-btn-custom ${
                  usertab === 2
                    ? "custom-color-btn-tab-active"
                    : "custom-color-btn-tab"
                }`}
                onClick={() => {
                  setUserTab(2);
                  // setRoleManage(1);
                }}
              >
                บทบาท
              </button>
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: "10px" }}>
        {usertab === 1 ? (
          <DataMember
            pathmenu="usermanagement"
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            dataSource={userData}
            fieldFilter={field}
            pageConfig={{ currentPage, totalPage, limitPage }}
            onChangePage={onChangePage}
          />
        ) : manageRole === 1 ? (
          <RoleMember />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default UserManageMent;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form, Row, Col, Input, Checkbox } from "antd";
import Select from "../../../../components/Select";
import ChechBoxGroup from "../../../../components/CheckBoxGroup";
import RadioGroup from "../../../../components/RadioGroup";
import { ReactElement } from "react-dom/node_modules/@types/react";
import { useFlagState, descFlagState, checkedFlagState } from "./store/_store";
import { useRecoilState } from "recoil";
import UploadFileFormGenerate from "../../../../components/upload_file/UploadFileFormGenerate";
export enum EInputType {
  INPUT,
  SELECT,
  BLANK,
  CHECKBOX,
  RADIO,
}
export interface IFormItem {
  label: ILabel;
  name: string;
  type: EInputType;
  fieldProps: object;
  colProps?: object;
  initialValue?: any;
  hasAddition?: boolean;
  childrenField?: IFormItem[];
}

interface ILabel {
  label: string;
  align: string;
}

interface IDefaultField {
  [index: string]: any;
}

const BlankComponent = ({
  className,
  title,
  disabled,
  onChange,
}: {
  className: string;
  title: string;
  disabled: boolean;
  onChange: any;
  value: boolean;
}) => {
  useEffect(() => {
    onChange(!disabled);
    return undefined;

  }, [disabled]);

  return (
    <div className={`${className} my-3`}>
      <span style={{ color: disabled ? "rgba(0, 0, 0, 0.25)" : "black" }}>
        {title}
      </span>
    </div>
  );
};

const defFormField: IDefaultField = {
  [EInputType.INPUT]: Input,
  [EInputType.SELECT]: Select,
  [EInputType.BLANK]: BlankComponent,
  [EInputType.CHECKBOX]: ChechBoxGroup,
  [EInputType.RADIO]: RadioGroup,
};

const CreateForm = React.memo(
  ({
    field,
    parentKey,
    toggleFieldValue,
  }: {
    field: IFormItem[];
    parentKey: string;
    toggleFieldValue: Function;
  }) => {
    const [useFlag, setUseFlag] = useRecoilState(useFlagState);
    const [descFlag, setDescFlag] = useRecoilState(descFlagState);
    const [checkedFlag, setCheckedFlag] = useRecoilState(checkedFlagState);

    const toggleDescFlag = (flagKey: string) => {
      setDescFlag({ ...descFlag, [flagKey]: !descFlag[flagKey] });
    };

    const toggleUseFlag = (flagKey: string) => {
      if (useFlag[parentKey][flagKey] && descFlag[flagKey]) {
        toggleDescFlag(flagKey);
      }

      const child = {
        ...useFlag[parentKey],
        [flagKey]: !useFlag[parentKey][flagKey],
      };
      const _useFlag = { ...useFlag, [parentKey]: child };
      const isChecked = Object.values(child).some((e) => e);

      setCheckedFlag({ ...checkedFlag, [parentKey]: isChecked });
      setUseFlag(_useFlag);
    };

    const generateField = (_field: IFormItem[]): ReactElement[] => {
      return _field.map((item, index: number) => {
        const arrSplit = item.name.split("_");
        const keyName = arrSplit[0];
        const Child = defFormField[item.type];
        return (
          <Col key={index} {...item.colProps} span={24} className="my-2">
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                onChange={() => {
                  toggleUseFlag(item.name);
                  toggleFieldValue(parentKey);
                }}
                checked={useFlag[parentKey][item.name]}
              />
              {item.label.align === "left" && item.type !== EInputType.BLANK ? (
                <span className="ml-2">{item.label.label}</span>
              ) : null}
              <Form.Item
                name={item.name}
                noStyle
                initialValue={item.initialValue}
              >
                <Child
                  {...item.fieldProps}
                  size="large"
                  className="mx-3"
                  disabled={!useFlag[parentKey][item.name]}
                />
              </Form.Item>
              {item.label.align === "right" && item.type !== EInputType.BLANK
                ? item.label.label
                : null}
              {useFlag[parentKey][item.name] &&
                useFlag[parentKey] &&
                item.hasAddition && (
                  <div className="ml-4 d-flex y-center">
                    <Checkbox
                      onChange={() => toggleDescFlag(item.name)}
                      checked={descFlag[item.name]}
                      disabled={!useFlag[parentKey][item.name]}
                    />
                    <span
                      className="ml-2"
                      style={{
                        fontSize: "12px",
                        color: !useFlag[parentKey][item.name]
                          ? "rgba(0, 0, 0, 0.25)"
                          : "",
                      }}
                    >
                      เพิ่มข้อมูล
                    </span>
                  </div>
                )}
            </Row>
            {generateChildrenField(item.name, item.childrenField)}
            {
              <Row
                gutter={15}
                className={
                  useFlag[parentKey][item.name] && descFlag[item.name]
                    ? `mx-4`
                    : "mx-4 d-none"
                }
              >
                <Col span={12}>
                  <DescriptionField
                    parentKey={keyName}
                    suffixKey={"desc"}
                    title="กรอกรายละเอียด"
                  />
                </Col>
                <Col span={12}>
                  <DescriptionField
                    parentKey={keyName}
                    suffixKey="operation"
                    title="การดำเนินการ"
                  />
                </Col>
              </Row>
            }  
          </Col>
        );
      });
    };

    const generateChildrenField = (
      parentItemKey: string,
      _field?: IFormItem[]
    ) => {
      if (_field) {
        return _field.map((item, index) => {
          const Child = defFormField[item.type];
          const arrSplit = item.name.split("_");
          const keyName = arrSplit[0];
          return (
            <div key={index}>
              <Row className="mt-3" style={{ marginLeft: 31 }}>
                <Form.Item
                  name={item.name}
                  style={{
                    width: "70%",
                    marginBottom: "0px",
                    marginRight: "15px",
                  }}
                >
                  <Child
                    {...item.fieldProps}
                    size="large"
                    style={{ width: "100%" }}
                    disabled={!useFlag[parentKey][parentItemKey]}
                  />
                </Form.Item>
                {useFlag[parentKey][parentItemKey] && item.hasAddition && (
                  <div className="d-flex y-center">
                    <Checkbox
                      onChange={() => toggleDescFlag(item.name)}
                      checked={descFlag[item.name]}
                    />
                    <span className="ml-2" style={{ fontSize: "12px" }}>
                      เพิ่มข้อมูล
                    </span>
                  </div>
                )}
              </Row>
              {generateSecondChildrenField(
                item.childrenField,
                !useFlag[parentKey][parentItemKey]
              )}
              <Row
                gutter={15}
                className={
                  useFlag[parentKey][parentItemKey] && descFlag[item.name]
                    ? `mx-4`
                    : `mx-4 d-none`
                }
              >
                <Col span={12}>
                  <DescriptionField
                    parentKey={keyName}
                    suffixKey="desc"
                    title="กรอกรายละเอียด"
                  />
                </Col>
                <Col span={12}>
                  <DescriptionField
                    parentKey={keyName}
                    suffixKey="operation"
                    title="การดำเนินการ"
                  />
                </Col>
              </Row>
            </div>
          );
        });
      }
    };

    const generateSecondChildrenField = (
      _field?: IFormItem[],
      _disbled?: boolean
    ) => {
      // if (!_field || _field.length <= 0) return null;
      if (_field) {
        return _field.map((item, index) => {
          const Child = defFormField[item.type];
          return (
            <div key={index} className="mt-3 ml-2">
              <Form.Item
                name={item.name}
                style={{
                  width: "70%",
                  marginBottom: "0px",
                  marginRight: "15px",
                }}
              >
                <Child
                  {...item.fieldProps}
                  size="large"
                  style={{ width: "100%" }}
                  disabled={_disbled}
                />
              </Form.Item>
            </div>
          );
        });
      }
    };

    return <Row>{generateField(field)}</Row>;
  }
);

const DescriptionField = ({
  parentKey,
  title,
  suffixKey,
  ...props
}: {
  parentKey: string;
  title: string;
  suffixKey: "operation" | "desc";
}) => {
  return (
    <div className="mt-4">
      <div style={{ border: "1px solid #CCCCCC", borderRadius: "5px" }}>
        <div
          className="p-2"
          style={{
            backgroundColor: "#F6F7F8",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <span>{title}</span>
        </div>
        <Form.Item name={`${parentKey}_${suffixKey}`} noStyle>
          <Input.TextArea
            bordered={false}
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item name={`${parentKey}_${suffixKey}_File`} noStyle>
          <UploadFileFormGenerate suffixKey={suffixKey} />
        </Form.Item>
      </div>
    </div>
  );
};

export default CreateForm;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Form, Button, DatePicker, Row, Select } from "antd";
import { SearchNew, Delete } from "../../assets/img/index";

import moment from "moment";
import { CalendarOutlined } from "@ant-design/icons";
import { GEN_DASHBOARD_ADMIN } from "../../services/api/Dashboard";
interface Props {
  HandleOnFinish: (data: any) => void;
}

const FormField = ({ HandleOnFinish }: Props) => {
  const [form] = Form.useForm();
  const [loadingAdmin, setLoadingAdmin] = useState(false);
  const [adminList, setAdminList] = useState([] as any);
  const [selectTypeField, setSelectTypeField] = useState("All");
  const type = [
    { label: "วัน", value: "Day" },
    { label: "เดือน", value: "Month" },
    { label: "ปี", value: "Year" },
  ];

  useEffect(() => {
    getAdmin();
    return undefined;

  }, []);

  const getAdmin = async () => {
    setLoadingAdmin(true);
    try {
      const res = await GEN_DASHBOARD_ADMIN();
      if (res.code === 200) {
        const result = res.result[0]?.item;
        setAdminList(result);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingAdmin(false);
  };

  const onFinish = (values: any) => {
    HandleOnFinish(values);
  };

  const onReset = () => {
    setSelectTypeField("All");
    form.resetFields();
  };

  const RenderFieldType = (type: string) => {
    return (
      <Form.Item name="date">
        <DatePicker
          size="large"
          allowClear={false}
          placeholder={
            selectTypeField === "All"
              ? "กรุณาเลือกประเภทก่อน"
              : type === "Day"
              ? "เลือกวัน"
              : type === "Year"
              ? "เลือกปี"
              : "เลือกเดือน"
          }
          style={{
            width: 210,
            border: "1px solid #d9d9d9",
            textAlign: "center",
          }}
          disabled={selectTypeField === "All"}
          className="date-picker-custom"
          suffixIcon={<CalendarOutlined style={{ fontSize: "20px" }} />}
          showTime
          picker={type === "Day" ? "date" : type === "Year" ? "year" : "month"}
          format={
            type === "Day"
              ? "DD-MM-YYYY HH:mm"
              : type === "Year"
              ? "YYYY"
              : "MM-YYYY"
          }
          disabledDate={(current) => {
            return current && current > moment().add(1, "month");
          }}
        />
      </Form.Item>
    );
  };

  return (
    <Row>
      <Form form={form} name="fromfilter" layout="inline" onFinish={onFinish}>
        <Form.Item name="type">
          <Select
            className="select-custom"
            placeholder="เลือกประเภท"
            onChange={(e:any) => {
              form.setFieldsValue({ date: "" });
              if (e) {
                setSelectTypeField(e.toString());
              }
            }}
          >
            {type?.map((item: any, idx: number) => (
              <Select.Option
                value={item?.value}
                className="select-custom"
                key={idx}
              >
                {item?.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {RenderFieldType(selectTypeField)}
        <Form.Item name="subjectRecipient">
          <Select
            className="select-custom"
            placeholder="เลือกผู้รับผิดชอบ"
            loading={loadingAdmin}
            style={{
              width: 210,
            }}
          >
            {adminList?.map((item: any, idx: number) => (
              <Select.Option value={item?.id} key={idx}>
                {`${item?.firstName} ${item?.lastName}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item shouldUpdate>
          <Button
            type="ghost"
            icon={
              <img
                src={SearchNew}
                style={{ width: "30px", margin: "0 10px 0" }}
                alt="pdf-btn"
              />
            }
            htmlType="submit"
            className="item-from-header-btn"
            style={{
              backgroundColor: "white",
              alignItems: "center",
              display: "flex",
            }}
          >
            ค้นหา
          </Button>
        </Form.Item>
        <div className="end-form">
          <Form.Item shouldUpdate>
            <Button
              type="ghost"
              onClick={onReset}
              icon={
                <img
                  src={Delete}
                  style={{ width: "30px", margin: "0 10px 0" }}
                  alt="pdf-btn"
                />
              }
              className="item-from-header-btn"
              style={{
                backgroundColor: "white",
                alignItems: "center",
                display: "flex",
              }}
            >
              รีเซ็ต
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Row>
  );
};

export default FormField;

import { Col, Row } from "antd";
import { Doughnut } from "react-chartjs-3";
import { RamdomColor } from "../../../tools/util";
import { CheckLengthNumber, CheckLengthText } from "./PolarArea";

type Props = {
  list: IRequirementType[];
};
interface IRequirementType {
  name: string;
  count: string;
}

const DoughnutChart = ({ list }: Props) => {

  let colorList = [
    "#FFC627",
    "#1ED6A5",
    "#09A877",
    "#0AB1F2",
    "#0697C4",
    "#AA3DC4",
    "#7A1493",
    "#B7163D",
    "#EF3A54",
    "#FF6B0B",
  ];

  const state = {
    labels: list.map((item: any) => item?.name),
    datasets: [
      {
        backgroundColor: list
          .map((item: any, idx: number) => {
            return {
              ...item,
              color:
                idx > colorList.length - 1
                  ? RamdomColor(list.length, idx)
                  : colorList[idx],
            };
          })
          .map((item: any) => item?.color),
        borderColor: colorList,
        data: list.map((item: any) => item?.count),
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      onFinishs: {
        label: function (tooltipItem: any, data: any) {
          let sum = data.datasets[0].data.reduce((a: any, b: any) => a + b, 0);
          let percentage = Math.round(
            (data.datasets[0].data[tooltipItem.index] / sum) * 100
          );

          return (
            " " + data.labels[tooltipItem.index] + " : " + percentage + " %"
          );
        },
      },
    },
  };

  return (
    <div>
      <Row>
        <Col lg={24} xs={24} className="mb-3">
          <h3>ประเภทความต้องการ</h3>
        </Col>
        <Col lg={8} xs={24} className="d-flex direction-col x-around">
          <Doughnut data={state} options={options} />
        </Col>
        <Col lg={16} xs={24} className="d-flex direction-col x-around p-2">
          <Row className="chart-height">
            {state.labels.map((item, index) => (
              <Col key={index} lg={12} xs={12} className="px-2 py-1">
                <Row justify="space-between" align="top">
                  <Row>
                    <button
                      style={{
                        height: 17,
                        width: 17,
                        border: "unset",
                        borderRadius: 5,
                        background: state.datasets[0].backgroundColor[index],
                      }}
                    />
                    {CheckLengthText(item)}
                  </Row>
                  <span className="mb-0">
                    {" "}
                    {CheckLengthNumber(
                      `${state?.datasets[0]?.data[index].toString()}`
                    )}
                  </span>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DoughnutChart;

import {
  CloseOutlined,
  DeleteOutlined,
  LeftOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, FormInstance, Row } from "antd";
import React from "react";

import { useHistory } from "react-router";
import FSelect from "../../../../../components/form_floatlabel/FSelect";
import { ISettingList } from "../../../../../interface/ISetting.interface";
import FInput from "./FInput";

interface ITitle {
  nameTh: string;
  nameEn: string;
}
type Props = {
  isActioned: boolean;
  handleClickAction: () => void;
  actionName: string;
  title: ITitle;
  handleSubmit: (values: any) => void;
  form: FormInstance<any>;
  requirementTypeSource: ISettingList[];
  generateField?: boolean;
};
const HeaderRequirement = ({
  isActioned,
  handleClickAction,
  actionName,
  title,
  handleSubmit,
  form,
  requirementTypeSource,
  generateField = false,
}: Props) => {
  const history = useHistory();

  return (
    <div>
      <Card className="border-header-diy ">
        <Row className="page-heder">
          <div style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
            <Row gutter={12} align="middle">
              <Col>
                <LeftOutlined style={{ fontSize: "16px", marginRight: 10 }} />
              </Col>
              <Col>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#FFC627",
                    fontSize: "25px",
                  }}
                >
                  <b>{title.nameEn}</b>
                </div>
                <div
                  style={{
                    fontFamily: "SemiBold",
                    fontSize: "18px",
                  }}
                >
                  {title.nameTh}
                </div>
              </Col>
            </Row>
          </div>

          <Button
            className={isActioned ? "d-none" : "btn-gradient mb2"}
            type="primary"
            onClick={() => handleClickAction()}
          >
            <PlusOutlined />
            เพิ่ม
          </Button>
        </Row>
      </Card>

      <Form onFinish={handleSubmit} form={form}>
        <Card className={isActioned ? "round" : "d-none"}>
          <Row className="page-heder">
            <div>
              <Row gutter={12} align="middle">
                <Col>
                  <div
                    style={{
                      fontFamily: "SemiBold",
                      color: "#FFC627",
                      fontSize: "20px",
                    }}
                  >
                    <b>
                      {actionName} {title.nameEn}
                    </b>
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      fontFamily: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {title.nameTh}
                  </div>
                </Col>
              </Row>
            </div>

            <Row gutter={10} className={isActioned ? "" : "d-none"}>
              <Col>
                <Button
                  className="btn-gray"
                  type="primary"
                  onClick={() => {
                    handleClickAction();
                  }}
                >
                  <CloseOutlined />
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn-save"
                  type="primary"
                  onClick={() => form.submit()}
                >
                  <SaveOutlined />
                  บันทึก
                </Button>
              </Col>
            </Row>

            <Divider className="custom-devide" />
            <Row className="w-100 mt-3" gutter={[10, 10]}>
              <Col lg={12}>
                <FInput
                  label="ชื่อประเภท"
                  name="name"
                  rules={{ required: true }}
                />
              </Col>
              <Col lg={12}>
                <FSelect
                  label="ประเภทความต้องการ"
                  name="requirementType"
                  rules={{ required: false }}
                  dataSource={requirementTypeSource}
                  keyName={"name"}
                  keyValue={"id"}
                />
              </Col>

              {generateField && (
                <Form.List name="actionStep">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }: any) => (
                          <React.Fragment key={key}>
                            <Col lg={2}>
                              <FInput
                                isNo={true}
                                label="ลำดับ"
                                name={[name, "no"]}
                                fieldKey={[fieldKey, "no"]}
                                options={{ disabled: true }}
                              />
                            </Col>
                            <Col lg={21}>
                              <FInput
                                label="ชื่อ Step"
                                name={[name, "name"]}
                                rules={{ required: true }}
                                restField={restField}
                                fieldKey={[fieldKey, "name"]}
                              />
                            </Col>
                            <Col lg={1}>
                              <Button
                                style={{ height: "100%" }}
                                onClick={() => remove(name)}
                                block
                                icon={<DeleteOutlined />}
                              ></Button>
                            </Col>
                          </React.Fragment>
                        )
                      )}

                      <Button
                        type="dashed"
                        className="btn-step"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        เพิ่ม Step
                      </Button>
                    </>
                  )}
                </Form.List>
              )}
            </Row>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default HeaderRequirement;

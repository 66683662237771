/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { Col, Row, Button, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  GET_CUSTOMER_SERVICE_API,
  CUSTOMER_SERVICE_EXCEL_API,
} from "../../../../services/api/customer_api";
import { _isEmpty } from "@yim/util-tool-ts";

// @ts-ignore
import arrow_suc from "../../../../assets/img/icon/icon_card_customer/arrow_suc.png";
// @ts-ignore
import arrow from "../../../../assets/img/icon/icon_card_customer/arrow.png";
// @ts-ignore
import finish from "../../../../assets/img/icon/icon_card_customer/finish.png";
// @ts-ignore
import finish_suc from "../../../../assets/img/icon/icon_card_customer/finish-suc.png";
// @ts-ignore
import time from "../../../../assets/img/icon/icon_card_customer/time.png";
// @ts-ignore
import time_suc from "../../../../assets/img/icon/icon_card_customer/time-suc.png";
import { useHistory } from "react-router-dom";
import { FormDefault } from "../../../../components/index";
import { tableLoading } from "../../../../components/loading/loading";
import { useSetRecoilState } from "recoil";
import { loadingTable } from "../../../../tools/recoil/recoil-atom";
import TableModifind from "../../../../components/table/Table";
import { finishIcon, wait } from "../../../../assets/img/index";
import { AlertToast } from "../../../../components/alert/alert";
import { ConvertDateNum } from "../../../../tools/util";

export const RefactDataBeforeGet = (data: any) => {
  let result = {};
  for (const [key, value] of Object.entries(data)) {
    if (value === null || _isEmpty(value)) {
      result = { ...result, [key]: "" };
    } else {
      result = {
        ...result,
        [key]:
          key === "endDate"
            ? ConvertDateNum(data.endDate)
            : key === "startDate"
            ? ConvertDateNum(data.startDate)
            : value,
      };
    }
  }
  return result;
};

const List = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [menuTab, setMenuTab] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [initailValue, setInitailValue] = useState({} as any);
  const [limitPage, setLimitPage] = useState(10);
  const [total, setTotal] = useState<number>();
  const [initailLength, setInitailLength] = useState({} as any);
  const [searchInfo, setSearchInfo] = useState({
    page: 1,
    limit: 10,
    status: 0,
    search: "",
    startDate: "",
    endDate: "",
  } as any);

  let history = useHistory();

  useEffect(() => {
    handleGetDataAPI();
    return undefined;
  }, [menuTab, limitPage, searchInfo]);

  const CheckStatusSelect = () => {
    return menuTab === 0 ? "ALL" : menuTab === 1 ? "ACKNOWLEDGE" : "DONE";
  };

  const handleGetDataAPI = async () => {
    const result: any = RefactDataBeforeGet(searchInfo);
    const dataInfo = {
      page: result?.page,
      limit: limitPage,
      status: CheckStatusSelect(),
      search: result?.search,
      startDate: result?.startDate,
      endDate: result?.endDate,
    };
    setLoading(true);
    try {
      const res = await GET_CUSTOMER_SERVICE_API(dataInfo);
      if (res.code === 200) {
        if (_isEmpty(initailLength)) {
          const all = res.result[0];
          GetCardStatusLength(all);
        }
        setDataTable(res.result[0]?.item);
        setTotal(res.result[0]?.total);
      } else {
        AlertToast({
          icon: "error",
          title: `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const GetCardStatusLength = (all: any) => {
    setInitailLength({
      all: all.total,
      done: all.doneTotal,
      acknowledge: all.acknowledgeTotal,
    });
  };

  const handleGetExcelAPI = async () => {
    const result: any = RefactDataBeforeGet(searchInfo);
    const dataInfo = {
      status: CheckStatusSelect(),
      search: result?.firstname,
      startDate: result?.startDate,
      endDate: result?.endDate,
    };
    setLoading(true);
    try {
      const res = await CUSTOMER_SERVICE_EXCEL_API(dataInfo);
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `customer-service.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        AlertToast({
          icon: "error",
          title: `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const HandleAdd = () => {
    history.push("/customerservice/list/add");
  };

  const ContentRender = (value: string, row: any, index: number) => {
    const obj = {
      children:
        value === "ACKNOWLEDGE" || value === "DONE" ? (
          <div
            style={{
              color: value === "ACKNOWLEDGE" ? "#16BAFF" : "#00C200",
              textAlign: "center",
            }}
          >
            <div>
              <img
                src={value === "ACKNOWLEDGE" ? wait : finishIcon}
                alt="value"
              />
            </div>
            {value === "ACKNOWLEDGE" ? "รอรับทราบ" : "เสร็จสิ้น"}
          </div>
        ) : (
          value
        ),
      props: {
        colSpan: 0,
        rowSpan: 0,
      },
    };
    if (value === "empty") {
      obj.props.colSpan = 1;
      obj.props.rowSpan = 0;
    } else {
      obj.props.colSpan = 1;
      obj.props.rowSpan = row.row_span;
    }
    return obj;
  };

  /////////////////////////////////////////Function of Form Default///////////////////////////////////////////////////

  const Refacter = () => {
    const result = dataTable?.map((item: any, idx: any) => {
      return item.answer?.map((child: any, index: any) => {
        return {
          inquiryShow: index === 0 ? item.inquiryNO : "empty",
          inquiryNO: item.inquiryNO,
          companyName: index === 0 ? item?.userCS?.companyName : "empty",
          contactName: index === 0 ? item?.userCS?.contactName : "empty",
          mainTopic: child?.firstAnswer?.description,
          topic: child?.secondAnswer?.description,
          fillDetail: item.answer[index].fillDetail,
          operationDetail: item.answer[index].operationDetail,
          type: index === 0 ? item?.userCS?.type : "empty",
          contact: index === 0 ? item?.contactChannel?.name : "empty",
          contactDate: index === 0 ? item.contactDate : "empty",
          recipient:
            index === 0
              ? item.createBy?.firstName +
                "." +
                item.createBy?.lastName?.substring(0, 2)
              : "empty",
          aE:
            index === 0
              ? item?.aE?.firstName + "." + item?.aE?.lastName.substring(0, 2)
              : "empty",
          aO:
            index === 0
              ? item?.aO?.firstName + "." + item?.aO?.lastName.substring(0, 2)
              : "empty",
          status: index === 0 ? item.status : "empty",
          answer: item.answer,
          row_span: item.answer?.length,
        };
      });
    });
    const resultres = result.flat(Infinity);
    const dataRefacter = resultres.map((item, index) => {
      return { ...item, index: index };
    });
    return dataRefacter;
  };

  const columns: any = [
    {
      title: "หมายเลขที่ติดต่อ",
      dataIndex: "inquiryShow",
      key: "inquiryShow",
      fixed: true,
      render: ContentRender,
    },
    {
      title: "ชื่อบริษัท",
      dataIndex: "companyName",
      key: "companyName",
      fixed: true,
      render: ContentRender,
    },
    {
      title: "หัวข้อหลัก",
      dataIndex: "mainTopic",
      key: "mainTopic",
    },

    {
      title: "เรื่องที่ติดต่อ",
      dataIndex: "topic",
      key: "topic",
    },
    {
      title: "รายละเอียด",
      dataIndex: "fillDetail",
      key: "fillDetail",
    },
    {
      title: "การดำเนินงาน",
      dataIndex: "operationDetail",
      key: "operationDetail",
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
      render: ContentRender,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "contactName",
      key: "contactName",
      render: ContentRender,
    },
    {
      title: "ช่องทางการติดต่อ",
      dataIndex: "contact",
      key: "contact",
      render: ContentRender,
    },
    {
      title: "วันที่ติดต่อ",
      dataIndex: "contactDate",
      key: "contactDate",
      render: ContentRender,
    },
    {
      title: "ผู้รับเรื่อง",
      dataIndex: "recipient",
      key: "recipient",
      render: ContentRender,
    },
    {
      title: "AE",
      dataIndex: "aE",
      key: "aE",
      render: ContentRender,
    },
    {
      title: "AO",
      dataIndex: "aO",
      key: "aO",
      render: ContentRender,
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      render: ContentRender,
    },
  ];

  const HandleOnChangeTab = (idx: number) => {
    setMenuTab(idx);
  };

  const datainfoTabCard = [
    {
      img: arrow,
      imgAct: arrow_suc,
      text: "รวมทั้งหมด",
      count: initailLength?.all,
    },
    {
      img: time,
      imgAct: time_suc,
      text: "รอรับทราบ",
      count: initailLength?.acknowledge,
    },
    {
      img: finish,
      imgAct: finish_suc,
      text: "เสร็จสิ้น",
      count: initailLength?.done,
    },
  ];

  /////////////////////////////////////////Function of Form Default///////////////////////////////////////////////////
  const field = [
    {
      type: "input",
      name: "firstname",
      initialValue: initailValue?.firstname,
    },
    {
      type: "rangepicker",
      name: "date",
      initialValue: initailValue?.date,
    },
  ];

  const action = [
    {
      type: "btnReport",
      onClick: () => history.push("/customerservice/list/report"),
    },
    {
      type: "btnExport",
      onClick: handleGetExcelAPI,
    },
  ];

  const filterFalseValue = (values: any) => {
    let result = {};
    for (const [key, value] of Object.entries(values)) {
      if (value || value !== false) {
        result = { ...result, [key]: value };
      }
    }
    return result;
  };

  const HandleOnFinish = (value: any) => {
    const data = {
      page: 1,
      limit: limitPage,
      status: CheckStatusSelect(),
      search: value.firstname,
      startDate: value?.date ? value?.date[0] : "",
      endDate: value?.date ? value?.date[1] : "",
    };
    setInitailValue(value);
    const result = filterFalseValue(data);
    setSearchInfo(result);
  };

  const HandleOnDetail = (value: any) => {
    if (!_isEmpty(value.inquiryNO)) {
      history.push({
        pathname: `/customerservice/list/detail/${value.inquiryNO}`,
      });
    } else {
      AlertToast({
        icon: "error",
        title: `ผิดพลาด! ไม่มี INQUIRY NO!`,
      });
    }
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setSearchInfo({ ...searchInfo, page: page });
  };
  /////////////////////////////////////////Function of Form Default///////////////////////////////////////////////////

  return (
    <div>
      <Row align="middle" className="box-row-card">
        {datainfoTabCard.map((item, idx) => (
          <Col
            key={idx}
            span={8}
            className={
              menuTab === idx ? `card-menu-tab-active` : `card-menu-tab`
            }
            style={{
              backgroundColor: menuTab === idx ? "#FFC627" : "white",
              borderTopLeftRadius: idx === 0 ? "5px" : 0,
              borderBottomLeftRadius: idx === 0 ? "5px" : 0,
              borderTopRightRadius: idx === 2 ? "5px" : 0,
              borderBottomRightRadius: idx === 2 ? "5px" : 0,
              borderRight: idx === 2 ? 0 : "1px solid #d9d9d9",
            }}
          >
            <div onClick={() => HandleOnChangeTab(idx)}>
              <Row justify="space-between" align="middle">
                <img
                  src={menuTab === idx ? item.imgAct : item.img}
                  style={{ width: "80px" }}
                  alt="upToYou"
                />
                <Col>
                  <div
                    style={{
                      fontFamily: "semiBold",
                      textAlign: "right",
                      fontSize: "18px",
                    }}
                  >
                    {item.text}
                  </div>
                  <div
                    style={{
                      fontFamily: "Bold",
                      fontSize: "40px",
                      textAlign: "right",
                    }}
                  >
                    {item.count}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        ))}
      </Row>
      <Row justify="space-between" align="middle" className="my-3">
        <FormDefault
          Field={field}
          Action={action}
          HandleOnFinish={HandleOnFinish}
        />
        <Button
          icon={<PlusOutlined />}
          onClick={HandleAdd}
          type="primary"
          className="btn-gradient mb-2"
        >
          เพิ่มรายการ
        </Button>
      </Row>
      <Card
        className="setting-cover-card"
        cover={
          <>
            <h1 className="mb-0">
              <span
                style={{
                  fontFamily: "Bold",
                  color: "#faa41a",
                  fontSize: "23px",
                }}
              >
                CUSTOMER SERVICE
              </span>
              <span
                className="ml-2"
                style={{ fontFamily: "Bold", fontSize: "23px" }}
              >
                การบริการลูกค้า
              </span>
            </h1>
          </>
        }
      >
        <TableModifind
          cursor={true}
          rowKey={"index"}
          loading={tableLoading()}
          bordered={true}
          scroll={{ x: 2500, y: 500 }}
          config={{
            total: total,
            pageSize: limitPage,
            currentPage: searchInfo.page,
          }}
          columns={columns}
          dataSource={Refacter()}
          onClickRow={HandleOnDetail}
          onChangePage={onChangePage}
        />
      </Card>
    </div>
  );
};

export default List;

import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Row } from "antd";

type Props = {
  on: boolean;
  onClick?: (value: any) => void;
};

const CloseBtnTab = ({ on = false, onClick }: Props) => {
  const OnCahnge = (value?: any) => {
    if (onClick) {
      onClick(value);
    }
  };
  return (
    <div className="w-100">
      <Row justify={"end"} align="middle">
        <div onClick={OnCahnge} className="close-tab-table-btn">
          {on ? (
            <Row justify="space-between" align="middle">
              ปิด <CaretUpOutlined className="ml-1" />
            </Row>
          ) : (
            <Row justify="space-between" align="middle">
              เเสดง <CaretDownOutlined className="ml-1" />
            </Row>
          )}
        </div>
      </Row>
    </div>
  );
};

export default CloseBtnTab;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { Button, Card, Col, Modal, Row } from "antd";
import { _isEmpty } from "@yim/util-tool-ts";
import { useSetRecoilState } from "recoil";
import { loadingTable } from "../../../tools/recoil/recoil-atom";
import { AlertToast } from "../../../components/alert/alert";
import CloseSale from "../../../assets/img/icon-direc-customer/CloseSale.svg";
import DontBuy from "../../../assets/img/icon-direc-customer/DontBuy.svg";
import OnPlan from "../../../assets/img/icon-direc-customer/OnPlan.svg";
import TableModifind from "../../../components/table/Table";
import { tableLoading } from "../../../components/loading/loading";
import {
  DEL_ITEM_REVERT_API,
  LEAD_GET_DELETE_ITEM_API,
  REVERT_ITEM_DEL_API,
} from "../../../services/api/Lead_api";
import HeaderTabMenu from "../../../components/header_tab_menu/HeaderTabMenu";
import moment from "moment";
import {
  DeleteOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { _numToDate } from "../../../tools/util";
import { IProjectList } from "../project/Project";

const RycycleBin = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [dataTable, setDataTable] = useState<IProjectList[]>([]);
  const [limitPage, setLimitPage] = useState(10);
  const [total, setTotal] = useState<number>();
  const [searchInfo, setSearchInfo] = useState({
    page: 1,
  } as any);
  const [selectItem, setSelectItem] = useState([] as any);
  const history = useHistory();

  useEffect(() => {
    handleGetDataAPI();
    return undefined;

  }, [limitPage, searchInfo]);

  const handleGetDataAPI = async () => {
    const dataInfo = {
      page: searchInfo?.page,
      limit: limitPage,
    };
    setLoading(true);
    try {
      const res = await LEAD_GET_DELETE_ITEM_API(dataInfo);
      if (res.code === 200) {
        const refacter = HandleRefacter(res.result[0].item);
        setDataTable(refacter);
        setTotal(res.result[0]?.total);
      } else {
        AlertToast({
          icon: "error",
          title: `ไม่สามารถเเสดงตัวเลือก เนื่องจาก ข้อผิดพลาด !${res.message}`,
        });
        history.goBack();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const HandleRefacter = (arr: IProjectList[] = []) => {
    const result = arr.map((item: any, idx: number) => {
      const dataRefacter = {
        ...item,
        customerName: !_isEmpty(item?.firstName)
          ? `${item?.firstName} ${item?.lastName}`
          : !_isEmpty(item?.nickname)
          ? `${item?.nickname}`
          : `${item?.socialName}`,
        date: _numToDate(Number(item?.date)),
        specAlumet: item?.specAlumet ? "Yes" : "No",
      };
      return dataRefacter;
    });
    return result;
  };
  
  /////////////////////////////////////////Function of Form Default///////////////////////////////////////////////////
  const RenderStatusColumn = (src: string, alt: string, text: string) => {
    return (
      <div style={{ textAlign: "center" }}>
        <img
          src={src}
          alt={alt}
          style={{
            border: "1px solid #EDEDED",
            backgroundColor: "white",
            padding: 3,
            borderRadius: 10,
            textAlign: "center",
          }}
        />
        <div
          style={{
            paddingTop: 5,
            fontWeight: "bold",
            color:
              text === "ปิดการขาย"
                ? "#18C318"
                : text === "ดำเนินการ"
                ? "#16BAFF"
                : "#DB4B28",
          }}
        >
          {text}
        </div>
      </div>
    );
  };

  const columns: any = [
    {
      title: "สถานะ",
      width: 85,
      dataIndex: "status",
      key: "status",
      render: (value: any, data: any) => {
        return (
          <div>
            {value === "DO_NOT_BUY"
              ? RenderStatusColumn(DontBuy, "DontBuy", "ไม่ซื้อ")
              : value === "ON_PLAN"
              ? RenderStatusColumn(OnPlan, "OnPlan", "ดำเนินการ")
              : value === "PENDING"
              ? RenderStatusColumn(DontBuy, "DontBuy", "อยู่ระหว่างพิจารณา")
              : value === "CAN_NOT_TACKING"
              ? RenderStatusColumn(DontBuy, "DontBuy", "ไม่สามารถติดต่อได้")
              : value === "COMPLETED"
              ? RenderStatusColumn(DontBuy, "DontBuy", "ได้รับบริการเรียบร้อยเเล้ว")
              : RenderStatusColumn(CloseSale, "CloseSale", "ปิดการขาย")}
          </div>
        );
      },
    },
    {
      title: "วันที่ติดต่อ",
      dataIndex: "date",
      key: "date",
      width: 85,
      render: (value: any, _row: any) => {
        return (
          <Row justify="center" align="middle">
            {value}
          </Row>
        );
      },
    },
    {
      title: "ประเภทผู้ติดต่อ",
      dataIndex: "userContactType",
      key: "userContactType",
      width: 105,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customerName",
      key: "customerName",
      width: 90,
    },
    {
      title: "ชื่อบริษัท",
      dataIndex: "companyName",
      key: "companyName",
      width: 80,
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "projectName",
      key: "projectName",
      width: 90,
    },
    {
      title: "ความต้องการ",
      dataIndex: "subjectContact",
      key: "subjectContact",
      width: 100,
      render: (value: {}[]) => {
        return value?.map((item: any, idx: number) => (
          <div
            key={idx}
            style={{ paddingBottom: idx === value.length - 1 ? "" : "5px" }}
          >
            {item}
          </div>
        ));
      },
    },
    {
      title: "สินค้า",
      dataIndex: "interestedProduct",
      key: "interestedProduct",
      width: 80,
      render: (value: any, _row: any) => {
        return (
          <div>
            {value.map(
              (item: any, idx: number) =>
                `${item}${idx === value.length - 1 ? "" : ", "}`
            )}
          </div>
        );
      },
    },
    {
      title: "สี",
      dataIndex: "aluminiumColor",
      key: "aluminiumColor",
      width: 75,
      render: (value: any, _row: any) => {
        return (
          <div>
            {value.map(
              (item: any, idx: number) =>
                `${item}${idx === value.length - 1 ? "" : ", "}`
            )}
          </div>
        );
      },
    },
    {
      title: "สเปค",
      dataIndex: "specAlumet",
      key: "specAlumet",
      width: 55,
      align: "center" as "center",
      render: (value: any, _row: any) => {
        return (
          <Row justify="center" align="middle">
            {value}
          </Row>
        );
      },
    },
    {
      title: "ผู้รับผิดชอบ",
      dataIndex: "adminName",
      key: "adminName",
      width: 90,
    },
    {
      title: "รายละเอียดเพิ่มเติม",
      dataIndex: "notation",
      key: "notation",
      width: 130,
    },
    {
      title: "เวลาถอยหลัง",
      dataIndex: "countDown",
      key: "countDown",
      width: 125,
      align: "center" as "center",
      render: (value: any, _data: any) => {
        return (
          <>
            <span>รายการจะถูกลบภายใน</span>
            <span
              style={{
                color:
                  value < 7 ? "red" : value < 18 ? "rgb(255, 217, 0)" : "green",
                fontWeight: "bold",
                fontSize: 18,
              }}
              className="mx-2"
            >
              {value}
            </span>
            <span>วัน</span>
          </>
        );
      },
    },
  ];

  /////////////////////////////////////////Function of Form Default///////////////////////////////////////////////////
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setSearchInfo({ ...searchInfo, page: page });
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], _selectedRows: any[]) => {
      setSelectItem(selectedRowKeys);
    },
  };

  const HandleRevert = async () => {
    const datainfo = {
      ids: selectItem,
    };
    try {
      if (selectItem.length > 0) {
        const res = await REVERT_ITEM_DEL_API(datainfo);
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `ท่านทำรายการสำเร็จ !`,
          });
          handleGetDataAPI();
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ทำรายการของท่านไม่สำเร็จ กรุณาลองอีกครั้งในภายหลัง`,
          });
        }
      } else {
        AlertToast({
          icon: "warning",
          title: `ท่านไม่มีรายการที่ต้องการคืนค่า !`,
        });
        handleCancel();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const HandleDeleteRevert = async () => {
    const datainfo = {
      ids: selectItem,
    };
    try {
      if (selectItem.length > 0) {
        const res = await DEL_ITEM_REVERT_API(datainfo);
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `ท่านทำรายการสำเร็จ!`,
          });
          handleCancel();
          handleGetDataAPI();
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ทำรายการของท่านไม่สำเร็จ กรุณาลองอีกครั้งในภายหลัง`,
          });
        }
      } else {
        AlertToast({
          icon: "warning",
          title: `ท่านไม่มีรายการที่ต้องการลบ!`,
        });
        handleCancel();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  /////////////////////////////////////////Function of Form Default///////////////////////////////////////////////////

  return (
    <div>
      <HeaderTabMenu
        title="RYCICLE BIN"
        message="รายการที่ถูกลบ"
        style={{ borderRadius: `0 0 10px 10px` }}
      />
      {/* ///FOR TO SEARCH WHEUN USER NEED IT */}
      {/* <Row justify="space-between" align="middle" className="my-3">
        <FormDefault
          Field={field}
          Action={[]}
          HandleOnFinish={HandleOnFinish}
        />
        <Button
          icon={<PlusOutlined />}
          onClick={()=>{}}
          type="primary"
          className="btn-gradient mb-2"
        >
          เพิ่มรายการ
        </Button>
      </Row> */}
      {/* ///FOR TO SEARCH WHEUN USER NEED IT */}
      <Modal
        title={false}
        open={isModalVisible}
        onOk={HandleDeleteRevert}
        onCancel={handleCancel}
        closable={false}
      >
        <div className="text-center">
          <QuestionCircleOutlined
            style={{
              color: "#fdb714",
              fontSize: 130,
            }}
            className="mt-5 mb-4"
          />
          <h1
            style={{
              color: "#fdb714",
              fontWeight: "bold",
            }}
          >
            เเน่ใจหรือไม่ ?
          </h1>
          <h3
            style={{
              color: "black",
            }}
          >
            รายการของคุณจะถูกลบถาวร
          </h3>
          <p>
            <span
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              หมายเหตุ :{" "}
            </span>
            หากไม่มีการกระทำใดๆ รายการของคุณจะถูกลบ ภายในเวลานับถอยหลังที่กำหนด
          </p>
        </div>
      </Modal>
      <Card
        className="setting-cover-card mt-4"
        cover={
          <Row align="middle" justify="space-between">
            <h1 className="mb-0">
              <span
                style={{
                  fontFamily: "Bold",
                  color: "#faa41a",
                  fontSize: "23px",
                }}
              >
                RYCICLE BIN
              </span>
              <span
                className="ml-2"
                style={{ fontFamily: "Bold", fontSize: "23px" }}
              >
                รายการที่ถูกลบ
              </span>
            </h1>
            <Row align="middle" gutter={8}>
              <Col span={12}>
                <Button
                  icon={<RetweetOutlined />}
                  onClick={HandleRevert}
                  className="btn-card-detail-revert mt-2"
                  size="large"
                  style={{ height: 50 }}
                >
                  Revert
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  onClick={() => setIsModalVisible(true)}
                  icon={<DeleteOutlined />}
                  className="btn-card-detail-delete mt-2"
                  size="large"
                  style={{ height: 50 }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Row>
        }
      >
        <TableModifind
          cursor={false}
          rowKey={"id"}
          loading={tableLoading()}
          bordered={true}
          scroll={{ x: 2000, y: 500 }}
          config={{
            total: total,
            pageSize: limitPage,
            currentPage: searchInfo.page,
          }}
          columns={columns}
          dataSource={dataTable}
          onChangePage={onChangePage}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Card>
    </div>
  );
};

export default RycycleBin;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Row, Popover, Col } from "antd";
import { useState } from "react";
// @ts-ignore
import logo from "../assets/img/logoAlumet.png";
// @ts-ignore
import { useHistory } from "react-router-dom";
import { onImgErrorProfile } from "../tools/util";

const HeadBarLayout = () => {
  let history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);
  const currentUser = JSON.parse(localStorage.getItem("userInfo") || "");

  const HandleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("session");
    history.push("/login");
  };

  return (
    <Row justify="space-between" align="middle">
      <img src={logo} style={{ width: "170px" }} />
      <Col>
        <Row align="middle">
          <img
            src={currentUser?.image}
            onError={(e: any) => {
              onImgErrorProfile(e);
            }}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: 50,
              margin: "15px",
              objectFit: "cover",
            }}
          />
          <Popover
            className="pop-logout"
            content={
              <div className="btn-logout-head" onClick={HandleLogout}>
                ออกจากระบบ
              </div>
            }
            title={false}
            open={visible}
            onOpenChange={() => setVisible(!visible)}
          >
            <div
              style={{
                fontSize: "18px",
                fontFamily: "Bold",
                paddingRight: "10px",
                height: 20,
              }}
            >
              {`${currentUser?.firstName} ${currentUser?.lastName}` || "admin"}
            </div>
            <div style={{ fontFamily: "SemiBold" }}>
              {currentUser?.role || "admin"}
            </div>
          </Popover>
        </Row>
      </Col>
    </Row>
  );
};

export default HeadBarLayout;

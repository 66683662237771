/* eslint-disable react-hooks/exhaustive-deps */

import { Form, Table } from "antd";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { AlertToast } from "../../../components/alert/alert";
import { tableLoading } from "../../../components/loading/loading";
import {
  GETQUALIFYREPORT_API,
  QUALIFY_EXCEL_API,
} from "../../../services/api/report/qualify";
import { IResStatus } from "../../../tools/fecth/interface";
import { loadingTable } from "../../../tools/recoil/recoil-atom";
import Header from "./Header/Header";

const SubReportQualifyLead = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [searchYears, setSearchYears] = useState(new Date().getFullYear().toString());
  const [isFirstWork, setisFirstWork] = useState<boolean>(true);
  const [qualify, setQualify] = useState<any[]>([]);

  const currentYear = new Date();
  const [form] = Form.useForm();

  useEffect(() => {
    if (isFirstWork) {
      form.setFieldsValue({ years: `${currentYear.getFullYear()}` });
      handleGetQualify();
      setisFirstWork(false);
    } else {
      handleGetQualify();
    }
    return undefined;

  }, [searchYears]);

  const handleGetQualify = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await GETQUALIFYREPORT_API({
        year: searchYears,
      });
      if (res.code === 200) {
        const itemList = res.result[0].item;
        const qualifyDataFormat = itemList.map((item: any, idMain: number) => {
          let mainObj = {};
          item.data.forEach((header: any) => {
            mainObj = {
              key: `main ${idMain + 1}`,
              name: `${idMain + 1}. ${item.name}`,
              avr: item.average.toFixed(2),
              ...mainObj,
              [header.label]: header.count,
            };
          });
          mainObj = {
            ...mainObj,
            type: item.name,
            target: item.goal,
            description: item?.description || "",
          };
          return mainObj;
        });
        setQualify(qualifyDataFormat);
      }
    } catch (error) {}
    setLoading(false);
  };

  const filterCoulumnToCurrentMount = (columns: any) => {
    const dateTime = new Date();
    const currentMount = dateTime.getMonth();
    const toCurrentMount = Number(searchYears) === dateTime.getFullYear()
    ? columns.filter(
      (item: any) =>
        item.key === "description" ||
        item.key === "name" ||
        item.key === "type" ||
        item.key === "target" ||
        item.key === "avr" ||
        Number(item.key) <= currentMount + 1
    ) : columns;
    return toCurrentMount;
  };

  const handleExportExcel = async () => {
    setLoading(true);
    try {
      const res = await QUALIFY_EXCEL_API({
        year: searchYears,
      });
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `qualify.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถส่งออกข้อมูลได้`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Qualify Lead",
      dataIndex: "description",
      key: "description",
      fixed: true,
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      fixed: true,
      width: 90,
      align: "center" as "center",
    },
    {
      title: "เป้าหมาย",
      dataIndex: "target",
      key: "target",
      fixed: true,
      width: 60,
      align: "center" as "center",
    },

    {
      title: "ม.ค.",
      align: "center" as "center",
      dataIndex: "1",
      key: "1",
      width: 40,
    },
    {
      title: "ก.พ.",
      align: "center" as "center",
      dataIndex: "2",
      key: "2",
      width: 40,
    },
    {
      title: "มี.ค.",
      align: "center" as "center",
      dataIndex: "3",
      key: "3",
      width: 40,
    },
    {
      title: "เม.ย.",
      align: "center" as "center",
      dataIndex: "4",
      key: "4",
      width: 40,
    },
    {
      title: "พ.ค.",
      align: "center" as "center",
      dataIndex: "5",
      key: "5",
      width: 40,
    },
    {
      title: "มิ.ย.",
      align: "center" as "center",
      dataIndex: "6",
      key: "6",
      width: 40,
    },
    {
      title: "ก.ค.",
      align: "center" as "center",
      dataIndex: "7",
      key: "7",
      width: 40,
    },
    {
      title: "ส.ค.",
      align: "center" as "center",
      dataIndex: "8",
      key: "8",
      width: 40,
    },
    {
      title: "ก.ย.",
      align: "center" as "center",
      dataIndex: "9",
      key: "9",
      width: 40,
    },
    {
      title: "ต.ค.",
      align: "center" as "center",
      dataIndex: "10",
      key: "10",
      width: 40,
    },
    {
      title: "พ.ย.",
      align: "center" as "center",
      dataIndex: "11",
      key: "11",
      width: 40,
    },
    {
      title: "ธ.ค.",
      align: "center" as "center",
      dataIndex: "12",
      key: "12",
      width: 40,
    },
    {
      title: "ค่าเฉลี่ย",
      dataIndex: "avr",
      key: "avr",
      align: "center" as "center",
      width: 50,
    },
  ];
  const handleFilter = (value: string) => {
    setSearchYears(value);
  };
  return (
    <div>
      <Header
        form={form}
        handleExportExcel={handleExportExcel}
        handleFilter={handleFilter}
        data={{
          item: {
            key: 1,
            text: "รายงานสรุปยอด Qualify Lead (หน่วย : เคส)",
          },
        }}
      >
        <Table
          rowKey={(row) => row.key}
          style={{ marginTop: "20px" }}
          scroll={{ y: 500 }}
          dataSource={qualify}
          columns={filterCoulumnToCurrentMount(columns)}
          bordered
          expandRowByClick
          loading={tableLoading()}
        />
      </Header>
    </div>
  );
};

export default SubReportQualifyLead;

import { Fetch } from "../../../tools/fecth/fecth";
import { apiVersion } from '../../../config/api'
import { createQueryString } from "../../../tools/util";

export const GETREQUIREMENTTYPE_API = async (data: {}) => {
    const qrs = createQueryString(data);
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/requirement-type${qrs}` });
    return res;
}

export const GETREQUIREMENTTYPEALL_API = async () => {
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/requirement-type/all` });
    return res;
}

export const GETREQUIREMENTTYPEID_API = async (id: number) => {
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/requirement-type/${id}` });
    return res;
}

export const ADDREQUIREMENTTYPE_API = async (data: any) => {
    const res = await Fetch({ method: 'POST', path: `${apiVersion}/requirement-type`, data });
    return res;
}

export const EDITREQUIREMENTTYPE_API = async ({ data, id }: { data: any, id: number }) => {
    const res = await Fetch({ method: 'PUT', path: `${apiVersion}/requirement-type/${id}`, data });
    return res;
}

export const DELETEREQUIREMENTTYPE_API = async (id: number) => {
    const res = await Fetch({ method: 'DELETE', path: `${apiVersion}/requirement-type/${id}` });
    return res;
}
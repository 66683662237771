import { atom, selectorFamily } from "recoil";

export enum EFieldsProject {
  CUSTOMER_CONTACT = "customer_contact",
  REQUIREMENT = "requirement",
  ACTION = "action",
  NOTE = "note",
  ACTIVITY = "activity",
}

const tab_visible_atom_project = atom({
  key: "tab_visible_atom_project",
  default: {
    [EFieldsProject.CUSTOMER_CONTACT]: true,
    [EFieldsProject.REQUIREMENT]: true,
    [EFieldsProject.ACTION]: true,
    [EFieldsProject.NOTE]: true,
    [EFieldsProject.ACTIVITY]: true,
  },
});

export const tab_visible_selector_project = selectorFamily({
  key: "tab_visible_selector_project",
  get:
    (key: EFieldsProject) =>
    ({ get }) => {
      return get(tab_visible_atom_project)[key];
    },
  set:
    (key: EFieldsProject) =>
    ({ set, get }, bool) => {
      const current = get(tab_visible_atom_project);
      const next = { ...current, [key]: bool };
      set(tab_visible_atom_project, next);
    },
});

const tab_edit_atom_project = atom({
  key: "tab_edit_atom_project",
  default: {
    [EFieldsProject.CUSTOMER_CONTACT]: false,
    [EFieldsProject.REQUIREMENT]: false,
    [EFieldsProject.ACTION]: false,
    [EFieldsProject.NOTE]: false,
    [EFieldsProject.ACTIVITY]: false,
  },
});

export const tab_edit_selector = selectorFamily({
  key: "tab_edit_selector",
  get:
    (key: EFieldsProject) =>
    ({ get }) => {
      return get(tab_edit_atom_project)[key];
    },
  set:
    (key: EFieldsProject) =>
    ({ set, get }, bool) => {
      const current = get(tab_edit_atom_project);
      const next = { ...current, [key]: bool };
      set(tab_edit_atom_project, next);
    },
});

/* eslint-disable react-hooks/rules-of-hooks */
import Swal from "sweetalert2";
import { titleFormat } from "./title_format";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

type TMethod =
  | "LOADDING"
  | "POST_SUCCESS"
  | "POST_ERROR"
  | "PUT_SUCCESS"
  | "PUT_ERROR"
  | "DEL_SUCCESS"
  | "DEL_ERROR"
  | "DOWNLOAD_SUCCESS"
  | "DOWNLOAD_ERROR"
  | "UPLOAD_SUCCESS"
  | "UPLOAD_ERROR"
  | "TRANSFER_SUCCESS"
  | "TRANSFER_ERROR";

export const CAlertToast = ({
  method,
  text = "",
}: {
  method: TMethod;
  text?: string;
}) => {
  text ? Toast.fire({
    icon: titleFormat[method]["type"],
    title: titleFormat[method]["title"],
    text: text,
  }) : Toast.fire({
    icon: titleFormat[method]["type"],
    title: titleFormat[method]["title"],
  });
};

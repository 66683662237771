/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { Card, Col, Divider, Row, Typography, Form, Modal } from "antd";

import HeaderSetting from "../header_setting/HerderSetting";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Password from "../../../../../components/PasswordEdit";

//////////////////////////api//////////////////////
import {
  ADDFORMUSER_API,
  EDITADMINPROFILE_API,
  EDITFORMUSER_API,
  GETADMINPROFILE_API,
  GETUSERDATAID_API,
} from "../../../../../services/api/setting/usermanagement_api";
import Uploader from "../../../../../components/uploader/Uploader";
import { _isEmpty } from "@yim/util-tool-ts";
import FInput from "../../../../../components/form_floatlabel/FInput";
import { IResStatus } from "../../../../../tools/fecth/interface";
import { GETDEPARTMENTALL_API } from "../../../../../services/api/setting/department_api";
import { GETUSERROLEALL_API } from "../../../../../services/api/setting/userrole_api";
import CImage from "../../../../../components/custom_image/CImage";
import { createFormDataFields } from "../../../../../tools/util";
import { CAlertToast } from "../../../../../components/alert/CAlert";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../../../tools/recoil/recoil-atom";
import {
  ADDTECHNICIAN_API,
  EDITTECHNICIAN_API,
  GETTECHNICIANID_API,
} from "../../../../../services/api/setting/technicial_api";
import WorkerForm from "./WorkerForm";
import CompanyForm from "./CompanyForm";
import {
  ADDCUSTOMER_API,
  DELETECUSTOMER_API,
  EDITCUSTOMER_API,
  GETCUSTOMERID_API,
} from "../../../../../services/api/setting/customer_api";
import {
  GET_DISTRICT_BY_ID,
  GET_PROVINCE,
  GET_PROVINCE_BY_ID,
} from "../../../../../services/api/Lead_api";
import { useReactToPrint } from "react-to-print";
import PrintPDF from "./PrintPDF";

const UserForm = () => {
  const setLoading = useSetRecoilState(loadingState);

  const { Title } = Typography;

  const { url } = useRouteMatch();
  const { params } = useRouteMatch();
  const [disabledEdit, setDisabledEdit] = useState<boolean>(true);
  const [disabledEditPassword, setDisabledEditPassword] =
    useState<boolean>(true);
  const { id }: any = params;
  const history = useHistory();
  const [form] = Form.useForm();
  const [formHeader] = Form.useForm();

  const [imageUrl, setImageUrl] = useState("");
  const [imageRender, setImageRender] = useState("");
  const [isFisrtWork, setIsFirstWork] = useState(true);
  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [visible, isVisible] = useState(false);
  const [dataSource, setDataSource] = useState({});

  const isProfile = url.includes("/profile");
  const isUser = url.includes("/usermanagement");
  const isTechnician = url.includes("/technicianmanagement");
  const isCustomer = url.includes("/customermanagement");

  const pathID = !_isEmpty(id);
  const isCreate = !pathID && !isProfile;
  const location = useLocation();
  const pathName = location?.pathname?.split("/")[2] || "";
  const componentRef = useRef<any>(null);

  useEffect(() => {
    if (isFisrtWork) handleGetData();
    checkPage();
    return undefined;
  }, []);

  const checkPage = () => {
    if (isCreate) {
      setDisabledEdit(false);
      setDisabledEditPassword(false);
    } else if (isProfile) {
      getAdminProfile();
    } else if (isUser && pathID) {
      getAdminByID();
    } else if (isTechnician && pathID) {
      getTechnicianByID();
    } else if (isCustomer && pathID) {
      getCustomerByID();
    }
  };

  const getAdminProfile = async () => {
    setLoading(true);
    try {
      const res = await GETADMINPROFILE_API();
      if (res.code === 200) {
        const data = res.result[0];
        form.setFieldsValue({ ...data });
        setDataSource(data);
        setImageUrl(data.image);
        setImageRender(data.image);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getAdminByID = async () => {
    setLoading(true);
    try {
      const res = await GETUSERDATAID_API(id);
      if (res.code === 200) {
        const data = res.result[0];
        form.setFieldsValue({ ...data });
        setDataSource(data);
        setImageUrl(data.image);
        setImageRender(data.image);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getTechnicianByID = async () => {
    setLoading(true);
    try {
      const res = await GETTECHNICIANID_API(id);
      if (res.code === 200) {
        const data = res.result[0];
        form.setFieldsValue({ ...data });
        setDataSource(data);
        setImageUrl(data.image);
        setImageRender(data.image);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getCustomerByID = async () => {
    setLoading(true);
    try {
      const res = await GETCUSTOMERID_API(id);
      if (res.code === 200) {
        const data = res.result[0];
        form.setFieldsValue({ ...data });
        setDataSource(data);
        setImageUrl(data.image);
        setImageRender(data.image);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleClickEdit = () => {
    setDisabledEdit(!disabledEdit);
  };

  const handleClickDelete = () => {
    console.log("pathName", pathName);
    isVisible(!visible);
  };

  const OnDelete = async () => {
    const CheckApi = () => {
      if (isCustomer && pathID) return DELETECUSTOMER_API(id);
    };

    const CheckGoBack = () => {
      switch (pathName) {
        case "customermanagement":
          return "/setting/customermanagement/member";
        case "technicianmanagement":
          return "/setting/technicianmanagement/member";
        case "usermanagement":
          return "/setting/usermanagement/member";
        default:
          return "";
      }
    };

    setLoading(true);
    try {
      const res = await CheckApi();
      if (res.code === 200) {
        CAlertToast({ method: "DEL_SUCCESS", text: res.message });
        _isEmpty(CheckGoBack())
          ? history.goBack()
          : history.push(CheckGoBack());
      } else {
        CAlertToast({ method: "DEL_ERROR", text: res.message });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleClickCancel = () => {
    setDisabledEdit(true);
    setDisabledEditPassword(true);
    checkPage();
    if (!isProfile && !pathID) {
      history.goBack();
    }
  };
  const handleClickEditPassword = () => {
    setDisabledEditPassword(false);
    setDisabledEdit(false);
  };

  const onFinish = async (value: any) => {
    !value?.ssn && delete value.ssn
    const dataSetFormat = { ...value, avatar: imageUrl };
    const FormData = createFormDataFields(dataSetFormat);
    if (isProfile) handleUpdateProfile(FormData);
    else if (isUser && isCreate) handleCreateUser(FormData);
    else if (isUser && pathID) handleUpdateUser(FormData);
    else if (isTechnician && isCreate) handleCreateTechnician(FormData);
    else if (isTechnician && pathID) handleUpdateTechnician(FormData);
    else if (isCustomer && isCreate) handleCreateCustomer(FormData);
    else if (isCustomer && pathID) handleUpdateCustomer(FormData);
  };

  const handleUpdateProfile = async (FormData: any) => {
    setLoading(true);
    try {
      const res: IResStatus = await EDITADMINPROFILE_API(FormData);
      if (res.code === 200) {
        handleClickCancel();
        const data = res.result[0];
        form.setFieldsValue({ ...data });
        setDataSource(data);
        CAlertToast({ method: "PUT_SUCCESS", text: res.message });
      } else {
        CAlertToast({ method: "PUT_ERROR", text: res.message });
      }
    } catch (error) { }
    setLoading(false);
  };

  const handleCreateUser = async (FormData: any) => {
    setLoading(true);
    try {
      const res: IResStatus = await ADDFORMUSER_API(FormData);
      if (res.code === 200) {
        CAlertToast({ method: "POST_SUCCESS", text: res.message });
        history.push("/setting/usermanagement/member");
      } else {
        CAlertToast({ method: "POST_ERROR", text: res.message });
      }
    } catch (error) { }
    setLoading(false);
  };

  const handleUpdateUser = async (FormData: any) => {
    setLoading(true);
    try {
      const res: IResStatus = await EDITFORMUSER_API({ data: FormData, id });
      if (res.code === 200) {
        handleClickCancel();
        CAlertToast({ method: "PUT_SUCCESS", text: res.message });
        getAdminByID();
      } else {
        CAlertToast({ method: "PUT_ERROR", text: res.message });
      }
    } catch (error) { }
    setLoading(false);
  };

  const handleCreateTechnician = async (FormData: any) => {
    setLoading(true);
    try {
      const res: IResStatus = await ADDTECHNICIAN_API(FormData);
      if (res.code === 200) {
        CAlertToast({ method: "POST_SUCCESS", text: res.message });
        history.push("/setting/technicianmanagement/member");
      } else {
        CAlertToast({ method: "POST_ERROR", text: res.message });
      }
    } catch (error) { }
    setLoading(false);
  };

  const handleUpdateTechnician = async (FormData: any) => {
    setLoading(true);
    try {
      const res: IResStatus = await EDITTECHNICIAN_API({ data: FormData, id });
      if (res.code === 200) {
        handleClickCancel();
        CAlertToast({ method: "PUT_SUCCESS", text: res.message });
        getTechnicianByID();
      } else {
        CAlertToast({ method: "PUT_ERROR", text: res.message });
      }
    } catch (error) { }
    setLoading(false);
  };

  const handleCreateCustomer = async (FormData: any) => {
    setLoading(true);
    try {
      const res: IResStatus = await ADDCUSTOMER_API(FormData);
      if (res.code === 200) {
        CAlertToast({ method: "POST_SUCCESS", text: res.message });
        history.push("/setting/customermanagement/member");
      } else {
        CAlertToast({ method: "POST_ERROR", text: res.message });
      }
    } catch (error) { }
    setLoading(false);
  };

  const handleUpdateCustomer = async (FormData: any) => {
    setLoading(true);
    try {
      const res: IResStatus = await EDITCUSTOMER_API({ data: FormData, id });
      if (res.code === 200) {
        handleClickCancel();
        CAlertToast({ method: "PUT_SUCCESS", text: res.message });
        getCustomerByID();
      } else {
        CAlertToast({ method: "PUT_ERROR", text: res.message });
      }
    } catch (error) { }
    setLoading(false);
  };

  const handleGetData = () => {
    setIsFirstWork(false);
    handleGetDepartmentAll();
    handleGetRoleAll();
  };

  const handleGetRoleAll = async () => {
    try {
      const res: IResStatus = await GETUSERROLEALL_API();
      if (res.code === 200) {
        setRoleList(res.result[0].item);
      }
    } catch (error) { }
  };

  const handleGetDepartmentAll = async () => {
    try {
      const res: IResStatus = await GETDEPARTMENTALL_API();
      if (res.code === 200) {
        setDepartmentList(res.result[0].item);
      }
    } catch (error) { }
  };

  const profileTitleHeader = {
    nameEN: "PROFILE",
    nameTH: "จัดการโปรไฟล์ส่วนตัว",
  };
  const userManagementTitleHeader = {
    nameEN: "USER MANAGEMENT",
    nameTH: "จัดการผู้ใช้",
  };

  const technicianTitleHeader = {
    nameEN: "TECHNICIAN MANAGEMENT",
    nameTH: "จัดการช่าง",
  };
  const customerTitleHeader = {
    nameEN: "MEMBER CUSTOMER",
    nameTH: "สมาชิกลูกค้า",
  };

  const findTitleHeader = () => {
    if (isProfile) return profileTitleHeader;
    else if (isUser) return userManagementTitleHeader;
    else if (isTechnician) return technicianTitleHeader;
    else if (isCustomer) return customerTitleHeader;
    return { nameEN: "", nameTH: "" };
  };

  const [loadingWant, setLoadingWant] = useState(false);
  const [listProvince, setListProvince] = useState([] as any);
  const [listDistrict, setListDistrict] = useState([] as any);
  const [listSubDistrict, setListSubDistrict] = useState([] as any);

  useEffect(() => {
    HandleGetProvince();
    return undefined;
  }, []);

  const HandleGetProvince = async () => {
    try {
      const res = await GET_PROVINCE();
      if (res.code === 200) {
        setListProvince(res.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeProvince = async (data: any) => {
    setLoadingWant(true);
    try {
      const res = await GET_PROVINCE_BY_ID(data);
      if (res.code === 200) {
        form.setFieldsValue({
          province: listProvince?.find((item: any) => item?.id === data)
            ?.nameTh,
        });
        setListDistrict(res?.result);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingWant(false);
  };

  const handleChangeDistrict = async (data: any) => {
    setLoadingWant(true);
    try {
      const res = await GET_DISTRICT_BY_ID(data);
      if (res.code === 200) {
        form.setFieldsValue({
          district: listDistrict?.find((item: any) => item?.id === data)
            ?.nameTh,
        });
        setListSubDistrict(res?.result);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingWant(false);
  };

  const HandleChangeSubDistrict = (data: any) => {
    const result = listSubDistrict?.find((item: any) => item?.id === data);
    form.setFieldsValue({
      subDistrict: result?.nameTh,
      postalCode: result?.zipCode,
    });
  };

  const HandleCheckToVisiblePassword = () => {
    switch (pathName) {
      case "customermanagement":
        return false;
      case "technicianmanagement":
        return false;
      default:
        return true;
    }
  };

  const HandleCheckProfileImg = () => {
    switch (pathName) {
      case "customermanagement":
        return false;
      case "technicianmanagement":
        return true;
      default:
        return true;
    }
  };

  const HandleExportPDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Technician Management",
  });

  const HandleCheckListBtn = () => {
    switch (pathName) {
      case "customermanagement":
        return [
          {
            type: "btnEdit",
            name: "Edit",
            onClick: form.submit,
          },
          {
            type: "btnDel",
            name: "Delete",
          },
        ];
      case "technicianmanagement":
        return [
          {
            type: "btnEdit",
            name: "Edit",
            onClick: form.submit,
          },
          {
            type: "btnExport",
            name: "PDF",
            onClick: HandleExportPDF,
          },
        ];
      default:
        return [
          {
            type: "btnEdit",
            name: "Edit",
            onClick: form.submit,
          },
        ];
    }
  };

  const btnAction = [
    {
      type: "btnSave",
      name: "บันทึก",
      onClick: form.submit,
    },
  ];

  const btnEdit = HandleCheckListBtn();

  return (
    <div>
      <HeaderSetting
        form={formHeader}
        Title={findTitleHeader()}
        Action={pathID || isProfile ? btnEdit : btnAction}
        handleClickEdit={handleClickEdit}
        handleClickCancel={handleClickCancel}
        handleClickDelete={handleClickDelete}
        disabledEdit={disabledEdit}
      />
      <Form onFinish={onFinish} form={form}>
        <div style={{ marginTop: "20px" }}>
          <Card className="card-round card-padding-space">
            <Title level={4}>ข้อมูลพื้นฐาน</Title>

            <Divider
              style={{ border: "1px solid #E9E9E9", marginTop: "5px" }}
            />

            <Row align="middle">
              {HandleCheckProfileImg() && (
                <Col span={12}>
                  <Row>
                    <Col md={24}>
                      <div className="user-profile-photo">
                        {disabledEdit ? (
                          <CImage
                            src={imageRender}
                            alt="icon"
                            style={{
                              height: "14rem",
                              width: "14rem",
                              objectFit: "cover",
                            }}
                            className="circle-img h-100"
                            defautImage="user"
                          />
                        ) : (
                          <Uploader
                            setImageUrl={setImageUrl}
                            setImageRender={setImageRender}
                            imageRender={imageRender}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}

              <Col span={HandleCheckProfileImg() ? 12 : 24}>
                <Row gutter={[14, 14]}>
                  <Col md={24}>
                    <h1>
                      <b>ข้อมูลพื้นฐาน</b>
                    </h1>
                  </Col>
                  <Col md={12}>
                    <FInput
                      label="ชื่อจริง"
                      name="firstName"
                      rules={{ required: true }}
                      options={{ disabled: disabledEdit }}
                    />
                  </Col>
                  <Col md={12}>
                    <FInput
                      label="นามสกุล"
                      name="lastName"
                      rules={{ required: true }}
                      options={{ disabled: disabledEdit }}
                    />
                  </Col>
                  <Col
                    md={
                      pathName !== "usermanagement" && pathName !== "profile"
                        ? 8
                        : 12
                    }
                  >
                    <FInput
                      label="ชื่อเล่น"
                      name="nickname"
                      rules={{ required: true }}
                      options={{ disabled: disabledEdit }}
                    />
                  </Col>
                  <Col
                    md={
                      pathName !== "usermanagement" && pathName !== "profile"
                        ? 8
                        : 12
                    }
                  >
                    <FInput
                      label="เบอร์โทรศัพท์"
                      name="phone"
                      rules={{ required: true }}
                      options={{ disabled: disabledEdit }}
                      type="number"
                    />
                  </Col>
                  {pathName !== "usermanagement" && pathName !== "profile" && (
                    <Col md={8}>
                      <FInput
                        label="ไอดีไลน์"
                        name="line"
                        rules={{ required: true }}
                        options={{ disabled: disabledEdit }}
                      />
                    </Col>
                  )}
                  {!isTechnician && !isCustomer && (
                    <WorkerForm
                      disabledEdit={disabledEdit}
                      departmentList={departmentList}
                      roleList={roleList}
                    />
                  )}
                  {(isTechnician || isCustomer) && (
                    <CompanyForm
                      disabledEdit={disabledEdit}
                      isCustomer={isCustomer}
                      provinceList={listProvince}
                      districtList={listDistrict}
                      subDistrictList={listSubDistrict}
                      onChangeProvince={handleChangeProvince}
                      onChangeDistrict={handleChangeDistrict}
                      onChangeSubDistrict={HandleChangeSubDistrict}
                      loading={loadingWant}
                    />
                  )}
                </Row>
                {HandleCheckToVisiblePassword() && (
                  <Row className="mt-3" gutter={[14, 14]}>
                    <Col md={24}>
                      <h1>
                        <b>ตั้งค่ารหัสผ่าน</b>
                      </h1>
                    </Col>
                    <Col md={24}>
                      <h1>
                        {!pathID && !isProfile
                          ? "สร้างรหัสผ่าน"
                          : "เปลี่ยนรหัสผ่าน"}
                      </h1>
                    </Col>
                    <Password
                      handleClickEdit={handleClickEditPassword}
                      disabledEditPassword={disabledEditPassword}
                      isCreate={isCreate}
                      isChangePassword={pathID || isProfile}
                      disabledEdit={disabledEdit}
                      isProfile={isProfile}
                    />
                  </Row>
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </Form>
      <Modal
        title={false}
        open={visible}
        onOk={OnDelete}
        onCancel={() => isVisible(false)}
        okText="ตกลง"
        cancelText="ยกเลิก"
        closable={false}
      >
        <b style={{ fontSize: 18 }}>
          คุณเเน่ใจหรือไม่ ว่าต้องการจะลบข้อมูลนี้ ?
        </b>
      </Modal>
      <div className="d-none">
        <PrintPDF Ref={componentRef} data={dataSource} />
      </div>
    </div>
  );
};

export default UserForm;

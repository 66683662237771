import "./assets/scss/App.scss";
import "./assets/less/variables.less";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "./views/login/LoginPage";
import DefaultLayout from "./layout/DefaultLayout";
import { RecoilRoot } from "recoil";
import NavList from "./_navbar";

const App = () => {
  const userInfo = getUserInfo();
  const navMenu =
    NavList.filter((f) => {
      return userInfo?.some((item: any) => item?.name === f?.keyName) || "";
    }) || [];
  const fisrtPath = navMenu[0]?.link;

  return (
    <RecoilRoot>
      <HashRouter>
        <Switch>
          <Route exact path="/404" component={() => <div>404</div>} />
          <Route
            exact
            path="/login"
            render={(_props) => (
              <HomeRoute>
                <LoginPage />
              </HomeRoute>
            )}
          />
          <Route
            exact
            path="/"
            render={(_props) => (
              <Redirect from="/" to={fisrtPath || "/dashboard"} />
            )}
          />
          <Route
            path="/"
            render={(_props) => (
              <ProtectedRoute>
                <DefaultLayout />
              </ProtectedRoute>
            )}
          />
        </Switch>
      </HashRouter>
    </RecoilRoot>
  );
};

const ProtectedRoute = (child: any): any => {
  const accessToken = getToken();
  if (!accessToken) return <Redirect to="/login" />;
  return <Route {...child} exact={true} />;
};

const HomeRoute = (child: any): any => {
  const accessToken = getToken();
  if (accessToken) return <Redirect from="/" to="/dashboard" />;
  return <Route {...child} exact={true} />;
};

export const getToken = (): string => {
  const token = localStorage.getItem("token");
  return token || "";
};

export const getUserInfo = () => {
  const userInfo = localStorage.getItem("userInfo");
  const userData = userInfo ? JSON?.parse(userInfo) : {};
  return userData?.permission || [];
};

export default App;

import { Fetch } from "../../tools/fecth/fecth";
import { apiVersion } from "../../config/api";
import { createQueryString } from "../../tools/util";

export const GEN_DASHBOARD_INFO = async (data?: any) => {
  const value = createQueryString(data);
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/dashboard${value}`,
  });
  return res;
};
export const GEN_DASHBOARD_ADMIN = async () => {
  const res = await Fetch({
    method: "GET",
    path: `${apiVersion}/admin/all`,
  });
  return res;
};

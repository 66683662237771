/* eslint-disable react-hooks/exhaustive-deps */

import { Form } from "antd";
import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CAlertToast } from "../../../../../components/alert/CAlert";
import {
  DELETETECHNICIAN_API,
  GETTECHNICIANDATA_API,
} from "../../../../../services/api/setting/technicial_api";
import { loadingState } from "../../../../../tools/recoil/recoil-atom";
import HeaderSetting from "../../component/header_setting/HerderSetting";
import DataMember from "../../component/memberlists/DataMember";

const Member = () => {
  const setLoading = useSetRecoilState(loadingState);
  const [disabledEdit, setDisabledEdit] = useState<boolean>(false);
  const [initialValueSearch, setInitialValueSearch] = useState({
    search: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limitPage, setLimitPage] = useState(12);

  const history = useHistory();
  const [formHeader] = Form.useForm();

  const addTechnician = [
    {
      type: "btnPush",
      name: "เพิ่มช่าง",
      onClick: () => history.push(`/setting/technicianmanagement/form-user`),
    },
  ];
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getTechnician();
    return undefined;

  }, [initialValueSearch, currentPage, limitPage]);

  const tiltleHeader = { nameEN: "TECHNICIAN", nameTH: "จัดการช่างสมาร์ท" };

  const handleClickEdit = () => {
    setDisabledEdit(!disabledEdit);
  };

  const handleSubmit = (values: any) => {
    setCurrentPage(1);
    setInitialValueSearch({ ...values });
  };

  const getTechnician = async () => {
    setLoading(true);
    try {
      const queryStr = {
        page: currentPage,
        limit: limitPage,
        ...initialValueSearch,
      };
      const res = await GETTECHNICIANDATA_API(queryStr);
      if (res.code === 200) {
        const dataSet = res.result[0];
        setCurrentPage(initialValueSearch ? 1 : currentPage);
        setTotalPage(dataSet.total);
        setLimitPage(dataSet.perpage);
        setUserData(dataSet.item);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDelete = async (id: any) => {
    setLoading(true);
    try {
      const res = await DELETETECHNICIAN_API(id);
      if (res.code === 200) {
        CAlertToast({ method: "DEL_SUCCESS", text: res.message });
        setUserData(userData.filter((item: any) => item.id !== id));
      } else {
        CAlertToast({ method: "DEL_ERROR", text: res.message });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const field = [
    {
      type: "input",
      name: "search",
      placeholder: "ค้นหา ชื่อหรือเบอร์โทร",
      initialValue: initialValueSearch.search,
    },
  ];

  return (
    <div>
      <HeaderSetting
        form={formHeader}
        Title={tiltleHeader}
        Action={addTechnician}
        handleClickEdit={handleClickEdit}
        handleClickCancel={handleClickEdit}
        disabledEdit={disabledEdit}
      />
      <div style={{ marginTop: "10px" }}>
        <DataMember
          pathmenu="technicianmanagement"
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          dataSource={userData}
          fieldFilter={field}
          pageConfig={{ currentPage, totalPage, limitPage }}
          onChangePage={onChangePage}
        />
      </div>
    </div>
  );
};

export default Member;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Row, Col, Form, FormInstance, Popover, Progress, Divider } from "antd";
import {
  EditOutlined,
  CaretDownOutlined,
  SaveOutlined,
  CaretUpOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { RecoilRoot, useRecoilState } from "recoil";
import {
  INQUERY_GET_FORM_API,
  UPDATE_INITAIL_SERVICE,
  UPDATE_REQUIMENT,
  UPDATE_CUSTOMER_CONTACT,
  DELETE_ITEM_NOTE_BY_ID,
  UPDATE_ACTIVITY_BY_ID,
  POST_NOTE,
  SOURT_NOTE,
  POST_ACTIVITY,
  SUCCESS_ACTIVITY,
} from "../../services/api/Lead_api";
import moment from "moment";
import UploadFile from "../../components/upload_file/UploadFile";
import FSelect from "../../components/form_floatlabel/FSelect";
import FInput from "../../components/form_floatlabel/FInput";
import FTextArea from "../../components/form_floatlabel/FTextArea";
import { AlertToast } from "../../components/alert/alert";
import InputPickerFile from "../../components/input_picker_file/InputPickerFile";
import InputPickerFilePost from "../../components/input_picker_file/InputPickerFilePost";
import { generateButton } from "../../components/button_generate";
import { _numToDate } from "../../tools/util";
import { IDataDetailCard } from "../../interface/ILead.interface";
import {
  EFieldsProject,
  tab_edit_selector,
  tab_visible_selector_project,
} from "./_store";
import TxCheckBoxGroup from "../CheckBoxGroup";
import UploadFileLabel from "../UploadFileLabel";
import {
  EInputType,
  ICardProject,
  IFieldChild,
  IFieldParent,
  IFormInstance,
} from "../../interface/IDirect.interface";
import { loadingChildState } from "../../tools/recoil/recoil-atom";
import { LoadSomeContent } from "../loading/loading";
import VisibleDivider from "./VisibleDivider";
import { _isEmpty } from "@yim/util-tool-ts";
import FDatePicker from "../form_floatlabel/FDatePicker";

const DetailCardModal = ({
  onUpdate,
  dataSource,
  onChange,
  id,
  isForm,
  isEdit,
  byForm,
}: ICardProject) => {
  let inquiry_No = id;
  const [loading, setLoading] = useRecoilState(loadingChildState);
  const [contactForm] = Form.useForm();
  const [requirmentForm] = Form.useForm();
  const [actionForm] = Form.useForm();
  const [sourtNote, setSourtNote] = useState("เรียงกิจกรรมล่าสุด");
  const [listItem, setListItem] = useState({
    projectList: [],
    colorList: [],
    interestedList: [],
    contactChannelList: [],
    requirementTypeList: [],
    subjectContactList: [],
    userContactTypeList: [],
  });

  const contact_field: IFieldParent = {
    key: EFieldsProject.CUSTOMER_CONTACT,
    fieldTitle: "CUSTOMER CONTACT | ข้อมูลการติดต่อลูกค้า",
    visibleChildKey: "child1",
    visibleSubChildKey: "subChild1",
    items: [
      {
        label: "INQUIRY NO",
        value: dataSource?.customerContact?.inquiryNO,
        col: { span: 8 },
        name: "inquiryNO",
        input: {
          props: { disabled: true },
        },
      },
      {
        label: "วัน : เวลา",
        value: !_isEmpty(dataSource?.date)
          ? _numToDate(Number(dataSource?.date), "DD/MM/YYYY | h:mm:ss")
          : "",
        col: { span: 8 },
        name: "date",
        input: {
          props: { disabled: true },
        },
      },
      {
        label: "วันที่ติดต่อ",
        value: !_isEmpty(dataSource?.contactDate)
          ? _numToDate(Number(dataSource?.contactDate))
          : _numToDate(Number(dataSource?.date)),
        col: { span: 8 },
        name: "contactDate",
        input: {
          type: EInputType.DATE,
          props: { disabled: false, rule: { required: true } },
        },
      },
      {
        label: "divider",
        value: "",
        col: { span: 24 },
        name: "",
      },
      {
        label: "ชื่อ - นามสกุล",
        value: `${dataSource?.customerContact?.firstName} ${dataSource?.customerContact?.lastName}`,
        col: { span: 8 },
        name: "fullName",
        input: {
          props: { disabled: false },
        },
      },
      {
        label: "ชื่อจริง",
        value: dataSource?.customerContact?.firstName,
        name: "firstName",
        col: { span: 8 },
        input: {
          props: { disabled: false },
        },
      },
      {
        label: "นามสกุล",
        value: dataSource?.customerContact?.lastName,
        name: "lastName",
        col: { span: 8 },
        input: {
          props: { disabled: false },
        },
      },
      {
        label: "ชื่อเล่น",
        value: dataSource?.customerContact?.nickname,
        name: "nickname",
        col: { span: 8 },
        input: {
          props: { disabled: false },
        },
      },
      {
        label: "เบอร์โทรศัพท์",
        value: dataSource?.customerContact?.phone,
        name: "phone",
        col: { span: 8 },
        input: {
          props: { disabled: false },
        },
      },
      {
        label: "อีเมล",
        value: dataSource?.customerContact?.email,
        col: { span: 8 },
        name: "email",
        input: {
          props: { rule: { required: false },disabled: false },
        },
      },
      {
        label: "ไลน์",
        value: dataSource?.customerContact?.line,
        col: { span: 8 },
        name: "line",
        input: {
          props: { rule: { required: false },disabled: false },
        },
      },
      {
        label: "ชื่อบัญชีโซเชียลมีเดีย",
        value: dataSource?.customerContact?.socialName,
        col: { span: 8 },
        name: "socialName",
        input: {
          props: { rule: { required: false },disabled: false },
        },
      },
      // child
      {
        label: "ชื่อบริษัท",
        value: dataSource?.customerContact?.companyName,
        col: { span: 12 },
        name: "companyName",
        typeSelf: "child",
      },
      {
        label: "ตำแหน่งงาน หรือแผนก",
        value: dataSource?.customerContact?.position,
        col: { span: 12 },
        name: "position",
        typeSelf: "child",
      },
      {
        label: "ที่อยู่",
        value: dataSource?.customerContact?.address,
        col: { span: 24 },
        name: "address",
        typeSelf: "child",
      },
      {
        label: "ตำบล",
        value: dataSource?.customerContact?.subDistrict,
        col: { span: 6 },
        name: "subDistrict",
        typeSelf: "child",
      },
      {
        label: "อำเภอ",
        value: dataSource?.customerContact?.district,
        col: { span: 6 },
        name: "district",
        typeSelf: "child",
      },
      {
        label: "จังหวัด",
        value: dataSource?.customerContact?.province,
        col: { span: 6 },
        name: "province",
        typeSelf: "child",
      },
      {
        label: "รหัสไปรษณีย์",
        value: dataSource?.customerContact?.postalCode,
        col: { span: 6 },
        name: "postalCode",
        typeSelf: "child",
      },
      // sub-child
      {
        label: "อธิบายเกี่ยวกับธุรกิจ",
        value: dataSource?.customerContact?.explainAboutBusiness,
        col: { span: 24 },
        name: "explainAboutBusiness",
        input: {
          type: EInputType.TEXTAREA,
          props: { rows: 3 },
        },
        typeSelf: "subChild",
      },
    ],
  };

  const requirment_field: IFieldParent = {
    key: EFieldsProject.REQUIREMENT,
    fieldTitle: "REQUIREMENT | ความต้องการของลูกค้า",
    visibleChildKey: "child2",
    items: [
      {
        label: "ประเภทผู้ติดต่อ",
        value: dataSource.requirement?.userContactType?.id,
        name: "userContactType",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            menuItem: {
              values: listItem?.userContactTypeList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "ประเภทโครงการ",
        value: dataSource.requirement?.projectCategory?.id,
        name: "projectCategory",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            menuItem: {
              values: listItem?.projectList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "สีอลูมิเนียม",
        value:
          dataSource.requirement?.aluminiumColor?.map((item) => item.id) || [],
        name: "colorAluminium",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            multiple: true,
            menuItem: {
              values: listItem?.colorList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "ช่องทางการติดต่อ",
        value:
          dataSource.requirement?.contactChannel?.map((item) => item.id) || [],
        name: "contactChannel",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            multiple: true,
            menuItem: {
              values: listItem?.contactChannelList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "ชื่อโครงการ",
        value: dataSource.requirement.projectName,
        name: "projectName",
        col: { span: 8 },
        input: {
          props: {
            rule: { required: true },
          },
        },
      },
      {
        label: "สินค้าที่สนใจ",
        col: { span: 8 },
        value: dataSource.requirement?.interestedProduct,
        name: "interestedProduct",
        input: {
          type: EInputType.SELECT,
          props: {
            multiple: true,
            menuItem: {
              values: listItem?.interestedList,
              key: "id",
              name: "nameTh",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "เรื่องที่ต้องการติดต่อ",
        value: dataSource.requirement?.subjectContact?.map((item) => item.id),
        name: "subjectContact",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            multiple: true,
            menuItem: {
              values: listItem?.subjectContactList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "ประเภทความต้องการ",
        value: dataSource.requirement?.requirementType?.id,
        name: "requirementType",
        col: { span: 8 },
        input: {
          type: EInputType.SELECT,
          props: {
            menuItem: {
              values: listItem?.requirementTypeList,
              key: "id",
              name: "name",
            },
            rule: { required: true },
          },
        },
      },
      {
        label: "",
        value: dataSource.requirement?.specAlumet,
        name: "specAlumet",
        col: { className: "d-flex y-center", span: 8 },
        input: {
          type: EInputType.CHECKBOX,
          props: {
            optionValue: {
              span: 24,
              name: "label",
              key: "id",
              values: [{ label: "สแป็คอลูเม็ท", id: 1 }],
            },
          },
        },
      },
      {
        label: "อัพโหลดไฟล์",
        value: dataSource.requirement?.file,
        name: "file",
        input: {
          type: EInputType.UPLOAD,
          props: {
            disabled: true,
          },
        },
      },
      {
        label: "ความต้องการของลูกค้า",
        value: dataSource.requirement.requirement,
        name: "requirement",
        input: {
          type: EInputType.TEXTAREA,
          props: {
            rows: 3,
            rule: { required: false },
          },
        },
        col: { span: 24 },
        typeSelf: "child",
      },
    ],
  };

  const action_field: IFieldParent = {
    key: EFieldsProject.ACTION,
    fieldTitle: "ACTION | การให้บริการเบื้องต้น",
    items: [
      {
        label: "ผู้ให้บริการ",
        value: dataSource.action.admin,
        name: "admin",
        input: { props: { disabled: true } },
      },
      {
        label: "วันที่และเวลา",
        value: !_isEmpty(dataSource.action.dateTime)
          ? moment(Number(dataSource.action.dateTime)).format(
              "DD/MM/YYYY | h:mm:ss"
            )
          : "",
        name: "dateTime",
        input: { props: { disabled: true } },
      },
      {
        label: "การดำเนินการ",
        value: dataSource.action.initialService,
        col: { span: 24 },
        name: "initialService",
      },
      {
        label: "แจ้งลูกค้าติดต่อช่องทางอื่นๆ",
        value: dataSource.action?.otherContact?.id,
        col: { span: 24 },
        name: "otherContact",
        input: {
          type: EInputType.SELECT,
          props: {
            menuItem: {
              values: listItem?.contactChannelList,
              key: "id",
              name: "name",
            },
          },
        },
      },
    ],
  };

  const note_field = {
    fieldTitle: "NOTE | บันทึกการดำเนินการ",
    key: EFieldsProject.NOTE,
    items: {
      listField: dataSource.note,
    },
  };

  const activity_field = {
    fieldTitle: "ACTIVITY | กิจกรรมระหว่างดำเนินการ",
    key: EFieldsProject.ACTIVITY,
    items: {
      listField: dataSource.activity,
    },
  };

  const initialForm: IFormInstance = {
    contactForm: { form: contactForm, id: "contactForm", field: contact_field },
    requirmentForm: {
      form: requirmentForm,
      id: "requirmentForm",
      field: requirment_field,
    },
    actionForm: { form: actionForm, id: "actionForm", field: action_field },
  };

  //remove form className from Form Element
  for (const [, value] of Object.entries(initialForm)) {
    document.getElementById(value.id)?.classList.remove("ant-form");
  }

  useEffect(() => {
    HandleGetAllDatalist();
    return undefined;

  }, []);

  useEffect(() => {
    setFormField(initialForm.contactForm);
    setFormField(initialForm.requirmentForm);
    setFormField(initialForm.actionForm);
    return undefined;

  }, [dataSource]);

  const HandleAllSuccessDoing = (code: number, scroll?: string) => {
    if (code === 200) {
      onChange(scroll || "contactField");
    } else {
      setLoading(false);
    }
  };

  const HandleGetAllDatalist = async () => {
    try {
      const res = await INQUERY_GET_FORM_API();
      if (res.code === 200) {
        const result = res.result[0];
        setListItem({
          ...listItem,
          projectList: result?.projectCategory || [],
          colorList: result?.aluminiumColor || [],
          interestedList: result?.interestedProduct || [],
          contactChannelList: result?.contactChannel || [],
          requirementTypeList: result?.requirementType || [],
          subjectContactList: result?.subjectContact || [],
          userContactTypeList: result?.userContactType || [],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setFormField = ({
    form,
    field,
  }: {
    field: IFieldParent;
    form: FormInstance<any>;
  }) => {
    const { items } = field;
    const val = items.reduce((result, item) => {
      return { ...result, [item.name]: item.value };
    }, {});
    form.setFieldsValue(val); //this is warning pass form
  };

  ///////////////////////////////////////////////////////////////////////// FieldGenerateNote /////////////////////////////////////////////////////////////
  const FieldGenerateNote = ({ fieldList }: { fieldList: any }) => {
    const [vsb, setVsb] = useRecoilState(
      tab_visible_selector_project(fieldList.key)
    );

    const listField = fieldList?.items?.listField || [];

    const onPost = async (value: any) => {
      let resCode = 0;
      setLoading(true);
      const inquiryId = inquiry_No || 0;
      const formData = new FormData();
      for (let i = 0; i < value.files.length; i++) {
        formData.append("fileUpload", value?.files[i]?.originFileObj || "");
      }
      formData.append("inquiryId", inquiryId.toString());
      formData.append("note", value?.text || "");
      try {
        const res = await POST_NOTE(formData);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! บันทึกข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถบันทึกข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      }
      HandleAllSuccessDoing(resCode, "noteField");
    };

    const onFinish = async (value: any) => {
      let resCode = 0;
      setLoading(true);
      const formData = new FormData();
      const currentFile = {
        file: value?.currentFile,
      };
      for (let i = 0; i < value.files.length; i++) {
        formData.append("fileUpload", value?.files[i]?.originFileObj || "");
      }
      formData.append("files", JSON.stringify(currentFile));
      formData.append("note", value?.text || "");
      formData.append("type", value?.type || "");
      formData.append("date", value?.date.toString() || "");
      try {
        const res = await UPDATE_ACTIVITY_BY_ID(value.id, formData);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! บันทึกข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถบันทึกข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      }
      HandleAllSuccessDoing(resCode, `${value.id}`);
    };

    const onDelete = async (id: number) => {
      let resCode = 0;
      setLoading(true);
      try {
        const res = await DELETE_ITEM_NOTE_BY_ID(id);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! ลบข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถลบข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (error) {
        console.log(error);
      }
      HandleAllSuccessDoing(resCode, "noteField");
    };

    const expendBtn = generateButton({
      Icon: vsb ? CaretUpOutlined : CaretDownOutlined,
      onClick: () => {
        setVsb(!vsb);
      },
    });

    const currentUser = JSON.parse(localStorage.getItem("userInfo") || "");
    const imagepost = {
      src: currentUser?.image,
      name: currentUser?.firstName,
    };

    const HandleSourtNoteList = async (value: string) => {
      const dataInfo = {
        noteOrderby: value,
      };
      let resCode = 0;
      setLoading(true);
      try {
        const res = await SOURT_NOTE(inquiry_No, dataInfo);
        resCode = res.code;
        if (res.code === 200) {
          const sourtText =
            value === "DESC" ? "เรียงกิจกรรมล่าสุด" : "เรียงกิจกรรมย้อนหลัง";
          setSourtNote(sourtText);
          const [result] = res.result;
          const dataUpdate = {
            ...dataSource,
            note: result?.note,
          };
          onUpdate(dataUpdate);
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถเรียงข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      }
      HandleAllSuccessDoing(resCode, "noteField");
    };

    const ContentSourtNote = () => {
      return (
        <div>
          <div
            className="btn-sourte-note"
            onClick={() => HandleSourtNoteList("DESC")}
          >
            เรียงกิจกรรมล่าสุด
          </div>
          <div
            className="btn-sourte-note"
            onClick={() => HandleSourtNoteList("ASC")}
          >
            เรียงกิจกรรมย้อนหลัง
          </div>
        </div>
      );
    };

    return (
      <div>
        <div
          className="leads-card-title px-3 mb-3 cursor"
          onClick={() => {
            setVsb(!vsb);
          }}
        >
          <h3>
            <b>{fieldList.fieldTitle}</b>
          </h3>
          <div>{expendBtn}</div>
        </div>
        {vsb && (
          <>
            <Row align="middle" justify="space-between" className="mr-2 ml-4">
              <h3>ประวัติการทำงาน</h3>
              <div>
                {sourtNote}
                <Popover
                  placement="bottom"
                  title={"เลือกการเรียงกิจกรรม"}
                  content={ContentSourtNote}
                  trigger="click"
                >
                  <CaretDownOutlined className="mx-2" />
                </Popover>
              </div>
            </Row>
            {CheckStatusCard(isForm) && byForm === "direct" && (
              <InputPickerFilePost image={imagepost} onFinish={onPost} />
            )}
            {listField.map((item: any, idx: number) => {
              return (
                <div id={`${item?.note?.id}`} key={idx}>
                  <InputPickerFile
                    text={item?.note?.note}
                    remark={item.note?.type && true}
                    value={item}
                    id={item?.note?.id}
                    label={`${item?.actionBy?.firstName} ${item?.actionBy?.lastName}`}
                    onFinish={onFinish}
                    onDelete={onDelete}
                    isEdit={item?.isEdit}
                    isBtn={CheckStatusCard(isForm)}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  };
  ///////////////////////////////////////////////////////////////////////// FieldGenerateNote /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////// FieldGenerateACtivity /////////////////////////////////////////////////////////////
  const FieldGenerateACtivity = ({ fieldList }: { fieldList: any }) => {
    const [vsb, setVsb] = useRecoilState(
      tab_visible_selector_project(fieldList.key)
    );
    const list = fieldList?.items?.listField?.actionStep || [];
    const listField = fieldList?.items?.listField || {};
    const resultVsb = list?.map((item: any) => {
      return { id: item.id, value: true };
    });
    const [vsbChild, setVsbChild] = useState(resultVsb);

    const onPost = async (value: any) => {
      let resCode = 0;
      setLoading(true);
      const result = listField?.actionStep.find(
        (item: any) => item.id === value?.activityId
      );
      const inquiryId = inquiry_No || 0;
      const formData = new FormData();
      for (let i = 0; i < value.files.length; i++) {
        formData.append("fileUpload", value?.files[i]?.originFileObj || "");
      }
      formData.append("inquiryId", inquiryId.toString());
      formData.append("note", value?.text || "");
      formData.append("actionStepId", value?.activityId || "");
      try {
        const res = await POST_ACTIVITY(formData);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! บันทึกข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถบันทึกข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        HandleAllSuccessDoing(resCode, result?.name || "activityField");
      }
    };

    const onFinish = async (value: any) => {
      let resCode = 0;
      setLoading(true);
      const formData = new FormData();
      const currentFile = {
        file: value?.currentFile,
      };
      for (let i = 0; i < value.files.length; i++) {
        formData.append("fileUpload", value?.files[i]?.originFileObj || "");
      }
      formData.append("files", JSON.stringify(currentFile));
      formData.append("note", value?.text || "");
      try {
        const res = await UPDATE_ACTIVITY_BY_ID(value.id, formData);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! บันทึกข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถบันทึกข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      }
      HandleAllSuccessDoing(resCode, `${value.id}`);
    };

    const onDelete = async (id: number) => {
      let resCode = 0;
      setLoading(true);
      try {
        const res = await DELETE_ITEM_NOTE_BY_ID(id);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! ลบข้อมูลเรียบร้อย`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถลบข้อมูลได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (error) {
        console.log(error);
      }
      HandleAllSuccessDoing(resCode, "activityField");
    };

    const onSuccess = async (id: string) => {
      let resCode = 0;
      setLoading(true);
      try {
        const res = await SUCCESS_ACTIVITY(inquiry_No);
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `สำเร็จ! การดำเนินการเสร็จสิ้น`,
          });
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! ไม่สามารถดำเนินการเสร็จสิ้นได้เนื่องจาก ${res.message}`,
          });
        }
      } catch (err) {
        console.log(err);
      }
      HandleAllSuccessDoing(resCode, id);
    };

    const expendBtn = generateButton({
      Icon: vsb ? CaretUpOutlined : CaretDownOutlined,
      onClick: () => {
        setVsb(!vsb);
      },
    });

    const expendChildBtn = (id: number, index: number) => {
      return generateButton({
        Icon: vsbChild[index].value ? CaretUpOutlined : CaretDownOutlined,
        onClick: () => {
          const result = vsbChild.map((item: any) => {
            return item.id === id ? { id: item.id, value: !item.value } : item;
          });
          setVsbChild(result);
        },
      });
    };

    const currentUser = JSON.parse(localStorage.getItem("userInfo") || "");
    const imagepost = {
      src: currentUser?.image,
      name: currentUser?.firstName,
    };

    const checkkStatuss = (status: boolean = false) => {
      return status ? "| เสร็จสิ้น" : "| รอดำเนินการ";
    };

    const DateNow = (date: string | number) => {
      const result = Number(date);
      if (result) {
        return _numToDate(result, "DD/MM/YYYY | h:mm:ss");
      } else {
        return date;
      }
    };

    return (
      <div>
        <div
          className="leads-card-title px-3 mb-3 cursor"
          onClick={() => {
            setVsb(!vsb);
          }}
        >
          <h3>
            <b>{fieldList.fieldTitle}</b>
          </h3>
          <div>{expendBtn}</div>
        </div>
        {vsb && (
          <>
            <Row
              className="activity-progress mb-3"
              justify="space-between"
              align="middle"
            >
              <span className="font-activitty">ACTION</span>
              <Col span={22}>
                <Progress
                  percent={listField?.percent || 0}
                  strokeColor="#FFB12E"
                  trailColor="#C7C7C7"
                />
              </Col>
            </Row>

            {listField?.actionStep?.map((item: any, idx: number) => {
              const current = item?.activitySuccess;
              const prev =
                idx - 1 >= 0
                  ? listField?.actionStep[idx - 1].activitySuccess
                  : false;

              return (
                <div key={idx}>
                  <div
                    className="leads-card-title-ativity px-3 mb-3"
                    id={item?.name}
                  >
                    <Row align="middle" justify="space-between">
                      <h3>
                        <b>{item?.name || ""}</b>
                      </h3>
                      <span className="mx-5">
                        อัพเดทล่าสุด
                        <CalendarOutlined
                          className="mx-2"
                          style={{ color: "lightgray" }}
                        />
                        {DateNow(item.dateTime)}
                        <span className="mx-1">
                          {checkkStatuss(item?.activitySuccess)}
                        </span>
                      </span>
                    </Row>
                    {current || prev || idx === 0 ? (
                      <div>{expendChildBtn(item?.id || 0, idx)}</div>
                    ) : null}
                  </div>
                  {current || prev || idx === 0
                    ? vsbChild[idx].value && (
                        <>
                          {!item?.activitySuccess &&
                          CheckStatusCard(isForm) &&
                          byForm === "direct"
                            ? true
                            : isEdit && (
                                <InputPickerFilePost
                                  activityId={item?.id}
                                  image={imagepost}
                                  onFinish={onPost}
                                  onSuccess={() => onSuccess(item?.name)}
                                  saveBtn={true}
                                  postBtn={false}
                                />
                              )}
                          {item?.note?.map((child: any, index: number) => {
                            return (
                              <div id={`${child?.note?.id}`} key={index}>
                                <InputPickerFile
                                  text={child?.note?.note}
                                  value={child}
                                  id={child?.note?.id}
                                  label={`${child?.admin?.firstName} ${child?.admin?.lastName}`}
                                  onFinish={onFinish}
                                  onDelete={onDelete}
                                  isEdit={byForm === "direct" ? true : isEdit}
                                  isBtn={CheckStatusCard(isForm)}
                                />
                              </div>
                            );
                          })}
                        </>
                      )
                    : null}
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  };
  ///////////////////////////////////////////////////////////////////////// FieldGenerateACtivity /////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////// FieldGeneate /////////////////////////////////////////////////////////////
  const FieldGeneate = ({
    field,
    form,
  }: {
    field: IFieldParent;
    form: { form: FormInstance<any>; id: string };
    isBtn?: boolean;
  }) => {
    const [edit, setEdit] = useRecoilState(tab_edit_selector(field.key));
    const [vsb, setVsb] = useRecoilState(
      tab_visible_selector_project(field.key)
    );
    const [visibleField, setVisibleField] = useState({
      child1: false,
      subChild1: false,
      child2: false,
    } as any);

    const editBtn = generateButton({
      text: "แก้ไข",
      Icon: EditOutlined,
      onClick: (e) => {
        e.stopPropagation();
        setEdit(!edit);
      },
    });
    const expendBtn = generateButton({
      Icon: vsb ? CaretUpOutlined : CaretDownOutlined,
      onClick: () => {
        setVsb(!vsb);
      },
    });
    const cancelBtn = generateButton({
      text: "ยกเลิก",
      onClick: (e) => {
        e.stopPropagation();
        setEdit(!edit);
      },
    });
    const saveBtn = generateButton({
      Icon: SaveOutlined,
      text: "บันทึกข้อมูล",
      onClick: async (e) => {
        e.stopPropagation();
        const dataIfo = await form.form.validateFields();
        HandleOnfinish(dataIfo, field.key);
        setEdit(!edit);
      },
    });

    const renderForm = (item: IFieldChild) => {
      const Input = FInput;
      return item.input?.type === "UPLOAD" ? (
        <UploadFile name="file" />
      ) : item.input?.type === "DATE" ? (
        <FDatePicker
          name={item.name}
          label={item.label}
          {...item.input?.props}
          value={
            item?.value
              ? moment(new Date(item?.value)).format("DD/MM/YYYY")
              : moment().format("DD/MM/YYYY")
          }
          options={{
            disabled: item.input?.props.disabled || false,
          }}
          rules={item.input?.props?.rule || { required: false }}
        />
      ) : item.input?.type === "SELECT" ? (
        <FSelect
          options={{
            mode: item.input?.props?.multiple ? "multiple" : "",
            disabled: item.input?.props.disabled || false,
          }}
          rules={item.input?.props?.rule || { required: false }}
          label={item.label || ""}
          name={item.name || ""}
          dataSource={item.input?.props?.menuItem.values || []}
          keyName={item.input?.props?.menuItem?.name}
          keyValue={item.input?.props?.menuItem?.key}
        />
      ) : item.input?.type === "CHECKBOX" ? (
        <Row>
          <Col span={2} style={{ fontWeight: "bold" }}>
            {item.label}
          </Col>
          <Form.Item noStyle name={item.name}>
            <TxCheckBoxGroup
              optionValue={item.input.props?.optionValue}
              value={item.value}
              disabled={false}
            />
          </Form.Item>
        </Row>
      ) : item.input?.type === "TEXTAREA" ? (
        <FTextArea
          label={item.label || ""}
          name={item.name || ""}
          options={{
            rows: item.input.props?.rows,
            disabled: item.input?.props.disabled || false,
          }}
          rules={item.input?.props?.rule || { required: false }}
        />
      ) : (
        <Input
          name={item.name}
          label={item.label}
          {...item.input?.props}
          options={{
            disabled: item.input?.props.disabled || false,
          }}
          rules={item.input?.props?.rule || { required: false }}
        />
      );
    };

    const getFieldLabel = (item: IFieldChild) => {
      switch (item.input?.type) {
        case EInputType.SELECT:
          const ids: number | number[] = item.value;
          const { props } = item?.input;
          let { menuItem } = props;
          if (!menuItem) {
            menuItem = { values: [], name: "", key: "" };
          }
          if (props?.multiple && Array.isArray(ids)) {
            const filterId = menuItem.values.filter(
              (item: { [key: string]: any }) => {
                return ids.some((e) => e === item.id);
              }
            );
            return filterId.map(
              (item: { [key: string]: any }, index: number) => {
                return (
                  <span key={index}>
                    {item[menuItem.name]}
                    {index !== filterId.length - 1 && ", "}
                  </span>
                );
              }
            );
          }
          const value = menuItem.values?.find(
            (e: any) => e[menuItem.key] === ids
          );

          return value ? value[menuItem?.name] : "";
        case EInputType.UPLOAD:
          return (
            <Row>
              {item?.value?.map((child: any, idx: number) => (
                <Col className="mr-1 mt-2" key={idx}>
                  <UploadFileLabel name={child?.name} value={child.url} />
                </Col>
              ))}
            </Row>
          );
        case EInputType.CHECKBOX:
          return (
            <TxCheckBoxGroup
              optionValue={item.input.props.optionValue}
              value={item.value}
              disabled={true}
            />
          );
        default:
          return item.value;
      }
    };

    const renderField = (item: IFieldChild) => {
      const valueInfo = getFieldLabel(item);
      return (
        <Row>
          <Col span={24} className="font-label">
            {item?.label}
          </Col>
          <Col span={24} className="font-label-value-project break-word">
            {valueInfo}
          </Col>
        </Row>
      );
    };

    const HandleClearData = () => {
      contactForm.resetFields();
      actionForm.resetFields();
      requirmentForm.resetFields();
    };

    const HandleRefacterData = (keyForm: string, dataInfo: any) => {
      const dataAction =
        keyForm === "action"
          ? {
              initialService: dataInfo?.initialService || "",
              otherContact: dataInfo?.otherContact || "",
            }
          : keyForm === "requirement"
          ? {
              fileUpload: dataInfo?.file || [],
              specAlumet: dataInfo?.specAlumet[0] === 1 ? "TRUE" : "FALSE",
              requirement: {
                projectCategory: dataInfo?.projectCategory,
                aluminiumColor: dataInfo?.colorAluminium,
                projectName: dataInfo?.projectName,
                subjectContact: dataInfo?.subjectContact,
                requirementType: dataInfo?.requirementType,
                interestedProduct: dataInfo?.interestedProduct,
                contactChannel: dataInfo?.contactChannel,
                requirement: dataInfo?.requirement,
                userContactType: dataInfo?.userContactType,
                line: dataInfo?.line,
                files: dataInfo?.file
                  ?.filter((item: any) => item?.id)
                  .map((child: any) => child.id),
              },
            }
          : {
              contactDate: dataInfo?.contactDate?.valueOf(),
              customerContact: {
                firstName: dataInfo?.firstName,
                lastName: dataInfo?.lastName,
                nickname: dataInfo?.nickname,
                position: dataInfo?.position,
                socialName: dataInfo?.socialName,
              },
              address: {
                companyName: dataInfo?.companyName,
                explainAboutBusiness: dataInfo?.explainAboutBusiness,
                phone: dataInfo?.phone,
                email: dataInfo?.email,
                address: dataInfo?.address,
                subDistrict: dataInfo?.subDistrict,
                district: dataInfo?.district,
                province: dataInfo?.province,
                postalCode: Number(dataInfo?.postalCode),
              },
            };
      return dataAction;
    };

    const jsonToFormData = (data: any) => {
      const fileList = data?.fileUpload.filter(
        (item: any) => item.originFileObj
      );
      const formData = new FormData();
      fileList.forEach((item: any) => {
        formData.append("fileUpload", item.originFileObj);
      });
      formData.append("specAlumet", data["specAlumet"]);
      formData.append("requirement", JSON.stringify(data["requirement"]));
      return formData;
    };

    const HandleOnfinish = async (dataInfo: any, keyForm: string) => {
      let resCode = 0;
      setLoading(true);
      const dataApi = HandleRefacterData(keyForm, dataInfo);
      const callAPI =
        keyForm === "action"
          ? await UPDATE_INITAIL_SERVICE(inquiry_No, dataApi)
          : keyForm === "requirement"
          ? await UPDATE_REQUIMENT(inquiry_No, jsonToFormData(dataApi))
          : await UPDATE_CUSTOMER_CONTACT(inquiry_No, dataApi);
      try {
        const res = callAPI;
        resCode = res.code;
        if (res.code === 200) {
          AlertToast({
            icon: "success",
            title: `บันทึกข้อมูลสำเร็จ`,
          });
          HandleClearData();
        } else {
          AlertToast({
            icon: "error",
            title: `ผิดพลาด! บันทึกข้อมูลไม่สำเร็จ`,
          });
        }
      } catch (error) {
        console.log(error);
      }
      const targetScroll =
        keyForm === "action"
          ? "actionField"
          : keyForm === "requirement"
          ? "requirmentField"
          : "contactField";
      HandleAllSuccessDoing(resCode, targetScroll);
    };

    const CheckPaddingLeft = (check: boolean | any = false) => {
      return !check ? { paddingLeft: "2rem" } : {};
    };

    const CheckFieldVisible = (items: IFieldChild, idx: number) => {
      return items?.label === "divider" ? (
        <Col {...items?.col} span={items?.col?.span || 12} key={idx}>
          <Divider className="m-0" style={{ backgroundColor: "#DCDCDC" }} />
        </Col>
      ) : (
        <Col
          {...items?.col}
          span={items?.col?.span || 12}
          key={idx}
          style={CheckPaddingLeft(edit)}
        >
          {edit ? renderForm(items) : renderField(items)}
        </Col>
      );
    };

    return (
      <Form className="mb-4" form={form.form} id={form.id}>
        <div
          className="leads-card-title px-3 mb-3 cursor"
          onClick={() => {
            setVsb(!vsb);
          }}
        >
          <h3>
            <b>{field.fieldTitle}</b>
          </h3>
          {dataSource.status === "FINISH" ? (
            <div>{expendBtn}</div>
          ) : byForm === "direct" ? (
            <div>
              {CheckStatusCard(isForm) && vsb && !edit && editBtn}
              {CheckStatusCard(isForm) && vsb && edit && cancelBtn}
              {CheckStatusCard(isForm) && vsb && edit && saveBtn}
              {expendBtn}
            </div>
          ) : (
            <div>
              {CheckStatusCard(isForm) && isEdit && vsb && !edit && editBtn}
              {CheckStatusCard(isForm) && isEdit && vsb && edit && cancelBtn}
              {CheckStatusCard(isForm) && isEdit && vsb && edit && saveBtn}
              {expendBtn}
            </div>
          )}
        </div>
        {vsb && (
          <div className="px-2 mt-2 mb-5">
            <Row gutter={[24, 24]}>
              {field.items
                .filter((item) =>
                  edit
                    ? item?.name !== "fullName"
                    : item?.name !== "firstName" && item?.name !== "lastName"
                )
                .filter(
                  (item) =>
                    item?.typeSelf !== "child" && item?.typeSelf !== "subChild"
                )
                .map((item, index) => {
                  return CheckFieldVisible(item, index);
                })}
              {field.visibleChildKey && (
                <Col span={24} className="m-0">
                  <VisibleDivider
                    classDivider={"my-0"}
                    visible={visibleField[`${field.visibleChildKey}`]}
                    onClick={() => {
                      setVisibleField({
                        ...visibleField,
                        [`${field.visibleChildKey}`]:
                          !visibleField[`${field.visibleChildKey}`],
                      });
                    }}
                  />
                </Col>
              )}
              {visibleField[`${field.visibleChildKey}`] &&
                field.items
                  .filter((item) => item?.typeSelf === "child")
                  .map((item, index) => {
                    return CheckFieldVisible(item, index);
                  })}
              {visibleField[`${field.visibleChildKey}`] &&
                field.visibleSubChildKey && (
                  <Col span={24} className="m-0">
                    <VisibleDivider
                      classDivider={"my-0"}
                      visible={visibleField[`${field.visibleSubChildKey}`]}
                      onClick={() => {
                        setVisibleField({
                          ...visibleField,
                          [`${field.visibleSubChildKey}`]:
                            !visibleField[`${field.visibleSubChildKey}`],
                        });
                      }}
                    />
                  </Col>
                )}
              {visibleField[`${field.visibleChildKey}`] &&
                visibleField[`${field.visibleSubChildKey}`] &&
                field.items
                  .filter((item) => item?.typeSelf === "subChild")
                  .map((item, index) => {
                    return CheckFieldVisible(item, index);
                  })}
            </Row>
          </div>
        )}
      </Form>
    );
  };
  ///////////////////////////////////////////////////////////////////////// FieldGeneate /////////////////////////////////////////////////////////////
  const CheckStatusCard = (cardStatus: string) => {
    if (cardStatus === "SEND" || cardStatus === "FINISH") {
      return false;
    } else return true;
  };

  const CardBody = ({
    formInstance,
  }: {
    item: IDataDetailCard;
    editAble?: boolean;
    formInstance: IFormInstance;
  }) => {
    const { contactForm, requirmentForm, actionForm } = formInstance;
    const contactField =
      contactForm && FieldGeneate({ field: contact_field, form: contactForm });
    const requirmentField =
      requirmentForm &&
      FieldGeneate({ field: requirment_field, form: requirmentForm });
    const actionField =
      actionForm && FieldGeneate({ field: action_field, form: actionForm });
    const noteField = FieldGenerateNote({ fieldList: note_field });
    const activityField = FieldGenerateACtivity({ fieldList: activity_field });

    return (
      <Row gutter={[24, 0]} style={{ height: "100%" }} justify="center">
        <Col span={24} className="py-3 lead-card-item-detail">
          <div id="contactField">{contactField}</div>
          <div id="requirmentField">{requirmentField}</div>
          <div id="actionField">{actionField}</div>
          <div id="noteField">
            {isForm !== "inbox" && isForm !== "newinquiry" ? noteField : null}
          </div>
          <div id="activityField">
            {isForm !== "inbox" && isForm !== "newinquiry"
              ? activityField
              : null}
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <RecoilRoot>
      <LoadSomeContent loading={loading}>
        <CardBody item={dataSource} formInstance={initialForm} />
      </LoadSomeContent>
    </RecoilRoot>
  );
};

export default DetailCardModal;

/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Button, Col, Row } from "antd";
import { useEffect } from "react";
import logo from "../../assets/img/brand/Alumet.png";
import newLogo from "../../assets/img/brand/logo_full.svg";
import { ILogin } from "../../interface/ILogin.interface";
import { userLoginAPT } from "../../services/api/auth";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { AlertToast } from "../../components/alert/alert";
import NavList from "../../_navbar";

const LoginPage = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    HandleCheckSeccionTime();
    return undefined;
  }, []);

  const HandleCheckSeccionTime = () => {
    // form.setFieldsValue({
    //   username: "admin@email.com",
    //   password: "admin",
    // });
    const sessionCheck = localStorage.getItem("session");
    if (sessionCheck === "true") {
      AlertToast({
        icon: "warning",
        title: `เซสชันหมดอายุ! กรุณาเข้าสู่ระบบใหม่อีกครั้ง`,
      });
    }
  };

  const handleLogin = async (valus: ILogin) => {
    const res = await userLoginAPT(valus);
    if (res.code === 200) {
      const token = res.result[0].accessToken;
      const userInfo = res.result[0].userInfo;
      localStorage.setItem("token", token);
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      const navMenu = NavList?.filter((f) =>
        userInfo?.permission?.some((item: any) => item?.name === f?.keyName)
      );
      const sortNav = navMenu.sort((a, b) => a.id - b.id);
      history.push(sortNav[0]?.link || "/");
      AlertToast({
        icon: "success",
        title: `ยินดีต้อนรับ คุณเข้าสู่ระบบสำเร็จ!`,
      });
    } else {
      AlertToast({
        icon: "error",
        title: `เข้าสู่ระบบไม่สำเร็จ เนื่องจาก ${res.message}`,
      });
    }
  };
  return (
    <Row>
      <Col span={24} className="bg-login">
        <div className="page-center">
          <div className="card-login">
            <div style={{ textAlign: "center" }}>
              <img src={newLogo} alt="Alumet" style={{ width: "395px", margin: "40px" }} />
            </div>
            <div className="text-center">
              <span className="span-topic1">ยินดีต้อนรับ</span>
              <br />
              <span className="span-topic2">
                เข้าสู่ระบบบัญชีของคุณเพื่อดำเนินการต่อ
              </span>
            </div>
            <Form form={form} onFinish={handleLogin}>
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined className="icon-login" />}
                  placeholder="อีเมล"
                  className="login-input"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password
                  size="large"
                  prefix={<KeyOutlined className="icon-login" />}
                  placeholder="รหัสผ่าน"
                  // visibilityToggle={true}
                  className="login-input"
                />
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  size="large"
                  className="btn-login"
                >
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default LoginPage;

import { Fetch, FetchFormData } from "../../../tools/fecth/fecth";
import { apiVersion } from '../../../config/api'

import { createQueryString } from "../../../tools/util";


//////////////////////////////user////////////////////////////


export const GETUSERDATA_API = async (data: {}) => {
    const qrs = createQueryString(data);
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/admin${qrs}` });
    return res;
}

export const GETUSERALLDATA_API = async () => {
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/admin/all` });
    return res;
}

export const GETUSERDATAID_API = async (id: number) => {
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/admin/${id}` });
    return res;
}

export const ADDFORMUSER_API = async (data: any) => {
    const res = await FetchFormData({ method: 'POST', path: `${apiVersion}/admin`, data });
    return res;
}

export const EDITFORMUSER_API = async ({ data, id }: { data: any, id: number }) => {
    const res = await FetchFormData({ method: 'PUT', path: `${apiVersion}/admin/${id}`, data });
    return res;
}


export const DELETEUSER_API = async (id: number) => {
    const res = await Fetch({ method: 'DELETE', path: `${apiVersion}/admin/${id}` });
    return res;
}

//////////////////////////////admin////////////////////////////

export const GETADMINPROFILE_API = async () => {
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/admin/profile` });
    return res;
}
export const EDITADMINPROFILE_API = async (data: any) => {
    const res = await FetchFormData({ method: 'PUT', path: `${apiVersion}/admin/profile`, data });
    return res;
}
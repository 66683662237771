import { LeftOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, FormInstance, Row } from "antd";
import { useHistory } from "react-router";
import FSelect from "../../../../../components/form_floatlabel/FSelect";

interface ITitle {
  nameTh: string;
  nameEn: string;
}
type Props = {
  isActioned: boolean;
  handleClickAction: () => void;
  title: ITitle;
  handleSubmit: (values: any) => void;
  form: FormInstance<any>;
  rowSelectLength: number;
  adminList: any[];
  handleChageFirstResponsiblePerson: (value: any) => void;
  filteredResponsiblePerson: any[];
};
const HeaderProjectTransfer = ({
  isActioned,
  handleClickAction,
  title,
  handleSubmit,
  form,
  rowSelectLength,
  adminList,
  handleChageFirstResponsiblePerson,
  filteredResponsiblePerson,
}: Props) => {
  const history = useHistory();

  return (
    <div>
      <Card className="border-header-diy ">
        <Row className="page-heder">
          <div style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
            <Row gutter={12} align="middle">
              <Col>
                <LeftOutlined style={{ fontSize: "16px",marginRight:10 }} />
              </Col>
              <Col>
                <div
                  style={{
                    fontWeight:'bold',
                    color: "#FFC627",
                    fontSize: "25px",
                  }}
                >
                  <b>{title.nameEn}</b>
                </div>
                <div
                 style={{
                  fontFamily: "SemiBold",
                  fontSize: "18px",
                }}
                >
                  {title.nameTh}
                </div>
              </Col>
            </Row>
          </div>

          <Button
            className={isActioned ? "d-none" : "btn-gradient mb2"}
            type="primary"
            onClick={() => handleClickAction()}
          >
            <SwapOutlined />
            โอนย้าย
          </Button>
        </Row>
      </Card>

      <Form onFinish={handleSubmit} form={form}>
        <Card className={isActioned ? "round" : "d-none"}>
          <Row className="page-heder">
            <div>
              <Row gutter={12} align="middle">
                <Col>
                  <div
                    style={{
                      fontFamily: "SemiBold",
                      color: "#FFC627",
                      fontSize: "20px",
                    }}
                  >
                    <b>{title.nameEn}</b>
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      fontFamily: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {title.nameTh}
                  </div>
                </Col>
              </Row>
            </div>

            <Divider className="custom-devide" />
            <Row className="w-100" gutter={[10, 10]}>
              <Col lg={7}>
                <FSelect
                  label="ผู้รับผิดชอบโครงการ"
                  name="admin"
                  rules={{ required: true }}
                  dataSource={adminList}
                  keyName={"firstName"}
                  keyName2={"lastName"}
                  keyValue={"id"}
                  onChange={handleChageFirstResponsiblePerson}
                />
              </Col>
              <Col lg={5}>
                <div
                  style={{
                    border: "solid 1px #d9d9d9",
                    borderRadius: "4px",
                    height: "50px",
                  }}
                  className="d-flex y-center x-center"
                >
                  {rowSelectLength !== 0
                    ? `การโอนย้าย ${rowSelectLength} โครงการ`
                    : `โครงการที่ถูกเลือก ${rowSelectLength}`}
                </div>
              </Col>
              <Col lg={7}>
                <FSelect
                  label="ผู้รับผิดชอบโครงการต่อ"
                  name="sendToAdmin"
                  rules={{ required: true }}
                  dataSource={filteredResponsiblePerson}
                  keyName={"firstName"}
                  keyName2={"lastName"}
                  keyValue={"id"}
                  options={{ disabled: filteredResponsiblePerson.length === 0 }}
                />
              </Col>
              <Col lg={5}>
                <Row justify="space-between">
                  <Col>
                    <Button
                      className="btn-trasfer"
                      type="primary"
                      onClick={() => form.submit()}
                      disabled={rowSelectLength === 0}
                    >
                      โอนย้าย
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="btn-gray-transfer"
                      type="primary"
                      onClick={() => {
                        handleClickAction();
                        form.resetFields();
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default HeaderProjectTransfer;

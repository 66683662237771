import { ReactNode } from "react";
import { Card, Row, Col, Button, Form, FormInstance } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Excel from "../../../../assets/img/icon/Excel.png";
import FSelect from "../../../../components/form_floatlabel/FSelect";
import { years } from "../../../../default_data/years";
import { useState } from "react";
import CloseBtnTab from "../../../../components/btn/CloseBtnTab";

type Props = {
  data: any;
  children?: ReactNode;
  handleFilter: (value: string) => void;
  handleExportExcel?: () => void;
  form: FormInstance<any> | undefined;
};

const Header = ({
  children,
  data,
  handleFilter,
  handleExportExcel,
  form,
}: Props) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const ContentHeader = () => {
    return visible ? (
        <Row className="page-heder" style={{padding: '30px 0 15px 0'}}>
          <Col
            lg={4}
            className="page-heder "
            style={{ justifyContent: "space-between", width: "195px" }}
          >
            <Form form={form}>
              <FSelect
                label={"ปี ค.ศ."}
                name={"years"}
                dataSource={years}
                keyName={"key"}
                keyValue={"key"}
                onChange={(e: any) => handleFilter(e)}
              />
            </Form>
          </Col>
          <Col>
            <div>
              <Button className="pdf-btn" onClick={handleExportExcel}>
                <img src={Excel} style={{ width: "30px" }} alt="pdf-btn" />
                <span className="pdf-btn-text">EXCEL</span>
              </Button>
            </div>
          </Col>
        </Row>
    ) : null;
  };

  return (
    <div>
      <Card className="header-setting border-header-diy-no-tab">
        <Row className="page-heder">
          <div style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
            <Row gutter={12} align="middle">
              <Col>
                <LeftOutlined style={{ fontSize: "16px" }} />
              </Col>
              <Col>
                <div
                  style={{
                    fontFamily: "SemiBold",
                    color: "#faa41a",
                    fontSize: "25px",
                  }}
                >
                  Report
                </div>
                <div
                  style={{
                    fontFamily: "SemiBold",
                    fontSize: "18px",
                  }}
                >
                  {data.item.text}
                </div>
              </Col>
            </Row>
          </div>
          <Col>
            <CloseBtnTab on={visible} onClick={() => setVisible(!visible)} />
          </Col>
        </Row>
      <ContentHeader />
      </Card>
      <div style={{ marginTop: "10px" }}>
        <Card
          style={{
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Row justify="start" align="middle">
            <div
              style={{
                fontFamily: "SemiBold",
                color: "#faa41a",
                fontSize: "25px",
              }}
            >
              REPORT
            </div>

            <div
              style={{
                fontFamily: "SemiBold",
                fontSize: "20px",
                marginLeft: "15px",
              }}
            >
              {data.item.text}
            </div>
          </Row>
        </Card>
        <Card
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          {children}
        </Card>
      </div>
    </div>
  );
};

export default Header;

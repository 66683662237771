/* eslint-disable react-hooks/exhaustive-deps */

import {
  CloseOutlined,
  LeftOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Row,
  Image,
  Divider,
  Form,
  FormInstance,
} from "antd";
import { useHistory } from "react-router-dom";
import Edit from "../../../../../assets/img/edit.png";
import FInput from "../../../../../components/form_floatlabel/FInput";
import { _isEmpty } from "@yim/util-tool-ts";
import { PDF } from "../../../../../assets/img";

interface Props {
  Action: {
    type?: string;
    name?: string;
    edit?: boolean;
    onClick?: () => void;
  }[];
  Title: {
    nameTH: string;
    nameEN: string;
  };
  handleClickEdit: (value?: any) => void;
  handleClickDelete?: (value?: any) => void;
  handleClickCancel: () => void;

  disabledEdit: boolean;
  editOnPage?: boolean;
  manageContent?: boolean;
  actionName?: string;
  onFinish?: (value: any) => void;

  fieldKey?: any[];
  form: FormInstance<any>;
}

const HeaderSetting = ({
  Action,
  Title,
  handleClickEdit,
  handleClickDelete,
  disabledEdit = false,
  handleClickCancel,
  editOnPage = false,
  manageContent = false,
  actionName = "",
  onFinish,
  fieldKey = [],
  form,
}: Props) => {
  const history = useHistory();

  const CheckTypeBtn = (value: any, index: number) => {
    const handleSubmitContentHeader = () => {
      form.submit();
    };

    return value.type === "btnPush" && !manageContent ? (
      <Button
        className="btn-gradient mb2"
        type="primary"
        onClick={() => (editOnPage ? handleClickEdit() : value.onClick())}
        key={index}
      >
        <PlusOutlined />
        <b style={{ fontSize: 15 }}>{value.name}</b>
      </Button>
    ) : value.type === "btnEdit" && disabledEdit ? (
      <Button
        className="btn-edit"
        onClick={() => handleClickEdit()}
        key={index}
      >
        <Image src={Edit} width={13} preview={false}></Image>
        <b style={{ fontSize: 15 }}>{value.name}</b>
      </Button>
    ) : value.type === "btnDel" ? (
      <Button
        className="btn-Del"
        onClick={() => handleClickDelete && handleClickDelete()}
        key={index}
      >
        <DeleteFilled style={{ fontSize: 15 }} />
        <b style={{ fontSize: 15 }}>{value.name}</b>
      </Button>
    ) : value.type === "btnExport" ? (
      <Button
        className="btn-Del"
        onClick={() => value.onClick()}
        key={index}
      >
        <img src={PDF} style={{ width: 15 }} alt="Logo" />
        <b style={{ fontSize: 15 }}>{value.name}</b>
      </Button>
    ) : (
      <Row gutter={10} key={index}>
        <Col>
          <Button
            className="btn-gray"
            type="primary"
            onClick={() => {
              handleClickCancel();
              form.resetFields();
            }}
          >
            <CloseOutlined />
            ยกเลิก
          </Button>
        </Col>
        <Col>
          <Button
            className="btn-save"
            type="primary"
            onClick={() =>
              editOnPage ? handleSubmitContentHeader() : value.onClick()
            }
          >
            <SaveOutlined />
            บันทึก
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Card className="border-header-diy ">
        <Row className="page-heder">
          <div style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
            <Row gutter={12} align="middle">
              <Col>
                <LeftOutlined style={{ fontSize: "16px", marginRight: 10 }} />
              </Col>
              <Col>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#FFC627",
                    fontSize: "25px",
                  }}
                >
                  <b>{Title.nameEN}</b>
                </div>
                <div
                  style={{
                    fontFamily: "SemiBold",
                    fontSize: "18px",
                  }}
                >
                  {Title.nameTH}
                </div>
              </Col>
            </Row>
          </div>

          <Row className={manageContent ? "d-none" : ""} gutter={10}>
            {Action.map((item, index) => (
              <Col key={index}>{CheckTypeBtn(item, index)}</Col>
            ))}
          </Row>
        </Row>
      </Card>
      {manageContent && (
        <Card className="card-round">
          <Form onFinish={onFinish} form={form}>
            <Row className="page-heder">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => history.goBack()}
              >
                <Row gutter={12} align="middle">
                  <Col>
                    <div
                      style={{
                        fontFamily: "SemiBold",
                        color: "#FFC627",
                        fontSize: "20px",
                      }}
                    >
                      <b>
                        {actionName} {Title.nameEN}
                      </b>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        fontFamily: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {Title.nameTH}
                    </div>
                  </Col>
                </Row>
              </div>
              {Action.map((item, index) => CheckTypeBtn(item, index))}
              <Divider className="custom-devide" />
              <Row className="w-100 mt-3" gutter={[14, 14]}>
                {fieldKey.map((item, idx) => {
                  return (
                    <Col
                      lg={item.col ? item.col : 24}
                      className="mb-2"
                      key={idx}
                    >
                      <FInput
                        label={item ? item.label : ""}
                        name={item ? item.key : ""}
                        rules={{ required: true }}
                        type={!_isEmpty(item.type) ? item.type : ""}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Row>
          </Form>
        </Card>
      )}
    </>
  );
};
export default HeaderSetting;

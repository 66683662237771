import { Fetch, FetchExcel } from "../../../tools/fecth/fecth";
import { apiVersion } from '../../../config/api'
import { createQueryString } from "../../../tools/util";

export const GETINTERESTREPORT_API = async (query?: any) => {
    const qrs = createQueryString(query);
    const res = await Fetch({ method: 'GET', path: `${apiVersion}/report/interested-product${qrs}` });
    return res;
}

export const INTEREST_EXCEL_API = async (query: any) => {
    const qrs = createQueryString(query);
    const res = await FetchExcel({
        method: "GET",
        path: `${apiVersion}/report/interested-product/excel${qrs}`,
    });
    return res;
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Form, Table } from "antd";
import Header from "./Header/Header";
import {
  GETREQUIREMENTREPORT_API,
  REQUIREMENT_EXCEL_API,
} from "../../../services/api/report/requirement";
import { useSetRecoilState } from "recoil";
import { loadingTable } from "../../../tools/recoil/recoil-atom";
import { AlertToast } from "../../../components/alert/alert";
import { tableLoading } from "../../../components/loading/loading";

const SubReportRequirement = () => {
  const setLoading = useSetRecoilState<boolean>(loadingTable);
  const [keyValue, setkeyValue] = useState<React.Key[]>([]);
  const [requirements, setRequirements] = useState([] as any);
  const [searchYears, setSearchYears] = useState(
    new Date().getFullYear().toString()
  );
  const [isFirstWork, setisFirstWork] = useState<boolean>(true);

  const [form] = Form.useForm();
  const currentYear = new Date();

  useEffect(() => {
    if (isFirstWork) {
      form.setFieldsValue({ years: `${currentYear.getFullYear()}` });
      handleGetRequirement();
      setisFirstWork(false);
    } else {
      handleGetRequirement();
    }
    return undefined;

  }, [searchYears]);

  const handleGetRequirement = async () => {
    try {
      const res = await GETREQUIREMENTREPORT_API({
        year: searchYears,
      });
      if (res.code === 200) {
        toFormatTable(res.result[0].item);
      }
    } catch (error) {}
  };

  const handleExportExcel = async () => {
    setLoading(true);
    try {
      const res = await REQUIREMENT_EXCEL_API({
        year: searchYears,
      });
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `requirement.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        AlertToast({
          icon: "error",
          title: `ผิดพลาด! ไม่สามารถส่งออกข้อมูลได้`,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const toFormatTable = (itemList: any) => {
    const requirementDataFormat = itemList.map((item: any, idMain: number) => {
      let mainObj = {};
      item?.header?.subHeader.length > 0
        ? item?.header?.subHeader?.forEach((header: any, idx: number) => {
            mainObj = {
              key: `${idMain + 1}`,
              name: `${idMain + 1}. ${item?.name}`,
              avr: item?.header?.average?.toFixed(2),
              type: item?.type,
              ...mainObj,
              [header?.label]: header?.count,
            };
          })
        : (mainObj = {
            key: `${idMain + 1}`,
            name: `${idMain + 1}. ${item?.name}`,
            avr: item?.header?.average?.toFixed(2),
            type: item?.type,
            ...mainObj,
          });
      let childrenList: any[] = [];

      let children = {};

      if (item.subHeader.data !== null) {
        item.subHeader.data.forEach((subHeader: any, idChild: number) => {
          children = {
            name: subHeader.name,
            key: `children${idMain + 1}${idChild + 1}`,
          };
          subHeader.subData.forEach((child: any) => {
            children = {
              ...children,
              [child.label]: child.count,
              avr: subHeader.average.toFixed(2),
            };
          });
          childrenList = [...childrenList, children];
        });
      } else {
        children = {};
        let subChildren = {};

        item.children.forEach((subHeader: any) => {
          subHeader.forEach((subHeaderMain: any, subHeaderMainId: number) => {
            children = {
              name: subHeaderMain.name,
              key: `children${idMain + 1}${subHeaderMainId + 1}`,
            };

            subHeaderMain.header.subHeader.forEach((child: any) => {
              children = {
                ...children,
                [child.label]: child.count,
                avr: subHeaderMain.header.average.toFixed(2),
              };
            });

            let subChildrenList: any[] = [];
            subHeaderMain.subHeader.data.forEach(
              (subChild: any, subChildId: number) => {
                subChildren = {
                  name: subChild.name,
                  key: `sub_children${idMain + 1}${subHeaderMainId + 1}${
                    subChildId + 1
                  }`,
                };
                subChild.subData.forEach((subChildData: any) => {
                  subChildren = {
                    ...subChildren,

                    [subChildData.label]: subChildData.count,
                    avr: subChild.average.toFixed(2),
                  };
                });
                subChildrenList = [...subChildrenList, subChildren];
              }
            );
            children = {
              ...children,
              children: [...subChildrenList],
            };
            childrenList = [...childrenList, children];
          });
        });
      }
      if (childrenList.length > 0) {
        mainObj = { ...mainObj, children: [...childrenList] };
      }
      return mainObj;
    });
    setRequirements(requirementDataFormat);
  };

  const filterCoulumnToCurrentMount = (columns: any) => {
    const dateTime = new Date();
    const currentMount = dateTime.getMonth();
    const toCurrentMount =
      Number(searchYears) === dateTime.getFullYear()
        ? columns.filter(
            (item: any) =>
              item.key === "key" ||
              item.key === "name" ||
              item.key === "type" ||
              item.key === "avr" ||
              Number(item.key) <= currentMount + 1
          )
        : columns;
    return toCurrentMount;
  };

  const columns = [
    {
      title: "Requirement",
      dataIndex: "name",
      key: "name",
      fixed: true,
      width: 450,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      fixed: true,
      width: 150,
      onCell: (value: any, _index: any) => {
        if (
          value.key === "4" &&
          keyValue?.find((e) => e === "4") &&
          keyValue?.find((e) => e === "children41") &&
          keyValue?.find((e) => e === "children42") &&
          keyValue?.find((e) => e === "children43")
        ) {
          return { colSpan: 1, rowSpan: 11 };
        } else if (
          value.key === "4" &&
          keyValue?.find((e) => e === "4") &&
          keyValue?.find((e) => e === "children41") &&
          keyValue?.find((e) => e === "children42")
        ) {
          return { colSpan: 1, rowSpan: 10 };
        } else if (
          value.key === "4" &&
          keyValue?.find((e) => e === "4") &&
          keyValue?.find((e) => e === "children41") &&
          keyValue?.find((e) => e === "children43")
        ) {
          return { colSpan: 1, rowSpan: 9 };
        } else if (
          value.key === "4" &&
          keyValue?.find((e) => e === "4") &&
          keyValue?.find((e) => e === "children42") &&
          keyValue?.find((e) => e === "children43")
        ) {
          return { colSpan: 1, rowSpan: 7 };
        } else if (
          value.key === "4" &&
          keyValue?.find((e) => e === "4") &&
          keyValue?.find((e) => e === "children41")
        ) {
          return { colSpan: 1, rowSpan: 8 };
        } else if (
          value.key === "4" &&
          keyValue?.find((e) => e === "4") &&
          keyValue?.find((e) => e === "children42")
        ) {
          return { colSpan: 1, rowSpan: 6 };
        } else if (
          value.key === "4" &&
          keyValue?.find((e) => e === "4") &&
          keyValue?.find((e) => e === "children43")
        ) {
          return { colSpan: 1, rowSpan: 5 };
        } else if (keyValue?.find((e) => e === value.key) && value.type) {
          return { colSpan: 1, rowSpan: value?.children?.length + 1 || 1 };
        } else {
          return value.type ? { colSpan: 1 } : { colSpan: 0 };
        }
      },
      align: "center" as "center",
    },
    {
      title: "ม.ค.",
      dataIndex: "1",
      key: "1",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "ก.พ.",
      dataIndex: "2",
      key: "2",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "มี.ค.",
      dataIndex: "3",
      key: "3",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "เม.ย.",
      dataIndex: "4",
      key: "4",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "พ.ค.",
      dataIndex: "5",
      key: "5",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "มิ.ย.",
      dataIndex: "6",
      key: "6",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "ก.ค.",
      dataIndex: "7",
      key: "7",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "ส.ค.",
      dataIndex: "8",
      key: "8",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "ก.ย.",
      dataIndex: "9",
      key: "9",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "ต.ค.",
      dataIndex: "10",
      key: "10",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "พ.ย.",
      dataIndex: "11",
      key: "11",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "ธ.ค.",
      dataIndex: "12",
      key: "12",
      width: 80,
      align: "center" as "center",
    },
    {
      title: "ค่าเฉลี่ย",
      dataIndex: "avr",
      key: "avr",
      width: 90,
      align: "center" as "center",
    },
  ];

  const handleFilter = (value: string) => {
    setSearchYears(value);
  };
  return (
    <div>
      <Header
        form={form}
        handleFilter={handleFilter}
        handleExportExcel={handleExportExcel}
        data={{
          item: {
            key: 1,
            text: "รายงานสรุปยอดรวมผู้ที่ติดต่อเข้ามาแยกตามประเภท Requirement (หน่วย : เคส)",
          },
        }}
      >
        <Table
          rowKey={(row) => row.key}
          style={{ marginTop: "20px" }}
          scroll={{ y: 500 }}
          loading={tableLoading()}
          dataSource={requirements}
          columns={filterCoulumnToCurrentMount(columns)}
          bordered
          expandRowByClick
          onExpandedRowsChange={(item) => {
            setkeyValue([...item]);
          }}
        />
      </Header>
    </div>
  );
};

export default SubReportRequirement;

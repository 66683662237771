//////////////////////// One Data List ///////////////////
export const list_11_action = [
  { id: "1.1-Inbound", value: "สอบถาม" },
  { id: "1.1-Outbound", value: "นำเสนอ" },
];

export const list_12_action = [
  { id: "1.2-Inbound", value: "เช็ค" },
  { id: "1.2-Outbound", value: "เสนอขาย" },
];

export const list_13_action = [
  { id: "1.3-Inbound", value: "สอบถาม" },
  { id: "1.3-Outbound", value: "แจ้ง" },
];

export const list_14_action = [
  { id: "1.4-Inbound", value: "สอบถาม" },
  { id: "1.4-Outbound", value: "แจ้ง" },
];

export const list_11_sub = [
  { id: "1.1.1", value: "งานสถาปัตยกรรม" },
  { id: "1.1.2", value: "งานอุตสาหกรรม" },
];

export const list_16_sub = [
  { id: "1.6.1", value: "Standard" },
  { id: "1.6.2", value: "Visionary" },
  { id: "1.6.3", value: "OEM (อุตสาหกรรม)" },
  { id: "1.6.4", value: "OEM (สถาปัตยกรรม)" },
  { id: "1.6.5", value: "อุปกรณ์" },
  { id: "1.6.6", value: "Pre-fab" },
];

//////////////////////// Two Data List ///////////////////
export const list_21_action = [
  { id: "2.1-Inbound", value: "ติดตาม" },
  { id: "2.1-Outbound", value: "แจ้ง" },
];

export const list_22_action = [
  { id: "2.2-Inbound", value: "ติดตาม" },
  { id: "2.2-Outbound", value: "แจ้ง" },
];

export const list_23_action = [
  { id: "2.3-Inbound", value: "ติดตาม" },
  { id: "2.3-Outbound", value: "แจ้ง" },
];

//////////////////////// Third Data List ///////////////////
export const list_31_action = [
  { id: "3.1-Inbound", value: "แจ้ง" },
  { id: "3.1-Outbound", value: "ชี้แจง" },
];

export const list_32_action = [
  { id: "3.2-Inbound", value: "แจ้ง" },
  { id: "3.2-Outbound", value: "ชี้แจง" },
];

export const list_33_action = [
  { id: "3.3-Inbound", value: "แจ้ง" },
  { id: "3.3-Outbound", value: "ชี้แจง" },
];

export const list_31_sub = [
  { id: "3.1.1", value: "ขนาด รูปร่าง รูปทรง" },
  { id: "3.1.2", value: "พื้นผิวและสีอลูมิเนียม" },
  { id: "3.1.3", value: "น้ำหนัก" },
  { id: "3.1.4", value: "ความหนา" },
  { id: "3.1.5", value: "สติ๊กเกอร์ หีบห่อ บรรจุภัณฑ์" },
  { id: "3.1.6", value: "เรื่องอื่นๆ" },
];

export const list_32_sub = [
  { id: "3.2.1", value: "ส่งสินค้าผิดรายการ" },
  { id: "3.2.2", value: "สถานที่ไม่ถูกต้อง" },
  { id: "3.2.3", value: "ไม่ตรงต่อเวลา" },
  { id: "3.2.4", value: "จำนวนไม่ครบ" },
  { id: "3.2.5", value: "การบริการของพนักงานจัดส่ง" },
];

export const list_33_sub = [
  { id: "3.3.1", value: "ใบยืนยันการสั่งซื้อ" },
  { id: "3.3.2", value: "ใบเสนอราคา" },
  { id: "3.3.3", value: "ใบส่งสินค้า" },
  { id: "3.3.4", value: "ใบกำกับภาษี" },
  { id: "3.3.5", value: "ใบวางบิล" },
  { id: "3.3.6", value: "ใบลดหนี้" },
];

//////////////////////// Four Data List ///////////////////
export const list_41_action = [
  { id: "4.1-Inbound", value: "สอบถาม" },
  { id: "4.1-Outbound", value: "แจ้ง" },
];

export const list_42_action = [
  { id: "4.2-Inbound", value: "สอบถาม" },
  { id: "4.2-Outbound", value: "แจ้ง" },
];

export const list_43_action = [
  { id: "4.3-Inbound", value: "สอบถาม" },
  { id: "4.3-Outbound", value: "แจ้ง" },
];

//////////////////////// Five Data List ///////////////////
export const list_51_action = [
  { id: "5.1-Inbound", value: "แจ้ง" },
  { id: "5.1-Outbound", value: "สอบถาม" },
];

export const list_52_action = [
  { id: "5.2-Inbound", value: "แจ้ง" },
  { id: "5.2-Outbound", value: "สอบถาม" },
];

export const list_53_action = [
  { id: "5.3-Inbound", value: "แจ้ง" },
  { id: "5.3-Outbound", value: "สอบถาม" },
];

export const list_54_action = [
  { id: "5.4-Inbound", value: "แจ้ง" },
  { id: "5.4-Outbound", value: "สอบถาม" },
];

export const mixList: {[p: string]: any} = {
  list_11_action:list_11_action, 
  list_12_action:list_12_action,
  list_13_action:list_13_action,
  list_14_action:list_14_action,
  list_11_sub:list_11_sub,
  list_16_sub:list_16_sub,
  list_21_action:list_21_action,
  list_22_action:list_22_action,
  list_23_action:list_23_action,
  list_31_action:list_31_action,
  list_32_action:list_32_action,
  list_33_action:list_33_action,
  list_31_sub:list_31_sub,
  list_32_sub:list_32_sub,
  list_33_sub:list_33_sub,
  list_41_action:list_41_action,
  list_42_action:list_42_action,
  list_43_action:list_43_action,
  list_51_action:list_51_action,
  list_52_action:list_52_action,
  list_53_action:list_53_action,
  list_54_action:list_54_action,
};
/////////////////////////////// Other/////////////////////////////
